import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import numeral from "numeral";

const InputTableNumber = ({
  name,
  decimalScale = "2",
  defaultValue = 0,
  handleBlur = () => "void",
  ...rest
}) => {
  const { control, errors } = useFormContext();
  let ni1 = name.indexOf("[");
  let ni2 = name.indexOf("]");
  let arr = name.substring(0, ni1);
  const idx = parseInt(name.substring(ni1 + 1, ni2), 10);
  const item = name.substring(ni2 + 2);
  const err = errors[arr] && errors[arr][idx] ? errors[arr][idx][item] : null;

  // console.log(errors, err);
  const error = err ? err.message : null;
  // const error = errors[name] ? errors[name].message : null;

  const updateBlur = (value, onBlur) => {
    handleBlur(value);
    value = numeral(value).value();
    onBlur();
  };

  return (
    <div className="form-group" style={{ marginBottom: "0px" }}>
      <div>
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          render={({ onChange, onBlur, value }) => (
            <NumberFormat
              id={name}
              className="form-control text-right"
              thousandSeparator={true}
              value={value}
              // isNumericString={true}
              fixedDecimalScale={true}
              decimalScale={decimalScale}
              onChange={onChange}
              onBlur={() => updateBlur(value, onBlur)}
              {...rest}
            />
          )}
        />
      </div>

      <small className="text-danger">{error}</small>
    </div>
  );
};

export default InputTableNumber;
