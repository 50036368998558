import React, { useState, useEffect, useCallback } from "react";
import Loader from "../common/LocalLoader";
// import JobDisplayPDF from "./JobDisplayPDF";
import PdfView from "../common/PdfView";
import { getMimeType } from "../../utils/getFileType";
import { blobFileUrl } from "../../utils/blobFileUrl";
import {
  // uploadFile,
  listFiles,
  download,
} from "../../services/digitalOceanService";
// import { checkFileExtension, fileTypes } from "../../utils/fileExtensions";
// import LiveToast from "../../utils/liveToast";
// import { compressImage, compressPdf } from "../../utils/fileCompressor";
import FileListItem from "../common/files/FileListItem";
import FileListHeader from "../common/files/FileListHeader";
import FileListWrapper from "../common/files/FileListWrapper";
import FileUpload from "../common/files/FileUpload";

function ItemFiles(props) {
  const { folderName, title } = props;
  const [files, setFiles] = useState([]);
  const [folder, setFolder] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [showFiles, setShowFiles] = useState(false);
  const [viewFile, setViewFile] = useState({ name: "", pdfFile: null });

  const listFilesAsyncCall = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await listFiles("Items", folder);
      setFiles(data);
    } catch (error) {
      console.log("ERROR LISTING FILES", error);
    }
    setLoading(false);
  }, [folder]);

  useEffect(() => {
    setFolder(folderName);
  }, [folderName]);

  const handleDisplayFile = async (id, fileName, downloadUrl) => {
    setFileLoading(true);
    setViewFile({ name: "", pdfFile: null });

    const mimetype = getMimeType(fileName);

    const downloadedFile = await download(id);
    const fileUrl = blobFileUrl(downloadedFile, mimetype);

    const pdf = {
      name: fileName,
      pdfFile: fileUrl,
      downloadUrl: downloadUrl,
    };

    setViewFile(pdf);
    setFileLoading(false);
  };

  const handleOpenInNewTab = async (id, fileName) => {
    setFileLoading(true);
    setViewFile({ name: "", pdfFile: null });

    const mimetype = getMimeType(fileName);

    const downloadedFile = await download(id);
    const fileUrl = blobFileUrl(downloadedFile, mimetype);

    const newWindow = window.open(fileUrl, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;

    const pdf = {
      name: fileName,
      pdfFile: fileUrl,
    };

    setViewFile(pdf);
    setFileLoading(false);
  };

  const handleClose = () => {
    setViewFile({ name: "", pdfFile: null });
  };

  const handleShowFilesClick = () => {
    if (showFiles) {
      setShowFiles(false);
    } else {
      listFilesAsyncCall();
      setShowFiles(true);
    }
  };

  return (
    <div>
      <h5 className="text-uppercase">{title}</h5>
      <FileListWrapper>
        <FileListHeader
          title="Current Folder:"
          folder={folder}
          showFiles={showFiles}
          onShowFiles={handleShowFilesClick}
        />

        <div hidden={!showFiles}>
          <hr />
          {loading && <Loader />}
          <ul hidden={!showFiles}>
            {files.map((file) => {
              return (
                <FileListItem
                  key={file.id}
                  file={file}
                  viewId="DisplayPdfModal"
                  displayFile={handleDisplayFile}
                  openFileInTab={handleOpenInNewTab}
                />
              );
            })}
          </ul>
        </div>
        <FileUpload
          category={"Items"}
          folder={folder}
          numberOfFiles={3}
          // checkExt={false}
        />
      </FileListWrapper>
      <PdfView
        id="DisplayPdfModal"
        viewId="DisplayPdfModal"
        title={props.title ? props.title : "View PDF"}
        pdfFile={viewFile && viewFile.pdfFile}
        fileLoading={fileLoading}
        onClose={handleClose}
        // pdfFile={props.pdfFile}
        // {...props}
      />
      {/* <JobDisplayPDF
        viewId="JobDisplayPdfModal"
        title={viewFile && viewFile.name}
        pdfFile={viewFile && viewFile.pdfFile}
        fileLoading={fileLoading}
        onClose={handleClose}
      /> */}
    </div>
  );
}

export default ItemFiles;
