import React from "react";
import DateFormat from "./dateFormat";
import NumberFormat from "react-number-format";
// import numeral from numeral;

export const CellCenter = (props) => (
  <div className="text-center">{props.children}</div>
);

export const CellRight = (props) => (
  <div className="text-right">{props.children}</div>
);

export const CellLeft = (props) => (
  <div className="text-left">{props.children}</div>
);

export const CellIndent = (props) => (
  <div className="ml-4">{props.children}</div>
);

export const CellCurrency = (props) => (
  <CellRight>
    {props.value === 0 ? (
      "-"
    ) : (
      <NumberFormat
        value={props.value}
        displayType="text"
        thousandSeparator={true}
        fixedDecimalScale={true}
        decimalScale={2}
        // decimalScale={props.decimalScale || "0"}
        prefix={"$"}
      />
    )}
  </CellRight>
);

// export const CellInteger = props => (
//   <CellRight>
//     {props.value === 0 ? "-" : numberFormat(props.value, 0)}
//   </CellRight>
// );
export const CellInteger = (props) => (
  <CellCenter>
    {props.value === 0 ? (
      "-"
    ) : (
      <NumberFormat
        value={props.value}
        displayType="text"
        thousandSeparator={true}
        decimalScale="0"
      />
    )}
  </CellCenter>
);

export const CellDecimal2 = (props) => (
  <CellCenter>
    {props.value === 0 ? (
      "-"
    ) : (
      <NumberFormat
        value={props.value}
        displayType="text"
        thousandSeparator={true}
        decimalScale="2"
      />
    )}
  </CellCenter>
);

export const CellNumber = (props) => (
  <NumberFormat
    value={props.value}
    displayType="text"
    thousandSeparator={true}
    fixedDecimalScale={true}
    decimalScale="2"
  />
);

export const CellPercent = (props) => (
  <CellCenter>
    {isNaN(props.value) ? "-" : parseFloat(props.value * 100).toFixed(2) + "%"}
  </CellCenter>
);

export const CellDate = (value, ...props) => (
  <DateFormat value={value} {...props} />
);
