import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// import withFormMode from "../common/withFormMode";
import Panel from "../common/panel";
import { RowFourColumns, RowTwoColumns } from "../common/formLayout";
import Field from "../common/fieldText";
import { populateSetting, setPreferences } from "../../services/settingService";

const Preferences = () => {
  const [prefs, setPrefs] = useState({});

  useEffect(() => {
    async function populatePrefs() {
      try {
        const { data } = await populateSetting("sales");
        setPreferences(data.details);
        setPrefs(data.details);
      } catch (ex) {
        if (ex.response && ex.response.status === 404)
          window.location.href = "/settings/sales/edit";
      }
    }

    populatePrefs();
  }, []);

  const version = `Version: ${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`;

  return (
    <div>
      <h2>Preferences</h2>

      <hr />
      <Panel>
        <h5 className="card-title"> Currencies</h5>
        <RowTwoColumns
          colOne={
            <h6>
              Multiple currencies: {prefs.isMultiCurrency ? "Yes" : "Disabled"}
            </h6>
          }
        />
        <RowFourColumns
          colOne={
            <Field
              name="homeCurrency"
              label="Home currency"
              value={prefs.homeCurrency}
            />
          }
          colTwo={
            <Field
              name="xRateToUSD"
              label="Exchange rate to USD"
              value={prefs.xRateToUSD}
            />
          }
          colThree={
            <Field
              name="xRateToEUR"
              label="Exchange rate to EUR"
              value={prefs.xRateToEUR}
            />
          }
          colFour={
            <Field
              name="xRateToGBR"
              label="Exchange rate to GBR"
              value={prefs.xRateToGBR}
            />
          }
        />
        <hr />
        <h5 className="card-title">Company files</h5>
        <RowTwoColumns
          colOne={
            <Field
              name="localPath"
              label="Local path"
              value={prefs.localPath}
            />
          }
          colTwo={
            <Field name="urlPath" label="URL path" value={prefs.urlPath} />
          }
        />
        <RowTwoColumns
          colOne={
            <Field
              name="salesFolder"
              label="Sales folder"
              value={prefs.salesFolder}
            />
          }
        />
        <hr />
        <Link
          to={`/settings/sales/edit`}
          className="btn btn-secondary shadow-sm"
        >
          Edit...
        </Link>
      </Panel>
      <small>{version}</small>
    </div>
  );
};

export default Preferences;
