import React, { useEffect, useState, useMemo, useCallback } from "react";
import withLoading from "../common/withLoading";
import DatePicker from "react-datepicker";
import PanelTabs from "../common/PanelTabs";
import ConsignmentTable from "./ConsignmentTable";
import { getStorages } from "../../services/entityService";
import { getInventoryByLocation } from "../../services/reportService";
import { toast } from "react-toastify";
import _ from "lodash";
import numeral from "numeral";

const Consignment = (props) => {
  const [items, setItems] = useState([]);
  const [storages, setStorages] = useState([]);
  const [selectedStorage, setSelectedStorage] = useState(null);
  const [toDate, setToDate] = useState(new Date().toISOString());
  const [sortColumn, setSortComun] = useState({ path: "name", order: "asc" });

  useEffect(() => {
    const populateStorages = async () => {
      const { data } = await getStorages("?consignment=true");
      const mapped = data.map((s) => {
        return {
          value: s._id,
          label: s.title,
          data: s.data,
          costRatio: 0.8,
          currency: "USD",
        };
      });
      setStorages(mapped);
      if (mapped.length > 0) setSelectedStorage(mapped[0]);
    };
    populateStorages();
    props.loadingOff();

    return () => {
      setStorages([]);
    };
  }, []);

  const populateItems = async () => {
    props.loadingOn();
    const query = `?id=${selectedStorage.value}&toDate=${toDate}`;
    try {
      const { data } = await getInventoryByLocation(query);
      setItems(data);
      props.loadingOff();
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      props.loadingOff();
    }
  };

  useEffect(() => {
    if (!selectedStorage) setItems([]);

    if (selectedStorage) populateItems();

    return () => {
      setItems([]);
    };
  }, [selectedStorage, toDate]);

  const totals = useMemo(() => {
    const value = items.reduce((sum, i) => {
      return (
        sum +
        numeral(i.unitPrice)
          .multiply(selectedStorage?.costRatio ?? 1)
          .multiply(i.balance)
          .value()
      );
    }, 0);
    const salesValue = items.reduce((sum, i) => {
      return sum + numeral(i.unitPrice).multiply(i.balance).value();
    }, 0);
    return { value, salesValue };
  }, [items]);

  return (
    <div className="container-fluid px-5 mt-3">
      <div className="d-flex pt-2 pb-1 mb-2 border-bottom">
        <h2 className="text-uppercase">Consignment Goods</h2>
      </div>
      <div className="mb-1 pb-2">
        <PanelTabs
          tabs={storages}
          selected={selectedStorage}
          onSelectTab={(selected) => setSelectedStorage(selected)}
        />
      </div>
      <div className="d-flex align-items-baseline">
        <h5 className="px-1">As of</h5>
        <div className="form-group col-2">
          <DatePicker
            className="form-control"
            dateFormat="yyyy-MM-dd"
            selected={new Date(toDate)}
            onChange={(value) => {
              setToDate(value.toISOString());
            }}
          />
        </div>
        <h5>Currency: {selectedStorage?.currency ?? "CAD"} </h5>
      </div>
      <div className="d-flex py-2">
        <ConsignmentTable
          storage={selectedStorage}
          items={items}
          sortColumn={sortColumn}
          onSort={(sortColumn) => setSortComun(sortColumn)}
          totals={totals}
        />
      </div>
    </div>
  );
};

export default withLoading(Consignment);
