// import { sanitizeNumberString } from "./sanitize";
// import { numberFormat } from "./numberFormat";
import _ from "lodash";

export const validateNumber = (value) => {
  let error = null;

  if (!/^-?\d*([,.]\d*)*?([+*/-]\d*)*?([,.]\d*)*?$/.test(value.toString())) {
    error = "Not a number or formula";
  }

  return error;
};

export const sanitizeNumberString = (value) => {
  return value
    .toString()
    .replace(/[^0-9 //*+ .-]/g, "")
    .replace(/(\d*\.)([\d.]+)/g, (_, g1, g2) => g1 + g2.replace(/\./g, ""))
    .replace(/([-+/*]){2,}/g, "$1")
    .trim();
};

export const numberFormat = (value, decimals = 2, grouping = true) => {
  const locale = "en-CA";
  return new Intl.NumberFormat(locale, {
    style: "decimal",
    useGrouping: grouping,
    minimumFractionDigits: decimals,
  }).format(parseFloat(value));
};

export const numberInteger = (value, grouping = true) => {
  const locale = "en-CA";
  return new Intl.NumberFormat(locale, {
    style: "decimal",
    useGrouping: grouping,
    maximumFractionDigits: 0,
  }).format(parseFloat(value));
};

export const marginRatio = (value, costs) => {
  let result = _.divide(_.subtract(value - costs), value);
  if (isFinite(result)) return result;
  return 0;
};
