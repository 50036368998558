import React, { useState } from "react";
import { toast } from "react-toastify";
import Loader from "../common/LocalLoader";
import { forgotPassword } from "../../services/loginApiService";

const AuthForgotPassword = (props) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = ({ currentTarget: input }) => {
    setEmail(input.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (email === "") return;
    setLoading(true);
    try {
      await forgotPassword(email);
      toast.success("The recovery email was sent");
      setLoading(false);
      props.history.replace("/auth/login");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="card-header">
        <h5>Forgot Password</h5>
      </div>
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="email"
              name="email"
              id="email"
              aria-describedby="emailHelp"
              placeholder="Enter recovery email.. ."
              className="form-control"
              value={email}
              onChange={handleChange}
              autoFocus
              required
              // error={errors["username"]}
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary mb-2 shadow-sm"
            disabled={loading}
          >
            Send request
          </button>
          {loading && <Loader />}
        </form>
      </div>
    </div>
  );
};

export default AuthForgotPassword;
