import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";
import TableTotals from "./tableTotals";

const Table = ({
  columns,
  sortColumn,
  onSort,
  data,
  totals,
  includeTotals
}) => {
  return (
    <table className="table table-sm table-font-sm table-hover border-bottom">
      <TableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} />
      <TableBody columns={columns} data={data} />
      {includeTotals && <TableTotals columns={columns} totals={totals} />}
    </table>
  );
};

Table.defaultProps = {
  sortColumn: { path: "", order: "asc" },
  includeTotals: false,
  totals: {},
  onSort: () => {}
};

export default Table;
