// re-write this service
import qboApi from "../../services/qboApiService";

const apiEndpoint = qboApi.apiUrl + "/qboExchange";

// const apiEndpoint = "/qboExchange";

function typeUrl(entity) {
  return `${apiEndpoint}/${entity}`;
}

function entityUrl(entity, id) {
  return `${apiEndpoint}/${entity}/${id}`;
}

export function getQboEntities(entity, query) {
  return qboApi.get(typeUrl(entity), query);
}

export function qboEntityCount(entity) {
  return qboApi.get(typeUrl(entity) + "/count");
}

export function getExportToQbo(entity) {
  return qboApi.get(typeUrl(entity) + "/export-to-qbo");
}

export function qboEntityLastUpdated(entity) {
  return qboApi.get(typeUrl(entity) + "/lastupdated");
}

export function findQboEntity(entity, query) {
  return qboApi.get(typeUrl(entity) + "/find" + query);
}

export function saveQboEntity(entity, item) {
  if (item._id) {
    const body = { ...item };
    delete body._id;
    return qboApi.put(entityUrl(entity, item._id), body);
  }
  return qboApi.post(typeUrl(entity), item);
}

export function batchQboCreate(entity, batchRequest) {
  return qboApi.post(typeUrl(entity) + "/batchCreate", batchRequest);
}

export function batchQboUpdate(entity, batchRequest) {
  return qboApi.post(typeUrl(entity) + "/batchUpdate", batchRequest);
}

// export function deleteQbdItem(entity, id) {
//   return qboApi.delete(entityUrl(entity, id));
// }
