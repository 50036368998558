import { toast } from "react-toastify";

export default class LiveToast {
  constructor(message, type = toast.TYPE.INFO, autoClose = 5000) {
    this.id = toast.info(message);
    this.autoClose = autoClose;
  }

  success(message) {
    toast.update(this.id, {
      render: message,
      type: toast.TYPE.SUCCESS,
      autoClose: this.autoClose,
    });
  }

  error(message) {
    toast.update(this.id, {
      render: message,
      type: toast.TYPE.ERROR,
      autoClose: this.autoClose,
    });
  }

  info(message) {
    toast.update(this.id, {
      render: message,
      type: toast.TYPE.SUCCESS,
      autoClose: this.autoClose,
    });
  }
}
