import React from "react";
import Loader from "./loader";
import ProgressModal from "./progressModal";

function withLoading(Component) {
  return class WithLoading extends React.Component {
    state = { 
      loading: true,
      showProgress: false,
      progress: { title: "Started...", value: 0, total: 100 },
     };

    loadingOff = () => this.setState({ loading: false });
    loadingOn = () => this.setState({ loading: true });
    progressOn = () => this.setState({ showProgress: true });;
    progressUpdate = (title= "Working on...", value = 33, total=100) => {
      let updated = {
        title: title,
        value: value,
        total: total
      }
      this.setState({ progress: updated });      
    }
    progressOff = () => {
      let progress = { title: "Started...", value: 0, total: 100 };
      this.setState({ progress, showProgress: false });
    };

    render() {
      const { loading, showProgress, progress} = this.state;
      return (
        <div className="row" style={{ marginLeft: "0px", marginRight: "0px" }}>
          <Component
            {...this.props}
            loadingOn={this.loadingOn}
            loadingOff={this.loadingOff}
            progressOn={this.progressOn}
            progressOff={this.progressOff}
            progressUpdate={this.progressUpdate}
          />
          {loading && <Loader />}
          {showProgress && <ProgressModal 
              title={progress.title}
              value={progress.value}
              total={progress.total}
          />}
        </div>
      );
    }
  };
}

export default withLoading;

