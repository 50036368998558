import React, { Component } from "react";
import withLoading from "../common/withLoading";
// import { DateTime } from "luxon";
import { toast } from "react-toastify";
import { getInventories } from "./../../services/inventoryService";
import { getEntities } from "../../services/entityService";
import InventoryToPartsTable from "./InventoryToPartsTable";
import _ from "lodash";
import numeral from "numeral";

class InventoryToParts extends Component {
  state = {
    inventories: [],
    parts: [],
    searchQuery: "",
    sortColumn: { path: "name", order: "asc" },
  };

  populateInventories = async () => {
    try {
      const { data } = await getInventories();
      const filtered = data.filter((inv) => inv.type === "Raw Materials");
      const inventories = filtered.map((i) => {
        i.inStock = _.sumBy(i.current, "qty");
        i.reserved = _.sumBy(i.reservations, "qty");
        i.ordered = _.sumBy(i.orders, "qty");
        i.available = numeral(i.inStock - i.reserved).format("0,0.0");
        return i;
      });
      //   const uniqTypes = _.uniqBy(inventories, "type");
      //   const mapped = uniqTypes.map((t) => ({ value: t.type, label: t.type }));
      //   const types = [{ value: "", label: "All inventories" }, ...mapped];

      this.setState({ inventories });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  populateParts = async () => {
    try {
      const { data } = await getEntities("Item");
      let itemsWithParts = data.filter((i) => i.data && i.data.parts);
      let allParts = [];
      itemsWithParts.forEach((item) => {
        item.data.parts.forEach((p) => {
          p.inventory = p.item;
          p.item = { value: item._id, label: item.title };
          p.qtyPart = numeral(p.qty).value();
          p.uom = item.data.uom;
          allParts.push(p);
        });
      });

      const parts = allParts;
      this.setState({ parts });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  async componentDidMount() {
    await this.populateInventories();
    await this.populateParts();
    this.props.loadingOff();
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
    });
  };

  getData = () => {
    const { sortColumn, searchQuery, inventories, parts } = this.state;

    const mapped = inventories.map((inv) => {
      const invParts = parts.filter((p) => p.inventory.value === inv.itemId);
      const calcs = invParts.map((i) => {
        const qty = numeral(inv.available).value() / i.qtyPart;
        i.qty = numeral(qty).format(0, 0.0);
        return i;
      });
      inv.parts = calcs;
      return inv;
    });

    let filtered = mapped;

    if (searchQuery)
      filtered = mapped.filter((o) =>
        o.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    return { totalCount: filtered.length, data: sorted };
  };

  render() {
    const { sortColumn } = this.state;
    const { data: items } = this.getData();

    return (
      <div className="container-fluid px-5 mt-3">
        <h2 className="text-uppercase">Inventory to Parts</h2>
        <div className="d-flex py-2">
          <InventoryToPartsTable
            items={items}
            sortColumn={sortColumn}
            onSort={this.handleSort}
          />
        </div>
      </div>
    );
  }
}

export default withLoading(InventoryToParts);
