import React from "react";

function SidebarLeft({ children }) {
  return (
    <div id="sidebar-left" className="d-print-none col-md-2 pt-3">
      {children}
    </div>
  );
}

export default SidebarLeft;
