import React, { Component } from "react";
// import auth from "../services/authService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "../common/table";
import { Link } from "react-router-dom";
import DateFormat from "../common/dateFormat";
import { camelPad } from "./../../utils/utils";
// import { inventoryToTextArea } from "./../../utils/inventoryUtils";
// import { numberInteger } from "../../utils/numberUtils";

class DocumentTable extends Component {
  tableColumns = [
    {
      path: "entity",
      label: "Transaction Type",
      className: "text-center",
      content: (document) => camelPad(document.entity),
    },
    {
      path: "data.txnNumber",
      label: "Doc Number",
      className: "text-center",
      content: (document) => (
        <Link to={`/documents/${document._id}`}>
          {document.data && document.data.txnNumber}
        </Link>
      ),
    },
    {
      path: "data.txnDate",
      label: "Date",
      content: (document) => (
        <DateFormat value={document.data && document.data.txnDate} />
      ),
    },

    // {
    //   path: "records",
    //   label: "Inventory",
    //   className: "w-25",
    //   content: (document) => (
    //     <div style={{ whiteSpace: "pre-wrap" }}>
    //       {inventoryToTextArea(document.records)}
    //     </div>
    //   ),
    // },
    {
      path: "data.memo",
      label: "Memo",
      className: "w-25",
      content: (document) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {document.data && document.data.memo}
        </div>
      ),
    },
    {
      path: "data.locationFrom.label",
      label: "From",
      className: "text-center",
    },
    {
      path: "data.locationTo.label",
      label: "To",
      className: "text-center",
    },
    {
      key: "controls",
      content: (document) => (
        <div className="text-right d-print-none">
          <button
            onClick={() => this.props.onClick(document)}
            className="btn btn-secondary btn-sm mr-1"
          >
            <FontAwesomeIcon icon="edit" />
          </button>
          <button
            onClick={() => this.props.onDelete(document)}
            className="btn btn-danger btn-sm"
          >
            <FontAwesomeIcon icon="trash" />
          </button>
        </div>
      ),
    },
  ];

  listColumns = [
    {
      path: "data.txnNumber",
      label: "Doc Number",
      className: "text-center",
      content: (document) => (
        <Link to={`/documents/${document._id}`}>
          {document.data && document.data.txnNumber}
        </Link>
      ),
    },
    {
      path: "data.txnDate",
      label: "Name",
      content: (document) => (
        <DateFormat value={document.data && document.data.txnDate} />
      ),
    },
    {
      path: "entity",
      label: "Transaction Type",
      className: "text-center",
    },
    {
      key: "delete",
      content: (document) => (
        <button
          onClick={() => this.props.onDelete(document)}
          className="btn btn-danger btn-sm"
        >
          <FontAwesomeIcon icon="trash" />
        </button>
      ),
    },
  ];

  // constructor() {
  //   super();
  //   // const user = auth.getCurrentUser();
  //   // if (user && user.isAdmin) this.tableColumns.push(this.deleteColumn);
  // }

  render() {
    const { items, onSort, sortColumn, isList } = this.props;
    const columns = isList ? this.listColumns : this.tableColumns;

    return (
      <Table
        columns={columns}
        data={items}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default DocumentTable;
