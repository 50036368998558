import React from "react";
import { Text, View } from "@react-pdf/renderer";
import { DateTime } from "luxon";

const SoPdfFooter = (props) => {
  const { po, poDate, styling, title } = props;
  const formattedDate = DateTime.fromISO(poDate).toISODate();
  const docInfo = `${title} # ${po} of ${formattedDate}`;
  return (
    <View style={styling.footer} fixed>
      <View>
        <Text
          render={({ pageNumber, totalPages }) => {
            return pageNumber !== totalPages + 1
              ? `Page ${pageNumber} of ${totalPages}`
              : ``;
          }}
          fixed
        />
      </View>
      <View>
        <Text>{docInfo}</Text>
      </View>
    </View>
  );
};

export default SoPdfFooter;
