import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Panel from "../common/panel";
import Image from "../common/images/ImageBase64";
import { RowTwoColumns, RowOneLeftTwoRight } from "../common/formLayout";
import Field from "../common/fieldText";
import {
  getSetting,
  setSetting,
  populateSetting,
} from "../../services/settingService";
import { toast } from "react-toastify";
import { checkFileExtension, fileTypes } from "../../utils/fileExtensions";
import { blobFileUrl } from "../../utils/blobFileUrl";
import { downloadFile, uploadFile } from "../../services/logoService";
import LiveToast from "../../utils/liveToast";
import { compressImage } from "../../utils/fileCompressor";
import { imageFileExt } from "../common/images/utils";

const General = (props) => {
  const [info, setInfo] = useState({});
  const [logo, setLogo] = useState({});

  useEffect(() => {
    async function populateInfo() {
      try {
        const { data } = await populateSetting("general");
        setSetting("general", data.details);
        setInfo(data.details);
      } catch (ex) {
        if (ex.response && ex.response.status === 404)
          window.location.href = "/settings/general/edit";
      }
    }

    const general = getSetting("general");
    if (general) {
      setInfo(general);
    } else {
      populateInfo();
    }
  }, []);

  useEffect(() => {
    async function populateLogo() {
      try {
        const { data: fileData } = await downloadFile("logo");
        const { data, mimetype } = fileData;
        console.log("populateLogo", mimetype);
        const ext = imageFileExt(mimetype);
        const fileUrl = blobFileUrl(data, mimetype);
        const img = {
          name: `logo.${ext}`,
          fileUrl: fileUrl,
          data: data,
        };
        setSetting("logo", img);
        setLogo(img);
      } catch (ex) {
        if (ex.response && ex.response.status === 404) {
          toast.info("Please set a logo");
        }
      }
    }

    const logo = getSetting("logo");
    if (logo) {
      setLogo(logo);
    } else {
      populateLogo();
    }
  }, []);

  const displayLogo = async (imgFile) => {
    const { data, mimetype } = imgFile;

    const fileUrl = blobFileUrl(data, mimetype);

    const img = {
      name: "logo",
      fileUrl: fileUrl,
      data: data,
    };
    setSetting("logo", img);
    setLogo(img);
  };

  let fileCompressionRequired = false;
  const compressFile = async (file_original) => {
    let file_processed = null;
    const fileType = await checkFileExtension(file_original.name);
    switch (fileType) {
      case fileTypes.image:
        file_processed = await compressImage(file_original);
        break;

      default:
        file_processed = file_original;
        break;
    }
    return file_processed;
  };

  const handleFileSelectorChange = async (event) => {
    if (event.target.files.length !== 1) return;
    const liveToast = new LiveToast("UploadingFIle");

    const file_original = event.target.files[0];

    const fileType = await checkFileExtension(file_original.name);
    if (fileType === fileTypes.invalid) {
      liveToast.error("Unacceptable file extension");
      return;
    }

    const file_processed = fileCompressionRequired
      ? await compressFile(file_original, fileType)
      : file_original;

    setLogo(file_processed);

    await saveLogo(file_processed);
  };

  const saveLogo = async (logo) => {
    try {
      const { data } = await uploadFile(logo);
      if (data) displayLogo(data);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
        console.log(ex.response.data);
      }
    }
  };

  const fileSelector = document.createElement("input");
  fileSelector.setAttribute("type", "file");
  fileSelector.onchange = handleFileSelectorChange;

  const handleUploadClick = (compress = true) => {
    fileCompressionRequired = compress;
    fileSelector.click();
  };

  return (
    <div>
      <h2>General Settings</h2>
      <hr />
      <Panel>
        <h5 className="card-title"> Company Information</h5>
        <RowTwoColumns
          colOne={<Field name="name" label="Company name" value={info.name} />}
          colTwo={
            <Field name="legalName" label="Legal Name" value={info.legalName} />
          }
        />
        <RowTwoColumns
          colOne={
            <Field
              name="fiscalNumber"
              label="GST/HST Number"
              value={info.fiscalNumber}
            />
          }
        />
        <RowTwoColumns
          colOne={
            <Field name="mainPhone" label="Main Phone" value={info.mainPhone} />
          }
        />
        <RowTwoColumns
          colOne={
            <Field name="mainEmail" label="Main Email" value={info.mainEmail} />
          }
        />

        <hr />
        <h5 className="card-title">Contact Information</h5>
        <RowTwoColumns
          colOne={
            <Field name="line1" label="Address (Line 1)" value={info.line1} />
          }
          colTwo={
            <Field name="line2" label="Address (Line 2)" value={info.line2} />
          }
        />
        <RowOneLeftTwoRight
          colOne={<Field name="city" label="City" value={info.city} />}
          colTwo={
            <Field
              name="subDivision"
              label="Province/State"
              value={info.subDivision}
            />
          }
          colThree={
            <Field
              name="postalCode"
              label="Postal Code / ZIP"
              value={info.postalCode}
            />
          }
        />
        <RowTwoColumns
          colOne={<Field name="country" label="Country" value={info.country} />}
        />
        <hr />
        <h5 className="card-title">Logo</h5>
        {/* {logo ? <image src={logo.fileUrl} /> : <div></div>} */}
        {/* <img alt="" src={logo.fileUrl} /> */}
        <div className="mb-3" style={{ maxWidth: "425px" }}>
          <Image name="logo" data={logo.data} mimetype="image/png" />
        </div>
        <button
          type="file"
          className="btn btn-sm btn-outline-secondary mr-2 mt-n1 shadow-sm"
          data-toggle="tooltip"
          data-placement="top"
          title="Upload a new file"
          onClick={() => handleUploadClick(true)}
        >
          Upload File ...
        </button>
        <hr />
        <Link
          to={`/settings/general/edit`}
          className="btn btn-secondary shadow-sm"
        >
          Edit...
        </Link>
      </Panel>
    </div>
  );
};

export default General;
