import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { RowTwoColumns } from "../common/formLayout";
import InputSelect from "../common/inputSelect";
import { getStorages } from "../../services/entityService";

const HeaderWriteDown = () => {
  const [storages, setStorages] = useState([]);
  const { setValue } = useFormContext();

  useEffect(() => {
    const populateStorages = async () => {
      const { data } = await getStorages();
      const mapped = data.map((s) => {
        return { value: s._id, label: s.title };
      });
      if (mapped) setStorages(mapped);
    };
    populateStorages();
    return () => {
      setStorages([]);
    };
  }, []);

  const handleSelect = (name, selected) => {
    setValue(selected.name, { value: name.value, label: name.label });
  };

  return (
    <RowTwoColumns
      colOne={
        <InputSelect
          name="locationFrom"
          label="Location"
          options={storages}
          onChange={handleSelect}
          valueOption="value"
          labelOption="label"
        />
      }
    />
  );
};

export default HeaderWriteDown;
