import React, { useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers";
// import { toast } from "react-toastify";
import numeral from "numeral";
import { DateTime } from "luxon";
import Panel from "../common/panel";
import Controls from "./Controls";
import EditDetails from "./EditDetails";
import HeaderEdit from "./HeaderEdit";
import {
  // RowTwoColumns,
  RowFourColumns,
  RowTwoColumns,
} from "../common/formLayout";
import InputText from "../common/inputText";
import InputDate from "../common/inputDate";
import InputTextArea from "./../common/inputTextArea";
import DocumentSelect from "./HeaderDocSelect";
import MetaDataEdit from "../common/MetaDataEdit";
import { getMetaData } from "../../services/metaDataService";
// import { getInventories } from "./../../services/inventoryService";

const Edit = (props) => {
  const { docType, data, records, onSave } = props;
  // const { data: defaultValues } = getDefaultValues();
  const metaKeys = useMemo(() => {
    return getMetaData(docType);
  }, [docType]);

  const defaultValues = {
    txnDate: DateTime.local().toJSDate(),
    txnNumber: "",
    selectType: null,
    locationFrom: null,
    job: null,
    addressFrom: "",
    locationTo: null,
    purchaseOrder: null,
    addressTo: "",
    currency: { value: "CAD" },
    memo: "",
    notes: "",
    metadata: metaKeys,
  };

  const methods = useForm({
    defaultValues,
    mode: "onBlur",
    shouldUnregister: false,
  });
  const { reset } = methods;

  useEffect(() => {
    const docData = { ...data };
    docData.txnDate = DateTime.fromISO(docData.txnDate).toJSDate();
    if (!docData.metadata || docData.metadata.length < 1)
      docData.metadata = metaKeys;

    docData.details = records;
    reset({
      txnDate: docData.txnDate,
      txnNumber: docData.txnNumber,
      selectType: docData.selectedType,
      locationFrom: docData.locationFrom,
      addressFrom: docData.addressFrom,
      locationTo: docData.locationTo,
      addressTo: docData.addressTo,
      purchaseOrder: docData.purchaseOrder,
      currency: docData.curremcy,
      details: docData.details,
      memo: docData.memo,
      notes: docData.notes,
      metadata: docData.metaKeys,
    });
  }, [data, reset, metaKeys, records]);

  const onSubmit = async (values) => {
    values.txnDate = DateTime.fromJSDate(values.txnDate).toISO();
    const records = values.details.map((i) => {
      const updated = i;
      updated.qty = numeral(i.qty).value();
      return updated;
    });
    values.records = records;
    values.type = docType;
    // console.log(values);
    await onSave(values);
  };

  if (!docType) {
    return <DocumentSelect />;
  } else {
    return (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Panel>
            <HeaderEdit docType={docType} {...props} />
            <RowFourColumns
              colOne={<InputDate name="txnDate" label="Document Date" />}
              colTwo={
                props.documentId === "new" ? (
                  ""
                ) : (
                  <InputText name="txnNumber" label="Number" />
                )
              }
            />
            <EditDetails details={records} {...props} />
            <div id="docFooter">
              <hr />
              <RowTwoColumns
                colOne={<InputTextArea name="memo" label="Memo" />}
                colTwo={<InputTextArea name="notes" label="Intenal Notes" />}
              />
            </div>
            <hr />
            <MetaDataEdit metaKeys={metaKeys} docType={docType} />
          </Panel>
          <Controls isEdit={true} {...props} />
        </form>
      </FormProvider>
    );
  }
};

export default Edit;
