import React, { useState, useEffect } from "react";
import withLoading from "../common/withLoading";
import { DateTime } from "luxon";
import SidebarLeft from "./../common/SidebarLeft";
import ListGroup from "./../common/listGroup";
import SelectDateBox from "./../common/SelectDateBox";
import SelectBox from "./SelectBox";
import Select from "react-select";
import GenInventoryReceiptsTable from "./GenInventoryReceiptsTable";
import { toast } from "react-toastify";
import {
  getEntities,
  getItems,
  getStorages,
  getVendors,
  getEntitiesQuery,
  saveEntity,
} from "../../services/entityService";
import { saveDocument } from "../../services/documentService";
import { getInventoriesQuery } from "./../../services/inventoryService";
import { mapBillDetailToInventoryReceipt } from "./purchasing/inventoryReceipt";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

const GenInventoryReceipts = (props) => {
  const { loadingOn, loadingOff, progressOn, progressUpdate, progressOff } =
    props;
  const [vendors, setVendors] = useState([]);
  const [storages, setStorages] = useState([]);
  const [purchaseItems, setPurchaseItems] = useState([]);
  const [inventories, setInventories] = useState([]);
  const [bills, setBills] = useState([]);
  const [billDetails, setBillDetails] = useState([]);
  const [verified, setVerified] = useState([]);
  const [receipts, setReceipts] = useState([]);
  const [selectedStorage, setSelectedStorage] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(300);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);
  const [sortColumn, setSortColumn] = useState({
    path: "txnDate",
    order: "asc",
  });
  const [fromDate, setFromDate] = useState(DateTime.local().startOf("month"));
  const [toDate, setToDate] = useState(DateTime.local().endOf("month"));
  const [queryOption, setQueryOption] = useState(1);
  const [query, setQuery] = useState({});

  useEffect(() => {
    populateStorages();
    populateVendors();
    populatePurchasingItems();
    populateInventories();
    loadingOff();
  }, []);

  const populateStorages = async () => {
    try {
      const { data } = await getStorages();
      const storages = data.map((s) => {
        return { value: s._id, label: s.title, qty: 0, code: s.data.code };
      });
      const sorted = _.orderBy(storages, "code", "asc");
      // let found = storages.find((s) =>
      //   s.label.toLowerCase().includes("raw")
      // );
      if (sorted.length > 0) {
        setStorages(sorted);
        setSelectedStorage(sorted[0]);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  const populateVendors = async () => {
    try {
      const { data } = await getVendors();
      const vendors = data.map((i) => ({
        label: i.title,
        value: i._id,
        // data: i.data,
      }));
      setVendors(vendors);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  const populatePurchasingItems = async () => {
    // const query = encodeURI("?type=Finished Products");
    try {
      const { data } = await getItems(`?purchasing=${true}`);
      const mapped = data.map((i) => {
        const item = {
          value: i._id,
          label: i.title,
        };
        return item;
      });
      setPurchaseItems(mapped);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  const populateInventories = async () => {
    // const query = encodeURI("?type=Finished Products");
    try {
      const { data: inventory } = await getInventoriesQuery();
      // const filtered = data.filter((i) =>
      //   ["Raw materials", "Parts"].includes(i.type)
      // );
      // const mapped = filtered.map((i) => {
      const mapped = inventory.map((i) => {
        const item = {
          value: i._id,
          label: i.name,
          uom: i.uom || "EACH",
          itemId: i.itemId,
          type: i.type,
          description: i.description || i.name,
        };
        return item;
      });
      setInventories(mapped);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  const populateBillDetails = async () => {
    let query = "";
    if (fromDate) query = query + `?fromDate=${fromDate.toISO()}`;
    if (toDate)
      query = query
        ? query + `&toDate=${toDate.toISO()}`
        : `&toDate=${toDate.toISO()}`;
    // console.log(selectedCustomer);
    if (selectedVendor)
      query = query
        ? query + "&Vendor=" + selectedVendor.value
        : "?Vendor=" + selectedVendor.value;
    loadingOn();
    try {
      const { data } = await getEntitiesQuery("Bill", query);
      const details = mapBillDetails(data);
      // const bills = data.map((inv) => {
      //   if (inv.data && inv.data.refs) {
      //     const prodRefs = inv.data.refs.filter(
      //       (r) => r.type === "ProductionReceipt"
      //     );
      //     inv.isProd = prodRefs.length > 0 ? true : false;
      //   }
      //   return inv;
      // });
      setBills(data);
      setBillDetails(details);
      loadingOff();
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      loadingOff();
    }
  };

  const mapBillDetails = (bills) => {
    let details = [];
    console.log("Total bills", bills.length);
    let billNum = 0;
    for (const bill of bills) {
      billNum++;
      console.log(`Bill # ${billNum} ${bill.title}`, bill);
      if (bill.data && bill.data.details) {
        for (const line of bill.data.details) {
          if (bill.data && bill.data.refs) {
            console.log("Refs found: ", bill);
            let ref = bill.data.refs.find(
              (ref) =>
                ref.type === "InventoryReceipt" && ref.lineId === line.lineId
            );
            if (ref) continue;
          }
          if (!line.item) continue;

          if (!line.item.value) {
            if (!line.item.label) continue;
            let item = purchaseItems.find(
              (purchase) => purchase.label === line.item.label
            );
            if (!item) continue;
            line.item = item;
          }
          // #TODO redo inventory creation
          let foundInventory = inventories.find(
            (inventory) => inventory.itemId === line.item.value
          );

          if (!foundInventory) {
            console.log(
              `Not found inventory item ${line.item.label}`,
              foundInventory
            );
            continue;
          }

          let detail = {
            ref: {
              type: "Bill",
              value: bill._id,
              label: bill.title,
              vendor: bill.data.vendor,
              currency: bill.data.currency || { value: "CAD" },
              txnNumber: bill.data.txnNumber,
              lineId: line.lineId,
            },
            name: bill.data.vendor,
            storage: selectedStorage,
            inventory: foundInventory,
            item: line.item,
            txnDate: bill.data.txnDate,
            qty: line.qty,
          };
          console.log(`Detail ${line.item.label}`, detail);
          details.push(detail);
        }
      }
    }
    // console.log("Details list: ", details);
    return details;
  };

  const handleGetData = async () => {
    await populateBillDetails();
  };

  const handleSearch = (query) => {
    if (!query) setSelectedVendor(null);
    // this.setState({ selectedCustomer: null });

    const filtered = vendors.filter((item) =>
      item.label.toLowerCase().includes(query.toLowerCase())
    );

    const options = filtered.slice(0, 5);

    setSearchQuery(query);
    setSearchOptions(options);
  };

  const handleVendorSelect = () => console.log("Vendor select");

  const handleCreateReceipts = async () => {
    console.log("Saving started...");

    progressOn();

    // const { data } = await getTxnNewNumber("Invoice");
    // let { newNumber } = Number(data.newNumber);

    let d = 0;
    for (let txn of billDetails) {
      // if (!invoice.data.txnNumber) {
      //   invoice.data.txnNumber = newNumber;
      //   invoice.title = "" + newNumber + " " + invoice.title;
      //   newNumber = newNumber + 1
      // }
      txn = await handleCreateOneReceipt(txn);
      d++;
      progressUpdate("Saving inventory receipts...", d, billDetails.length);
      console.log("Saved: ", d, txn.title);
    }

    progressOff();

    const notSaved = billDetails.filter((o) => o.errors !== undefined);
    if (notSaved.length === 0) {
      window.location = "/generate-inventory-receipts";
    } else {
      setReceipts(notSaved);
    }
  };

  // TODO finish
  const handleCreateOneReceipt = async (txn) => {
    // this.props.loadingOn();
    // txn.storage = selectedStorage;
    txn.user = props.user;
    const receipt = await mapBillDetailToInventoryReceipt(txn);
    const txnDate = DateTime.fromISO(receipt.data.txnDate).toISODate();

    receipt.title = `${txnDate} Production Receipt`;

    if (receipt.data.records) receipt.records = receipt.data.records;
    delete receipt.data.details;
    delete receipt.data.records;

    // console.log(doc);

    const saved = await onSaveDocument(receipt);
    let refs = [
      {
        type: "InventoryReceipt",
        value: saved._id,
        label: saved.title,
        lineId: txn.ref.lineId,
      },
    ];
    // console.log(refs);

    const billToUpdate = bills.find((i) => i._id === txn.ref.value);
    billToUpdate.data.refs = billToUpdate.data.refs
      ? [...billToUpdate.data.refs, ...refs]
      : refs;

    const bill = await onSaveBill(billToUpdate);
    if (bill) bill.isReceived = true;
    // console.log(saved);
    // this.props.loadingOff();
    return bill;
  };

  const onSaveDocument = async (doc) => {
    // const isNew = doc._id === "new" ? true : false;
    if (doc._id === "new") delete doc._id;

    try {
      const { data } = await saveDocument(doc);
      return data;
      // else window.location = "/documents";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      return null;
    }
  };

  const onSaveBill = async (bill) => {
    // this.props.loadingOn();
    if (bill.__v) delete bill.__v;
    try {
      const { data } = await saveEntity(bill);
      return data;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      return null;
    }
  };
  const handleDelete = (txn) => {
    const filtered = bills.filter((bill) => bill._id !== txn._id);
    setBills(filtered);
  };

  return (
    <>
      <div className="px-4">
        <div className="d-flex py-2">
          <h2 className="text-uppercase">Generate Inventory Receipts</h2>
        </div>
        <p>
          Select date range to find supplier bills for generating inventory
          receipts, the supplier name is optional. Choose a storage where you
          want to store received items.
        </p>
        <SelectDateBox
          fromDate={fromDate}
          toDate={toDate}
          setFromDate={(value) => setFromDate(value)}
          setToDate={(value) => setToDate(value)}
          handleDateChange={handleGetData}
        />
        <div className="d-flex justify-content-md-between border-bottom mt-2 pb-2 d-print-none">
          <div>
            <SelectBox
              searchQuery={searchQuery}
              onSearch={handleSearch}
              options={searchOptions}
              onSelect={handleVendorSelect}
              label={"Select vendor..."}
            />
            <Select
              name="storage"
              value={selectedStorage}
              options={storages}
              onSelect={(storage) => setSelectedStorage(storage)}
              label={"Search storage..."}
            />
          </div>
          <div>
            <button
              type="button"
              className="btn btn-info mr-2 shadow-sm"
              disabled={billDetails < 1}
              onClick={handleCreateReceipts}
            >
              Generate Inventory Receipts
            </button>
          </div>
        </div>
        <GenInventoryReceiptsTable
          title={
            selectedVendor
              ? `${selectedVendor.label} - Bill Details`
              : "Bill Details"
          }
          items={billDetails}
          sorting={sortColumn}
          onCreateInvReceipt={handleCreateOneReceipt}
          onDelete={handleDelete}
        />
      </div>
    </>
  );
};

export default withLoading(GenInventoryReceipts);
