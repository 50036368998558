import React from "react";
import DocumentSelect from "./HeaderDocSelect";
import HeaderCount from "./HeaderCount";
import HeaderReceipt from "./HeaderReceipt";
import HeaderShipment from "./HeaderShipment";
import HeaderTransfer from "./HeaderTransfer";
import HeaderWriteDown from "./HeaderWriteDown";
import HeaderRequisitionForm from "./HeaderRequisitionForm";
import HeaderProduction from "./HeaderProduction";

const HeaderEdit = (props) => {
  const { docType } = props;

  switch (docType) {
    case "InventoryTransfer":
      return <HeaderTransfer {...props} />;
    case "InventoryReceipt":
      return <HeaderReceipt {...props} />;
    case "InventoryShipment":
      return <HeaderShipment {...props} />;
    case "InventoryCount":
      return <HeaderCount {...props} />;
    case "InventoryWriteDown":
      return <HeaderWriteDown {...props} />;
    case "MaterialsRequisitionForm":
      return <HeaderRequisitionForm {...props} />;
    case "ProductionReceipt":
      return <HeaderProduction {...props} />;
    default:
      return <DocumentSelect {...props} />;
  }
};

export default HeaderEdit;
