import React from "react";

const DetailsHeader = () => {
  return (
    <thead>
      <tr className="text-center">
        <th scope="col" style={{ width: "5%" }}>
          #
        </th>
        <th scope="col" style={{ width: "25%" }}>
          Item
        </th>
        <th scope="col" style={{ width: "45%" }}>
          Description
        </th>
        <th scope="col" style={{ width: "10%" }}>
          UOM
        </th>
        <th scope="col" style={{ width: "10%" }}>
          QTY
        </th>
        <th scope="col" style={{ width: "5%" }}></th>
      </tr>
    </thead>
  );
};

export default DetailsHeader;
