export const getMetaData = (type) => {
  let metaKeys = [];
  switch (type) {
    case "InventoryReceipt":
      metaKeys = [
        { key: "Part #", value: "" },
        { key: "Inspection Date", value: "" },
        { key: "Packing Slip", value: "" },
        { key: "Description", value: "" },
        { key: "Actual Dimensions", value: "" },
        { key: "Material Heat #", value: "" },
        { key: "Accepted / Rejected", value: "" },
        { key: "Comments", value: "" },
      ];
      break;
    case "Item":
      metaKeys = [
        { key: "Supplier", value: "" },
        { key: "Brand", value: "" },
        { key: "Material", value: "" },
        { key: "Size", value: "" },
        { key: "Thickness", value: "" },
        { key: "Weight", value: "" },
        { key: "Finish", value: "" },
        { key: "Process steps", value: "" },
      ];
      break;

    default:
      break;
  }
  return metaKeys;
};
