import React, { useEffect, useState } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PartsHeader from "./PartsHeader";
// import InputTableTextArea from "../common/inputTableTextArea";
import InputTableSelect from "../common/inputTableSelect";
import InputTableNumber from "../common/inputTableNumber";
import Button from "../common/button";
import { getEntities } from "../../services/entityService";
// import { updateTotalAmount } from "../../utils/poUtils";
import InputTableText from "../common/inputTableText";

const EditParts = (props) => {
  const initialDetail = {
    item: null,
    description: "",
    uom: "EACH",
    qty: 0,
    unitCost: 0,
    amount: 0,
  };
  const { control, setValue } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "parts",
  });

  const [items, setItems] = useState([]);

  // const handleBlur = (e) => {
  //   // recalculateLine(e.target);
  // };

  // const updateTotals = () => {
  //   const { parts } = getValues();
  //   if (parts) {
  //     const sum = updateTotalAmount(parts);
  //     // const taxAmount = updateTaxAmount(parts, taxes);

  //     // setValue("totals.subTotal", sum);
  //     // setValue("totals.totalTax", taxAmount);
  //     setValue("totals.total", sum);
  //   }
  // };

  // useEffect(() => {
  //   updateTotals();
  // });

  useEffect(() => {
    const populateItems = async () => {
      try {
        const { data } = await getEntities("Item");
        const items = data.map((item) => ({
          label: item.title,
          value: item._id,
          data: item.data,
          // description: item.data.description || "",
          // unitCost: item.data.cost || 0,
          // uom: item.data.uom || "",
        }));
        setItems(items);
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          toast.error(ex.response.data);
        }
      }
    };

    populateItems();
    return () => {
      setItems([]);
    };
  }, []);

  // const recalculateLine = (field) => {
  //   const values = getValues();
  //   const { parts } = values;
  //   let str = field.id;
  //   let n = str.lastIndexOf(".");
  //   let name = str.substr(n + 1, str.length);
  //   let ni1 = str.indexOf("[");
  //   let ni2 = str.indexOf("]");
  //   const index = str.substring(ni1 + 1, ni2);
  //   let qty = numeral(parts[index].qty).value();
  //   let unitCost = numeral(parts[index].unitCost).value();
  //   let amount = numeral(parts[index].amount).value();
  //   if (name === "unitCost" || name === "qty") {
  //     amount = qty * unitCost;
  //     amount = amount.toFixed(2);
  //     // setValue(`parts[${index}].amount`, amount);
  //     // setValue(`parts[${index}].qty`, qty);
  //     // setValue(`parts[${index}].price`, price);
  //   }
  //   if (name === "amount") {
  //     unitCost = amount / qty;
  //     // setValue(`parts[${index}].price`, price);
  //   }
  //   setValue(`parts[${index}].qty`, qty);
  //   // setValue(`parts[${index}].amount`, amount);
  //   // setValue(`parts[${index}].unitCost`, unitCost);
  //   updateTotals();
  // };

  const populateLine = (name, selected) => {
    setValue(selected.name, { value: name.value, label: name.label });

    const { name: str } = selected;
    let ni1 = str.indexOf("[");
    let ni2 = str.indexOf("]");
    const index = str.substring(ni1 + 1, ni2);

    // setValue(`parts[${index}].unitCost`, name.data.cost);
    // setValue(`parts[${index}].description`, name.data.description);
    if (name.data.uom) setValue(`parts[${index}].uom`, name.data.uom);
  };

  return (
    <div id="parts" className="mt-2">
      <hr />
      <h5 className="text-uppercase">Parts</h5>
      <table className="table table-sm border-bottom">
        <PartsHeader />
        <tbody>
          {fields.map((line, index) => (
            <tr key={index} name={`parts-${index}`}>
              <th scope="row" className="align-baseline">
                {index + 1}
              </th>
              <td className="align-baseline">
                <InputTableSelect
                  name={`parts[${index}].item`}
                  options={items}
                  onChange={populateLine}
                  valueOption="value"
                  labelOption="label"
                  defaultValue={line.item}
                />
              </td>
              {/* <td className="align-baseline">
                <InputTableTextArea
                  name={`parts[${index}].description`}
                  defaultValue={line.description}
                />
              </td> */}
              <td className="align-baseline">
                <InputTableText
                  name={`parts[${index}].uom`}
                  defaultValue={line.uom}
                />
              </td>
              <td className="align-baseline">
                <InputTableNumber
                  name={`parts[${index}].qty`}
                  // onBlur={handleBlur}
                  defaultValue={line.qty}
                  decimalScale="6"
                />
              </td>
              {/* <td className="align-baseline">
                <InputTableNumber
                  name={`parts[${index}].unitCost`}
                  onBlur={handleBlur}
                  defaultValue={line.unitCost}
                  decimalScale="4"
                />
              </td>
              <td className="align-baseline">
                <InputTableNumber
                  name={`parts[${index}].amount`}
                  defaultValue={line.amount}
                  onBlur={handleBlur}
                />
              </td> */}
              <td className="align-baseline">
                <button
                  className="btn btn-secondary btn-sm d-print-none"
                  type="button"
                  onClick={() => remove(index)}
                >
                  <FontAwesomeIcon icon="trash" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="d-flex justify-content-between">
        <div>
          <Button
            label="+ Add Item"
            type="button"
            className="btn-primary btn-sm shadow-sm"
            onClick={() => append(initialDetail)}
          />
        </div>
      </div>
    </div>
  );
};

export default EditParts;
