import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "../common/table";
// import { DateTime } from "luxon";

class QboExportListTable extends Component {
  checkmark = (
    <div className="text-center text-success">
      <FontAwesomeIcon icon="check" />
    </div>
  );

  tableColumns = [
    {
      key: "title",
      label: "Name",
      content: (item) => item.title,
    },
    {
      key: "DBupdated",
      label: "DB Updated",
      content: (item) => <div className="text-center">{item.lastUpdated}</div>,
    },
    {
      key: "QBOupdated",
      label: "QBO Updated",
      content: (item) => (
        <div className="text-center">{item.qbo.MetaData.LastUpdatedTime}</div>
      ),
    },
    {
      key: "data",
      label: "Data",
      content: (item) => (
        <div className="text-center">
          {item.data ? <FontAwesomeIcon icon="check-circle" /> : ""}
        </div>
      ),
    },
    {
      key: "qbo",
      label: "Qbo",
      content: (item) => (
        <div className="text-center">
          {item.isImported ? <FontAwesomeIcon icon="check-circle" /> : ""}
        </div>
      ),
    },
    {
      key: "controls",
      content: (item) => (
        <div className="text-right d-print-none">
          <button
            onClick={() => this.props.onExport(item)}
            className="btn btn-info btn-sm mr-1"
            disabled={item?.qbo?.Id ? true : false}
          >
            <FontAwesomeIcon icon="file-export" />
          </button>
          <button
            onClick={() => this.props.onDelete(item)}
            className="btn btn-danger btn-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="Remove from the export list"
          >
            <FontAwesomeIcon icon="trash" />
          </button>
        </div>
      ),
    },
    // {
    //   key: "delete",
    //   content: (item) => (
    //     <button
    //       onClick={() => this.props.onDelete(item)}
    //       className="btn btn-outline-danger btn-sm"
    //       data-toggle="tooltip"
    //       data-placement="top"
    //       title="Remove from the update list"
    //     >
    //       <FontAwesomeIcon icon="trash" />
    //     </button>
    //   ),
    // },
  ];

  simpleListColumns = [
    {
      path: "title",
      label: "Title",
    },
    {
      key: "data",
      label: "Data",
      content: (item) => (
        <div className="text-center">
          {item.data ? <FontAwesomeIcon icon="check-circle" /> : ""}
        </div>
      ),
    },
    {
      key: "qboId",
      label: "QBO",
      content: (item) => (
        <div className="text-center">
          {item.qbo ? (
            <FontAwesomeIcon
              icon="check-circle"
              className={item?.qbo?.Id ? "text-success" : "text-secondary"}
            />
          ) : (
            ""
          )}
        </div>
      ),
    },
    // {
    //   key: "lastUpdated",
    //   label: "Last Updated",
    //   content: (item) => (
    //     <div className="text-center">
    //       {/* {DateTime.fromISO(item.MetaData.LastUpdatedTime).format("YYYY-MM-DD HH:mm:ss")} */}
    //       {item.MetaData.LastUpdatedTime}
    //     </div>
    //   ),
    // },

    {
      key: "controls",
      content: (item) => (
        <div className="text-right d-print-none">
          <button
            onClick={() => this.props.onExport(item)}
            className="btn btn-info btn-sm mr-1"
            disabled={item?.qbo?.Id ? true : false}
          >
            <FontAwesomeIcon icon="file-export" />
          </button>
          <button
            onClick={() => this.props.onDelete(item)}
            className="btn btn-danger btn-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="Remove from the export list"
          >
            <FontAwesomeIcon icon="trash" />
          </button>
        </div>
      ),
    },
  ];

  render() {
    const { items, onSort, onExport, onDelete, sortColumn, isList } =
      this.props;
    const columns = isList ? this.simpleListColumns : this.tableColumns;

    return (
      <Table
        columns={columns}
        data={items}
        sortColumn={sortColumn}
        onSort={onSort}
        onExport={onExport}
        onDelete={onDelete}
      />
    );
  }
}

export default QboExportListTable;
