import React from "react";
import DateFormat from "./dateFormat";

const FieldDate = ({
  name = "date",
  label = "Date",
  value = "",
  comment = "",
  ...rest
}) => {
  return (
    <div className="form-group">
      {label && (
        <label
          htmlFor={name}
          className="form-label form-label-sm font-weight-bold"
          style={{ marginBottom: "0px" }}
        >
          {label}
        </label>
      )}
      <div className="form-control" name={name} id={name} {...rest}>
        <DateFormat value={value} />
      </div>
      {comment}
    </div>
  );
};

export default FieldDate;
