import React from "react";
import DetailsHeader from "./DetailsHeader";
import FieldTable from "../common/fieldTable";
import FieldTableNumber from "../common/fieldTableNumber";

const ViewDetails = (props) => {
  const { details } = props;
  return (
    <table className="table table-sm border-bottom">
      <DetailsHeader />
      <tbody>
        {details &&
          details.map((line, index) => (
            <tr key={index} name={`details-${index}`}>
              <th scope="row" className="align-baseline text-center">
                {line.lineId || index + 1}
              </th>
              <td>
                <FieldTable
                  name={`details[${index}].inventory`}
                  value={line.inventory.label}
                />
              </td>
              <td>
                <FieldTable
                  name={`details[${index}].description`}
                  value={line.description}
                />
              </td>
              <td>
                <FieldTable name={`details[${index}].uom`} value={line.uom} />
              </td>
              <td>
                <FieldTableNumber
                  name={`details[${index}].qty`}
                  value={line.qty}
                  decimal="3"
                />
              </td>
              <td></td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default ViewDetails;
