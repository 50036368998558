import React from "react";

const RowGroup = ({
  items,
  textProperty = "label",
  valueProperty = "value",
  onItemSelect,
  selectedItem,
}) => {
  return (
    <div className="btn-group shadow-sm my-2" role="group" aria-label="Select Period">
      {items.map((item) => (
        <button
          onClick={() => onItemSelect(item)}
          key={valueProperty ? item[valueProperty] : item}
          className={
            item.value === selectedItem.value
              ? "btn btn-secondary"
              : "btn btn-outline-secondary"
          }
        >
          {textProperty ? item[textProperty] : item}
        </button>
      ))}
    </div>
  );
};

RowGroup.defaultProps = {
  textProperty: "label",
  valueProperty: "value",
};

export default RowGroup;