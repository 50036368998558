import React from "react";
import PoPdfHeader from "./PoPdfHeader";
import PoPdfContent from "./PoPdfContent";
import PoPdfFooter from "./PoPdfFooter";
import { Document, Page } from "@react-pdf/renderer";

import PoPdfSummary from "./PoPdfSummary";
import { docPrintStyles as styling } from "../common/docPrint/styling";

const SoPdf = (props) => {
  const { title, info, data } = props;

  return (
    <Document>
      <Page size="LETTER" wrap style={styling.page}>
        <PoPdfHeader title={title} info={info} styling={styling} data={data} />
        <PoPdfContent data={data} styling={styling} />
        <PoPdfSummary data={data} styling={styling} />
        <PoPdfFooter
          title={title}
          po={data.txnNumber}
          poDate={data.txnDate}
          styling={styling}
        />
      </Page>
    </Document>
  );
};

export default SoPdf;
