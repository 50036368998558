import React from "react";

const DetailsHeader = () => {
  return (
    <thead>
      <tr className="text-center">
        <th scope="col" style={{ width: "3%" }}>
          #
        </th>
        <th scope="col" style={{ width: "18%" }}>
          Item
        </th>
        {/* <th scope="col" style={{ width: "35%" }}>
          Description
        </th> */}
        <th scope="col" style={{ width: "7%" }}>
          Part UOM
        </th>
        <th scope="col" style={{ width: "10%" }}>
          Required Qty
        </th>
        {/* <th scope="col" style={{ width: "10%" }}>
          
        </th>
        <th scope="col" style={{ width: "12%" }}>
          Amount
        </th> */}
        <th scope="col" style={{ width: "5%" }}></th>
      </tr>
    </thead>
  );
};

export default DetailsHeader;
