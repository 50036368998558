import React from "react";
import SearchBox from "../common/searchBox";

const SelectBox = (props) => {
  const {
    searchQuery,
    onSearch,
    options,
    onSelect,
    label = "Search item...",
  } = props;
  return (
    <div className="d-flex border-bottom py-2">
      <div className="dropdown">
        <SearchBox
          value={searchQuery}
          placeholder={label}
          col="col"
          onChange={onSearch}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          autoComplete="off"
        />
        <div
          className="dropdown-menu"
          //   aria-labelledby="dropdownSelectCustomer"
        >
          {options.map((option) => (
            <button
              className="dropdown-item"
              key={option.value}
              type="button"
              onClick={() => onSelect(option)}
            >
              {option.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectBox;
