import React from "react";
// import auth from "../../services/authService";
import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "./../common/table";
import {
  // CellCenter,
  CellInteger,
  // CellRight,
  // CellInteger,
} from "../common/tableCellFormats";
import { numberInteger } from "../../utils/numberUtils";
// import { locationsToTextArea } from "../../utils/inventoryUtils";

const InventoryTable = (props) => {
  const { items, onSort, sortColumn, totals } = props;

  const columns = [
    {
      path: "label",
      label: "Label",
      content: (item) => (
        <Link to={`/inventory/by-item/${item._id}`}>
          {item.label || item.name}
        </Link>
      ),
    },
    {
      path: "description",
      label: "Description",
      className: "w-25",
      content: (item) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {item.purchaseDescription || item.description}
        </div>
      ),
    },
    // {
    //   path: "uom",
    //   label: "UOM",
    // },
    // {
    //   path: "available",
    //   label: "Available",
    //   className: "text-center",
    //   content: (inventory) => numberInteger(inventory.available || 0),
    // },
    {
      path: "inStock",
      label: "In Stock",
      className: "text-center",
      content: (item) => numberInteger(item.inStock || 0),
    },
    {
      path: "reserved",
      label: "Reserved",
      className: "text-center",
      content: (item) => numberInteger(item.reserved || 0),
    },

    {
      path: "ordered",
      label: "Ordered",
      className: "text-center",
      content: (item) => numberInteger(item.ordered || 0),
    },
    // {
    //   path: "locations",
    //   label: "Locations",
    //   className: "text-center",
    //   content: (inventory) => (
    //     <div style={{ whiteSpace: "pre-wrap" }}>
    //       {locationsToTextArea(inventory.locations)}
    //     </div>
    //   ),
    // },
  ];

  // const pivotColumns = [];
  // const pivotColumns = pivots.map((c, i) => ({
  //   path: `locations.${c.value}`,
  //   label: `${c.label}`,
  //   className: "text-right",
  //   // total: (totals) => <CellInteger value={totals.locations[i].qty} />,
  //   content: (line) => <CellInteger value={line.locations[i].qty || 0} />,
  // }));

  // const endColumns = [
  // {
  //   path: "inStock",
  //   label: "In Stock",
  //   className: "text-center",
  //   content: (inventory) => numberInteger(inventory.inStock || 0),
  // },
  // {
  //   path: "reserved",
  //   label: "Reserved",
  //   className: "text-center",
  //   content: (inventory) => numberInteger(inventory.reserved || 0),
  // },
  // {
  //   path: "available",
  //   label: "Available",
  //   className: "text-center",
  //   content: (inventory) => numberInteger(inventory.available || 0),
  // },
  // ];

  // const controls = [
  //   {
  //     key: "controls",
  //     content: (inventory) => (
  //       <div className="text-right">
  //         <button
  //           onClick={() => this.props.onClick(inventory)}
  //           className="btn btn-secondary btn-sm mr-1"
  //         >
  //           <FontAwesomeIcon icon="edit" />
  //         </button>
  //         <button
  //           onClick={() => this.props.onDelete(inventory)}
  //           className="btn btn-danger btn-sm"
  //         >
  //           <FontAwesomeIcon icon="trash" />
  //         </button>
  //       </div>
  //     ),
  //   },
  // ];

  // constructor() {
  //   super();
  //   // const user = auth.getCurrentUser();
  //   // if (user && user.isAdmin) this.tableColumns.push(this.deleteColumn);
  // }

  // const columns = [...startColumns, ...pivotColumns, ...endColumns];

  // const user = auth.getCurrentUser();
  // if (user && user.isAdmin) columns.push(controls);

  return (
    <Table
      columns={columns}
      data={items}
      sortColumn={sortColumn}
      onSort={onSort}
      totals={totals}
    />
  );
};

export default InventoryTable;
