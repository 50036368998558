import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { toast } from "react-toastify";
// import { DateTime } from "luxon";
import withFormMode from "../common/withFormMode";
import { FormTitle, FormSubTitle } from "../common/formLayout";

import { getEntity, saveEntity } from "../../services/entityService";
import Edit from "./Edit";
import View from "./View";

const Storage = (props) => {
  const [formMode] = useState({
    isAddActivities: false,
    isNew: false,
    isEdit: false,
    loading: true,
    search: "",
  });

  const [storage, setStorage] = useState({
    title: "Adding new storage...",
    data: {
      displayName: "",
      code: "",
      // entity: null,
      address: "",
      note: "",
    },
  });

  const { match } = props;
  const storageId = match.params.id;
  const storagePath = `/storages/${storageId}`;

  useEffect(() => {
    async function populateStorage(storageId) {
      try {
        const { data: storage } = await getEntity("StoragePlace", storageId);
        setStorage(storage);
      } catch (ex) {
        if (ex.response && ex.response.status === 404)
          window.location.href = "/not-found";
      }
    }

    if (storageId === "new") {
      return;
    }

    populateStorage(storageId);
  }, [storageId]);

  const onSave = async (values) => {
    const data = { ...storage.data };
    if (values.displayName) data.displayName = values.displayName;
    if (values.code) data.code = values.code;
    if (values.note) data.note = values.note;
    if (values.address) data.address = values.address;
    data.isInventory = values.isInventory;
    data.isCustomerSite = values.isCustomerSite;
    data.isEquipment = values.isEquipment;

    const title = data.displayName;

    storage.entity = "StoragePlace";
    storage.title = title;
    storage.data = data;

    if (storage._id === "new") delete storage._id;

    try {
      await saveEntity(storage);
      toast.success("The storage was saved!");
      window.location = "/storages";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  if (match.params.id === "new") {
    formMode.isNew = true;
    formMode.isEdit = true;
  }

  return (
    <div>
      <FormTitle title={formMode.isNew ? `Create Storage` : `Storage`} />
      <FormSubTitle
        title={
          <span className="font-weight-bolder text-primary">
            {storage.title}
          </span>
        }
      />
      <Switch>
        <Route
          exact
          path={`${storagePath}`}
          component={(props) =>
            formMode.isNew ? (
              <Edit
                data={storage.data}
                storagePath={storagePath}
                storageId={storageId}
                onSave={onSave}
                {...props}
              />
            ) : (
              <View data={storage.data} storagePath={storagePath} {...props} />
            )
          }
        />
        <Route
          path={`${storagePath}/edit`}
          component={(props) => (
            <Edit
              data={storage.data}
              storagePath={storagePath}
              storageId={storageId}
              onSave={onSave}
              {...props}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default withFormMode(Storage);
