import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { checkIframeDisplayType } from "../../../utils/fileExtensions";

const FileListItem = ({
  file,
  displayFile,
  viewId = "#DisplayPdfModal",
  openFileInTab = () => {},
  onDownloadFile = () => {},
}) => {
  const showFile = checkIframeDisplayType(file.name);
  return (
    <div id={file.id}>
      {showFile ? (
        <div className="d-inline-flex bg-light rounded m-1">
          <div
            className="btn btn-light btn-sm rounded-left"
            data-toggle="modal"
            data-target={`#${viewId}`}
            onClick={() => {
              displayFile(file.id, file.name, file.pdfFile);
            }}
          >
            {file.name}
          </div>
          <div
            className="btn btn-light btn-sm rounded-right ml-1"
            data-toggle="tooltip"
            data-placement="top"
            title="Open in a new tab"
            onClick={() => {
              openFileInTab(file.id, file.name, file.viewLink);
            }}
          >
            <FontAwesomeIcon icon="external-link-alt" />
          </div>
        </div>
      ) : (
        <div
          className="btn btn-light btn-sm rounded-left m-1"
          data-toggle="tooltip"
          data-placement="top"
          title="Click to dowload file"
          onClick={() => {
            onDownloadFile(file.id, file.name);
          }}
        >
          {file.name}
        </div>
      )}
    </div>
  );
};

export default FileListItem;
