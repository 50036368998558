import React from "react";

// usage: <DragDropFile handleFile={handleFile}>...</DragDropFile>
// handleFile(file:File):void;

class DragDropFile extends React.Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
  }
  suppress(evt) {
    evt.stopPropagation();
    evt.preventDefault();
  }
  onDrop(evt) {
    evt.stopPropagation();
    evt.preventDefault();
    const files = evt.dataTransfer.files;
    // if (files && files[0]) this.props.handleFile(files[0]);
    if (files && files.length > 0) {
      if (this.props.multiple === true) return this.props.handleFile(files);
      else this.props.handleFile(files[0]);
    }
    this.props.handleFile(files);
  }
  render() {
    return (
      <div
        onDrop={this.onDrop}
        onDragEnter={this.suppress}
        onDragOver={this.suppress}
      >
        {this.props.children}
      </div>
    );
  }
}

export default DragDropFile;
