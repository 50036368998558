import React from "react";
import { DateTime } from 'luxon';

const DateFormat = ({ value }) => {
  if (!value) return <div className="text-center">{value}</div>;

  
  // const locale = "en-CA";
  // let d = new Date(value);
  // const displayDate = new Intl.DateTimeFormat(locale).format(d);
  return <div className="text-center">{DateTime.fromISO(value).toISODate()}</div>;
};

export default DateFormat;
