import React, { Component } from "react";
// import auth from "../services/authService";
import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "./../common/table";
import DateFormat from "../common/dateFormat";
import {
  CellCenter,
  CellInteger,
  // CellRight,
} from "../common/tableCellFormats";
import numeral from "numeral";
import { camelPad } from "./../../utils/utils";

class RecordTable extends Component {
  tableColumns = [
    {
      path: "doc.txnNumber",
      label: "Doc Number",
      className: "text-center",
      total: () => <CellCenter>Totals:</CellCenter>,
      content: (record) => (
        <Link to={`/documents/${record.doc.value}`}>
          {record.doc && record.doc.txnNumber}
        </Link>
      ),
    },
    {
      path: "doc.txnDate",
      label: "Date",
      content: (record) => <DateFormat value={record.doc.txnDate} />,
    },
    {
      path: "doc.docType",
      label: "Transaction Type",
      className: "text-center",
      content: (record) => (
        <div>{record.doc && camelPad(record.doc.docType)}</div>
      ),
    },

    {
      path: "inventory.label",
      label: "Item",
      className: "text-center",
    },
    {
      path: "received",
      label: "Received",
      className: "text-center",
      content: (record) => record.received || "",
      total: (totals) => <CellInteger value={totals.received} />,
    },
    {
      path: "shipped",
      label: "Shipped",
      className: "text-center",
      content: (record) => numeral(record.shipped).format(0, 0.0) || "",
      total: (totals) => numeral(totals.shipped).format(0, 0.0) || "",
    },
  ];

  listColumns = [
    {
      path: "doc.txnDate",
      label: "Name",
      content: (record) => (
        <Link to={`/documents/${record.doc.value}`}>
          {record.doc && record.doc.txnDate}
        </Link>
      ),
    },
    {
      path: "doc.docType",
      label: "Type",
    },
    {
      path: "doc.txnNumber",
      label: "Doc Number",
    },
    {
      path: "inventory.label",
      label: "Item",
      className: "text-center",
    },
    {
      path: "received",
      label: "Received",
      className: "text-center",
      content: (record) => record.received || "",
    },
    {
      path: "shipped",
      label: "Shipped",
      className: "text-center",
      content: (record) => record.shipped || "",
    },
  ];

  render() {
    const { items, onSort, sortColumn, isList, totals } = this.props;
    const columns = isList ? this.listColumns : this.tableColumns;

    return (
      <Table
        columns={columns}
        data={items}
        sortColumn={sortColumn}
        onSort={onSort}
        totals={totals}
        includeTotals={true}
      />
    );
  }
}

export default RecordTable;
