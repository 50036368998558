import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { RowTwoColumns, RowFourColumns } from "../common/formLayout";
import InputSelect from "../common/inputSelect";
import InputTextArea from "./../common/inputTextArea";
import { getEntitiesQuery, getStorages } from "../../services/entityService";
import { getVendors } from "../../services/entityService";

const HeaderReceipt = (props) => {
  const [storages, setStorages] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const { setValue } = useFormContext();
  const { inventories, addLine } = props;

  useEffect(() => {
    const populateStorages = async () => {
      const { data } = await getStorages();
      const mapped = data.map((s) => {
        return { value: s._id, label: s.title };
      });
      if (mapped) setStorages(mapped);
    };
    populateStorages();
    return () => {
      setStorages([]);
    };
  }, []);

  useEffect(() => {
    const populateVendors = async () => {
      const { data } = await getVendors();
      const mapped = data.map((s) => {
        return { value: s._id, label: s.title };
      });
      if (mapped) setVendors(mapped);
    };
    populateVendors();
    return () => {
      setVendors([]);
    };
  }, []);

  const populatePurchaseOrders = async (vendorId) => {
    // props.loadingOn();
    const { data } = await getEntitiesQuery(
      "PurchaseOrder",
      `?Vendor=${vendorId}&status=Open`
    );
    const orders = data.map((s) => {
      return { value: s._id, label: s.title, data: s.data };
    });
    if (orders) setPurchaseOrders(orders);
    // props.loadingOff();
  };

  const handleSelect = async (name, selected) => {
    setValue(selected.name, { value: name.value, label: name.label });
    if (selected.name === "name") await populatePurchaseOrders(name.value);
  };

  const handlePOSelect = async (name, selected) => {
    setValue(selected.name, {
      value: name.value,
      label: name.label,
      data: name.data,
    });
    if (name.data && name.data.details) populateItems(name.data.details);
  };

  const populateItems = (details) => {
    details.forEach((detail, index) => {
      const inv = inventories.find((i) => i.itemId === detail.item.value);

      const line = {
        description: detail.description,
        uom: detail.uom,
        qty: detail.qty,
      };
      if (inv) line.inventory = { value: inv.value, label: inv.label };
      addLine(line);
    });
  };

  return (
    <>
      <RowTwoColumns
        colOne={
          <InputSelect
            name="name"
            label="From vendor"
            options={vendors}
            onChange={handleSelect}
            valueOption="value"
            labelOption="label"
          />
        }
        colTwo={
          <InputSelect
            name="locationTo"
            label="To storage"
            options={storages}
            onChange={handleSelect}
            valueOption="value"
            labelOption="label"
          />
        }
      />
      <RowTwoColumns
        colOne={<InputTextArea name="addressFrom" label="From address" />}
        colTwo={<InputTextArea name="addressTo" label="To address" />}
      />
      <RowFourColumns
        colOne={
          <InputSelect
            name="purchaseOrder"
            label="PO"
            options={purchaseOrders}
            onChange={handlePOSelect}
            valueOption="value"
            labelOption="label"
          />
        }
      />
    </>
  );
};

export default HeaderReceipt;
