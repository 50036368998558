import React, { useEffect, useState, useMemo } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { toast } from "react-toastify";
import numeral from "numeral";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DetailsHeader from "./DetailsHeader";
import EditDetailsCostOptions from "./EditDetailsCostOptions";
import InputTableTextArea from "../common/inputTableTextArea";
import InputTableSelect from "../common/inputTableSelect";
import InputTableNumber from "../common/inputTableNumber";
import InputNumber from "../common/inputNumber";
import Button from "../common/button";
import { RowFourColumns } from "../common/formLayout";
import { getEntities } from "../../services/entityService";
import { getPurchaseItems } from "../../services/itemService";
import { updateTotalAmount, updateTaxAmount } from "../../utils/poUtils";

const EditDetails = () => {
  const initialDetail = {
    item: null,
    description: "",
    uom: "EACH",
    qty: 1,
    unitCost: 0,
    amount: 0,
    costOptions: [],
  };
  const { control, watch, setValue, getValues, register } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "details",
  });

  const [items, setItems] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [onlySelectedVendor, setOnlySelectedVendor] = useState(true);

  const details = watch("details");
  const selectedVendor = watch("vendor");

  const itemOptions = useMemo(() => {
    let filtered = [...items];
    if (onlySelectedVendor) {
      filtered = items.filter(
        (i) => i.preferredVendor?.value === selectedVendor?.value
      );
    }
    return filtered;
  }, [selectedVendor, onlySelectedVendor, items]);

  const handleBlur = (e) => {
    const field = e.target;
    let str = field.id;
    let n = str.lastIndexOf(".");
    let name = str.substr(n + 1, str.length);
    let ni1 = str.indexOf("[");
    let ni2 = str.indexOf("]");
    const index = str.substring(ni1 + 1, ni2);
    recalculateLine(name, index);
  };

  const updateTotals = () => {
    if (details) {
      const sum = updateTotalAmount(details);
      const taxAmount = updateTaxAmount(details, taxes);
      const total = sum + taxAmount;
      setValue("totals.subTotal", sum);
      setValue("totals.taxTotal", taxAmount);
      setValue("totals.total", total);
    }
  };

  useEffect(() => {
    updateTotals();
  }, [details]);

  useEffect(() => {
    const populateItems = async () => {
      try {
        const { data } = await getPurchaseItems();
        const items = data.map((item) => ({
          ...item,
          label: item.title,
          value: item._id,
          preferredVendor: item.preferredVendor,
          costOptions: [
            {
              code: "BASE",
              uom: item.uom || "EACH",
              cost: item.cost || 0,
              description: item.purchaseDescription || item.description,
              uomOption: {
                ratioToBase: 1,
              },
            },
            ...(item?.costOptions ?? []),
          ],
        }));
        setItems(items);
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          toast.error(ex.response.data);
        }
      }
    };

    populateItems();
    return () => {
      setItems([]);
    };
  }, []);

  useEffect(() => {
    const populateTaxes = async () => {
      try {
        const { data } = await getEntities("TaxCode");
        const taxes = data.map((item) => ({
          label: item.title,
          value: item._id,
          rate: item.data && item.data.rate ? item.data.rate : 0,
        }));
        setTaxes(taxes);
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          toast.error(ex.response.data);
        }
      }
    };

    populateTaxes();
    return () => {
      setItems([]);
    };
  }, []);

  const calculateAmount = (name, index) => {
    const values = getValues();
    const { details } = values;
    let qty = numeral(details[index].qty).value();
    let unitCost = numeral(details[index].unitCost).value();
    let unitCostPerBase = numeral(details[index].unitCostPerBase).value();
    let baseRatio = numeral(
      details[index]?.costOption?.uomOption?.ratioToBase || 1
    );
    let amount = numeral(details[index].amount).value();
    if (name === "unitCostPerBase") {
      unitCost = unitCostPerBase * baseRatio;
      amount = qty * unitCost;
      amount = amount.toFixed(2);
    } else if (name === "unitCost" || name === "qty") {
      if (name === "unitCost") unitCostPerBase = unitCost / baseRatio;
      amount = qty * unitCost;
      amount = amount.toFixed(2);
    } else if (name === "amount") {
      unitCost = amount / qty;
      unitCostPerBase = unitCost / baseRatio;
    }

    return { qty, unitCost, amount, unitCostPerBase };
  };

  const recalculateLine = (name, index) => {
    const { qty, unitCost, amount, unitCostPerBase } = calculateAmount(
      name,
      index
    );

    setValue(`details[${index}].amount`, amount);
    setValue(`details[${index}].qty`, qty);
    setValue(`details[${index}].unitCost`, unitCost);
    setValue(`details[${index}].unitCostPerBase`, unitCostPerBase);
    updateTotals();
  };

  const populateLine = (name, selected) => {
    setValue(selected.name, { value: name.value, label: name.label });
    console.log("Selected", name);
    const { name: str } = selected;
    let ni1 = str.indexOf("[");
    let ni2 = str.indexOf("]");
    const index = str.substring(ni1 + 1, ni2);
    setValue(
      `details[${index}].description`,
      name.purchaseDescription || name.description
    );

    const preferred = name?.costOptions.find((o) => o.isDefault);
    if (preferred) {
      setValue(`details[${index}].costOption`, preferred);
      setValue(`details[${index}].uom`, preferred.uom);
      setValue(`details[${index}].unitCost`, preferred.cost);
      setValue(`details[${index}].description`, preferred.description);
    } else {
      setValue(`details[${index}].costOption`, name?.costOptions[0]);
      setValue(
        `details[${index}].description`,
        name?.costOptions[0].description
      );
      setValue(
        `details[${index}].uom`,
        name?.costOptions[0]?.uomOption?.code || name?.costOptions[0]?.uom
      );
      setValue(`details[${index}].unitCost`, name?.costOptions[0].cost);
    }
    const { amount, unitCostPerBase } = calculateAmount("unitCost", index);
    setValue(`details[${index}].unitCostPerBase`, unitCostPerBase);
    setValue(`details[${index}].amount`, amount);
  };

  const handleCostOptionSelect = (option, index) => {
    console.log("Option", option);
    setValue(`details[${index}].costOption`, option);
    setValue(`details[${index}].uom`, option?.uomOption?.value || option?.uom);
    setValue(
      `details[${index}].unitCostPerBase`,
      numeral(option.cost)
        .divide(option?.uomOption?.ratioToBase || 1)
        .value()
    );
    setValue(`details[${index}].unitCost`, option.cost);
    // setValue(`details[${index}].description`, option.description);
    recalculateLine("unitCost", index);
  };

  const populateTax = (name, selected) => {
    setValue(selected.name, {
      value: name.value,
      label: name.label,
      rate: name.rate,
    });

    updateTotals();
  };

  console.log("Details", details);

  return (
    <div id="details" className="mt-2">
      <div className="row">
        <div className="pl-3">
          <div className="form-group form-check pt-4">
            <input
              type="checkbox"
              id="displayAll"
              name="displayAll"
              className="form-check-input"
              checked={onlySelectedVendor}
              onClick={() => setOnlySelectedVendor(!onlySelectedVendor)}
            />
            <label className="form-check-label font-weight-bold pt-1">
              Show items only for the selected vendor
            </label>
          </div>
        </div>
      </div>
      <table className="table table-sm border-bottom">
        <DetailsHeader />
        <tbody>
          {fields.map((line, index) => (
            <tr key={index} name={`details-${index}`}>
              <th scope="row" className="align-baseline">
                {index + 1}
              </th>
              <td className="align-baseline">
                <InputTableSelect
                  name={`details[${index}].item`}
                  options={itemOptions}
                  onChange={populateLine}
                  valueOption="value"
                  labelOption="label"
                  defaultValue={line.item}
                />
              </td>
              <td className="align-baseline">
                <InputTableTextArea
                  name={`details[${index}].description`}
                  defaultValue={line.description}
                />
              </td>
              <td className="align-baseline">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <button
                      type="button"
                      className="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split"
                      data-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="sr-only">Toggle Dropdown</span>
                    </button>
                    <EditDetailsCostOptions
                      item={watch(`details[${index}].item`)}
                      lineIndex={index}
                      items={items}
                      onSelect={handleCostOptionSelect}
                    />
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name={`details[${index}].uom`}
                    defaultValue={line.uom}
                    readOnly
                    ref={register}
                  />
                </div>
                {/* <div className="text-right">
                  <small className="text-primary">
                    {`base: ${numeral(
                      line.costOption?.uomOption?.ratioToBase || 1
                    ).format("0,0.0")} ${
                      line.costOption?.uomOption?.baseUom || ""
                    }`}
                  </small>
                </div> */}
              </td>
              <td className="align-baseline">
                <InputTableNumber
                  name={`details[${index}].qty`}
                  onBlur={handleBlur}
                  defaultValue={line.qty}
                  decimalScale="1"
                />
                {/* <div className="text-right pr-2">
                  <small className="text-primary">
                    {numeral(line.costOption?.uomOption?.ratioToBase || 1)
                      .multiply(line.qty)
                      .format("0,0.000")}
                  </small>
                </div> */}
              </td>
              <td className="align-baseline">
                <InputTableNumber
                  name={`details[${index}].unitCostPerBase`}
                  onBlur={handleBlur}
                  defaultValue={
                    line.unitCostPerBase ||
                    numeral(line.unitCost)
                      .divide(line?.costOption?.uomOption?.ratioToBase || 1)
                      .value()
                  }
                  decimalScale="4"
                />
                {/* <div className="text-right pr-2">
                  <small className="text-primary">
                    {numeral(line.unitCost)
                      .divide(line?.costOption?.uomOption?.ratioToBase || 1)
                      .format("$ 0,0.00")}
                  </small>
                </div> */}
              </td>
              <td className="align-baseline">
                <InputTableNumber
                  name={`details[${index}].unitCost`}
                  onBlur={handleBlur}
                  defaultValue={line.unitCost}
                  decimalScale="4"
                />
                {/* <div className="text-right pr-2">
                  <small className="text-primary">
                    {numeral(line.unitCost)
                      .divide(line?.costOption?.uomOption?.ratioToBase || 1)
                      .format("$ 0,0.00")}
                  </small>
                </div> */}
              </td>
              <td className="align-baseline">
                <InputTableNumber
                  name={`details[${index}].amount`}
                  defaultValue={line.amount}
                  onBlur={handleBlur}
                />
              </td>
              <td className="align-baseline">
                <InputTableSelect
                  name={`details[${index}].taxCode`}
                  options={taxes}
                  onChange={populateTax}
                  valueOption="value"
                  labelOption="label"
                  defaultValue={line.taxCode}
                />
              </td>
              <td className="align-baseline">
                <button
                  className="btn btn-secondary btn-sm d-print-none"
                  type="button"
                  onClick={() => remove(index)}
                >
                  <FontAwesomeIcon icon="trash" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="d-flex justify-content-between">
        <div>
          <Button
            label="+ Add Item"
            type="button"
            className="btn-primary btn-sm shadow-sm"
            onClick={() => append(initialDetail)}
          />
        </div>
      </div>
      <hr />
      <RowFourColumns
        colThree={<h6 className="text-right pt-2">Subtotal: </h6>}
        colFour={<InputNumber name="totals.subTotal" disabled />}
      />
      <RowFourColumns
        colThree={<h6 className="text-right pt-2">Tax: </h6>}
        colFour={<InputNumber name="totals.taxTotal" disabled />}
      />
      <RowFourColumns
        colThree={<h6 className="text-right pt-2">Order Total: </h6>}
        colFour={<InputNumber name="totals.total" disabled />}
      />
    </div>
  );
};

export default EditDetails;
