import React from "react";
import numeral from "numeral";

const FieldNumber = ({
  name = "amount",
  label = "Amount",
  value = 0,
  comment = "",
  decimal = 2,
  ...rest
}) => {
  let zeros = "";
  let i;
  for (i = 0; i < decimal; i++) {
    zeros += "0";
  }
  const format = zeros ? `0,0.${zeros}` : "0,0";
  const formatted = numeral(value).format(format);

  return (
    <div className="form-group">
      {label && (
        <label
          htmlFor={name}
          className="form-label form-label-sm font-weight-bold"
          style={{ marginBottom: "0px" }}
        >
          {label}
        </label>
      )}
      <div className="form-control text-right" name={name} id={name} {...rest}>
        {formatted}
      </div>
      {comment}
    </div>
  );
};

export default FieldNumber;
