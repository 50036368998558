import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, NavLink } from "react-router-dom";
// import { loginUrl } from "../config.json";

const NavBar = ({ user }) => {
  return (
    <nav className="navbar navbar-expand-lg">
      <Link className="navbar-brand text-shadow" to="/">
        / yôrz /: inventory
      </Link>

      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon" />
      </button>

      <div className="collapse navbar-collapse" id="navbarNav">
        {user && (
          <div className="navbar-nav mr-auto">
            <div className="nav-item dropdown">
              <NavLink
                className="nav-link dropdown-toggle"
                style={{ color: "#BEE5EB", fontWeight: "500" }}
                to="/purchase-orders/new"
                id="navbarDropdownMenuLink"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                New
              </NavLink>
              <div
                className="dropdown-menu dropdown-menu-right mr-2"
                aria-labelledby="navbarDropdown"
              >
                <NavLink className="dropdown-item" to="/purchase-orders/new">
                  + Purchase Order
                </NavLink>
                <div className="dropdown-divider"></div>
                <NavLink className="dropdown-item" to="/inventory/new">
                  + Inventory
                </NavLink>
                <div className="dropdown-divider"></div>
                <NavLink
                  className="dropdown-item"
                  to="/documents/new?type=InventoryReceipt"
                >
                  + Inventory Receipt
                </NavLink>
                <NavLink
                  className="dropdown-item"
                  to="/documents/new?type=MaterialsRequisitionForm"
                >
                  + Materials Requisition Form
                </NavLink>
                <NavLink
                  className="dropdown-item"
                  to="/documents/new?type=ProductionReceipt"
                >
                  + Production Receipt
                </NavLink>
                <NavLink
                  className="dropdown-item"
                  to="/documents/new?type=InventoryTransfer"
                >
                  + Inventory Transfer
                </NavLink>
                <NavLink
                  className="dropdown-item"
                  to="/documents/new?type=InventoryShipment"
                >
                  + Inventory Shipment
                </NavLink>
                <NavLink
                  className="dropdown-item"
                  to="/documents/new?type=InventoryWriteDown"
                >
                  + Inventory Write-Down
                </NavLink>
              </div>
            </div>
            <NavLink
              className="nav-item nav-link"
              to="/purchase-orders-dashboard"
            >
              Purchase Orders
            </NavLink>
            <NavLink className="nav-item nav-link" to="/ordered-items">
              Ordered Items
            </NavLink>
            <NavLink className="nav-item nav-link" to="/documents">
              Documents
            </NavLink>
            <NavLink className="nav-item nav-link" to="/inventory">
              Inventory
            </NavLink>
            <NavLink className="nav-item nav-link" to="/transactions">
              Transactions
            </NavLink>
            <NavLink className="nav-item nav-link" to="/reports">
              Reports
            </NavLink>
          </div>
        )}
        {user && (
          <div className="nav-item dropdown">
            <NavLink
              className="nav-link dropdown-toggle"
              to="/settings"
              id="navbarDropdownMenuLink"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {user.company + " "}
            </NavLink>
            <div
              className="dropdown-menu dropdown-menu-right mr-2"
              aria-labelledby="navbarDropdown"
            >
              <NavLink className="dropdown-item" to="/settings/me">
                <FontAwesomeIcon icon="user" /> {user.name}
              </NavLink>
              <div className="dropdown-divider"></div>
              <NavLink className="dropdown-item" to="/settings">
                <FontAwesomeIcon icon="cog" /> Settings
              </NavLink>
              {user.isAdmin && (
                <NavLink className="dropdown-item" to="/tools">
                  <FontAwesomeIcon icon="tools" /> Tools
                </NavLink>
              )}
              <div className="dropdown-divider"></div>
              {/* {user.isAdmin && ( */}
              <NavLink className="dropdown-item" to="/export-qbo-lists">
                Export QBO Lists
              </NavLink>
              <div className="dropdown-divider"></div>
              {/* {user.isAdmin && ( */}
              <NavLink className="dropdown-item" to="/import-qbo-lists">
                Import QBO Lists
              </NavLink>
              {/* // )} */}
              {/* {user.isAdmin && ( */}
              <NavLink className="dropdown-item" to="/import-qbo-txns">
                Import QBO Transactions
              </NavLink>
              {/* )} */}
              <div className="dropdown-divider"></div>
              <NavLink className="dropdown-item" to="/vendors">
                Vendors
              </NavLink>
              <NavLink className="dropdown-item" to="/storages">
                Storages
              </NavLink>
              <NavLink className="dropdown-item" to="/items">
                Items
              </NavLink>
              <div className="dropdown-divider"></div>
              <NavLink className="dropdown-item" to="/logout">
                Logout
              </NavLink>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
