import React from "react";
import { toast } from "react-toastify";
import withLoading from "./../common/withLoading";
import SidebarLeft from "./../common/SidebarLeft";
import ListGroup from "./../common/listGroup";
import RecordsTable from "./RecordsTable";
import NewDocument from "../documents/NewDocument";
import SelectDateBox from "./../common/SelectDateBox";
import SelectBox from "./SelectBox";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getStorages } from "../../services/entityService";
import { getLocationRecords } from "./../../services/recordService";
import { DateTime } from "luxon";
import { paginate } from "../../utils/paginate";
import _ from "lodash";

class Records extends React.Component {
  state = {
    records: [],
    storages: [],
    inventories: [],
    fromDate: DateTime.local().startOf("month").minus({ months: 2 }),
    toDate: DateTime.local().endOf("month").plus({ months: 1 }),
    sortColumn: { path: "doc.txnDate", order: "asc" },
    currentPage: 1,
    pageSize: 300,
    searchQuery: "",
    selectedStorage: null,
    selectedItem: null,
  };

  populateStorages = async () => {
    try {
      const { data } = await getStorages();
      const mapped = data.map((s) => {
        return { value: s._id, label: s.title, code: s.data.code };
      });
      const storages = _.orderBy(mapped, "code", "asc");

      return storages;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  populateRecords = async (locationId) => {
    let query = "";
    if (this.state.fromDate && this.state.toDate)
      query =
        query +
        `?fromDate=${this.state.fromDate.toISODate()}&toDate=${this.state.toDate.toISODate()}`;
    else if (this.state.fromDate)
      query = query + `?fromDate=${this.state.fromDate.toISODate()}`;
    else if (this.state.toDate)
      query = query + `?toDate=${this.state.toDate.toISODate()}`;

    try {
      const { data: records } = await getLocationRecords(locationId, query);
      const items = records.map((r) => {
        return { value: r.inventory.value, label: r.inventory.label };
      });
      const inventories = _.uniqBy(items, "value");
      this.setState({ records, inventories });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  async componentDidMount() {
    const storages = await this.populateStorages();
    let locationId = this.props.match.params.id;
    if (!locationId) locationId = storages[0].value;
    const selectedStorage = storages.find((s) => s.value === locationId);
    await this.populateRecords(locationId);
    this.setState({ storages, selectedStorage });
    this.props.loadingOff();
  }

  handleStorageSelect = async (storage) => {
    window.location = "/transactions/" + storage.value;
  };

  handleSearch = (query) => {
    if (!query) this.setState({ selectedItem: null });
    this.setState({
      searchQuery: query,
      currentPage: 1,
    });
  };

  onItemSelect = (item) => {
    this.setState({
      selectedItem: item,
      searchQuery: item.label,
      currentPage: 1,
    });
  };

  handleDateChange = async () => {
    this.props.loadingOn();

    await this.populateRecords(this.state.selectedStorage.value);

    this.props.loadingOff();
  };

  handleSort = (sortColumn) => {
    this.setState({
      sortColumn: sortColumn,
    });
  };

  handleClick = (record) => {
    this.props.history.push(`/documents/${record._id}`);
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  getPagedData = () => {
    const {
      records,
      // searchQuery,
      selectedItem,
      // selectedStorage,
      sortColumn,
      currentPage,
      pageSize,
    } = this.state;
    const allRecords = [...records];
    let filtered = allRecords;
    // if (searchQuery) {
    //   filtered = allRecords.filter((i) =>
    //     i.inventory.label.toLowerCase().includes(searchQuery.toLowerCase())
    //   );
    if (selectedItem) {
      filtered = allRecords.filter(
        (i) => i.inventory.value === selectedItem.value
      );
      // } else if (selectedStorage) {
      //   console.log(selectedStorage);
      // filtered = allRecords.filter(
      //   (i) => i.location.value === selectedStorage.value
      // );
    }

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const items = paginate(sorted, currentPage, pageSize);

    const totals = {};
    totals.received = items.reduce((sum, i) => {
      return sum + i.received;
    }, 0);
    totals.shipped = items.reduce((sum, i) => {
      return sum + i.shipped;
    }, 0);

    return { totalCount: filtered.length, data: items, totals };
  };

  render() {
    const {
      storages,
      selectedStorage,
      sortColumn,
      fromDate,
      toDate,
      inventories,
      searchQuery,
    } = this.state;

    const { data: records, totalCount, totals } = this.getPagedData();

    return (
      <>
        <SidebarLeft>
          <NewDocument />
          <ListGroup
            items={storages}
            valueProperty="value"
            textProperty="label"
            selectedItem={selectedStorage}
            onItemSelect={this.handleStorageSelect}
            selectedCount={totalCount}
          />
        </SidebarLeft>
        <div className="col mt-2">
          <div className="d-flex py-2">
            <h2 className="text-uppercase">
              Transactions
              {selectedStorage && `: ${selectedStorage.label}`}
            </h2>
          </div>
          <SelectDateBox
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={(value) => this.setState({ fromDate: value })}
            setToDate={(value) => this.setState({ toDate: value })}
            handleDateChange={this.handleDateChange}
          />
          <div className="d-flex justify-content-between d-print-none">
            <SelectBox
              searchQuery={searchQuery}
              onSearch={this.handleSearch}
              options={inventories}
              onSelect={this.onItemSelect}
              label={"Select item..."}
            />
            <div>
              <button
                type="button"
                className="btn btn-secondary mr-2 shadow-sm"
                disabled
                onClick={this.handleExport}
              >
                Export
              </button>
            </div>
          </div>
          <RecordsTable
            items={records}
            totals={totals}
            sortColumn={sortColumn}
            isList={false}
            onDelete={this.handleDelete}
            onSort={this.handleSort}
            onClick={this.handleClick}
          />
        </div>
      </>
    );
  }
}

export default withLoading(Records);
