import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
// import qs from "query-string";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import withFormMode from "../common/withFormMode";
import Breadcrumb from "../common/Breadcrumb";
import { FormTitle, FormSubTitle } from "../common/formLayout";
import {
  getSetting,
  setSetting,
  populateSetting,
} from "../../services/settingService";
import { blobFileUrl } from "../../utils/blobFileUrl";
import { downloadFile } from "../../services/logoService";
import { mapDataToQbo } from "../../utils/poUtils";
import { saveQboEntity } from "../../services/qboEntityService";

import {
  getEntity,
  // getInventoryDocumentTypes,
  saveEntity,
} from "../../services/entityService";
import Edit from "./Edit";
import View from "./View";
import PurchaseOrderPrint from "./PurchaseOrderPrint";
import PurchaseOrderPrintSimple from "./PurchaseOrderPrintSimple";
// import { camelPad } from "./../../utils/utils";

const PurchaseOrder = (props) => {
  const [formMode, setFormMode] = useState({
    isAddActivities: false,
    isNew: false,
    isEdit: false,
    onPrinting: false,
    search: "",
    title: "Purchase Order",
  });

  let info = getSetting("general");
  if (!info)
    info = {
      legalName: "",
      fiscalNumber: "",
      mainPhone: "",
      mainEmail: "",
      line1: "",
      line2: "",
      city: "",
      subDivision: "",
      country: "",
      postalCode: "",
    };
  let logo = getSetting("logo");
  if (!logo)
    logo = {
      name: "",
      fileUrl: "",
      data: "",
    };

  const [purchaseOrder, setPurchaseOrder] = useState({
    title: "Adding new Purchase Order...",
    entity: "Purchase Order",
    data: {
      // type: "",
      txnDate: DateTime.local(),
      dueDate: null,
      status: { value: "100", label: "New" },
      txnNumber: "",
      locationFrom: "",
      vendor: null,
      addressFrom: "",
      locationTo: null,
      // name: null,
      addressTo: "",
      email: "",
      currency: { value: "CAD" },
      memo: "",
      notes: "",
      details: [],
      totals: {},
    },
  });

  const { match } = props;
  const purchaseOrderId = match.params.id;
  const purchaseOrderPath = `/purchase-orders/${purchaseOrderId}`;

  useEffect(() => {
    async function populateDocument(purchaseOrderId) {
      try {
        const { data: purchaseOrder } = await getEntity(
          "PurchaseOrder",
          purchaseOrderId
        );
        purchaseOrder.data.type = purchaseOrder.entity;
        setPurchaseOrder(purchaseOrder);
      } catch (ex) {
        if (ex.response && ex.response.status === 404)
          window.location.href = "/not-found";
      }
    }

    if (purchaseOrderId === "new") {
      return;
    }

    populateDocument(purchaseOrderId);
  }, [purchaseOrderId]);

  useEffect(() => {
    async function populateInfo() {
      try {
        const { data } = await populateSetting("general");
        setSetting("general", data.details);
      } catch (ex) {
        if (ex.response && ex.response.status === 404)
          window.location.href = "/settings/general/edit";
      }
    }

    populateInfo();
  }, []);

  useEffect(() => {
    async function populateLogo() {
      try {
        const { data: fileData } = await downloadFile("logo");
        const { data, mimetype } = fileData;

        const fileUrl = blobFileUrl(data, mimetype);

        const img = {
          name: "logo.png",
          fileUrl: fileUrl,
          data: data,
        };
        setSetting("logo", img);
      } catch (ex) {
        if (ex.response && ex.response.status === 404) {
          toast.info("Please set a logo");
        }
      }
    }

    populateLogo();
  }, []);

  const onSave = async (values) => {
    props.loadingOn();
    const data = { ...purchaseOrder.data };
    data.txnDate = values.txnDate;
    data.dueDate = values.dueDate;
    data.txnNumber = values.txnNumber;
    data.vendor = values.vendor;
    data.currency = values.currency;
    data.addressFrom = values.addressFrom;
    data.locationTo = values.locationTo;
    data.addressTo = values.addressTo;
    data.email = values.email;
    data.notes = values.notes;
    data.status = values.status;
    data.memo = values.memo;
    if (values.details) data.details = values.details;
    if (values.totals) data.totals = values.totals;

    const txnDate = DateTime.fromISO(data.txnDate).toISODate();

    purchaseOrder.entity = "PurchaseOrder";
    let title = "";
    if (data.txnNumber) title = `${data.txnNumber} `;
    title = title + `${txnDate} ${data.vendor && data.vendor.label}`;
    purchaseOrder.title = title;
    purchaseOrder.data = data;

    if (purchaseOrder._id === "new") delete purchaseOrder._id;

    try {
      const { data: po } = await saveEntity(purchaseOrder);
      props.loadingOff();
      toast.success("This purchase order was saved!");
      // if (formMode.isNew)
      //   setFormMode({ ...formMode, isNew: false, isEdit: false });
      // props.history.replace(`/purchase-orders/${po._id}`);
      window.location.href = `/purchase-orders/${po._id}`;
    } catch (ex) {
      props.loadingOff();
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  const onPrint = (print) => {
    console.log("On Print...", print);
    formMode.onPrinting = print;
  };

  if (match.params.id === "new") {
    formMode.isNew = true;
    formMode.isEdit = true;
  }

  const handleQBOExport = async () => {
    props.loadingOn();
    const mapped = await mapDataToQbo(purchaseOrder);
    console.log("QBO export", mapped);
    if (!mapped) {
      toast.error("Incorrect QBO export mapping");
      props.loadingOff();
    }

    try {
      const { data: exported } = await saveQboEntity("purchaseorder", mapped);
      toast.success("This PO was exported!");
      setPurchaseOrder(exported);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }

    props.loadingOff();
  };

  const breadcrumbs = [
    { value: "/purchase-orders", label: "Purchase Orders" },
    {
      value: `/purchase-orders/${purchaseOrderId}`,
      label: `${purchaseOrder.title}`,
    },
  ];

  return (
    <div>
      <Breadcrumb items={breadcrumbs} />
      <FormTitle
        title={formMode.isNew ? `Create Purchase Order` : `Purchase Order`}
      />
      <FormSubTitle
        title={
          <span className="font-weight-bolder text-primary">
            {purchaseOrder.title}
          </span>
        }
      />
      <Switch>
        <Route
          exact
          path={`${purchaseOrderPath}`}
          component={(props) =>
            formMode.isNew ? (
              <Edit
                data={purchaseOrder.data}
                purchaseOrderPath={purchaseOrderPath}
                purchaseOrderId={purchaseOrderId}
                onSave={onSave}
                {...props}
              />
            ) : (
              <View
                data={purchaseOrder.data}
                purchaseOrderPath={purchaseOrderPath}
                purchaseOrderId={purchaseOrderId}
                onPrint={onPrint}
                onExportToQbo={handleQBOExport}
                {...props}
              />
            )
          }
        />
        <Route
          path={`${purchaseOrderPath}/edit`}
          component={(props) => (
            <Edit
              data={purchaseOrder.data}
              purchaseOrderPath={purchaseOrderPath}
              purchaseOrderId={purchaseOrderId}
              onSave={onSave}
              {...props}
            />
          )}
        />
      </Switch>
      {/* {formMode.onPrinting && ( */}
      <PurchaseOrderPrint
        onPrint={onPrint}
        title={`Purchase Order PDF`}
        info={info}
        data={purchaseOrder.data}
      />
      <PurchaseOrderPrintSimple
        onPrint={onPrint}
        title={`Purchase Order Simple Form PDF`}
        info={info}
        data={purchaseOrder.data}
      />
      {/* )} */}
    </div>
  );
};

export default withFormMode(PurchaseOrder);
