import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { toast } from "react-toastify";
// import { DateTime } from "luxon";
import withFormMode from "../common/withFormMode";
import { FormTitle, FormSubTitle } from "../common/formLayout";

import { getEntity, saveEntity } from "../../services/entityService";
import Edit from "./Edit";
import View from "./View";
import { mapQBOtoData } from "./utils";
import { updateData } from "../../utils/updateData";

const Vendor = (props) => {
  const [formMode] = useState({
    isAddActivities: false,
    isNew: false,
    isEdit: false,
    loading: true,
    search: "",
  });

  const [vendor, setVendor] = useState({
    title: "Adding new vendor...",
    data: {
      displayName: "",
      currency: {
        value: "CAD",
        label: "CAD",
      },
      billingAddress: "",
      email: "",
      phone: "",
      note: "",
    },
  });

  const { match } = props;
  const vendorId = match.params.id;
  const vendorPath = `/vendors/${vendorId}`;

  useEffect(() => {
    async function populateVendor(vendorId) {
      try {
        const { data } = await getEntity("Vendor", vendorId);
        const vendor = {
          _id: data._id,
          title: data.title,
          data: data.data
            ? data.data
            : {
                displayName: data.title,
              },
        };
        if (data.qbo) vendor.qbo = data.qbo;
        setVendor(vendor);
      } catch (ex) {
        if (ex.response && ex.response.status === 404)
          window.location.href = "/not-found";
      }
    }

    if (vendorId === "new") {
      return;
    }

    populateVendor(vendorId);
  }, [vendorId]);

  const copyQboToData = async () => {
    if (!vendor.qbo) return null;
    const mapped = mapQBOtoData(vendor.qbo);
    const data = updateData(vendor.data, mapped);
    // return data;
    // console.log(data);
    await onSave(data);
  };

  const onSave = async (values) => {
    const data = { ...vendor.data };
    if (values.displayName) data.displayName = values.displayName;
    data.currency = values.currency;
    data.phone = values.phone;
    data.email = values.email;
    data.note = values.note;
    data.billingAddress = values.billingAddress;

    const title = data.displayName;

    vendor.entity = "Vendor";
    vendor.title = title;
    vendor.data = data;

    if (vendor._id === "new") delete vendor._id;
    try {
      await saveEntity(vendor);
      toast.success("The vendor was saved!");
      window.location = "/vendors/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  if (match.params.id === "new") {
    formMode.isNew = true;
    formMode.isEdit = true;
  }

  return (
    <div>
      <FormTitle title={formMode.isNew ? `Create Vendor` : `Vendor`} />
      <FormSubTitle
        title={
          <span className="font-weight-bolder text-primary">
            {vendor.title}
          </span>
        }
      />
      <Switch>
        <Route
          exact
          path={`${vendorPath}`}
          component={(props) =>
            formMode.isNew ? (
              <Edit
                data={vendor.data}
                vendorPath={vendorPath}
                vendorId={vendorId}
                onSave={onSave}
                isNew={true}
                {...props}
              />
            ) : (
              <View
                data={vendor.data}
                vendorPath={vendorPath}
                qbo={vendor.qbo}
                copyQboToData={copyQboToData}
                {...props}
              />
            )
          }
        />
        <Route
          path={`${vendorPath}/edit`}
          component={(props) => (
            <Edit
              data={vendor.data}
              vendorPath={vendorPath}
              vendorId={vendorId}
              onSave={onSave}
              {...props}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default withFormMode(Vendor);
