import http from "./httpService";
import { getJwt } from "./authService";

const apiEndpoint = "/settings";

const getOptionsHeader = async () => {
  const token = getJwt();
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": token,
    },
  };
};

export async function uploadFile(file) {
  const options_default = await getOptionsHeader();
  const options = { ...options_default };
  const formData = new FormData();
  formData.append("file", file);
  try {
    const result = await http.post(`${apiEndpoint}/logo`, formData, options);
    return result;
  } catch (error) {
    return error;
  }
}

export async function downloadFile() {
  const result = await http.get(`${apiEndpoint}/logo`);

  return result;
}
