import qboApi from "./qboApiService";

const apiEndpoint = qboApi.apiUrl + "/qboEntities";

function typeUrl(entity) {
  return `${apiEndpoint}/${entity}`;
}

function entityUrl(entity, id) {
  return `${apiEndpoint}/${entity}/${id}`;
}

export function getList(entity, query, startPosition = 0, maxResults = 1000) {
  let queryList = "?max=" + maxResults;
  if (startPosition > 0) queryList = queryList + "&start=" + startPosition;
  if (query && query.filter) queryList = queryList + "&filter=" + query.filter;
  if (query && query.fields) queryList = queryList + "&fields=" + query.fields;
  return qboApi.get(typeUrl(entity) + queryList);
}

export function countEntities(entity, query) {
  let queryList = "";
  if (query && query.filter) queryList = queryList + "?filter=" + query.filter;
  return qboApi.get(typeUrl(entity) + "/count" + queryList);
}

export function getEntity(entity, id) {
  return qboApi.get(entityUrl(entity, id));
}

export function saveQboEntity(entity, item) {
  return qboApi.post(typeUrl(entity), item);
}

export function batchCreate(entity, batchRequest) {
  return qboApi.post(typeUrl(entity) + "/batchCreate", batchRequest);
}

export function batchUpdateCustomers(entity, batchRequest) {
  return qboApi.post(typeUrl(entity) + "/batchUpdate", batchRequest);
}

export function deleteEntity(entity, id) {
  return qboApi.delete(entityUrl(entity, id));
}
