import React from "react";
import { Route, Redirect } from "react-router-dom";

const ProtectedRoute = ({
  children,
  component: Component,
  user,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => 
        user ? ( Component ? <Component user={user} {...props} /> :
          children
          ) : (
            <Redirect
              to={{
                pathname: "/auth/login",
                state: { from: props.location },
              }}
            />
       )}
    />
  )};

export default ProtectedRoute;
