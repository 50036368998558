import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";

export default function Form({ defaultValues, children, schema, onSubmit }) {
  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
  const { handleSubmit, errors } = methods;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {React.Children.map(children, (child) => {
        return child.props.name
          ? React.createElement(child.type, {
              ...{
                ...child.props,
                register: methods.register,
                key: child.props.name,
                error: errors[child.props.name]
                  ? errors[child.props.name].message
                  : null,
              },
            })
          : child;
      })}
    </form>
  );
}
