import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import withLoading from "../common/withLoading";
import SidebarLeft from "../common/SidebarLeft";
import ListGroup from "../common/listGroup";
import DashboardList from "./DashboardList";
import { poColumns } from "./dashboardUtils";
import DashboardControls from "./DashboardControls";
import SearchBox from "../common/searchBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { paginate } from "../../utils/paginate";
import {
  getEntitiesQuery,
  deleteEntity,
  saveEntity,
} from "../../services/entityService";
import { detailsToTextArea, poStatuses } from "../../utils/poUtils";
import { tableTiming } from "../../utils/timing";
// import * as auth from "../../services/authService";
import { DateTime } from "luxon";
import _ from "lodash";

class Dashboard extends React.Component {
  state = {
    purchaseOrders: [],
    statuses: [],
    fromDate: DateTime.local().minus({ days: 14 }),
    toDate: DateTime.local().plus({ months: 2 }).endOf("month"),
    sortColumn: { path: "data.txnDate", order: "desc" },
    selectedStatus: null,
    currentPage: 1,
    pageSize: 150,
    searchQuery: "",
    loading: true,
  };

  populatePurchaseOrders = async () => {
    let query = "";
    if (this.state.fromDate && this.state.toDate)
      query = `?fromDate=${this.state.fromDate.toISO()}&toDate=${this.state.toDate.toISO()}`;
    else if (this.state.fromDate)
      query = `?fromDate=${this.state.fromDate.toISO()}`;
    else if (this.state.toDate) query = `?toDate=${this.state.toDate.toISO()}`;
    // else query = "";

    try {
      const { data: purchaseOrders } = await getEntitiesQuery(
        "PurchaseOrder",
        query
      );
      return purchaseOrders;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      return [];
    }
  };

  populateOpenPurchaseOrders = async () => {
    let query = "?status=Open";

    try {
      const { data } = await getEntitiesQuery("PurchaseOrder", query);
      const purchaseOrders = data.map((purchaseOrder) => {
        purchaseOrder.rowClass = tableTiming(
          purchaseOrder.data && purchaseOrder.data.dueDate
        );
        return purchaseOrder;
      });
      return purchaseOrders;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      return [];
    }
  };

  populateStatuses = () => {
    const statuses = [{ value: "", label: "All statuses" }, ...poStatuses];
    this.setState({ statuses });
  };

  async componentDidMount() {
    await this.populateStatuses();
    const openPurchaseOrders = await this.populateOpenPurchaseOrders();
    const completePurchaseOrders = await this.populatePurchaseOrders();
    const united = [...openPurchaseOrders, ...completePurchaseOrders];
    const uniq = _.uniqBy(united, "_id");
    const purchaseOrders = uniq.map((purchaseOrder) => {
      purchaseOrder.search = purchaseOrder.title;
      if (purchaseOrder.data) {
        const details = detailsToTextArea(purchaseOrder.data.details);
        purchaseOrder.search =
          purchaseOrder.search + " " + details + " " + purchaseOrder.data.memo;
      }
      return purchaseOrder;
    });
    this.setState({ purchaseOrders });
    this.props.loadingOff();
  }

  //   handleDateChange = async () => {
  //     this.props.loadingOn();
  //     await this.populatePurchaseOrders();
  //     this.props.loadingOff();
  //   };

  handleStatusSelect = (status) => {
    this.setState({
      selectedStatus: status,
      searchQuery: "",
      currentPage: 1,
    });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      selectedStatus: null,
      currentPage: 1,
    });
  };

  handleSort = (sortColumn) => {
    this.setState({
      sortColumn: sortColumn,
    });
  };

  //   handleImport = () => {
  //     this.props.history.push(`/import-purchaseOrders`);
  //   };

  handleSchedulePurchaseOrder = (purchaseOrder) => {
    console.log("Schedule purchaseOrder:", purchaseOrder);
  };

  handleClick = (purchaseOrder) => {
    this.props.history.push(`/purchaseOrders/${purchaseOrder._id}`);
  };

  handleDelete = async (purchaseOrder) => {
    const originalPurchaseOrders = this.state.purchaseOrders;
    const purchaseOrders = originalPurchaseOrders.filter(
      (m) => m._id !== purchaseOrder._id
    );
    this.setState({ purchaseOrders });

    try {
      await deleteEntity("PurchaseOrder", purchaseOrder._id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This order has already been deleted.");

      this.setState({ purchaseOrders: originalPurchaseOrders });
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  handleStatusChange = async (purchaseOrder, status) => {
    const updated = { ...purchaseOrder.data };
    const timeStamp = DateTime.local().toISO();
    const addMessage = {
      timeStamp: timeStamp,
      user: { value: this.props.user._id, label: this.props.user.name },
      message: "Status changed to: " + status.label,
    };
    const messages = updated.messages ? updated.messages : [];
    messages.push(addMessage);
    updated.messages = messages;
    updated.status = status;
    if (status.value.startsWith("4") || status.value.startsWith("5")) {
      updated.finishDate = DateTime.local().toISO();
    }

    const updatedPurchaseOrder = {
      _id: purchaseOrder._id,
      entity: "PurchaseOrder",
      title: purchaseOrder.title,
      data: updated,
    };

    try {
      const { data } = await saveEntity(updatedPurchaseOrder);
      toast.success(`${data.title} was updated.`);
      window.location = "/dashboard/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  getPagedData = () => {
    const { purchaseOrders, searchQuery, selectedStatus, sortColumn } =
      this.state;
    const allPurchaseOrders = [...purchaseOrders];
    let filtered = allPurchaseOrders;
    if (searchQuery) {
      filtered = allPurchaseOrders.filter((i) =>
        i.search.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else if (selectedStatus && selectedStatus.value) {
      filtered = allPurchaseOrders.filter(
        (i) => i.data.status.value === selectedStatus.value
      );
    }

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    // const items = paginate(sorted, currentPage, pageSize);

    const totals = {};

    totals.total = sorted.reduce((sum, i) => {
      return sum + i.data.totals ? i.data.totals.total : 0;
    }, 0);

    totals.purchaseOrders = sorted.length;

    const newPurchaseOrders = sorted.filter((i) =>
      i.data.status.value.startsWith("1")
    );
    const confirmedPurchaseOrders = sorted.filter((i) =>
      i.data.status.value.startsWith("2")
    );
    const openPurchaseOrders = sorted.filter((i) =>
      i.data.status.value.startsWith("3")
    );
    const united = [
      ...newPurchaseOrders,
      ...confirmedPurchaseOrders,
      ...openPurchaseOrders,
    ];
    const recentlyFinishedPurchaseOrders = sorted.filter(
      (i) => !united.map((b) => b._id).includes(i._id)
    );

    return {
      totalCount: sorted.length,
      //   data: filtered,
      totals,
      newPurchaseOrders,
      confirmedPurchaseOrders,
      openPurchaseOrders,
      recentlyFinishedPurchaseOrders,
    };
  };

  render() {
    const { selectedStatus, searchQuery, statuses } = this.state;

    const {
      totalCount,
      newPurchaseOrders,
      confirmedPurchaseOrders,
      openPurchaseOrders,
      recentlyFinishedPurchaseOrders,
    } = this.getPagedData();

    return (
      <>
        <SidebarLeft>
          <div>
            <Link
              to="/purchase-orders/new"
              className="btn btn-primary shadow"
              data-toggle="tooltip"
              data-placement="top"
              title="Create a new purchase order"
              style={{ marginBottom: 18 }}
              disabled={false}
            >
              <FontAwesomeIcon icon="plus" className="mr-2" />
              Add Purchase Order
            </Link>
          </div>
          <ListGroup
            items={statuses}
            valueProperty="value"
            textProperty="label"
            selectedItem={selectedStatus}
            onItemSelect={this.handleStatusSelect}
            selectedCount={totalCount}
          />
          <ul className="list-group mt-3 pt-2 border-top shadow-sm">
            <Link to="/purchase-orders" className="list-group-item">
              All purchase orders...
            </Link>
          </ul>
          {/* <div className="mt-3 pt-2 border-top">
            <Link to="/purchase-orders" className="btn btn-info shadow-sm">
              All purchase orders...
            </Link>
          </div> */}
        </SidebarLeft>
        <div className="col mt-2">
          <div className="d-flex py-2 border-bottom">
            <h2 className="text-uppercase">Purchase Order Dashboard</h2>
          </div>
          <div className="d-flex justify-content-between border-bottom py-2 d-print-none ">
            <SearchBox
              value={searchQuery}
              placeholder="Search ..."
              onChange={this.handleSearch}
            />
            <div>
              <DashboardControls />
            </div>
          </div>
          <DashboardList
            title="New"
            // onHeaderClick={() => (window.location = "/production-schedule")}
            // headerToolTip="Schedule purchaseOrders"
            items={newPurchaseOrders}
            columns={poColumns()}
            sorting={{ path: "data.txnDate", order: "asc" }}
          />
          <DashboardList
            title="Confirmed"
            // onHeaderClick={() => (window.location = "/production-schedule")}
            // headerToolTip="Go to production schedule"
            items={confirmedPurchaseOrders}
            columns={poColumns()}
            sorting={{ path: "data.txnDate", order: "asc" }}
          />
          <DashboardList
            title="In progress"
            // onHeaderClick={() => (window.location = "/production-schedule")}
            // headerToolTip="Go to production schedule"
            items={openPurchaseOrders}
            columns={poColumns()}
            sorting={{ path: "data.txnDate", order: "asc" }}
          />
          <DashboardList
            title="Complete and Canceled"
            // onHeaderClick={() => (window.location = "/purchase-orders")}
            // headerToolTip="View all purchaseOrders"
            items={recentlyFinishedPurchaseOrders}
            columns={poColumns()}
            sorting={{ path: "data.txnDate", order: "asc" }}
          />
        </div>
      </>
    );
  }
  // const { length: count } = purchaseOrders;
}

export default withLoading(Dashboard);
