import React, { useState } from "react";
import Table from "../common/table";

const UomOptions = (props) => {
  const { options = [] } = props;
  const [sortColumn, setSortColumn] = useState({ path: "code", order: "asc" });
  const onSort = (sortColumn) => {
    setSortColumn(sortColumn);
  };

  const columns = [
    {
      path: "code",
      label: "Code",
    },
    {
      path: "description",
      label: "Description",
      className: "w-50",
    },
    {
      path: "baseUom",
      label: "Base UOM",
      className: "text-center",
    },
    {
      path: "ratioToBase",
      label: "Ratio to Base",
      className: "text-center",
    },
  ];
  if (options.length < 1)
    return <p>No unit measure options other than the base UOM</p>;
  return (
    <Table
      columns={columns}
      data={options}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default UomOptions;
