// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import DateFormat from "../common/dateFormat";
import { detailsToTextArea } from "../../utils/poUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import StatusLabel from "./../common/StatusLabel";

export const poColumns = () => {
  const cols = [
    {
      path: "data.txnNumber",
      label: "PO Number",
      className: "text-center",
      content: (order) => (
        <Link to={`/purchase-orders/${order._id}`}>
          {order.data && order.data.txnNumber}
        </Link>
      ),
    },
    {
      path: "data.txnDate",
      label: "Date",
      content: (order) => (
        <DateFormat value={order.data && order.data.txnDate} />
      ),
    },
    {
      path: "data.vendor.label",
      label: "Supplier",
      // className: "text-center",
    },
    {
      path: "data.locationTo.label",
      label: "To",
      className: "text-center",
    },

    {
      path: "details",
      label: "Details",
      content: (order) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {detailsToTextArea(order.data.details)}
        </div>
      ),
    },
    {
      path: "data.memo",
      label: "Memo",
      content: (order) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {order.data && order.data.memo}
        </div>
      ),
    },

    // {
    //   key: "controls",
    //   content: (order) => (
    //     <div className="text-right d-print-none">
    //       <button
    //         onClick={() => this.props.onDelete(order)}
    //         className="btn btn-danger btn-sm"
    //       >
    //         <FontAwesomeIcon icon="trash" />
    //       </button>
    //     </div>
    //   ),
    // },
  ];

  return cols;
};
