import React, { Component } from "react";
import withLoading from "../common/withLoading";
import { getQboTxns } from "../../services/qboData";
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import Button from "../common/button";
// import auth from "../services/authService";
import QboImportTxnsTable from "./QboImportTxnsTable";
import ListGroup from "../common/listGroup";
import { getList, countEntities } from "../../services/qboEntityService";
import Pagination from "../common/pagination";
import {
  qboEntityLastUpdated,
  findQboEntity,
  saveQboEntity,
} from "./qboExchange";
// import { saveQboEntity } from "../services/qboEntityService";
import { paginate } from "../../utils/paginate";
import { getEntities } from "../../services/entityService";
import * as billUtils from "../../utils/billUtils";
import * as orderUtils from "../../utils/poUtils";
import { findEntity } from "../../utils/findEntity";
import { toast } from "react-toastify";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

class QboImportTxns extends Component {
  state = {
    imported: [],
    verified: [],
    entityTypes: [],
    customers: [],
    vendors: [],
    salesClasses: [],
    items: [],
    taxCodes: [],
    summary: [],
    selected: null,
    currentPage: 1,
    pageSize: 150,
    searchQuery: "",
    sortColumn: { path: "title", order: "asc" },
    fromDate: DateTime.local().startOf("month").minus({ months: 1 }),
    toDate: DateTime.local().endOf("month"),
    queryOption: 1,
    query: {},
    getAllEntities: false,
    requestSize: 1000,
    requestPage: 1,
    displayImport: false,
    displaySummary: true,
  };

  populateEntityTypes = async () => {
    const itemTypes = ["PurchaseOrder", "Bill"];
    const { data } = await getQboTxns();
    const types = data.filter((t) => itemTypes.includes(t.entityName));
    return types;
  };

  populateCustomers = async () => {
    try {
      const { data } = await getEntities("Customer");
      const customers = data.map((i) => ({
        label: i.title,
        value: i._id,
        data: i.data,
        qbo: i.qbo,
      }));
      this.setState({ customers });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  populateVendors = async () => {
    try {
      const { data } = await getEntities("Vendor");
      const vendors = data.map((i) => ({
        label: i.title,
        value: i._id,
        data: i.data,
        qbo: i.qbo,
      }));
      this.setState({ vendors });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  populateSalesClasses = async () => {
    try {
      const { data } = await getEntities("Class");
      const salesClasses = data.map((i) => ({
        label: i.title,
        value: i._id,
        qbo: i.qbo,
      }));
      this.setState({ salesClasses });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  populateItems = async () => {
    try {
      const { data } = await getEntities("Item");
      const items = data.map((item) => ({
        label: item.title,
        value: item._id,
        data: item.data,
        qbo: item.qbo,
      }));
      this.setState({ items });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  populateTaxes = async () => {
    try {
      const { data } = await getEntities("TaxCode");
      const taxCodes = data.map((tax) => ({
        label: tax.title,
        value: tax._id,
        rate: tax.data.rate || 0,
        qbo: tax.qbo,
      }));
      this.setState({ taxCodes });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  async componentDidMount() {
    await this.populateCustomers();
    await this.populateVendors();
    await this.populateSalesClasses();
    await this.populateItems();
    await this.populateTaxes();
    const entityTypes = await this.populateEntityTypes();
    let selected = entityTypes[0];
    await this.handleOptionsSelect(selected);
    this.setState({ entityTypes, selected });
    this.props.loadingOff();
  }

  getLastUpdated = async (entity) => {
    // let { query, queryOption } = this.state;
    // if (queryOption !== 1) return null;
    // if (entity === "estimate") entity = "salesorder";
    // console.log(entity);
    let { data: lastUpdated } = await qboEntityLastUpdated(entity);
    let query = null;

    if (lastUpdated) {
      query = {
        filter: `Metadata.LastUpdatedTime > '${lastUpdated}'`,
      };
      this.setState({ query });
    }
    return query;
  };

  getOpenItems = (entity) => {
    const { fromDate, toDate } = this.state;
    // console.log(entity, fromDate, toDate);
    let query = null;
    if (entity) {
      query = {
        filter: `TxnDate >= '${fromDate.toISO()}' AND TxnDate <= '${toDate.toISO()}'`,
      };
    }
    return query;
  };

  getDateRange = (entity) => {
    const { fromDate, toDate } = this.state;
    // console.log(entity, fromDate, toDate);
    let query = null;
    if (entity) {
      query = {
        filter: `TxnDate >= '${fromDate.toISO()}' AND TxnDate <= '${toDate.toISO()}'`,
      };
    }
    return query;
  };

  handleDateChange = async () => {
    const { selected } = this.state;

    await this.handleOptionsSelect(selected, 3);
  };

  setQueryOption = async (option) => {
    this.setState({ queryOption: option });

    // console.log(option);
    let { selected } = this.state;

    // if (queryOption === 3) {
    // } else if (queryOption === 2) {
    // } else {
    //   query = await this.getLastUpdated(selected.entity);
    // }

    // this.setState({ queryOption: option });
    await this.handleOptionsSelect(selected, option);
  };

  handleOptionsSelect = async (selected, queryOption = 1) => {
    this.props.loadingOn();
    let { requestSize } = this.state;

    let query = null;
    if (queryOption === 1) query = await this.getLastUpdated(selected.entity);
    if (queryOption === 3) query = this.getDateRange(selected.entity);

    const { data: entities } = await countEntities(selected.entity, query);

    // const { totalCount } = entities;
    const pagesCount = Math.ceil(entities.totalCount / requestSize);

    let imported = [];
    this.setState({ imported });
    for (let p = 0; p < pagesCount; p++) {
      let startPosition = p * requestSize + 1;
      let received = await this.getImports(
        selected,
        query,
        startPosition,
        requestSize
      );
      if (received) {
        const filtered = received.filter(
          (entity) =>
            entity.Line.length > 0 &&
            entity.Line.some((line) =>
              line.hasOwnProperty("ItemBasedExpenseLineDetail")
            )
        );
        imported = [...imported, ...filtered];
      }
      // console.log(imported);
    }

    let allImported = imported;
    if (queryOption === 2 && selected.entityName === "PurchaseOrder")
      allImported = imported.filter((m) => m.POStatus === "Open");

    this.setState({
      selected: selected,
      searchQuery: "",
      imported: allImported,
      verified: [],
      currentPage: 1,
    });
    this.props.loadingOff();
  };

  getImports = async (entityType, query, startPosition, requestSize) => {
    try {
      let { data: received } = await getList(
        entityType.entity,
        query,
        startPosition,
        requestSize
      );
      return received;
    } catch (error) {
      console.log("Error: " + error);
      return null;
    }
  };

  handleVerify = async () => {
    let { imported } = this.state;
    this.props.progressOn();
    const verified = [];
    let d = 0;
    for (let item of imported) {
      d++;
      this.props.progressUpdate(
        "Verifying transactions...",
        d,
        imported.length
      );
      const updated = await this.onVerifyEntity(item);
      if (updated.data) verified.push(updated);
      if (updated.errors) item.errors = updated.errors;
    }

    const withErrors = imported.filter((e) => e.errors);

    this.props.progressOff();

    this.setState({ verified, imported: withErrors });
  };

  onVerifyEntity = async (item) => {
    let { selected, vendors, taxCodes, items, salesClasses } = this.state;

    const errors = [];

    let entityName = selected.entityName;
    // if (entityName === "Estimate") entityName = "SalesOrder";

    // find by qbo.Id or txnNumber verification
    if (!item.DocNumber) errors.push({ message: "Missing document number" });

    let query = `?DocNumber=${item.DocNumber}&QboId=${item.Id}`;

    let found = {};
    try {
      const { data } = await findQboEntity(entityName, query);
      found = data;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        errors.push({ message: ex.response.data });
      }
    }

    if (found.isFound === true) {
      let entityLink = "/";
      if (selected.entityName === "Bill")
        entityLink = entityLink + "bills/" + found.id;
      if (selected.entityName === "PurchaseOrder")
        entityLink = entityLink + "purchase-orders/" + found.id;
      errors.push({
        message: `Number ${item.DocNumber} is already taken, Ref: ${entityLink}`,
      });
    }

    let itemTitle =
      found.title ||
      `${item.TxnDate} ${item[selected.amount] || ""} Id:${
        item[selected.title]
      }`;

    let type = found.entity || "";
    let data = { ...found.data } || {};

    if (selected.entityName === "Bill") {
      // mapping Invoice QBO to data
      data = billUtils.mapQBOtoData(item, data);

      // finding refs
      data.vendor.value = findEntity(data.vendor.label, vendors);
      if (!data.vendor.value)
        errors.push({ message: "Couldn't find this vendor" });

      if (data.salesClass) {
        data.salesClass.value = findEntity(data.salesClass.label, salesClasses);
        if (!data.salesClass.value)
          errors.push({ message: "Couldn't find this sales class" });
      }

      data.details.forEach((line) => {
        if (line?.item?.label) {
          line.item.value = findEntity(line?.item?.label, items);
          if (!line.item.value)
            errors.push({ message: "Couldn't find the item" });
        }

        const tax = taxCodes.find((t) => t.qbo.Id === line.taxCode.qboId);

        if (tax) {
          line.taxCode = {
            value: tax.value,
            label: tax.label,
            rate: tax.rate,
          };
        } else {
          errors.push({ message: "Couldn't find the tax" });
        }
      });

      itemTitle = billUtils.generateTitle(data);
      type = "Bill";
    } else if (selected.entityName === "PurchaseOrder") {
      data = orderUtils.mapQBOtoData(item);

      data.vendor.value = findEntity(data.vendor.label, vendors);
      if (data.salesClass)
        data.salesClass.value = findEntity(data.salesClass.label, salesClasses);

      data.details.forEach((line) => {
        if (line.item && line.item.label) {
          line.item.value = findEntity(line.item.label, items);
          const tax = taxCodes.find((t) => t.qbo.Id === line.taxCode.qboId);
          if (tax) {
            line.taxCode = {
              value: tax.value,
              label: tax.label,
              rate: tax.rate,
            };
          }
          // console.log(line);
        } else if (line.DetailType === "ItemBasedExpenseLineDetail") {
          const tax = taxCodes.find((t) => t.qbo.Id === line.taxCode.qboId);
          if (tax) {
            line.taxCode = {
              value: tax.value,
              label: tax.label,
              rate: tax.rate,
            };
          }
        }
      });

      itemTitle = orderUtils.generateTitle(data);
      type = "PurchaseOrder";
    }

    if (errors.length > 0) item.errors = errors;

    let entity = {
      entity: type,
      title: itemTitle,
      data: data,
      qbo: item,
    };
    if (found._id) entity._id = found._id;

    return entity;
  };

  handleImport = async () => {
    let { verified } = this.state;
    this.props.progressOn();

    let d = 0;
    for (let item of verified) {
      d++;
      this.props.progressUpdate(
        "Importing transactions...",
        d,
        verified.length
      );
      item = await this.saveSingleEntity(item);
    }

    this.props.progressOff();

    const notImported = verified.filter((item) => item.errors);
    this.setState({ verified: notImported });
  };

  handleSingleImport = async (item) => {
    this.props.loadingOn();
    item = await this.saveSingleEntity(item);
    return item;
  };

  saveSingleEntity = async (item) => {
    const { selected } = this.state;
    let type = selected.entityName;
    if (type === "Estimate") type = "SalesOrder";

    try {
      const { data: saved } = await saveQboEntity(type, item);
      saved.isImported = true;
      console.log("Saved: ", saved.title);
      this.props.loadingOff();
      return saved;
    } catch (ex) {
      const errors = [{ error: ex.response, message: "Couldn't save entity" }];
      item.errors = errors;
      this.props.loadingOff();
      return null;
    }
  };

  handleDelete = (item) => {
    const imported = this.state.imported.filter((t) => t.Id !== item.Id);
    this.setState({ imported: imported });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      // queryOption,
      // selected,
      // searchQuery,
      imported: allImported,
    } = this.state;

    let filtered = allImported;

    // if (queryOption === 2 && selected.entityName === "PurchaseOrder")
    //   filtered = allImported.filter((m) => m.POStatus === "Open");

    // if (queryOption === "2" && selected.entityName === "Bill")
    //   filtered = allImported.filter((m) => m.qbo.POStatus === "Open");

    // if (searchQuery)
    //   filtered = allImported.filter((m) =>
    //     m.title.toLowerCase().includes(searchQuery.toLowerCase())
    //   );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.entity]);

    const imported = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: imported };
  };

  render() {
    let {
      pageSize,
      currentPage,
      imported: allEntities,
      fromDate,
      toDate,
      queryOption,
      verified,
      sortColumn,
      getAllEntities,
      selected,
    } = this.state;
    // const user = auth.getCurrentUser();

    const { totalCount, data: imported } = this.getPagedData();
    const totalItems = allEntities.length;

    // let displayImport = "d-none";
    // this.state.imported.length === 0
    //   ? (displayImport = "d-none")
    //   : (displayImport = "");

    let displayNoRecords = "";
    this.state.imported.length === 0
      ? (displayNoRecords = "")
      : (displayNoRecords = "d-none");

    return (
      <>
        <div id="sidebar-left" className="col-2 pt-4">
          <ListGroup
            items={this.state.entityTypes}
            valueProperty="entityName"
            textProperty="entityName"
            selectedItem={this.state.selected}
            onItemSelect={this.handleOptionsSelect}
            selectedCount={totalCount}
          />
        </div>
        <div className="col mt-2">
          <div className="d-flex py-2">
            <h2 className="text-uppercase">Import Transactions from QBO</h2>
          </div>
          <div className="row pb-3">
            <div className="col">
              <p>
                Showing items since your last update.
                <br />
                if you would like to import entities for a date range or all of
                them, select an uppropriate option.
              </p>
            </div>
            <div className="w-100"></div>
            <div className="col">
              <div className="form-check form-check-inline mx-2 align-bottom">
                <input
                  type="radio"
                  className="form-check-input"
                  id="LastUpdated"
                  value={queryOption}
                  checked={queryOption === 1}
                  // defaultChecked
                  onChange={() => this.setQueryOption(1)}
                />
                <label className="form-check-label" htmlFor="getAllEntities">
                  Since last updated
                </label>
              </div>
              <div className="form-check form-check-inline mx-2 align-bottom">
                <input
                  type="radio"
                  className="form-check-input"
                  id="openItems"
                  value={queryOption}
                  checked={queryOption === 2}
                  onChange={() => this.setQueryOption(2)}
                />
                <label className="form-check-label" htmlFor="getAllEntities">
                  Open items
                </label>
              </div>
              <div className="form-check form-check-inline mx-2 align-bottom">
                <input
                  type="radio"
                  className="form-check-input"
                  id="dateRange"
                  value={queryOption}
                  checked={queryOption === 3}
                  onChange={() => this.setQueryOption(3)}
                />
                <label className="form-check-label" htmlFor="getAllEntities">
                  Date range
                </label>
              </div>
              <div className="form-check form-check-inline mx-2 align-bottom">
                <input
                  type="radio"
                  className="form-check-input"
                  id="getAllEntities"
                  value={queryOption}
                  checked={queryOption === 4}
                  onChange={() => this.setQueryOption(4)}
                />
                <label className="form-check-label" htmlFor="getAllEntities">
                  Get all entities
                </label>
              </div>
            </div>
          </div>
          {queryOption === 3 && (
            <div className="col">
              <div className="d-flex pb-n5 ">
                <div className="form-group col-2 pt-2 ml-n3 mr-n2">
                  <label
                    htmlFor="fromDate"
                    className="form-label form-label-sm font-weight-bold"
                    style={{ marginBottom: "0px", marginRight: "10px" }}
                  >
                    Date from:
                  </label>
                  <DatePicker
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                    selected={Date.parse(fromDate)}
                    onChange={(value) => {
                      const selected = DateTime.fromJSDate(value);
                      this.setState({ fromDate: selected });
                    }}
                  />
                </div>
                <div className="form-group col-2 pt-2 mx-n3">
                  <label
                    htmlFor="toDate"
                    className="form-label form-label-sm font-weight-bold"
                    style={{ marginBottom: "0px", marginRight: "10px" }}
                  >
                    Date to:
                  </label>
                  <DatePicker
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                    selected={Date.parse(toDate)}
                    onChange={(value) => {
                      const selected = DateTime.fromJSDate(value);
                      this.setState({ toDate: selected });
                    }}
                  />
                </div>
                <div className="d-flex pt-4">
                  <Button
                    label="Get Entities.."
                    type="button"
                    className="btn-secondary btn shadow-sm ml-2 mr-4 mb-3"
                    onClick={this.handleDateChange}
                  />
                </div>
              </div>
            </div>
          )}
          <div id="noRecords" className={displayNoRecords}>
            <p> No items to update since your last sync.</p>
          </div>
          {verified < 1 && (
            <div id="displayImported">
              <h5>Review items to import</h5>
              {getAllEntities ? (
                <p>Total {totalItems} items available to import </p>
              ) : (
                <p>Total {totalItems} items since your last update.</p>
              )}

              <QboImportTxnsTable
                items={imported}
                type={selected}
                sortColumn={sortColumn}
                onDelete={this.handleDelete}
                onSort={this.handleSort}
                // onSave={this.handleSingleImport}
                isList={true}
              ></QboImportTxnsTable>

              <div>
                <Pagination
                  itemsCount={totalCount}
                  pageSize={pageSize}
                  currentPage={currentPage}
                  onPageChange={this.handlePageChange}
                />
              </div>
            </div>
          )}
          {verified.length > 0 && (
            <QboImportTxnsTable
              items={verified}
              type={selected}
              sortColumn={sortColumn}
              onDelete={this.handleDelete}
              onSort={this.handleSort}
              onSave={this.handleSingleImport}
              isList={false}
            ></QboImportTxnsTable>
          )}
          <div className="card mb-3">
            <div className="row p-3">
              <div className="col-6">
                Total imported: {imported.length}. Total verified:{" "}
                {verified.length}.
              </div>
              <div className="col-6 d-flex justify-content-end">
                <button
                  className="btn btn-info shadow ml-2"
                  onClick={this.handleVerify}
                  disabled={verified.length > 0 ? true : false}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Verify Items"
                >
                  Verify Items
                </button>
                <button
                  className="btn btn-success shadow ml-2"
                  onClick={this.handleImport}
                  disabled={verified.length > 0 ? false : true}
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Save items to database"
                >
                  Import Items
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withLoading(QboImportTxns);
