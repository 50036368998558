import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { toast } from "react-toastify";
// import { DateTime } from "luxon";
import withFormMode from "../common/withFormMode";
import { FormTitle, FormSubTitle } from "../common/formLayout";
import Breadcrumb from "../common/Breadcrumb";
import { getEntity, saveEntity } from "../../services/entityService";
import { exportToQboById } from "../../services/qboExportService";
import { updateData } from "../../utils/updateData";
import FormEdit from "./FormEdit";
import FormView from "./FormView";

const Item = (props) => {
  const [formMode] = useState({
    isAddActivities: false,
    isNew: false,
    isEdit: false,
    loading: true,
    search: "",
  });

  const [item, setItem] = useState({
    title: "Adding new item...",
    data: {
      name: "",
      type: "Item",
      description: "",
      purchaseDescription: "",
      uom: "EACH",
      sku: "",
      parts: [],
      // cost: "",
      // unitPrice: "",
    },
  });

  const { match } = props;
  const itemId = match.params.id;
  const itemPath = `/items/${itemId}`;

  useEffect(() => {
    async function populateItem(itemId) {
      try {
        const { data: item } = await getEntity("Item", itemId);
        if (item.data && !item.data.name) item.data.name = item.title;
        setItem(item);
      } catch (ex) {
        if (ex.response && ex.response.status === 404)
          window.location.href = "/not-found";
      }
    }

    if (itemId === "new") {
      return;
    }

    populateItem(itemId);
  }, [itemId]);

  const onSave = async (values) => {
    const data = { ...item.data };
    const updated = updateData(data, values);
    console.log("Data", updated);
    const title = updated.name;

    item.entity = "Item";
    item.title = title;
    item.data = updated;

    if (item._id === "new") delete item._id;
    if (item.lastUpdatedQBOnline) delete item.lastUpdatedQBOnline;

    console.log(item);

    try {
      const { data } = await saveEntity(item);
      toast.success("The item was saved!");
      window.location = `/items/${data._id}`;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  const handleQBOSync = async () => {
    props.loadingOn();

    if (!itemId || itemId === "new") {
      toast.error("Save the item first and after export it");
      props.loadingOff();
      return;
    }
    if (item?.qbo?.Id) {
      toast.info("Use import QBO list option");
      props.loadingOff();
      return;
    }

    try {
      const { data: exported } = await exportToQboById("Item", itemId);
      toast.success("This Item was exported!");
      setItem(exported);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }

    props.loadingOff();
  };

  if (match.params.id === "new") {
    formMode.isNew = true;
    formMode.isEdit = true;
  }

  const breadcrumbs = [
    { value: "/items", label: "Items" },
    { value: `/items/${itemId}`, label: `${item.title}` },
  ];
  return (
    <div>
      <Breadcrumb items={breadcrumbs} />
      <FormTitle title={formMode.isNew ? `Create Item` : item.title} />
      {/* <FormSubTitle
        title={<span className="font-weight-bolder text-primary">Item</span>}
      /> */}
      <Switch>
        <Route
          exact
          path={`${itemPath}`}
          component={(props) =>
            formMode.isNew ? (
              <FormEdit
                data={item.data}
                itemPath={itemPath}
                itemId={itemId}
                onSave={onSave}
                {...props}
              />
            ) : (
              <FormView
                data={item.data}
                itemPath={itemPath}
                onQBOSync={handleQBOSync}
                {...props}
              />
            )
          }
        />
        <Route
          path={`${itemPath}/edit`}
          component={(props) => (
            <FormEdit
              data={item.data}
              itemPath={itemPath}
              itemId={itemId}
              onSave={onSave}
              {...props}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default withFormMode(Item);
