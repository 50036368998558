import http from "./httpService";

const apiEndpoint = "/inventories";

function inventoryUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getInventories() {
  return http.get(apiEndpoint);
}

export function getTypes() {
  return http.get(apiEndpoint + "/types");
}

export function getInventoriesQuery(query = "") {
  return http.get(apiEndpoint + query);
}

export function getInventory(inventoryId) {
  return http.get(inventoryUrl(inventoryId));
}
export function getInventoryByItemId(itemId) {
  return http.get(apiEndpoint + "/item/" + itemId);
}

export function getAvailableInventory(inventoryId) {
  return http.get(inventoryUrl(inventoryId) + "/available");
}

export function saveInventory(inventory) {
  if (inventory._id) {
    const body = { ...inventory };
    delete body._id;
    return http.put(inventoryUrl(inventory._id), body);
  }

  return http.post(apiEndpoint, inventory);
}

export function deleteInventory(inventoryId) {
  return http.delete(inventoryUrl(inventoryId));
}
