import React, { useEffect, useState } from "react";
import XLSX from "xlsx";
import withLoading from "../common/withLoading";
// import Pagination from "./common/pagination";
// import { paginate } from "../utils/paginate";
// import {
//   getCustomerInvoices,
//   createInvoicesFromGEFile,
//   createInvoicesFromXLS,
//   //   updateInvoicesDetailsQty,
// } from "../utils/invoiceUtils";
// import { getCustomerOrders } from "../utils/salesOrderUtils";
// import { saveEntity, getEntities } from "./../services/entityService";
// import { getCustomerRefs } from "./../utils/customerUtils";
// import { getItemRefs } from "./../utils/itemUtils";
// import { mapToQbo } from "../utils/invoiceQBOExport";
// import { saveQboEntity } from "../services/qboEntityService";
// import InvoiceTable from "./invoiceTable";
import {
  getVendors,
  getItems,
  getEntitiesQuery,
  saveEntity,
} from "../../services/entityService";
import _ from "lodash";
import { toast } from "react-toastify";
import ImportXLS from "../common/ImportXLS/ImportXLS";

const ImportPurchaseOrders = (props) => {
  const { loadingOn, loadingOff } = props;

  const steps = [
    // "Preset",
    "Select File",
    "Map columns",
    "Review Imports",
    "Exit Page",
  ];
  const mappingOptions = ["column", "default", "selected"];
  const concatChars = [" ", ", ", " / ", " | ", " - ", " > "];
  const schema = {
    type: { label: "Type", option: "", value: "" },
    category: { label: "Category", option: "", value: "" },
    sku: { label: "SKU", option: "", value: "", required: true },
    name: { label: "Name", option: "", value: "", required: true },
    description: { label: "Description", option: "", value: "" },
    purchaseDescription: {
      label: "PurchaseDescription",
      option: "",
      value: "",
    },
    uom: { label: "Base UOM", option: "", value: "", required: true },
    cost: { label: "Purchase Cost", option: "", value: "" },
    unitPrice: { label: "Sale Price", option: "", value: "" },
    preferredSupplier: { label: "Preferred Supplier", option: "", value: "" },
    brand: { label: "Brand", option: "", value: "" },
    productLine: { label: "Product Line", option: "", value: "" },
    material: { label: "Material", option: "", value: "" },
    finish: { label: "Finish", option: "", value: "" },
    thickness: { label: "Thickness", option: "", value: "" },
    size: { label: "Size", option: "", value: "" },
    width: { label: "Width", option: "", value: "" },
    height: { label: "Height", option: "", value: "" },
    weight: { label: "Weight", option: "", value: "" },
  };

  const mappingSchema = {
    concatChars,
    mappingOptions,
    schema,
  };
  const [step, setStep] = useState(0);
  const [defaults, setDefaults] = useState({});
  const [workbook, setWorkbook] = useState({});
  const [workSheets, setWorkSheets] = useState([]);
  const [selectedWorkSheet, setSelectedWorkSheet] = useState("");
  const [vendors, setVendors] = useState([]);
  const [items, setItems] = useState([]);
  const [orders, setOrders] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [currencies, setCurrencies] = useState([
    { value: "CAD", label: "Canadian Dollar" },
  ]);
  const [mapping, setMapping] = useState(mappingSchema);
  const [imports, setImports] = useState([]);

  useEffect(() => {
    const populateVendors = async () => {
      const { data } = await getVendors();
      const mapped = data.map((s) => {
        return { value: s._id, label: s.title };
      });
      if (mapped) setVendors(mapped);
    };
    populateVendors();
    return () => {
      setVendors([]);
    };
  }, []);

  useEffect(() => {
    const populateItems = async () => {
      const { data } = await getItems();
      if (data) setItems(data);
    };
    populateItems();
    loadingOff();
    return () => {
      setItems([]);
    };
  }, []);

  const createPoFromXLS = (values) => {
    let lastPo;
    const pos = [];
    for (const row of values) {
      if (!row.PO || row.PO === lastPo.PO) {
      } else {
        const data = {
          txnDate: row.Delivery,
          dueDate: row.Delivery,
          txnNumber: row.PO,
          currency: currencies[0],
          notes: row.SPECIFIC_JOB,
          status: {
            value: "100",
            label: "New",
          },
          memo: "",
          details: [],
        };
        // const vendorRef = vendors.find((v) =>
        //   v.label.toLowerCase().includes(row.supplier.toLowerCase())
        // );
        // if (vendorRef) data.preferredVendor = vendorRef;
        // const po = {
        //   title: data.name,
        //   entity: "PurchaseOrder",
        //   data,
        // };
        // lastPo = { ...po };
        // pos.push(po);
      }
    }
    return pos;
  };

  const handleMove = (value) => {
    if (value === "+") setStep(step + 1);
    if (value === "-") setStep(step - 1);
  };

  const handleNavigate = (value) => {
    if (value === 0) window.location.href = "/import-purchase-orders";
    // setStep(value);
  };

  const handleFileSelect = (wb) => {
    if (wb) {
      const workSheets = wb.SheetNames;
      setWorkbook(wb);
      setWorkSheets(workSheets);
      if (workSheets.length === 1) {
        const wsname = wb.SheetNames[0];
        setSelectedWorkSheet(wsname);
        // this.importFileData();
      }
      setStep(step + 1);
      props.progressOff();
    } else {
      props.progressOff();
    }
  };

  const handleMapping = async () => {
    props.progressOn();
    const ws = workbook.Sheets[selectedWorkSheet];
    props.progressUpdate("Reading data...", 40);

    const data = XLSX.utils.sheet_to_json(ws, {
      header: 1,
      dateNF: "YYYY-MM-DD",
    });

    const originalCols = data.shift();
    const cols = originalCols.map((c) => {
      return c.replace(/-|\s/g, "");
    });

    props.progressUpdate("Verifying mapping...", 60);
    const requiredFields = [];
    Object.entries(schema).forEach(([k, v]) => {
      if (v.required) requiredFields.push(k);
    });
    // console.log("Required", requiredFields);
    // console.log("Cols", cols);
    for (let item of requiredFields) {
      const found = cols.find((col) => col === item);
      // if (!found) console.log("Not found:", item);
      if (!found) {
        toast.error("Incorrect file, missing required fields");
        props.progressOff();
        setStep(0);
        setWorkbook({});
        setWorkSheets([]);
        setSelectedWorkSheet("");
        break;
      }
    }

    if (!selectedWorkSheet) return;

    const named = data.map((line) => {
      let newLine = {};
      line.forEach((value, index) => {
        const key = cols[index];
        newLine[key] = value;
      });
      return newLine;
    });

    const items = createPoFromXLS(named);
    console.log("Purchase Order List", items.length);
    setImports(items);
    setStep(step + 1);

    props.progressOff();
  };

  const savePurchaseOrder = async (item) => {
    // const found = items.find(
    //   (i) => i.title.toLowerCase() === item.title.toLowerCase()
    // );
    // if (found) {
    //   const newData = { ...item.data };
    //   item = { ...found };
    //   item.data = newData;
    // }

    console.log("PO: ", item);
    // try {
    //   const { data } = await saveEntity(item);
    //   return data;
    // } catch (error) {
    //   item.errors = [{ massage: "Can't save item", error }];
    //   return item;
    // }
  };

  const handleImport = async () => {
    console.log("Handle Import");
    // props.progressOn();
    // let d = 0;
    // for (let item of imports) {
    //   item = await savePurchaseOrder(item);
    //   d++;
    //   props.progressUpdate("Saving items...", d, imports.length);
    //   console.log("Saved: ", d, item.title);
    // }

    // props.progressOff();

    // const notSaved = imports.filter((o) => o.errors !== undefined);
    // if (notSaved.length !== 0) setImports(notSaved);
    // else setStep(step + 1);
  };

  return (
    <div className="container mt-2">
      <ImportXLS
        title="Import Purchase Orders"
        steps={steps}
        step={step}
        mapping={mapping}
        imports={imports}
        onDirection={handleMove}
        onNavigate={handleNavigate}
        onFileSelect={handleFileSelect}
        onMapping={handleMapping}
        onImport={handleImport}
        {...props}
      />
    </div>
  );
};

export default withLoading(ImportPurchaseOrders);
