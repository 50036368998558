const signatures = {
  JVBERi0: "application/pdf",
  R0lGODdh: "image/gif",
  R0lGODlh: "image/gif",
  iVBORw0KGgo: "image/png",
  "/9j/": "image/jpg",
};

export const getMimetype = (base64string) => {
  console.log("Getting mime type", base64string);
  for (const s in signatures) {
    if (base64string.indexOf(s) === 0) return signatures[s];
  }
  return;
};

export const imageFileExt = (mimetype) => {
  return mimetype.substring(mimetype.indexOf("/") + 1, mimetype.length);
};
