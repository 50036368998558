import React from "react";
import { toast } from "react-toastify";
// import numeral from "numeral";
import Panel from "../common/panel";
import { RowTwoColumns, RowOneLeftTwoRight } from "../common/formLayout";
import InputText from "../common/inputText";
import { FormProvider, useForm } from "react-hook-form";
import {
  getSetting,
  setSetting,
  saveSetting,
} from "../../services/settingService";

const GeneralInfoEdit = (props) => {
  const company = props.user && props.user.info ? props.user.company : "N/A";
  const values = {
    name: company,
    legalName: "",
    fiscalNumber: "",
    mainPhone: "",
    mainEmail: "",
    line1: "",
    line2: "",
    city: "",
    subDivision: "",
    country: "",
    postalCode: "",
  };

  const defaultValues = getSetting("general") ? getSetting("general") : values;

  // const setGeneralInfo = (info) => {
  //   setSetting("general", info);
  // };

  const methods = useForm({ defaultValues });

  const saveInfo = async (general) => {
    const setting = {
      details: general,
    };

    try {
      const { data } = await saveSetting("general", setting);
      setSetting("general", data.details);
      props.history.replace("/settings/general");
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
        console.log(ex.response.data);
      }
    }
  };

  const onSubmit = async (values) => {
    await saveInfo(values);
    // props.history.replace("/settings/general");
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <h2>Edit General Information</h2>
        <hr />
        <Panel>
          <h5 className="card-title"> Company Information</h5>
          <RowTwoColumns
            colOne={<InputText name="name" label="Company name" readOnly />}
            colTwo={<InputText name="legalName" label="Legal Name" />}
          />
          <RowTwoColumns
            colOne={<InputText name="fiscalNumber" label="GST/HST Number" />}
          />
          <RowTwoColumns
            colOne={<InputText name="mainPhone" label="Main Phone" />}
          />
          <RowTwoColumns
            colOne={<InputText name="mainEmail" label="Main Email" />}
          />

          <hr />
          <h5 className="card-title">Billing Address</h5>
          <RowTwoColumns
            colOne={<InputText name="line1" label="Address (Line 1)" />}
            colTwo={<InputText name="line2" label="Address (Line 2)" />}
          />
          <RowOneLeftTwoRight
            colOne={<InputText name="city" label="City" />}
            colTwo={<InputText name="subDivision" label="Province / State" />}
            colThree={<InputText name="postalCode" label="Postal Code / ZIP" />}
          />
          <RowTwoColumns
            colOne={<InputText name="country" label="Country" />}
          />
          <hr />
          <div className="d-flex">
            <button
              type="button"
              className="btn btn-secondary shadow-sm mr-2"
              onClick={() => props.history.replace("/settings/general")}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-success shadow-sm">
              Save changes
            </button>
          </div>
        </Panel>
      </form>
    </FormProvider>
  );
};

export default GeneralInfoEdit;
