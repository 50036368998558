import qboApi from "./qboApiService";
const apiEndpoint = qboApi.apiUrl + "/qbo";
// const apiEndpoint = "/qbo";

export function authorizeUri() {
  return qboApi.get(apiEndpoint + "/authUri");
}

export function disconnect() {
  return qboApi.get(apiEndpoint + "/disconnect");
}

export function retrieveToken() {
  return qboApi.get(apiEndpoint + "/retrieveToken");
}
export function refreshAccessToken() {
  return qboApi.get(apiEndpoint + "/refreshAccessToken");
}

export function getCompanyInfo() {
  return qboApi.get(apiEndpoint + "/getCompanyInfo");
}

export function getConnectionStatus() {
  return qboApi.get(apiEndpoint + "/status");
}
