import { searchEntities, getEntity } from "../services/entityService";
import {
  getInventory,
  getInventoryByItemId,
} from "../services/inventoryService";
import numeral from "numeral";
import { DateTime } from "luxon";
import { partsToTextArea } from "./itemUtils";

const populateItem = async (id) => {
  if (!id) return null;
  try {
    const { data } = await getEntity("Item", id);
    return data;
  } catch (error) {
    return null;
  }
};

const populateInventory = async (id) => {
  if (!id) return null;
  try {
    const { data } = await getInventory(id);
    return data;
  } catch (error) {
    return null;
  }
};

const findInventoryByItemId = async (id) => {
  if (!id) return null;
  try {
    const { data } = await getInventoryByItemId(id);
    return data;
  } catch (error) {
    return null;
  }
};

export const mapProductionToInvoice = (data) => {
  let saved = {
    entity: "Invoice",
    data: data,
  };
  return saved;
};

export const mapProductionToJob = (data) => {
  console.log(data);
  return null;
};
export const mapJobToProduction = (data) => {
  let saved = {
    entity: "ProductionReceipt",
    data: data,
  };
  return saved;
};

// TODO update after updating inventory to batches
export const mapInvoiceDetailToProduction = async (txn) => {
  let records = [];
  let parts = [];

  const newRecord = {
    lineId: txn.ref.lineId,
    itemId: txn.item.value,
    inventory: { value: txn.inventory.value, label: txn.inventory.label },
    uom: txn.inventory.uom || "EACH",
    description: txn.inventory.description || "",
    qty: txn.qty,
  };
  records.push(newRecord);

  parts.push({
    lineId: txn.ref.lineId,
    item: txn.item,
    inventory: { value: txn.inventory.value, label: txn.inventory.label },
    qty: txn.qty,
  });

  let txnDate = DateTime.fromISO(txn.txnDate).toISODate();
  const data = {
    txnDate: txn.txnDate,
    currency: txn.ref.currency,
    details: parts,
    records: records,
    addressFrom: "Production",
    addressTo: "",
    locationTo: txn.storage,
    customer: txn.ref.customer,
    memo: `Customer: ${txn.ref.customer.label} \nInvoice: ${txn.ref.txnNumber}  ${txnDate}`,
    refs: [
      {
        type: "Invoice",
        value: txn.ref.value,
        lineId: txn.ref.lineId,
        label: `${txn.ref.txnNumber}  ${txnDate} ${txn.ref.customer.label}`,
      },
    ],
    messages: [
      {
        timeStamp: DateTime.local().toISO(),
        user: { value: txn.user._id, label: txn.user.name },
        message: `Document created`,
      },
    ],
  };
  console.log(data);
  return {
    entity: "ProductionReceipt",
    data: data,
  };
};

// TODO update after updating inventory to batches
export const mapProductionToMaterialUsage = (txn) => {
  let records = [];

  const newRecord = {
    lineId: txn.ref.lineId,
    itemId: txn.itemId,
    inventory: { value: txn.inventory.value, label: txn.inventory.label },
    uom: txn.inventory.uom || "EACH",
    description: txn.inventory.description || "",
    qty: txn.partQty,
  };
  records.push(newRecord);

  const data = {
    txnDate: txn.txnDate,
    currency: txn.ref.currency,
    // details: parts,
    records: records,
    addressFrom: "",
    addressTo: "Production",
    locationFrom: txn.storage,
    memo: `${txn.inventory.label} ${numeral(txn.partQty).format(0, 0.0)} ${
      txn.inventory.uom
    } 
    for ${txn.ref.label}
    Item ${txn.item.label} qty: ${txn.qty}`,
    refs: [
      {
        type: txn.ref.type,
        value: txn.ref.value,
        lineId: txn.ref.lineId,
        label: `${txn.ref.label}`,
      },
    ],
    messages: [
      {
        timeStamp: DateTime.local().toISO(),
        user: { value: txn.user._id, label: txn.user.name },
        message: `Document created`,
      },
    ],
  };
  // console.log(data);
  return {
    entity: "MaterialsRequisitionForm",
    data: data,
  };
};

// TODO update after updating inventory to batches
export const mapProductionToRequisition = async (doc) => {
  const { data: storages } = await searchEntities({
    entities: ["StoragePlace"],
    query: "Raw",
  });
  let storage = null;
  if (storages.length > 0) storage = storages[0];
  let records = [];
  let partsForMemo = [];
  if (doc.records) {
    for (const line of doc.records) {
      // console.log(line);
      const inventory = await populateInventory(line.inventory.value);
      const item = await populateItem(inventory.itemId);
      // console.log(item);
      if (item && item.data && item.data.parts)
        for (const part of item.data.parts) {
          const inv = await findInventoryByItemId(part.item.value);
          console.log(inv);
          if (inv) {
            const lineId = records.length + 1;
            const newRecord = {
              lineId: lineId,
              itemId: part.item.value,
              inventory: { value: inv._id, label: inv.name },
              uom: inv.uom || "EACH",
              description: inv.description,
              qty: numeral(part.qty).value() * numeral(line.qty).value(),
            };
            records.push(newRecord);
            const addToMemo = {
              lineId: lineId,
              item: line.inventory,
              uom: line.uom || "EACH",
              qty: numeral(line.qty).value(),
            };
            partsForMemo.push(addToMemo);
          }
        }
    }
  }
  const values = { ...doc.data };
  values.type = "MaterialsRequisitionForm";
  values.addressTo = "Production";
  const parts = partsToTextArea(partsForMemo);
  values.memo = `${doc.title}: \n${parts}`;

  values.refs = [
    { value: doc._id, label: doc.title },
    {
      type: "ProductionReceipt",
      value: doc._id,
      label: doc.title,
    },
  ];
  if (storage)
    values.locationFrom = { value: storage._id, label: storage.title };

  values.records = records;

  delete values.txnNumber;
  delete values.locationTo;
  return values;
};

export const mapJobToRequisition = (data) => {
  let saved = {
    entity: "Job",
    data: data,
  };
  return saved;
};
