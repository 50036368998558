import React from "react";
import { NavLink } from "react-router-dom";
import SidebarLeft from "../common/SidebarLeft";

const LeftBarItems = () => {
  return (
    <SidebarLeft>
      <ul className="list-group shadow-sm">
        <NavLink
          to="/tools/change"
          className="list-group-item list-group-item-action"
          activeClassName="active"
        >
          Update entities
        </NavLink>
        {/* <NavLink
          to="/tools/merge"
          className="list-group-item list-group-item-action"
          activeClassName="active"
        >
          Merge
        </NavLink> */}
        <NavLink
          to="/import-items"
          className="list-group-item list-group-item-action"
          activeClassName="active"
        >
          Import Items
        </NavLink>
        <NavLink
          to="/import-purchase-orders"
          className="list-group-item list-group-item-action"
          activeClassName="active"
        >
          Import Purchase Orders
        </NavLink>
        <NavLink
          to="/generate-production"
          className="list-group-item list-group-item-action"
          activeClassName="active"
        >
          GenProd (c)
        </NavLink>
        <NavLink
          to="/generate-material-usage"
          className="list-group-item list-group-item-action"
          activeClassName="active"
        >
          Generate Material Usage
        </NavLink>
        <NavLink
          to="/generate-inventory-receipts"
          className="list-group-item list-group-item-action"
          activeClassName="active"
        >
          Generate Inventory Receipts
        </NavLink>
        <NavLink
          to="/generate-inventory-shipments"
          className="list-group-item list-group-item-action"
          activeClassName="active"
        >
          Generate Inventory Shipments
        </NavLink>
      </ul>
    </SidebarLeft>
  );
};

export default LeftBarItems;
