import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { RowTwoColumns } from "../common/formLayout";
import InputSelect from "../common/inputSelect";
import InputTextArea from "./../common/inputTextArea";
import { getStorages } from "../../services/entityService";
import { getEntitiesQuery } from "../../services/entityService";

const HeaderRequisitionForm = () => {
  const [storages, setStorages] = useState([]);
  const [jobs, setJobs] = useState([]);
  const { setValue } = useFormContext();

  useEffect(() => {
    const populateStorages = async () => {
      const { data } = await getStorages();
      const mapped = data.map((s) => {
        return { value: s._id, label: s.title };
      });
      if (mapped) setStorages(mapped);
    };
    populateStorages();
    return () => {
      setStorages([]);
    };
  }, []);

  useEffect(() => {
    const populateJobs = async () => {
      const { data } = await getEntitiesQuery("Job", "?status=Open");
      const mapped = data.map((s) => {
        return { value: s._id, label: s.title };
      });
      if (mapped) setJobs(mapped);
    };
    populateJobs();
    return () => {
      setJobs([]);
    };
  }, []);

  const handleSelect = (name, selected) => {
    setValue(selected.name, { value: name.value, label: name.label });
  };

  return (
    <>
      <RowTwoColumns
        colOne={
          <InputSelect
            name="locationFrom"
            label="From storage"
            options={storages}
            onChange={handleSelect}
            valueOption="value"
            labelOption="label"
          />
        }
        colTwo={
          <InputSelect
            name="job"
            label=" To Job"
            options={jobs}
            onChange={handleSelect}
            valueOption="value"
            labelOption="label"
          />
        }
      />
      <RowTwoColumns
        colOne={<InputTextArea name="addressFrom" label="From address" />}
        colTwo={
          <InputTextArea
            name="addressTo"
            label="To address"
            defaultValue={"Production"}
          />
        }
      />
    </>
  );
};

export default HeaderRequisitionForm;
