import React, { useMemo } from "react";

const EditDetailsCostOptions = ({ item, lineIndex, items = [], onSelect }) => {
  const options = useMemo(() => {
    if (!item) return [];
    const found = items.find((i) => i.value === item.value);
    const options = found?.costOptions?.length > 0 ? found.costOptions : [];
    return options;
  }, [item, items]);

  return (
    <div className="dropdown-menu">
      {options.map((option) => (
        <button
          key={option.code}
          className="dropdown-item"
          type="button"
          onClick={() => onSelect(option, lineIndex)}
        >
          {`${option.code} > ${option?.uomOption?.code || option.uom}`}
        </button>
        // )
      ))}
    </div>
  );
};

export default EditDetailsCostOptions;
