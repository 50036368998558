import React from "react";

const FieldTable = ({ name = "Name", value = "", ...rest }) => {
  const className = value ? "form-control h-100" : "form-control";
  return (
    <div
      className={className}
      style={{ whiteSpace: "pre-wrap" }}
      name={name}
      id={name}
      {...rest}
    >
      {value}
    </div>
  );
};

export default FieldTable;
