import React from "react";
import Panel from "../common/panel";
import Controls from "./Controls";
import { RowOneLeftTwoRight } from "../common/formLayout";
import Field from "../common/fieldText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const View = (props) => {
  const { data } = props;

  return (
    <div id="StorageView">
      <Panel>
        <RowOneLeftTwoRight
          colOne={
            <Field name="displayName" label="Name" value={data.displayName} />
          }
          colTwo={<Field name="code" label="Code" value={data.code} />}
        />
        <RowOneLeftTwoRight
          colOne={<Field name="address" label="Address" value={data.address} />}
        />
        <hr />
        <RowOneLeftTwoRight
          colOne={<Field name="note" label="Note" value={data.note} />}
        />
        <div className="row">
          {data && data.isInventory && (
            <div className="pl-3">
              <FontAwesomeIcon icon="check-circle" /> Inventory
            </div>
          )}
          {data && data.isCustomerSite && (
            <div className="pl-3">
              <FontAwesomeIcon icon="check-circle" /> Customer Site
            </div>
          )}
          {data && data.isEquipment && (
            <div className="pl-3">
              <FontAwesomeIcon icon="check-circle" /> Equipment and Tools
            </div>
          )}
        </div>
      </Panel>
      <Controls isEdit={false} {...props} />
    </div>
  );
};

export default View;
