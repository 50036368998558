import axios from "axios";
import logger from "./logService";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";

const loginApiUrl = process.env.REACT_APP_API_LOGIN_URL;
const appClientCode = process.env.REACT_APP_CLIENT_CODE;
const globalTokenKey = "globalToken";
// axios.defaults.headers.common["x-auth-token"] = getGlobalJwt();

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    logger.log(error);
    toast.error("An unexpected error occurred.");
  }

  return Promise.reject(error);
});

export async function login(email, password) {
  const { data: jwt } = await axios.post(loginApiUrl + "/auth", {
    email,
    password,
  });
  localStorage.setItem(globalTokenKey, jwt);
}

export async function forgotPassword(email) {
  return await axios.post(loginApiUrl + "/auth/forgot_password", { email });
}

export async function resetPassword(token, password) {
  const { data: jwt } = await axios.post(
    loginApiUrl + "/auth/reset_password/" + token,
    { password }
  );
  localStorage.setItem(globalTokenKey, jwt);
}
// export function getClients() {
//   return axios.get(loginApiUrl+"/clients");
// }

export function getGlobalJwt() {
  return localStorage.getItem(globalTokenKey);
}

export function logout() {
  localStorage.removeItem(globalTokenKey);
}

export function getUserCompanies() {
  const globalJwt = getGlobalJwt();
  return axios.get(loginApiUrl + "/companies", {
    headers: {
      "x-auth-token": globalJwt,
    },
  });
}

export function getAppClients() {
  const globalJwt = getGlobalJwt();
  return axios.get(loginApiUrl + "/clients", {
    headers: {
      "x-auth-token": globalJwt,
    },
  });
}

export async function getAccessToken(companyId) {
  const globalJwt = getGlobalJwt();
  // const tokenUrl = `${loginApiUrl}/redirect/${companyId}/${appClientCode}`;
  // console.log(tokenUrl);
  return await axios.get(
    `${loginApiUrl}/redirect/${companyId}/${appClientCode}`,
    {
      headers: {
        "x-auth-token": globalJwt,
      },
    }
  );
}

export function getGlobalUser() {
  try {
    const jwt = localStorage.getItem(globalTokenKey);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

const loginApi = {
  login,
  logout,
  getGlobalUser,
  getAccessToken,
  getGlobalJwt,
};

export default loginApi;
