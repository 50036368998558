import React from "react";
import Panel from "../common/panel";
import Controls from "./Controls";
import ViewDetails from "./ViewDetails";
import { RowTwoColumns, RowFourColumns } from "../common/formLayout";
import Field from "../common/fieldText";
import FieldDate from "../common/fieldDate";

const View = (props) => {
  const { data } = props;

  return (
    <div id="DocumentView">
      <Panel>
        <RowFourColumns
          colOne={
            <FieldDate name="txnDate" label="PO Date" value={data.txnDate} />
          }
          colTwo={
            <Field name="txnNumber" label="PO Number" value={data.txnNumber} />
          }
          colThree={
            <FieldDate name="dueDate" label="Due Date" value={data.dueDate} />
          }
          colFour={
            <Field
              name="status"
              label="PO Status"
              value={data.status && data.status.label}
            />
          }
        />

        <RowTwoColumns
          colOne={
            <Field
              name="vendor"
              label="Supplier"
              value={data.vendor && data.vendor.label}
            />
          }
          colTwo={
            <Field
              name="locationTo"
              label="To location"
              value={data.locationTo && data.locationTo.label}
            />
          }
        />
        <RowTwoColumns
          colOne={
            <Field name="addressFrom" label="From" value={data.addressFrom} />
          }
          colTwo={<Field name="addressTo" label="To" value={data.addressTo} />}
        />
        <hr />
        <RowFourColumns
          colOne={<Field name="email" label="Email" value={data.email} />}
        />
        <ViewDetails details={data.details} totals={data.totals} {...props} />
        <hr />
        <RowTwoColumns
          colOne={<Field name="memo" label="Memo" value={data.memo} />}
          colTwo={
            <Field name="notes" label="Internal Notes" value={data.notes} />
          }
        />
      </Panel>
      <Controls isEdit={false} {...props} />
    </div>
  );
};

export default View;
