import React, { Component } from "react";
// import auth from "../services/authService";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "./../common/table";

class VendorTable extends Component {
  tableColumns = [
    {
      path: "title",
      label: "Name",
      className: "w-25",
      content: (vendor) => (
        <Link to={`/vendors/${vendor._id}`}>{vendor.title}</Link>
      ),
    },
    {
      path: "data.billingAddress",
      label: "Billing Address",
      className: "w-25",
      content: (vendor) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {vendor.data && vendor.data.billingAddress}
        </div>
      ),
    },
    {
      path: "data.phone",
      label: "Phone",
      className: "text-center",
    },
    {
      path: "data.email",
      label: "Email",
      className: "text-center",
    },
    {
      path: "data.note",
      label: "Notes",
      className: "w-25",
      content: (vendor) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {vendor.data && vendor.data.note}
        </div>
      ),
    },
    {
      key: "controls",
      content: (vendor) => (
        <div className="text-right">
          {/* <button
            onClick={() => this.props.onClick(vendor)}
            className="btn btn-secondary btn-sm mr-1"
          >
            <FontAwesomeIcon icon="edit" />
          </button> */}
          <button
            onClick={() => this.props.onDelete(vendor)}
            className="btn btn-danger btn-sm"
          >
            <FontAwesomeIcon icon="trash" />
          </button>
        </div>
      ),
    },
  ];

  listColumns = [
    {
      path: "title",
      label: "Name",
      content: (vendor) => (
        <Link to={`/vendors/${vendor._id}`}>{vendor.title}</Link>
      ),
    },
    {
      path: "data.code",
      label: "Code",
    },
    {
      key: "delete",
      content: (vendor) => (
        <button
          onClick={() => this.props.onDelete(vendor)}
          className="btn btn-danger btn-sm"
        >
          <FontAwesomeIcon icon="trash" />
        </button>
      ),
    },
  ];

  // constructor() {
  //   super();
  //   // const user = auth.getCurrentUser();
  //   // if (user && user.isAdmin) this.tableColumns.push(this.deleteColumn);
  // }

  render() {
    const { items, onSort, sortColumn, isList } = this.props;
    const columns = isList ? this.listColumns : this.tableColumns;

    return (
      <Table
        columns={columns}
        data={items}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default VendorTable;
