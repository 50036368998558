import React from "react";
import { DateTime } from "luxon";
import DatePicker from "react-datepicker";
import Button from "./button";

const SelectDateBox = (props) => {
  const { fromDate, toDate, setFromDate, setToDate, handleDateChange } = props;
  return (
    <div className="d-flex border-top border-bottom pb-n5 mb-3">
      <div className="form-group col-2 ml-n3 mr-n2 pt-2">
        <label
          htmlFor="fromDate"
          className="form-label form-label-sm font-weight-bold"
          style={{ marginBottom: "0px", marginRight: "10px" }}
        >
          Date from:
        </label>
        <DatePicker
          className="form-control"
          dateFormat="yyyy-MM-dd"
          selected={Date.parse(fromDate)}
          onChange={(value) => {
            const selected = DateTime.fromJSDate(value);
            setFromDate(selected);
          }}
        />
      </div>
      <div className="form-group col-2 mx-n3 pt-2">
        <label
          htmlFor="toDate"
          className="form-label form-label-sm font-weight-bold"
          style={{ marginBottom: "0px", marginRight: "10px" }}
        >
          Date to:
        </label>
        <DatePicker
          className="form-control"
          dateFormat="yyyy-MM-dd"
          selected={Date.parse(toDate)}
          onChange={(value) => {
            const selected = DateTime.fromJSDate(value);
            setToDate(selected);
          }}
        />
      </div>
      <div className="d-flex d-print-none pt-4">
        <Button
          label="Get data ..."
          type="button"
          className="btn-secondary btn shadow-sm ml-3 mr-4 mb-3"
          onClick={handleDateChange}
        />
      </div>
    </div>
  );
};

export default SelectDateBox;
