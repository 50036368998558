import http from "./httpService";

const apiEndpoint = "/documents";

function documentUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getDocuments() {
  return http.get(apiEndpoint);
}

export function getDocumentsQuery(query) {
  return http.get(apiEndpoint + query);
}

export function getInventoryDocumentTypes() {
  return http.get(`${apiEndpoint}/docTypes`);
}

export function getDocument(documentId) {
  return http.get(documentUrl(documentId));
}

export function saveDocument(document) {
  if (document._id) {
    const body = { ...document };
    delete body._id;
    return http.put(documentUrl(document._id), body);
  }

  return http.post(apiEndpoint, document);
}

export function deleteDocument(documentId) {
  return http.delete(documentUrl(documentId));
}
