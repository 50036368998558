import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NewDocument = () => {
  return (
    <div>
      <div className="btn-group" role="group" style={{ marginBottom: 20 }}>
        <button
          id="btnGroupDocSelect"
          type="button"
          className="btn btn-primary dropdown-toggle shadow"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <FontAwesomeIcon icon="plus" className="mr-2" />
          New Document
        </button>
        <div
          className="dropdown-menu shadow"
          aria-labelledby="btnGroupDocSelect"
        >
          <Link
            className="dropdown-item"
            to="/documents/new?type=InventoryTransfer"
          >
            Inventory Transfer
          </Link>
          <Link
            className="dropdown-item"
            to="/documents/new?type=InventoryReceipt"
          >
            Inventory Receipt
          </Link>
          <Link
            className="dropdown-item"
            to="/documents/new?type=InventoryShipment"
          >
            Inventory Shipment
          </Link>
          <Link
            className="dropdown-item"
            to="/documents/new?type=InventoryWriteDown"
          >
            Inventory Write-down
          </Link>
          <Link
            className="dropdown-item"
            to="/documents/new?type=MaterialsRequisitionForm"
          >
            Materials Requisition
          </Link>
          <Link
            className="dropdown-item"
            to="/documents/new?type=ProductionReceipt"
          >
            Production Receipt
          </Link>
          {/* <Link
            className="dropdown-item"
            to="/documents/new?type=InventoryCount"
          >
            Inventory Count
          </Link> */}
        </div>
      </div>
      {/* <Link
        to="/documents/new"
        className="btn btn-primary shadow"
        style={{ marginBottom: 20 }}
      >
        <FontAwesomeIcon icon="plus" className="mr-2" />
        New Document
      </Link> */}
    </div>
  );
};

export default NewDocument;
