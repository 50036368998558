import React from "react";

const ImageBase64 = ({
  name = "",
  data,
  mimetype = "image/png",
  classNames = "card-img-top p-2",
}) => {
  if (!data) return null;
  return (
    <img
      alt={name}
      src={`data:${mimetype};base64,${data}`}
      className={classNames}
    />
  );
};

export default ImageBase64;
