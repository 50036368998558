import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
// import DatePicker from "react-datepicker";
import withLoading from "../common/withLoading";
import SidebarLeft from "../common/SidebarLeft";
import ListGroup from "../common/listGroup";
import SelectDateBox from "./../common/SelectDateBox";
import DashboardControls from "./DashboardControls";
import SearchBox from "../common/searchBox";
import PurchaseOrderTable from "./PurchaseOrderTable";

import { getEntitiesQuery, deleteEntity } from "../../services/entityService";
// import { getStatuses } from "../../services/statusService";

import { detailsToTextArea } from "../../utils/poUtils";
import { paginate } from "../../utils/paginate";
import { poStatuses } from "../../utils/poUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
// import NewPurchaseOrder from "./NewPurchaseOrder";

class PurchaseOrders extends React.Component {
  state = {
    purchaseOrders: [],
    statuses: [],
    fromDate: DateTime.local().startOf("month").minus({ months: 2 }),
    toDate: DateTime.local().endOf("month").plus({ months: 1 }),
    sortColumn: { path: "doc.txnDate", order: "desc" },
    currentPage: 1,
    pageSize: 300,
    searchQuery: "",
    selectedStatus: null,
  };

  populateStatuses = () => {
    const statuses = [{ value: "", label: "All statuses" }, ...poStatuses];
    this.setState({ statuses });
  };

  populatePurchaseOrders = async () => {
    let query = "";
    if (this.state.fromDate && this.state.toDate)
      query = `?fromDate=${this.state.fromDate.toISODate()}&toDate=${this.state.toDate.toISODate()}`;
    else if (this.state.fromDate)
      query = `?fromDate=${this.state.fromDate.toISODate()}`;
    else if (this.state.toDate)
      query = `?toDate=${this.state.toDate.toISODate()}`;
    else query = "";

    try {
      const { data } = await getEntitiesQuery("PurchaseOrder", query);
      const purchaseOrders = data.map((purchaseOrder) => {
        purchaseOrder.search = purchaseOrder.title;
        if (purchaseOrder.data) {
          const details = detailsToTextArea(purchaseOrder.data.details);
          purchaseOrder.search =
            purchaseOrder.search +
            " " +
            details +
            " " +
            purchaseOrder.data.memo;
        }
        return purchaseOrder;
      });
      this.setState({ purchaseOrders });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      return null;
    }
  };

  async componentDidMount() {
    this.populateStatuses();
    await this.populatePurchaseOrders();
    this.props.loadingOff();
  }

  handleStatusSelect = (type) => {
    this.setState({
      selectedStatus: type,
      searchQuery: "",
      currentPage: 1,
    });
  };

  handleDateChange = async () => {
    this.props.loadingOn();
    await this.populatePurchaseOrders();
    this.props.loadingOff();
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      selectedStatus: null,
      currentPage: 1,
    });
  };

  handleSort = (sortColumn) => {
    this.setState({
      sortColumn: sortColumn,
    });
  };

  handleClick = (purchaseOrder) => {
    this.props.history.push(`/purchase-orders/${purchaseOrder._id}/edit`);
  };

  handleDelete = async (purchaseOrder) => {
    const originalItems = this.state.purchaseOrders;
    const purchaseOrders = originalItems.filter(
      (m) => m._id !== purchaseOrder._id
    );
    this.setState({ purchaseOrders });

    try {
      await deleteEntity("PurchaseOrder", purchaseOrder._id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This order has already been deleted.");

      this.setState({ purchaseOrders: originalItems });
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  getPagedData = () => {
    const {
      purchaseOrders,
      searchQuery,
      selectedStatus,
      sortColumn,
      currentPage,
      pageSize,
    } = this.state;
    const allPurchaseOrders = [...purchaseOrders];
    let filtered = allPurchaseOrders;
    if (searchQuery) {
      filtered = allPurchaseOrders.filter((i) =>
        i.search.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else if (selectedStatus && selectedStatus.value) {
      filtered = allPurchaseOrders.filter(
        (i) => i.data.status.value === selectedStatus.value
      );
    }

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const items = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: items };
  };

  render() {
    const {
      statuses,
      selectedStatus,
      sortColumn,
      fromDate,
      toDate,
      searchQuery,
    } = this.state;
    const { data: purchaseOrders, totalCount } = this.getPagedData();

    return (
      <>
        <SidebarLeft>
          <div>
            <Link
              to="/purchase-orders/new"
              className="btn btn-primary shadow"
              style={{ marginBottom: 18 }}
              data-toggle="tooltip"
              data-placement="top"
              title="Create a new purchase order"
            >
              <FontAwesomeIcon icon="plus" className="mr-2" />
              Add Purchase Order
            </Link>
          </div>
          <ListGroup
            items={statuses}
            valueProperty="value"
            textProperty="label"
            selectedItem={selectedStatus}
            onItemSelect={this.handleStatusSelect}
            selectedCount={totalCount}
          />
          {/* <div className="mt-3 pt-2 border-top"> */}
          <ul className="list-group mt-3 pt-2 border-top shadow-sm">
            <Link to="/purchase-orders-dashboard" className="list-group-item">
              PO dashboard...
            </Link>
          </ul>
          {/* </div> */}
        </SidebarLeft>
        <div className="col mt-2">
          <div className="d-flex py-2">
            <h2 className="text-uppercase">Inventory Purchase Orders</h2>
          </div>
          <SelectDateBox
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={(value) => this.setState({ fromDate: value })}
            setToDate={(value) => this.setState({ toDate: value })}
            handleDateChange={this.handleDateChange}
          />
          <div className="d-flex justify-content-between border-bottom py-2 d-print-none ">
            <SearchBox
              value={searchQuery}
              placeholder="Search ..."
              onChange={this.handleSearch}
            />
            <div>
              <DashboardControls />
            </div>
          </div>
          <div className="d-flex border-top pb-n5 mb-3">
            <PurchaseOrderTable
              items={purchaseOrders}
              sortColumn={sortColumn}
              isList={false}
              onDelete={this.handleDelete}
              onSort={this.handleSort}
              onClick={this.handleClick}
            />
          </div>
        </div>
      </>
    );
  }
}

export default withLoading(PurchaseOrders);
