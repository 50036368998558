import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import withLoading from "../common/withLoading";
import SidebarLeft from "../common/SidebarLeft";
import StorageTable from "./StorageTable";
import { getEntities, saveEntity } from "../../services/entityService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

class Storages extends React.Component {
  state = {
    storages: [],
    sortColumn: { path: "data.code", order: "asc" },
  };

  async getStorages() {
    try {
      const { data: storages } = await getEntities("StoragePlace");
      return storages;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      return null;
    }
  }
  async componentDidMount() {
    const storages = await this.getStorages();

    this.setState({ storages });
    this.props.loadingOff();
  }

  handleSort = (sortColumn) => {
    this.setState({
      sortColumn: sortColumn,
    });
  };

  handleClick = (storage) => {
    this.props.history.push(`/storages/${storage._id}/edit`);
  };

  handleDelete = async (storage) => {
    const originalStorages = this.state.storages;
    const storages = originalStorages.filter((m) => m._id !== storage._id);
    this.setState({ storages });

    storage.data.isInactive = true;

    try {
      await saveEntity(storage);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This storage already has been deleted.");

      this.setState({ storages: originalStorages });
    }
  };

  getPagedData = () => {
    const { storages, sortColumn } = this.state;
    const allStorages = [...storages];
    let filtered = allStorages;

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    return { totalCount: filtered.length, data: sorted };
  };

  render() {
    const { sortColumn } = this.state;
    const { data: storages } = this.getPagedData();

    return (
      <>
        <SidebarLeft>
          <div>
            <Link
              to="/storages/new"
              className="btn btn-primary shadow"
              style={{ marginBottom: 20 }}
            >
              <FontAwesomeIcon icon="plus" className="mr-2" />
              Add Storage
            </Link>
          </div>
        </SidebarLeft>
        <div className="col mt-2">
          <div className="d-flex py-2">
            <h2 className="text-uppercase">Storages</h2>
          </div>
          <div className="d-flex border-top pb-n5 mb-3">
            <StorageTable
              items={storages}
              sortColumn={sortColumn}
              isList={false}
              onDelete={this.handleDelete}
              onSort={this.handleSort}
              onClick={this.handleClick}
            />
          </div>
        </div>
      </>
    );
  }
}

export default withLoading(Storages);
