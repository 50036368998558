import React from "react";
// import { Link } from "react-router-dom";
import Table from "../common/table";
import numeral from "numeral";

numeral.zeroFormat("-");

// import DateFormat from "../common/dateFormat";

const ConsignmentTable = (props) => {
  const { items = [], onShowDetails, onSort, sortColumn, totals } = props;

  const columns = [
    {
      path: "_id",
      label: "Item",
      className: "w-25",
      content: (item) => (
        <div className="clickable" onClick={() => onShowDetails(item)}>
          {item._id.toUpperCase()}
        </div>
      ),
    },
    // {
    //   path: "description",
    //   label: "Available to Produce",
    //   className: "w-25",
    //   content: (inventory) => (
    //     <div style={{ whiteSpace: "pre-wrap" }}>
    //       {inventory.parts && partsToTextArea(inventory.parts)}
    //     </div>
    //   ),
    // },
    // { path: "uom", label: "UOM", className: "text-center" },
    {
      path: "qty",
      label: "Shipped",
      className: "text-right",
      content: (item) => <>{numeral(item.qty).format("0,0")}</>,
      total: (totals) => (
        <div className="text-right">{numeral(totals.qty).format("0,0")}</div>
      ),
    },
    // { path: "balance", label: "Balance", className: "text-center" },
    {
      path: "avgPrice",
      label: "Average Price",
      className: "text-right",
      content: (item) => <>{numeral(item.avgPrice).format("0,0.0000")}</>,
      // total: () => (
      //   <div className="text-center">{`Total ${
      //     storage?.currency ? "(" + storage.currency + ")" : ""
      //   }: `}</div>
      // ),
    },
    {
      key: "amount",
      label: "Amount",
      className: "text-right pr-2",
      content: (item) => <>{numeral(item.amount).format("0,0.00")}</>,
      total: (totals) => (
        <div className="text-right">
          {numeral(totals.amount).format("0,0.00")}
        </div>
      ),
    },
    // {
    //   key: "costRatio",
    //   label: "Cost Ratio",
    //   className: "text-center",
    //   content: () => <>{numeral(storage?.costRatio ?? 1).format("0%")}</>,
    // },
    // {
    //   key: "totalValue",
    //   label: "Total value",
    //   className: "text-right pr-2",
    //   content: (item) => (
    //     <>
    //       {numeral(item.unitPrice)
    //         .multiply(storage?.costRatio ?? 1)
    //         .multiply(item.balance)
    //         .format("0,0.00")}
    //     </>
    //   ),
    //   total: (totals) => (
    //     <div className="text-right">
    //       {numeral(totals.value).format("0,0.00")}
    //     </div>
    //   ),
    // },
  ];

  return (
    <Table
      columns={columns}
      data={items}
      sortColumn={sortColumn}
      onSort={onSort}
      totals={totals}
      includeTotals={true}
    />
  );
};

export default ConsignmentTable;
