import React from "react";
import Table from "./../common/table";
import {
  CellCenter,
  CellRight,
  CellInteger,
  // CellPercent,
} from "./../common/tableCellFormats";
// import { marginRatio } from "../utils/numberUtils";
// import { numeral } from "numeral";

const ProductionTable = (props) => {
  const columns = [
    {
      path: "name",
      label: "Period",
      total: () => <CellRight></CellRight>,
      content: (line) => <CellCenter>{line.name}</CellCenter>,
    },
    {
      path: "endDate",
      label: "End Date",
      total: () => <CellCenter>Totals:</CellCenter>,
      content: (line) => <CellCenter>{line.endDate}</CellCenter>,
    },
    // {
    //   path: "cut",
    //   label: "Saw Cut",
    //   className: "text-right",
    //   total: (totals) => <CellInteger value={totals.cut} />,
    //   content: (line) => <CellInteger value={line.cut || 0} />,
    // },
    // {
    //   path: "cutOVA",
    //   label: "SAW OVA",
    //   className: "text-right",
    //   total: (totals) => <CellInteger value={totals.cutOva} />,
    //   content: (line) => <CellInteger value={line.cutOva || 0} />,
    // },
    // {
    //   path: "cnc",
    //   label: "CNC Cut",
    //   className: "text-right",
    //   total: (totals) => <CellInteger value={totals.cnc} />,
    //   content: (line) => <CellInteger value={line.cnc || 0} />,
    // },
    // {
    //   path: "installed",
    //   label: "Installed",
    //   className: "text-right",
    //   total: (totals) => <CellInteger value={totals.installed} />,
    //   content: (line) => <CellInteger value={line.installed || 0} />,
    // },
    // {
    //   path: "waste",
    //   label: "Waste",
    //   className: "text-right",
    //   total: (totals) => <CellInteger value={totals.waste} />,
    //   content: (line) => (
    //     <div
    //       className="btn-link clickable"
    //       onClick={() => props.onClick(line, "Waste")}
    //     >
    //       <CellInteger value={line.waste || 0} />
    //     </div>
    //   ),
    // },
    // {
    //   path: "wasteOva",
    //   label: "Waste, SQF",
    //   className: "text-right",
    //   total: (totals) => <CellInteger value={totals.wasteOva} />,
    //   content: (line) => <CellInteger value={line.wasteOva || 0} />,
    // },
    // {
    //   path: "wasteOvaPercent",
    //   label: "Waste, %",
    //   className: "text-right",
    //   total: (totals) => <CellPercent value={totals.wasteOvaPercent} />,
    //   content: (line) => <CellPercent value={line.wasteOvaPercent || 0} />,
    // },
    // {
    //   path: "late",
    //   label: "Rescheduled",
    //   className: "text-right",
    //   total: (totals) => <CellInteger value={totals.late} />,
    //   content: (line) => (
    //     <div
    //       className="btn-link clickable"
    //       onClick={() => props.onClick(line, "Rescheduled")}
    //     >
    //       <CellInteger value={line.late || 0} />
    //     </div>
    //   ),
    // },
    // {
    //   path: "latePercent",
    //   label: "Rescheduled, %",
    //   className: "text-right",
    //   total: (totals) => <CellPercent value={totals.latePercent} />,
    //   content: (line) => <CellPercent value={line.latePercent || 0} />,
    // },
    // {
    //   path: "jobs",
    //   label: "Total Jobs",
    //   className: "text-right",
    //   total: (totals) => <CellInteger value={totals.jobs} />,
    //   content: (line) => (
    //     <div className="btn-link clickable" onClick={() => props.onClick(line)}>
    //       <CellInteger value={line.jobs || 0} />
    //     </div>
    //   ),
    // },
    {
      path: "docs",
      label: "Documents",
      className: "text-right",
      total: (totals) => <CellInteger value={totals.docs} />,
      content: (line) => (
        <div className="btn-link clickable" onClick={() => props.onClick(line)}>
          <CellInteger value={line.docs || 0} />,
        </div>
      ),
    },
    {
      path: "products",
      label: "Products",
      className: "text-right",
      total: (totals) => <CellInteger value={totals.products} />,
      content: (line) => <CellInteger value={line.products || 0} />,
    },
    {
      path: "qty",
      label: "Production, qty",
      className: "text-right",
      total: (totals) => <CellInteger value={totals.qty} />,
      content: (line) => <CellInteger value={line.qty || 0} />,
    },
  ];

  const { lines, onSort, sortColumn, totals } = props;

  return (
    <Table
      columns={columns}
      data={lines}
      sortColumn={sortColumn}
      onSort={onSort}
      totals={totals}
      includeTotals={true}
    />
  );
};

export default ProductionTable;
