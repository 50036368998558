import React, { useState } from "react";
import Table from "../common/table";
// import DateFormat from "../common/dateFormat";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

const GenSaleShipmentsTable = (props) => {
  const {
    title,
    items,
    // onHeaderClick,
    // headerToolTip,
    onCreateShipment,
    onDelete,
    // columns,
    sorting = { path: "ref.label", order: "asc" },
    // controls = null,
  } = props;

  const columns = [
    {
      path: "ref.label",
      className: "w-50",
      label: "Invoice",
    },
    {
      path: "ref.lineId",
      className: "text-center",
      label: "Line",
    },
    {
      path: "inventory.label",
      className: "w-25",
      label: "Item",
      content: (txn) => (
        <span className={txn?.inventory?.value ? "" : "text-danger"}>
          {txn?.inventory?.label}
        </span>
      ),
    },
    {
      path: "qty",
      className: "text-center",
      label: "Qty",
    },
    {
      key: "controls",
      className: "text-center",
      content: (txn) => (
        <div className="d-flex">
          <div>
            <button
              onClick={() => onCreateShipment(txn)}
              className="btn btn-outline-primary btn-sm"
              data-toggle="tooltip"
              data-placement="top"
              title="Create shipment"
              disabled={txn.isPurchasing ? true : false}
            >
              <FontAwesomeIcon icon="plus" />
            </button>
          </div>
          <div>
            <button
              onClick={() => onDelete(txn)}
              className="btn btn-outline-danger btn-sm ml-1"
              data-toggle="tooltip"
              data-placement="top"
              title="Remove from the update list"
            >
              <FontAwesomeIcon icon="trash" />
            </button>
          </div>
        </div>
      ),
    },
  ];

  const [sortColumn, setSortColumn] = useState(sorting);
  const [currentPage, setPage] = useState(1);
  const pageSize = 300;

  const handleSort = (sortColumn) => {
    setSortColumn(sortColumn);
  };

  const totals = { items: items.length };
  //   totals.qty = items.reduce((sum, i) => {
  //     return sum + i.data.qty;
  //   }, 0);

  const sorted = _.orderBy(items, [sortColumn.path], [sortColumn.order]);

  const data = paginate(sorted, currentPage, pageSize);

  return (
    <div className="my-2">
      <div className="d-flex justify-content-between pt-2">
        <h5
          className="text-uppercase clickable"
          data-toggle="tooltip"
          data-placement="top"
          // title={headerToolTip}
          // onClick={onHeaderClick}
        >{`${title} (${totals.items})`}</h5>
        {/* <div>{controls}</div> */}
      </div>
      <div className="border rounded bg-white p-3 shadow-sm">
        {totals.items < 1 ? (
          "No transactions"
        ) : (
          <Table
            columns={columns}
            data={data}
            sortColumn={sortColumn}
            onSort={handleSort}
            onCreateShipment={onCreateShipment}
            onDelete={onDelete}
            totals={totals}
          />
        )}
        <Pagination
          itemsCount={totals.items}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={(pageNumber) => setPage(pageNumber)}
        />
      </div>
    </div>
  );
};

export default GenSaleShipmentsTable;
