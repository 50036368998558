import { Component } from "react";
import auth from "../../services/authService";
import login from "../../services/loginApiService";
import settings from "../../services/settingService";

class Logout extends Component {
  componentDidMount() {
    auth.logout();
    login.logout();
    settings.logout();
    window.location = "/";
  }

  render() {
    return null;
  }
}

export default Logout;
