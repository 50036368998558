import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "../common/table";
import DateFormat from "../common/dateFormat";
import numeral from "numeral";

class PoDetailsTable extends React.Component {
  tableColumns = [
    {
      path: "item.label",
      label: "Item",
      // className: "text-center",
    },
    {
      path: "description",
      label: "Description",
      content: (detail) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{detail.description}</div>
      ),
    },
    {
      path: "uom",
      label: "Unit",
      // className: "text-center",
    },
    {
      path: "qty",
      label: "Qty",
      className: "text-right pr-2",
      content: (detail) => numeral(detail.qty).format("0,0.0"),
    },
    {
      path: "unitCost",
      label: "Cost",
      className: "text-right",
      content: (detail) => numeral(detail.unitCost).format("$0,0.00"),
    },
    {
      path: "poDate",
      label: "Ordered",
      content: (detail) => <DateFormat value={detail.poDate} />,
    },
    {
      path: "dueDate",
      label: "Due Date",
      content: (detail) => <DateFormat value={detail.dueDate} />,
    },
    {
      path: "poNumber",
      label: "PO Number",
      className: "text-center",
      content: (detail) => (
        <Link to={`/purchase-orders/${detail._id}`}>{detail.poNumber}</Link>
      ),
    },
    {
      path: "vendor.label",
      label: "Supplier",
      // className: "text-center",
    },
    {
      path: "status.label",
      label: "Status",
      // className: "text-center",
    },
  ];

  listColumns = [
    {
      path: "data.txnNumber",
      label: "Doc Number",
      className: "text-center",
      content: (detail) => (
        <Link to={`/purchase-orders/${detail._id}`}>
          {detail.data && detail.data.txnNumber}
        </Link>
      ),
    },
    {
      path: "data.txnDate",
      label: "Name",
      content: (detail) => (
        <DateFormat value={detail.data && detail.data.txnDate} />
      ),
    },
    {
      path: "entity",
      label: "Transaction Type",
      className: "text-center",
    },
    {
      key: "delete",
      content: (detail) => (
        <button
          onClick={() => this.props.onDelete(detail)}
          className="btn btn-danger btn-sm"
        >
          <FontAwesomeIcon icon="trash" />
        </button>
      ),
    },
  ];

  render() {
    const { items, onSort, sortColumn, isList } = this.props;
    const columns = isList ? this.listColumns : this.tableColumns;

    return (
      <Table
        columns={columns}
        data={items}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default PoDetailsTable;
