import React from "react";
import PagePrint from "../common/PagePrint";

const DashboardControls = () => {
  return (
    <div className="d-print-none">
      {/* <div
        className="btn btn-sm btn-success shadow-sm mr-2"
        onClick={() => (window.location = "/projects")}
      >
        All purchase orders
      </div> */}
      <PagePrint />
    </div>
  );
};

export default DashboardControls;
