import React from "react";
import { useFormContext } from "react-hook-form";

const Input = ({ name, label, ...rest }) => {
  const { register, errors } = useFormContext();
  const error = errors[name] ? errors[name].message : null;
  return (
    <div className="form-group">
      {label && (
        <label
          htmlFor={name}
          className="form-label form-label-sm font-weight-bold"
          style={{ marginBottom: "0px" }}
        >
          {label}
        </label>
      )}
      <input
        name={name}
        id={name}
        ref={register}
        className="form-control"
        {...rest}
      />
      <small className="text-danger">{error}</small>
    </div>
  );
};

export default Input;
