import http from "./httpService";

const apiEndpoint = "/reports";

export function getInventoryReceiptsQuery(query) {
  return http.get(apiEndpoint + "/inventory-receipts" + query);
}

export function getItemTransactionsQuery(query) {
  return http.get(apiEndpoint + "/item-transactions" + query);
}

export function getInventoryByLocation(query) {
  return http.get(apiEndpoint + "/inventory-by-location" + query);
}

export function getConsignmentShipping(query) {
  return http.get(apiEndpoint + "/items-consignment-shipped" + query);
}
