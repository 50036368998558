export const mapInvoiceDetails = ({
  invoices,
  productionItems,
  inventories,
  selectedStorage,
}) => {
  let details = [];

  console.log("Total invoices", invoices.length);
  let invoiceNum = 0;
  for (const invoice of invoices) {
    invoiceNum++;
    console.log(`Invoice # ${invoiceNum} ${invoice.title}`, invoice);
    if (invoice?.data?.customer?.label.toLowerCase().includes("consignment"))
      continue;
    if (invoice.data && invoice.data.details) {
      for (const line of invoice.data.details) {
        if (invoice.data && invoice.data.refs) {
          console.log("Refs found: ", invoice);
          let ref = invoice.data.refs.find(
            (ref) =>
              ref.type === "InventoryShipment" && ref.lineId === line.lineId
          );
          if (ref) continue;
        }
        if (!line.item) continue;

        if (!line.item.value) {
          if (!line.item.label) continue;
          let item = productionItems.find(
            (prod) => prod.label === line.item.label
          );
          if (!item) continue;
          line.item = item;
        }
        // #TODO redo inventory creation
        let foundInventory = inventories.find(
          (inventory) => inventory.itemId === line.item.value
        );

        if (!foundInventory) {
          console.log(
            `Not found inventory item ${line.item.label}`,
            foundInventory
          );
          continue;
        }

        let detail = {
          ref: {
            type: "Invoice",
            value: invoice._id,
            label: invoice.title,
            customer: invoice.data.customer,
            currency: invoice.data.currency || { value: "CAD" },
            txnNumber: invoice.data.txnNumber,
            lineId: line.lineId,
          },
          customer: invoice.data.customer,
          storage: selectedStorage,
          inventory: foundInventory,
          item: line.item,
          txnDate: invoice.data.txnDate,
          qty: line.qty,
        };
        console.log(`Detail ${line.item.label}`, detail);
        details.push(detail);
      }
    }
  }
  console.log("Details list: ", details);
  return details;
};
