import React from "react";
import ConsignmentRemoteTable from "./ConsignmentRemoteTable";

const ConsignmentRemote = () => {
  return (
    <div>
      <h2>Consignment</h2>
      <ConsignmentRemoteTable />
    </div>
  );
};

export default ConsignmentRemote;
