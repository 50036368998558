import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import withLoading from "./../common/withLoading";
import SidebarLeft from "./../common/SidebarLeft";
import ListGroup from "./../common/listGroup";
import SearchBox from "../common/searchBox";
import ListControls from "./ListControls";
import InventoryTable from "./InventoryTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getStorages } from "../../services/entityService";
import {
  getInventories,
  saveInventory,
} from "./../../services/inventoryService";
import { paginate } from "../../utils/paginate";
import qs from "query-string";
import _ from "lodash";
import InventoryCards from "./InventoryCards";

class Inventories extends React.Component {
  state = {
    inventories: [],
    types: [],
    storages: [],
    sortColumn: { path: "name", order: "asc" },
    currentPage: 1,
    pageSize: 300,
    searchQuery: "",
    selectedType: null,
    storageType: "isInventory",
  };

  populateStorages = async (type) => {
    try {
      const { data } = await getStorages();

      const filtered = data.filter((s) => s.data[type] === true);
      const mapped = filtered.map((s) => {
        return { value: s._id, label: s.title, qty: 0, code: s.data.code };
      });
      const storages = _.orderBy(mapped, "code", "asc");
      // const storages = [{ value: "", label: "All storages" }, ...mapped];
      this.setState({ storages, storageType: type });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  populateInventories = async () => {
    try {
      const { data } = await getInventories();
      const inventories = data.map((i) => {
        i.inStock = _.sumBy(i.current, "qty");
        i.reserved = _.sumBy(i.reservations, "qty");
        i.ordered = _.sumBy(i.orders, "qty");
        i.available = i.inStock - i.reserved;
        return i;
      });
      const uniqTypes = _.uniqBy(inventories, "type");
      const mapped = uniqTypes.map((t) => ({ value: t.type, label: t.type }));
      const types = [{ value: "", label: "All inventories" }, ...mapped];

      this.setState({ inventories, types });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  async componentDidMount() {
    const { type } = qs.parse(this.props.location.search);
    const storageType = type ? type : "isInventory";
    await this.populateStorages(storageType);
    await this.populateInventories();
    this.props.loadingOff();
  }

  // handleStorageSelect = (storage) => {
  //   this.setState({
  //     selectedType: storage,
  //     searchQuery: "",
  //     currentPage: 1,
  //   });
  // };

  handleTypeSelect = (type) => {
    this.setState({
      selectedType: type,
      searchQuery: "",
      currentPage: 1,
    });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      selectedType: null,
      currentPage: 1,
    });
  };

  handleSort = (sortColumn) => {
    this.setState({
      sortColumn: sortColumn,
    });
  };

  handleClick = (inventory) => {
    this.props.history.push(`/items/${inventory._id}/edit`);
  };

  handleDelete = async (inventory) => {
    const originalItems = this.state.inventories;
    const inventories = originalItems.filter((m) => m._id !== inventory._id);
    this.setState({ inventories });

    inventory.isInactive = true;

    try {
      await saveInventory(inventory);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This order has already been deleted.");

      this.setState({ inventories: originalItems });
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  getPagedData = () => {
    const {
      inventories,
      storages,
      searchQuery,
      selectedType,
      sortColumn,
      currentPage,
      pageSize,
    } = this.state;
    const allInventories = [...inventories];
    let filtered = allInventories;
    if (searchQuery) {
      filtered = allInventories.filter((i) =>
        i.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else if (selectedType) {
      if (selectedType.value) {
        filtered = allInventories.filter((i) => i.type === selectedType.value);
      } else {
        filtered = allInventories.map((i) => {
          i.locationQty = 0;
          return i;
        });
      }
    }

    const cols = [...storages];

    for (const item of filtered) {
      const lines = [];
      for (const col of cols) {
        let qty = 0;
        if (item.current) {
          const loc = item.current.find((l) => l.locationId === col.value);
          if (loc) qty = loc.qty;
        }
        const line = {
          value: col.value,
          label: col.label,
          qty: qty,
        };
        lines.push(line);
      }
      item.locations = lines;
    }

    // const pivots = cols.map((c) => {
    //   const line = c;
    //   line.qty = 0;
    //   return line;
    // });

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const items = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: items, pivots: cols };
  };

  render() {
    const { types, selectedType, sortColumn, searchQuery } = this.state;
    const { data: inventories, pivots, totalCount } = this.getPagedData();

    return (
      <>
        <SidebarLeft>
          <div>
            <Link
              to="/inventory/new"
              className="btn btn-primary shadow"
              style={{ marginBottom: 20 }}
            >
              <FontAwesomeIcon icon="plus" className="mr-2" />
              Add Inventory
            </Link>
          </div>
          <ListGroup
            items={types}
            valueProperty="value"
            textProperty="label"
            selectedItem={selectedType}
            onItemSelect={this.handleTypeSelect}
            selectedCount={totalCount}
          />
        </SidebarLeft>
        <div className="col mt-2">
          <div className="d-flex py-2">
            <h2 className="text-uppercase">Inventory List</h2>
          </div>
          <div className="d-flex justify-content-between border-bottom py-2 d-print-none ">
            <SearchBox
              value={searchQuery}
              placeholder="Search ..."
              onChange={this.handleSearch}
            />
            <div>
              <ListControls />
            </div>
          </div>
          <div className="d-flex border-top pb-n5 mb-3">
            <InventoryCards items={inventories} />
            {/* <InventoryTable
              items={inventories}
              pivots={pivots}
              sortColumn={sortColumn}
              isList={false}
              onDelete={this.handleDelete}
              onSort={this.handleSort}
              onClick={this.handleClick}
            /> */}
          </div>
        </div>
      </>
    );
  }
}

export default withLoading(Inventories);
