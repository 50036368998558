import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers";
// import { toast } from "react-toastify";
import Panel from "../common/panel";
import Controls from "./Controls";
import { RowOneLeftTwoRight } from "../common/formLayout";
import InputText from "../common/inputText";
import InputCheckbox from "../common/inputCheckbox";
import InputTextArea from "./../common/inputTextArea";

const Edit = (props) => {
  const { data, onSave } = props;
  // const { data: defaultValues } = getDefaultValues();
  const defaultValues = {
    displayName: "",
    code: "",
    // entity: null,
    address: "",
    note: "",
  };

  const methods = useForm({ defaultValues, mode: "onBlur" });
  const { reset } = methods;

  useEffect(() => {
    const storageData = { ...data };
    reset(storageData);
  }, [data, reset]);

  const onSubmit = async (values) => {
    const data = {
      displayName: values.displayName,
      code: values.code,
      note: values.note,
      address: values.address,
      isInventory: values.isInventory,
      isCustomerSite: values.isCustomerSite,
      isEquipment: values.isEquipment,
      // entity: values.entity,
    };
    await onSave(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Panel>
          <RowOneLeftTwoRight
            colOne={<InputText name="displayName" label="Name" />}
            colTwo={<InputText name="code" label="Code" />}
          />
          <RowOneLeftTwoRight
            colOne={<InputTextArea name="address" label="Address" />}
          />
          <hr />
          <RowOneLeftTwoRight
            colOne={<InputTextArea name="note" label="Note" />}
          />
          <div className="row">
            <InputCheckbox
              name="isInventory"
              label="Inventory"
              inputClass="form-check-inline pl-3"
            />
            <InputCheckbox
              name="isCustomerSite"
              label="Customer Site"
              inputClass="form-check-inline"
            />
            <InputCheckbox
              name="isEquipment"
              label="Equipment and Tools"
              inputClass="form-check-inline"
            />
          </div>
        </Panel>
        <Controls isEdit={true} {...props} />
      </form>
    </FormProvider>
  );
};

export default Edit;
