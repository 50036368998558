import React, { Component } from "react";

class Panel extends Component {
  panelHeader = (headerLeft, headerRight) => (
    <div className="card-header d-flex justify-content-between">
      <h5>{headerLeft || "New Item"}</h5>
      <div>{headerRight || ""}</div>
    </div>
  );

  panelContent(content) {
    return <div className="card-body">{content}</div>;
  }

  panelFooter = (footerLeft, footerRight) => (
    <div className="card-footer d-flex justify-content-between">
      <div className="d-flex flex-row">{footerLeft}</div>
      <div className="d-flex justify-content-right">{footerRight}</div>
    </div>
  );

  render() {
    const { name, header, footer } = this.props;
    const content = this.props.children;
    // console.log("render Panel: ", header, content, footer, this.props);
    return (
      <div name={name} className="card mb-3 shadow-sm">
        {header && this.panelHeader(header.left, header.right)}
        {content && this.panelContent(content)}
        {footer && this.panelFooter(footer.left, footer.right)}
      </div>
    );
  }
}

export default Panel;
