import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import ReportList from "./ReportList";
import Consignment from "./Consignment";
import ConsignmentShipped from "./ConsignmentShipped";
import InventoryToParts from "./InventoryToParts";
import InventoryByLocation from "./InventoryByLocation";
import IncomingMaterial from "./IncomingMaterial";
import InventoryReceipts from "./InventoryReceipts";
import ItemTransactions from "./ItemTransactions";
import Production from "./Production";
import ConsignmentBalance from "./ConsignmentBalance";
import ConsignmentRemote from "./ConsignmentRemote";
// import ProductionByProcess from "./ProductionByProcess";
// import ProductionBySalesGroup from "./ProductionBySalesGroup";
// import ProductionByStatus from "./ProductionByStatus";
// import ProductionByItem from "./ProductionByItem";
// import MaterialUsage from "./MaterialUsage";
// import MaterialUsagePeriods from "./MaterialUsagePeriods";
// import MaterialUsageProjects from "./MaterialUsageProjects";
// import ProductionByWorker from "./ProductionByWorker";
// import WorkerJobsSummary from "./WorkerJobsSummary";
// import JobCostBySalesGroup from "./JobCostBySalesGroup";
// import JobCostBySalesOrder from "./JobCostBySalesOrder";

const Reports = ({ match }) => {
  return (
    <div className="container-fluid px-5">
      <Switch>
        <Route path={`${match.path}/consignment`} component={Consignment} />
        <Route
          path={`${match.path}/consignment-shipped`}
          component={ConsignmentShipped}
        />
        <Route
          path={`${match.path}/consignment-balance`}
          component={ConsignmentBalance}
        />
        <Route
          path={`${match.path}/consignment-transactions`}
          component={ConsignmentRemote}
        />
        <Route
          path={`${match.path}/inventory-by-location`}
          component={InventoryByLocation}
        />
        <Route
          path={`${match.path}/inventory-to-parts`}
          component={InventoryToParts}
        />
        <Route
          path={`${match.path}/incoming-material`}
          component={IncomingMaterial}
        />
        <Route
          path={`${match.path}/inventory-receipts`}
          component={InventoryReceipts}
        />
        <Route
          path={`${match.path}/item-transactions`}
          component={ItemTransactions}
        />
        <Route path={`${match.path}/production`} component={Production} />

        <Route
          path={`${match.path}/`}
          component={(props) => <ReportList {...props} />}
        />
        <Redirect to={`${match.path}/`} />
      </Switch>
    </div>
  );
};

export default Reports;
