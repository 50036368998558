import React from "react";
// import auth from "../../services/authService";
import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "./../common/table";
import {
  // CellCenter,
  CellInteger,
  // CellRight,
  // CellInteger,
} from "../common/tableCellFormats";
import { numberInteger } from "../../utils/numberUtils";
// import { locationsToTextArea } from "../../utils/inventoryUtils";

const InventoryBalanceTable = (props) => {
  const { items, pivots, onSort, sortColumn, totals } = props;

  const startColumns = [
    {
      path: "name",
      label: "Name",
      content: (inventory) => (
        <Link to={`/inventory/${inventory._id}`}>{inventory.name}</Link>
      ),
    },
    // {
    //   path: "type",
    //   label: "Type",
    // },
    {
      path: "uom",
      label: "UOM",
    },
    {
      path: "available",
      label: "Available",
      className: "text-center",
      content: (inventory) => numberInteger(inventory.available || 0),
    },

    {
      path: "ordered",
      label: "Ordered",
      className: "text-center",
      content: (inventory) => numberInteger(inventory.ordered || 0),
    },
    {
      path: "reserved",
      label: "Reserved",
      className: "text-center",
      content: (inventory) => numberInteger(inventory.reserved || 0),
    },
    {
      path: "inStock",
      label: "In Stock",
      className: "text-center",
      content: (inventory) => numberInteger(inventory.inStock || 0),
    },
    // {
    //   path: "locations",
    //   label: "Locations",
    //   className: "text-center",
    //   content: (inventory) => (
    //     <div style={{ whiteSpace: "pre-wrap" }}>
    //       {locationsToTextArea(inventory.locations)}
    //     </div>
    //   ),
    // },
  ];

  // const pivotColumns = [];
  const pivotColumns = pivots.map((c, i) => ({
    path: `locations.${c.value}`,
    label: `${c.label}`,
    className: "text-right",
    // total: (totals) => <CellInteger value={totals.locations[i].qty} />,
    content: (line) => <CellInteger value={line.locations[i].qty || 0} />,
  }));

  const endColumns = [
    // {
    //   path: "inStock",
    //   label: "In Stock",
    //   className: "text-center",
    //   content: (inventory) => numberInteger(inventory.inStock || 0),
    // },
    // {
    //   path: "reserved",
    //   label: "Reserved",
    //   className: "text-center",
    //   content: (inventory) => numberInteger(inventory.reserved || 0),
    // },
    // {
    //   path: "available",
    //   label: "Available",
    //   className: "text-center",
    //   content: (inventory) => numberInteger(inventory.available || 0),
    // },
  ];

  // const controls = [
  //   {
  //     key: "controls",
  //     content: (inventory) => (
  //       <div className="text-right">
  //         <button
  //           onClick={() => this.props.onClick(inventory)}
  //           className="btn btn-secondary btn-sm mr-1"
  //         >
  //           <FontAwesomeIcon icon="edit" />
  //         </button>
  //         <button
  //           onClick={() => this.props.onDelete(inventory)}
  //           className="btn btn-danger btn-sm"
  //         >
  //           <FontAwesomeIcon icon="trash" />
  //         </button>
  //       </div>
  //     ),
  //   },
  // ];

  // constructor() {
  //   super();
  //   // const user = auth.getCurrentUser();
  //   // if (user && user.isAdmin) this.tableColumns.push(this.deleteColumn);
  // }

  const columns = [...startColumns, ...pivotColumns, ...endColumns];

  // const user = auth.getCurrentUser();
  // if (user && user.isAdmin) columns.push(controls);

  return (
    <Table
      columns={columns}
      data={items}
      sortColumn={sortColumn}
      onSort={onSort}
      totals={totals}
    />
  );
};

export default InventoryBalanceTable;
