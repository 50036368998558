import React, { useEffect, useState } from "react";
import withFormMode from "../common/withFormMode";
import { FormTitle, FormSubTitle } from "../common/formLayout";
import Breadcrumb from "../common/Breadcrumb";
import ImageCard from "./ImageCard";
import ItemDetailsCard from "./ItemDetailsCard";
import InventoryBalanceTable from "./InventoryBalanceTable";
import {
  getEntity,
  saveEntity,
  getStorages,
} from "../../services/entityService";
import { getInventoriesQuery } from "../../services/inventoryService";
import { paginate } from "../../utils/paginate";
// import { updateData } from "../../utils/updateData";
import { toast } from "react-toastify";
import qs from "query-string";
import _ from "lodash";

const ItemInvList = (props) => {
  const [item, setItem] = useState({
    title: "",
    data: {},
    slabsmith: {},
    thumbnail: {},
  });

  const [inventories, setInventories] = useState([]);
  const [storages, setStorages] = useState([]);
  const [storageType, setStorageType] = useState("isInventory");
  const [sortColumn, setSortColumn] = useState({ path: "name", order: "asc" });
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 50;

  const { match } = props;
  const itemId = match.params.id;

  useEffect(() => {
    async function populateItem(itemId) {
      try {
        const { data: item } = await getEntity("Item", itemId);
        if (item.data && !item.data.name) item.data.name = item.title;
        setItem(item);
      } catch (ex) {
        if (ex.response && ex.response.status === 404)
          window.location.href = "/not-found";
      }
    }

    if (itemId === "new") {
      return;
    }

    populateItem(itemId);
  }, [itemId]);

  useEffect(() => {
    const populateInventories = async (itemId) => {
      try {
        const { data } = await getInventoriesQuery("?item=" + itemId);
        const mapped = data.map((i) => {
          i.inStock = _.sumBy(i.current, "qty");
          i.reserved = _.sumBy(i.reservations, "qty");
          i.ordered = _.sumBy(i.orders, "qty");
          i.available = i.inStock - i.reserved;
          return i;
        });
        setInventories(mapped);
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          toast.error(ex.response.data);
        }
      }
    };

    populateInventories(itemId);
    return () => setInventories([]);
  }, [itemId]);

  // useEffect(() => {
  //   if (inventories) updateInStock();
  // }, [inventories]);

  useEffect(() => {
    const populateStorages = async (type) => {
      try {
        const { data } = await getStorages();

        const filtered = data.filter((s) => s.data[type] === true);
        const mapped = filtered.map((s) => {
          return { value: s._id, label: s.title, qty: 0, code: s.data.code };
        });
        const ordered = _.orderBy(mapped, "code", "asc");
        // const storages = [{ value: "", label: "All storages" }, ...mapped];
        setStorages(ordered);
        setStorageType(type);
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          toast.error(ex.response.data);
        }
      }
    };

    const { type } = qs.parse(props.location.search);
    const storageType = type ? type : "isInventory";
    populateStorages(storageType);
  }, []);

  const handleSearch = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleClick = (inventory) => {
    props.history.push(`/inventory/${inventory._id}`);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleThumbnailSave = async (thumbnail) => {
    const updated = { ...item };
    updated.thumbnail = thumbnail;
    console.log(thumbnail);
    try {
      const { data: saved } = await saveEntity(updated);
      if (saved.data && !saved.data.name) saved.data.name = saved.title;
      setItem(saved);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  const getPagedData = () => {
    const allInventories = [...inventories];
    const totalInStock = _.sumBy(inventories, "inStock");

    let filtered = allInventories;
    if (searchQuery) {
      filtered = allInventories.filter((i) =>
        i.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      // } else if (selectedType) {
      //   if (selectedType.value) {
      //     filtered = allInventories.filter((i) => i.type === selectedType.value);
      //   } else {
      //     filtered = allInventories.map((i) => {
      //       i.locationQty = 0;
      //       return i;
      //     });
      //   }
    }

    const cols = [...storages];

    for (const item of filtered) {
      const lines = [];
      for (const col of cols) {
        let qty = 0;
        if (item.current) {
          const loc = item.current.find((l) => l.locationId === col.value);
          if (loc) qty = loc.qty;
        }
        const line = {
          value: col.value,
          label: col.label,
          qty: qty,
        };
        lines.push(line);
      }
      item.locations = lines;
    }

    // const pivots = cols.map((c) => {
    //   const line = c;
    //   line.qty = 0;
    //   return line;
    // });

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const items = paginate(sorted, currentPage, pageSize);

    return {
      totalCount: filtered.length,
      data: items,
      pivots: cols,
      totalInStock,
    };
  };

  const { data, pivots, totalCount, totalInStock } = getPagedData();

  const breadcrumbs = [
    { value: "/inventories", label: "Inventory" },
    { value: `/inventory/by-item/${itemId}`, label: `${item.title}` },
  ];

  return (
    <div>
      <Breadcrumb items={breadcrumbs} />
      <FormTitle title={item.title} />
      {/* <ImageCard item={item} /> */}
      <ItemDetailsCard
        item={item}
        inStock={totalInStock}
        onThumbnailSave={handleThumbnailSave}
      />
      <FormSubTitle
        title={
          <span className="font-weight-bolder text-primary">
            Available inventory
          </span>
        }
      />

      <div className="d-flex border-top pb-n5 mb-3">
        <InventoryBalanceTable
          items={data}
          pivots={pivots}
          sortColumn={sortColumn}
          isList={false}
          onDelete={() => console.log("Delete item")}
          onSort={(sortColumn) => {
            setSortColumn(sortColumn);
          }}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default withFormMode(ItemInvList);
