import Compressor from "compressorjs";

export async function compressImage(file, maxSize = 150000, maxWidth = 800) {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      convertSize: maxSize,
      maxWidth,
      success(blob) {
        const result = new File([blob], blob.name, {
          type: blob.type,
        });
        resolve(result);
      },
      error(err) {
        reject(err);
      },
    });
  });
}

export async function compressPdf(file, maxSize) {
  return new Promise((resolve, reject) => {
    resolve(file);
  });
}
