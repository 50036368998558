import React from "react";
import Panel from "../common/panel";
import Controls from "./Controls";
import ViewDetails from "./ViewDetails";
import { RowTwoColumns, RowFourColumns } from "../common/formLayout";
import Field from "../common/fieldText";
import FieldDate from "../common/fieldDate";
import HeaderView from "./HeaderView";
import MetaDataView from "../common/MetaDataView";
import Messages from "./Messages";
// import Comments from "../common/communications/Comments";

const View = (props) => {
  const { data, records } = props;
  return (
    <div id="DocumentView">
      <Panel>
        <HeaderView {...props} />
        <RowFourColumns
          colOne={
            <FieldDate
              name="txnDate"
              label="Document Date"
              value={data.txnDate}
            />
          }
          colTwo={
            <Field name="txnNumber" label="Number" value={data.txnNumber} />
          }
        />

        <RowTwoColumns
          colOne={
            <Field
              name="addressFrom"
              label="From address"
              value={data.addressFrom}
            />
          }
          colTwo={
            <Field name="addressTo" label="To address" value={data.addressTo} />
          }
        />

        <ViewDetails details={records} {...props} />
        <hr />
        <RowTwoColumns
          colOne={<Field name="memo" label="Document Memo" value={data.memo} />}
          colTwo={
            <Field name="notes" label="Internal Notes" value={data.notes} />
          }
        />
        <hr />
        {data.metadata && (
          <>
            <MetaDataView metadata={data.metadata} />
          </>
        )}
      </Panel>
      <Controls isEdit={false} {...props} />

      {/* <Comments comments={data.comments} path={props.documentPath} {...props} /> */}
      <Messages messages={data.messages} />
    </div>
  );
};

export default View;
