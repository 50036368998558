import React, { useState } from "react";
import Panel from "../common/panel";
import Table from "./../common/table";
import DateFormat from "../common/dateFormat";
import numeral from "numeral";

const Ordered = (props) => {
  const { orders } = props;

  const [sortColumn, setSortColumn] = useState({
    path: "doc.txnDate",
    order: "asc",
  });

  const columns = [
    {
      path: "doc.txnDate",
      label: "Date",
      content: (order) => <DateFormat value={order.doc && order.doc.txnDate} />,
    },
    {
      path: "doc.txnNumber",
      label: "Number",
    },
    {
      path: "qty",
      label: "Qty",
      className: "text-right pr-2",
      content: (item) => numeral(item.qty).format("0,0.000"),
    },
  ];

  const totalOrdered = orders.reduce((sum, i) => {
    return sum + i.qty;
  }, 0);

  return (
    <div>
      <h4>Purchase orders</h4>
      <Panel>
        <Table
          columns={columns}
          data={orders}
          sortColumn={sortColumn}
          onSort={(sortColumn) => setSortColumn(sortColumn)}
        />
        <h6 className="text-right pr-2">{`Total: ${numeral(totalOrdered).format(
          "0,0.000"
        )}`}</h6>
      </Panel>
    </div>
  );
};

export default Ordered;
