import http from "./httpService";

const apiEndpoint = "/purchase-orders";

function itemUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getPoDetails(query = "") {
  return http.get(apiEndpoint + "/details" + query);
}

// export function getItems(query = "") {
//   return http.get(apiEndpoint + query);
// }

// export function getItemsQty(query = "") {
//   return http.get(apiEndpoint + "/qty" + query);
// }

// export function getItemsInStock(query = "") {
//   return http.get(apiEndpoint + "/inStock" + query);
// }
