import React from "react";

const Field = ({
  name = "Name",
  label = "Name",
  value = "",
  comment = "",
  ...rest
}) => {
  const className = value ? "form-control h-100" : "form-control";
  return (
    <div className="form-group">
      {label && (
        <label
          htmlFor={name}
          className="form-label form-label-sm font-weight-bold"
          style={{ marginBottom: "0px" }}
        >
          {label}
        </label>
      )}
      <div
        className={className}
        style={{ whiteSpace: "pre-wrap" }}
        name={name}
        id={name}
        {...rest}
      >
        {value}
      </div>
      {comment}
    </div>
  );
};

export default Field;
