import React from "react";

const DetailsHeader = () => {
  return (
    <thead>
      <tr className="text-center">
        <th scope="col" style={{ width: "2%" }}>
          #
        </th>
        <th scope="col" style={{ width: "16%" }}>
          Item
        </th>
        <th scope="col" style={{ width: "26%" }}>
          Description
        </th>
        <th scope="col" style={{ width: "9%" }}>
          UOM
        </th>
        <th scope="col" style={{ width: "8%" }}>
          QTY
        </th>
        <th scope="col" style={{ width: "7%" }}>
          Cost/base
        </th>
        <th scope="col" style={{ width: "8%" }}>
          UOM Cost
        </th>
        <th scope="col" style={{ width: "12%" }}>
          Amount
        </th>
        <th scope="col" style={{ width: "9%" }}>
          Tax
        </th>
        <th scope="col" style={{ width: "2%" }}></th>
      </tr>
    </thead>
  );
};

export default DetailsHeader;
