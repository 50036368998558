import { DateTime } from "luxon";

export const timing = (dueDate) => {
  const first = DateTime.fromISO(dueDate);
  let second = DateTime.local();
  if (first < second) return "danger";

  second = DateTime.local().plus({ days: 3 });
  if (first <= second) return "dark bg-warning";

  second = DateTime.local().plus({ days: 7 }).endOf("week");
  if (first <= second) return "info";

  return "dark";
};

export const tableTiming = (dueDate) => {
  const first = DateTime.fromISO(dueDate);
  let second = DateTime.local();
  if (first < second) return "table-danger";

  second = DateTime.local().plus({ days: 3 });
  if (first <= second) return "table-warning";

  second = DateTime.local().plus({ days: 7 }).endOf("week");
  if (first <= second) return "table-info";

  return "dark";
};
