import React from "react";

const FormControl = ({ left, right }) => {
  return (
    <div className="d-flex justify-content-between sw-bg-light p-3 border-top shadow-sm">
      <div className="flex-row">{left}</div>
      <div className="flex-row-reverse">{right}</div>
    </div>
  );
};

export default FormControl;
