import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers";
// import { toast } from "react-toastify";
import Panel from "../common/panel";
import Controls from "./Controls";
import { RowOneLeftTwoRight, RowFourColumns } from "../common/formLayout";
import Field from "../common/fieldText";
import InputText from "../common/inputText";
import InputSelect from "../common/inputSelect";
import InputTextArea from "./../common/inputTextArea";

const Edit = (props) => {
  const { data, onSave } = props;
  // const { data: defaultValues } = getDefaultValues();
  const defaultValues = {
    displayName: "",
    currency: {
      value: "CAD",
      label: "CAD",
    },
    billingAddress: "",
    email: "",
    phone: "",
    note: "",
  };

  const currencies = [
    {
      value: "CAD",
      label: "CAD",
    },
    {
      value: "USD",
      label: "USD",
    },
    {
      value: "EUR",
      label: "EUR",
    },
  ];

  const methods = useForm({ defaultValues, mode: "onBlur" });
  const { reset, setValue } = methods;

  useEffect(() => {
    const vendorData = { ...data };
    reset(vendorData);
  }, [data, reset]);

  const handleSelect = async (name, selected) => {
    setValue(selected.name, { value: name.value, label: name.label });
  };

  const onSubmit = async (values) => {
    // const data = {
    //   displayName: "",
    //   currency: {
    //     value: "CAD",
    //     label: "CAD",
    //   },
    //   billingAddress: "",
    //   email: "",
    //   phone: "",
    //   note: values.note,
    // };
    await onSave(values);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Panel>
          <RowOneLeftTwoRight
            colOne={<InputText name="displayName" label="Name" />}
            colTwo={
              props.isNew ? (
                <InputSelect
                  name="currency"
                  label="Currency"
                  options={currencies}
                  onChange={handleSelect}
                  valueOption="value"
                  labelOption="label"
                />
              ) : (
                <Field
                  name="currency"
                  label="Currency"
                  value={data.currency && data.currency.value}
                  readOnly
                />
              )
            }
          />
          <RowFourColumns
            colOne={<InputText name="phone" label="Phone" />}
            colTwo={<InputText name="email" label="Email" />}
          />
          <RowOneLeftTwoRight
            colOne={
              <InputTextArea name="billingAddress" label="Billing Address" />
            }
          />
          <hr />
          <RowOneLeftTwoRight
            colOne={<InputTextArea name="note" label="Note" />}
          />
        </Panel>
        <Controls isEdit={true} {...props} />
      </form>
    </FormProvider>
  );
};

export default Edit;
