import React, { useEffect, useState } from "react";
import withLoading from "../common/withLoading";
import SelectDateBox from "../common/SelectDateBox";
import SearchDropdown from "../common/SearchDropdown";
import SearchBox from "../common/searchBox";
import ItemTransactionTable from "./ItemTransactionTable";
import { DateTime } from "luxon";
import { getItemTransactionsQuery } from "../../services/reportService";
import { getItemList } from "../../services/itemService";
import numeral from "numeral";
import { toast } from "react-toastify";
import _ from "lodash";

const ItemTransactions = (props) => {
  const { loadingOn, loadingOff } = props;
  const [records, setRecords] = useState([]);
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchItem, setSearchItem] = useState("");
  const [selectedItem, setSelected] = useState(null);
  const [sortColumn, setSortColumn] = useState({
    path: "doc.txnDate",
    order: "asc",
  });
  const [fromDate, setFromDate] = useState(DateTime.local().startOf("month"));
  const [toDate, setToDate] = useState(DateTime.local().endOf("month"));

  const populateData = async () => {
    let query = `?fromDate=${fromDate.toISO()}&toDate=${toDate.toISO()}`;
    if (selectedItem) {
      query = query + `&item=${selectedItem.value}`;
      // query = query + `&item=614394f57ed2cb44841f64f4`;
      try {
        const { data } = await getItemTransactionsQuery(query);
        const mapped = data.map((t) => {
          const created = { ...t };
          created.search = `${t.inventory.label} ${t.name?.label} ${t.customer?.label} ${t.memo}`;
          return created;
        });
        setRecords(mapped);
        loadingOff();
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          toast.error(ex.response.data);
        }
        loadingOff();
      }
    }
  };

  const populateItems = async () => {
    try {
      const { data } = await getItemList();
      const mapped = data.map((s) => {
        return { value: s._id, label: s.title };
      });

      setItems(mapped);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  useEffect(() => {
    populateItems();
    // populateData();
    loadingOff();
  }, []);

  const handleDateChange = async () => {
    loadingOn();
    await populateData();
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleSearchItem = (query) => {
    setSearchItem(query);
  };

  const handleSelectItem = (selected) => {
    setSearchItem("");
    setSelected(selected);
  };

  const getData = () => {
    let filtered = [...records];

    if (searchQuery) {
      filtered = records.filter((i) =>
        i.search.toLowerCase().includes(searchQuery.toLowerCase())
      );
      // } else if (selectedType) {
      //   filtered = allDocuments.filter((i) => i.entity === selectedType.value);
    }

    const totals = {
      qtyIn: 0,
      qtyOut: 0,
      docs: filtered.length,
    };

    filtered.forEach((line) => {
      totals.qtyIn = totals.qtyIn + numeral(line.qtyIn).value();
      totals.qtyOut = totals.qtyOut + numeral(line.qtyOut).value();
    });

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    return { data: sorted, totals };
  };

  const getOptions = () => {
    let options = [...items];
    const filtered = items.filter((item) =>
      item?.label.toLowerCase().includes(searchItem?.toLowerCase())
    );

    options = filtered.slice(0, 5);
    return options;
  };

  const searchOptions = getOptions();

  const { data, totals } = getData();

  return (
    <main className="container-fluid px-5">
      <div className="d-flex py-2">
        <h2 className="text-uppercase">Item Transactions</h2>
      </div>
      <SearchDropdown
        query={searchItem}
        onChange={handleSearchItem}
        options={searchOptions}
        onSelect={handleSelectItem}
        placeholder="Search for item..."
      />
      <h4 className="text-uppercase mt-3 pt-2 border-top">{`Item: ${
        selectedItem?.label ?? "not selected"
      }`}</h4>
      <div className="d-none d-print-block">{`Period: ${fromDate.toISODate()} - ${toDate.toISODate()}`}</div>
      <SelectDateBox
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={(value) => setFromDate(value)}
        setToDate={(value) => setToDate(value)}
        handleDateChange={handleDateChange}
      />
      {data.length > 0 && (
        <SearchBox
          value={searchQuery}
          placeholder="Filter results..."
          onChange={handleSearch}
        />
      )}
      <ItemTransactionTable
        lines={data}
        totals={totals}
        sortColumn={sortColumn}
        onSort={(sortColumn) => setSortColumn(sortColumn)}
      />
    </main>
  );
};

export default withLoading(ItemTransactions);
