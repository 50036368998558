import React from "react";
import Thumbnail from "../common/images/Thumbnail";
import numeral from "numeral";
import _ from "lodash";

const ImageCard = ({ item }) => {
  const shortDesc = _.truncate(item.description, {
    length: 88,
    separator: /,? +-/,
  });
  const qty = numeral(item.inStock || 0).format("0,0");
  return (
    <div className="card h-100 my-3 shadow-sm">
      <Thumbnail
        navPath={`/inventory/by-item/${item._id}`}
        name={item.label}
        thumbnail={item?.thumbnail}
        classNames="card-img-top p-2"
      />
      <div
        className="card-body clickable"
        onClick={() => (window.location = `/inventory/by-item/${item._id}`)}
      >
        <div className="card-title h5 d-flex justify-content-between">
          <span>{item.label}</span>
          <span className="text-primary">{qty}</span>
          {/* <span>{item.inStock}</span> */}
        </div>
        <div className="card-text">{shortDesc}</div>
      </div>
      {/* <div className="card-footer">
        <small
          className="text-muted clickable"
          onClick={() => (window.location = `/items/${item._id}`)}
        >
          Item details...
        </small>
      </div> */}
    </div>
  );
};

export default ImageCard;
