import React from "react";
import Field from "./fieldText";

const MetaDataView = (props) => {
  const { metadata = [] } = props;
  const secondColumnStart = Math.floor(metadata.length / 2);

  return (
    <div>
      <h5 className="text-uppercase border-bottom">Additional Information</h5>
      <div className="row">
        <div className="col-md-6">
          {metadata.slice(0, secondColumnStart).map((item, index) => (
            <Field
              key={item.key}
              name={`metadata[${index}].value`}
              label={item.key}
              value={item.value}
            />
          ))}
        </div>
        <div className="col-md-6">
          {metadata.slice(secondColumnStart).map((item, index) => (
            <Field
              key={item.key}
              name={`metadata[${index + secondColumnStart}].value`}
              label={item.key}
              value={item.value}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MetaDataView;
