export function getDefaultValues() {
  const data = {
    displayName: "",
    currency: {
      value: "CAD",
      label: "CAD",
    },
    billingAddress: "",
    email: "",
    phone: "",
    note: "",
  };
  return { data: data };
}

export const mapQBOtoData = (qbo) => {
  if (!qbo) return;
  const data = {
    displayName: qbo.DisplayName,
  };
  if (qbo.CurrencyRef) {
    data.currency = {
      value: qbo.CurrencyRef.value,
      label: qbo.CurrencyRef.name,
    };
  } else {
    data.currency = {
      value: "CAD",
      label: "Canadian Dollar",
    };
  }
  if (qbo.BillAddr) data.billingAddress = addressToString(qbo.BillAddr);
  if (qbo.ShipAddr) data.shippingAddress = addressToString(qbo.ShipAddr);
  if (qbo.PrimaryPhone && qbo.PrimaryPhone.FreeFormNumber)
    data.phone = qbo.PrimaryPhone.FreeFormNumber;
  if (qbo.PrimaryEmailAddr && qbo.PrimaryEmailAddr.Address)
    data.email = qbo.PrimaryEmailAddr.Address;

  return data;
};

export const addressToString = (address) => {
  if (!address) return "";
  let line = address.Line1 ? address.Line1 : "";
  if (address.Line2) line = `${line} \n${address.Line2}`;
  if (line) line = `${line} \n`;
  return `${line}${address.City || ""} ${
    address.CountrySubDivisionCode || ""
  } ${address.PostalCode || ""} ${address.Country || ""}`;
};
