import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "../common/table";
// import { DateTime } from "luxon";

class QboImportListTable extends Component {
  checkmark = (
    <div className="text-center text-success">
      <FontAwesomeIcon icon="check" />
    </div>
  );

  tableColumns = [
    {
      key: "title",
      label: "Name",
      content: (item) => item.title,
    },
    {
      key: "DBupdated",
      label: "DB Updated",
      content: (item) => <div className="text-center">{item.lastUpdated}</div>,
    },
    {
      key: "QBOupdated",
      label: "QBO Updated",
      content: (item) => (
        <div className="text-center">{item.qbo.MetaData.LastUpdatedTime}</div>
      ),
    },
    {
      key: "data",
      label: "Data",
      content: (item) => (
        <div className="text-center">
          {item.data ? <FontAwesomeIcon icon="check-circle" /> : ""}
        </div>
      ),
    },
    {
      key: "qbo",
      label: "Qbo",
      content: (item) => (
        <div className="text-center">
          {item.isImported ? <FontAwesomeIcon icon="check-circle" /> : ""}
        </div>
      ),
    },
    {
      key: "import",
      label: "Import",
      content: (item) => (
        <div className="text-center">
          <button
            onClick={() => this.props.onSave(item)}
            className="btn btn-outline-primary btn-sm"
            disabled={item.isImported ? true : false}
          >
            <FontAwesomeIcon icon="file-download" />
          </button>
        </div>
      ),
    },

    {
      key: "delete",
      content: (item) => (
        <button
          onClick={() => this.props.onDelete(item)}
          className="btn btn-outline-danger btn-sm"
          data-toggle="tooltip"
          data-placement="top"
          title="Remove from the update list"
        >
          <FontAwesomeIcon icon="trash" />
        </button>
      ),
    },
  ];

  simpleListColumns = [
    {
      key: "title",
      label: `Name`,
      content: (item) => item[this.props.type.title],
    },
    {
      key: "data",
      label: "Data",
      content: (item) => (
        <div className="text-center">
          {item.Active ? <FontAwesomeIcon icon="check-circle" /> : ""}
        </div>
      ),
    },
    {
      key: "qboId",
      label: "Qbo Id",
      content: (item) => <div className="text-center">{item.Id}</div>,
    },
    {
      key: "lastUpdated",
      label: "Last Updated",
      content: (item) => (
        <div className="text-center">
          {/* {DateTime.fromISO(item.MetaData.LastUpdatedTime).format("YYYY-MM-DD HH:mm:ss")} */}
          {item.MetaData.LastUpdatedTime}
        </div>
      ),
    },
    {
      key: "delete",
      content: (item) => (
        <button
          onClick={() => this.props.onDelete(item)}
          className="btn btn-outline-danger btn-sm"
          data-toggle="tooltip"
          data-placement="top"
          title="Remove from the update list"
        >
          <FontAwesomeIcon icon="trash" />
        </button>
      ),
    },
  ];

  render() {
    const { items, onSort, onSave, onDelete, sortColumn, isList } = this.props;
    const columns = isList ? this.simpleListColumns : this.tableColumns;

    return (
      <Table
        columns={columns}
        data={items}
        sortColumn={sortColumn}
        onSort={onSort}
        onSave={onSave}
        onDelete={onDelete}
      />
    );
  }
}

export default QboImportListTable;
