import React from "react";

import FormControl from "../common/formControl";
import Button from "../common/button";

function Controls(props) {
  const { data } = props;
  const handleCancel = () => {
    props.history.goBack();
  };

  const handleClose = () => {
    props.history.push("/inventory");
  };

  const formControlLeft = props.isEdit ? (
    <div></div>
  ) : (
    <div>
      <button
        type="button"
        className="btn btn-primary mr-2 shadow-sm disabled"
        onClick={() => props.history.push(`transactions?item=${data._id}`)}
      >
        Transactions
      </button>
    </div>
  );

  const formControlRight = props.isEdit ? (
    <React.Fragment>
      <button
        type="button"
        className="btn btn-secondary mr-2 shadow-sm"
        onClick={() => handleCancel()}
      >
        Cancel
      </button>
      <Button
        label="Save"
        className="btn-success mr-2 shadow-sm"
        type="submit"
      />
    </React.Fragment>
  ) : (
    <React.Fragment>
      <button
        type="button"
        className="btn btn-info mr-2 shadow-sm"
        onClick={() => props.history.push(`${props.inventoryPath}/edit`)}
      >
        Edit...
      </button>
      <button
        type="button"
        className="btn btn-secondary shadow-sm"
        onClick={() => handleClose()}
      >
        Close
      </button>
    </React.Fragment>
  );
  return (
    <div className="card mt-3 d-print-none">
      <FormControl left={formControlLeft} right={formControlRight} />
    </div>
  );
}

export default Controls;
