import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = ({ items = [] }) => {
  // if (items.length < 1) return null;
  const itemList = [{ value: "/", label: "Home" }, ...items];
  return (
    <ol className="breadcrumb">
      {itemList.map((item, index) => {
        if (index === itemList.length - 1) {
          return (
            <li key={item.label} className="breadcrumb-item active">
              {item.label}
            </li>
          );
        } else {
          return (
            <li key={item.label} className="breadcrumb-item active">
              <Link to={item.value}>{item.label}</Link>
            </li>
          );
        }
      })}
    </ol>
  );
};

export default Breadcrumb;
