import React from "react";
import DragDropFile from "../DragDropFile";
import { uploadFiles } from "../../../services/digitalOceanService";
import { checkFileExtension, fileTypes } from "../../../utils/fileExtensions";
import LiveToast from "../../../utils/liveToast";

const FileUpload = ({
  category,
  folder,
  numberOfFiles = 3,
  checkExt = true,
}) => {
  if (!category || !folder) return <div id="fileUploadMissingInfo"></div>;

  const handleFileSelectorChange = async (event) => {
    const { files } = event.target;
    await handleFile(files);
  };

  const handleFile = async (files) => {
    const maxFileAmount = 3;
    if (files.length < 1) return;
    const liveToast = new LiveToast("UploadingFIle");
    if (files.length > maxFileAmount) {
      liveToast.error("Cannot upload more than three files at time");
      return;
    }

    const filesToUpload = [];
    for (let index = 0; index < files.length; index++) {
      const file_original = files[index];
      if (checkExt) {
        const fileType = await checkFileExtension(file_original.name);
        if (fileType === fileTypes.invalid) {
          liveToast.error(
            `Unacceptable file extension for ${file_original.name}`
          );
        } else {
          filesToUpload.push(file_original);
        }
      } else {
        filesToUpload.push(file_original);
      }
      // } else {
      //   const file_processed = fileCompressionRequired
      //     ? await compressFile(file_original, fileType)
      //     : file_original;

      //   await filesToUpload.push(file_processed);
      // }
    }
    const uploadResult = await uploadFiles(filesToUpload, category, folder);
    if (uploadResult.status === 200) {
      liveToast.success("Files uploaded");
    } else liveToast.error("Error uploading files");
  };

  const fileSelector = document.createElement("input");
  fileSelector.setAttribute("type", "file");
  fileSelector.setAttribute("multiple", "true");
  fileSelector.onchange = handleFileSelectorChange;

  // const handleUploadClick = (compress = true) => {
  const handleClick = () => {
    fileSelector.click();
  };

  return (
    <DragDropFile
      handleFile={handleFile}
      multiple={numberOfFiles > 1 ? true : false}
    >
      <div
        className="m-1 p-4 border bg-light clickable"
        onClick={() => handleClick()}
      >
        Click here or drag and drop file to upload
      </div>
    </DragDropFile>
  );
};

export default FileUpload;
