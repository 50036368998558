import React from "react";

const ListGroup = ({
  items,
  textProperty = "name",
  valueProperty = "_id",
  onItemSelect,
  selectedItem,
  selectedCount,
}) => {
  return (
    <ul className="list-group shadow-sm">
      {items.map((item, index) => (
        <li
          onClick={() => onItemSelect(item)}
          key={`listItem-${index}`}
          className={
            item === selectedItem
              ? "list-group-item active"
              : "list-group-item list-group-item-action"
          }
        >
          {textProperty ? item[textProperty] : item}
          {item === selectedItem ? (
            <span className="badge badge-primary badge-pill ml-2">
              {selectedCount}
            </span>
          ) : (
            " "
          )}
        </li>
      ))}
    </ul>
  );
};

ListGroup.defaultProps = {
  textProperty: "name",
  valueProperty: "_id",
};

export default ListGroup;
