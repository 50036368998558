import React from "react";
import Table from "../common/table";
import DateFormat from "../common/dateFormat";
import numeral from "numeral";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";

const ItemTransactionTable = ({ lines = [], sortColumn, onSort, totals }) => {
  const columns = [
    {
      path: "inventory.label",
      label: "Item",
    },
    {
      path: "doc.txnDate",
      label: "Date",
      content: (line) => <DateFormat value={line?.doc?.txnDate} />,
    },
    {
      path: "doc.txnNumber",
      label: "Doc #",
      total: (totals) => <span>{numeral(totals.docs).format("0,0")}</span>,
    },
    {
      path: "doc.docType",
      label: "Transaction",
    },
    {
      path: "name.label",
      label: "Vendor",
      content: (line) => (
        <div>{line?.name?.label || line?.customer?.label || ""}</div>
      ),
    },
    {
      path: "memo",
      label: "Memo",
      className: "w-25",
      content: (line) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{line.memo}</div>
      ),
    },
    {
      path: "uom",
      label: "UOM",
      className: "text-center",
    },
    {
      path: "qtyIn",
      label: "Qty In",
      className: "text-center",
      total: (totals) => (
        <div className="text-center">
          {numeral(totals.qtyIn).format("0,0.00")}
        </div>
      ),
      content: (line) => (
        <span>
          {line.qtyIn === 0 ? "-" : numeral(line.qtyIn).format("0,0.00")}
        </span>
      ),
    },
    {
      path: "qtyOut",
      label: "Qty Out",
      className: "text-center",
      total: (totals) => (
        <div className="text-center">
          {numeral(totals.qtyOut).format("0,0.00")}
        </div>
      ),
      content: (line) => (
        <span>
          {line.qtyOut === 0 ? "-" : numeral(line.qtyOut).format("0,0.00")}
        </span>
      ),
    },
  ];
  return (
    <Table
      columns={columns}
      data={lines}
      sortColumn={sortColumn}
      onSort={onSort}
      totals={totals}
      includeTotals={true}
    />
  );
};

export default ItemTransactionTable;
