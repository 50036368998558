import React from "react";
import numeral from "numeral";

const FieldTableNumber = ({
name = "amount",
value = 0,
decimal = 2,
...rest
}) => {
let zeros = "";
let i;
for (i = 0; i < decimal; i++) {
  zeros += "0";
}
const format = zeros ? `0,0.${zeros}` : "0,0";
const formatted = numeral(value).format(format);

return (
    <div className="form-control text-right" name={name} id={name} {...rest}>
      {formatted}
    </div>
  );
};

export default FieldTableNumber;
