import React from "react";
import { toast } from "react-toastify";
// import numeral from "numeral";
import Panel from "../common/panel";
import { RowFourColumns, RowTwoColumns } from "../common/formLayout";
import InputText from "../common/inputText";
import InputCheckbox from "../common/inputCheckbox";
import { FormProvider, useForm } from "react-hook-form";
import * as service from "../../services/settingService";

const PreferencesEdit = (props) => {
  const values = {
    lacalPath: "",
    urlPath: "",
    salesFolderPath: "/sales",
    isMultiCurrency: true,
    homeCurrency: "CAD",
    xRateToUSD: "1.3",
    xRateToEUR: "1.5",
    xRateToGBR: "1.75",
  };

  const defaultValues = service.getPreferences()
    ? service.getPreferences()
    : values;

  const setPrefs = (prefs) => {
    service.setPreferences({ prefs });
  };

  const methods = useForm({ defaultValues });

  const savePrefs = async (prefs) => {
    const setting = {
      details: prefs,
    };

    try {
      const { data } = await service.saveSetting("sales", setting);
      setPrefs(data.details);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
        console.log(ex.response.data);
      }
    }
  };

  const onSubmit = async (values) => {
    await savePrefs(values);
    props.history.replace("/settings/sales");
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <h2>Edit Preferences</h2>
        <hr />
        <Panel>
          <h5 className="card-title"> Currencies</h5>
          <RowTwoColumns
            colOne={
              <InputCheckbox
                name="isMultiCurrency"
                label="Multiple Currencies"
              />
            }
          />
          <RowFourColumns
            colOne={<InputText name="homeCurrency" label="Home Currency" />}
            colTwo={
              <InputText name="xRateToUSD" label="Exchange Rate to USD" />
            }
            colThree={
              <InputText name="xRateToEUR" label="Exchange Rate to EUR" />
            }
            colFour={
              <InputText name="xRateToGBR" label="Exchange Rate to GBR" />
            }
          />

          <hr />
          <h5 className="card-title">Company files</h5>
          <RowTwoColumns
            colOne={<InputText name="localPath" label="Local path to folder" />}
            colTwo={<InputText name="urlPath" label="URL path to folder" />}
          />
          <RowTwoColumns
            colOne={<InputText name="salesFolder" label="Sales folder" />}
          />
          <hr />
          <div className="d-flex">
            <button
              type="button"
              className="btn btn-secondary shadow-sm mr-2"
              onClick={() => props.history.replace("/settings/sales")}
            >
              Cancel
            </button>
            <button type="submit" className="btn btn-success shadow-sm">
              Save changes
            </button>
          </div>
        </Panel>
      </form>
    </FormProvider>
  );
};

export default PreferencesEdit;
