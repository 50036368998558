import React from "react";

const Input = ({ name, label, register, error, ...rest }) => {
  return (
    <div className="form-group">
      <label
        htmlFor={name}
        className="form-label form-label-sm font-weight-bold"
        style={{ marginBottom: "0px" }}
      >
        {label}
      </label>
      <input
        name={name}
        id={name}
        ref={register}
        className="form-control"
        {...rest}
      />
      <small className="text-danger">{error}</small>
    </div>
  );
};

export default Input;
