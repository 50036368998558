import React, { useState, useEffect } from "react";
import withLoading from "../common/withLoading";
import { DateTime } from "luxon";
import SelectDateBox from "./../common/SelectDateBox";
import SelectBox from "./SelectBox";
import Select from "react-select";
import GenSaleShipmentsTable from "./GenSaleShipmentsTable";
import { mapInvoiceDetails } from "./shipment/invoiceDetails";
import { mapInvoiceDetailToShipment } from "./shipment/inventoryShipment";
import {
  getEntities,
  getItems,
  getStorages,
  // getVendors,
  getEntitiesQuery,
  saveEntity,
} from "../../services/entityService";
import { getInventoriesQuery } from "./../../services/inventoryService";
import { saveDocument } from "../../services/documentService";
import { toast } from "react-toastify";
import _ from "lodash";

const GenSaleShipments = (props) => {
  const { loadingOn, loadingOff, progressOn, progressUpdate, progressOff } =
    props;
  const [customers, setCustomers] = useState([]);
  const [storages, setStorages] = useState([]);
  const [productionItems, setProductionItems] = useState([]);
  const [inventories, setInventories] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const [verified, setVerified] = useState([]);
  const [shipments, setShipments] = useState([]);
  const [selectedStorage, setSelectedStorage] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(300);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);
  const [sortColumn, setSortColumn] = useState({
    path: "txnDate",
    order: "asc",
  });
  const [fromDate, setFromDate] = useState(DateTime.local().startOf("month"));
  const [toDate, setToDate] = useState(DateTime.local().endOf("month"));

  useEffect(() => {
    populateStorages();
    populateCustomers();
    populateProductionItems();
    populateInventories();
    loadingOff();
  }, []);

  const populateStorages = async () => {
    try {
      const { data } = await getStorages();
      const storages = data.map((s) => {
        return { value: s._id, label: s.title, qty: 0, code: s.data.code };
      });
      const sorted = _.orderBy(storages, "code", "asc");
      if (sorted.length > 0) {
        setStorages(sorted);
        const found = sorted.find((s) =>
          s.label.toLowerCase().includes("shipping")
        );
        setSelectedStorage(found || sorted[0]);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  const populateCustomers = async () => {
    try {
      const { data } = await getEntities("Customer");
      const mapped = data.map((i) => ({
        label: i.title,
        value: i._id,
        // data: i.data,
      }));
      setCustomers(mapped);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  const populateInventories = async () => {
    // const query = encodeURI("?type=Finished Products");
    try {
      const { data } = await getInventoriesQuery();
      const filtered = data.filter((i) => i.type === "Finished Products");
      const mapped = filtered.map((i) => {
        const item = {
          value: i._id,
          label: i.name,
          uom: i.uom || "EACH",
          itemId: i.itemId,
          type: i.type,
          description: i.description || i.name,
        };
        return item;
      });
      setInventories(mapped);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  const populateInvoiceDetails = async () => {
    let query = `?fromDate=${fromDate.toISO()}&toDate=${toDate.toISO()}`;
    if (selectedCustomer) query = query + "&Customer=" + selectedCustomer.value;
    loadingOn();
    try {
      const { data: invoices } = await getEntitiesQuery("Invoice", query);
      setInvoices(invoices);
      const details = mapInvoiceDetails({
        invoices,
        productionItems,
        inventories,
        selectedStorage,
      });
      setInvoiceDetails(details);
      loadingOff();
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      loadingOff();
    }
  };

  const populateProductionItems = async () => {
    try {
      const { data } = await getItems(`?production=${true}`);
      const mapped = data.map((i) => {
        const item = {
          value: i._id,
          label: i.title,
        };
        return item;
      });
      setProductionItems(mapped);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  const handleGetData = async () => {
    await populateInvoiceDetails();
  };

  const handleSearch = (query) => {
    if (!query) setSelectedCustomer(null);

    const filtered = customers.filter((item) =>
      item.label.toLowerCase().includes(query.toLowerCase())
    );

    const options = filtered.slice(0, 5);

    setSearchQuery(query);
    setSearchOptions(options);
  };

  const handleCreateShipments = async () => {
    console.log("Saving started...");

    progressOn();

    // const { data } = await getTxnNewNumber("Invoice");
    // let { newNumber } = Number(data.newNumber);

    let d = 0;
    for (let txn of invoiceDetails) {
      // if (!invoice.data.txnNumber) {
      //   invoice.data.txnNumber = newNumber;
      //   invoice.title = "" + newNumber + " " + invoice.title;
      //   newNumber = newNumber + 1
      // }
      txn = await createInventoryShipment(txn);
      d++;
      progressUpdate("Saving inventory shipments...", d, invoiceDetails.length);
      console.log("Saved: ", d, txn.title);
    }

    progressOff();

    const notSaved = invoiceDetails.filter((o) => o.errors !== undefined);
    if (notSaved.length === 0) {
      window.location = "/generate-inventory-shipments";
    } else {
      setShipments(notSaved);
    }
  };

  const createInventoryShipment = async (txn) => {
    txn.user = props.user;
    const shipment = mapInvoiceDetailToShipment(txn);

    const txnDate = DateTime.fromISO(shipment.data.txnDate).toISODate();
    shipment.title = `${txnDate} Inventory Shipment`;

    if (shipment.data.records) shipment.records = shipment.data.records;
    delete shipment.data.details;
    delete shipment.data.records;

    // console.log(doc);

    const saved = await onSaveDocument(shipment);
    let refs = [
      {
        type: "InventoryShipment",
        value: saved._id,
        label: saved.title,
        lineId: txn.ref.lineId,
      },
    ];
    // console.log(refs);

    const invoiceToUpdate = invoices.find((i) => i._id === txn.ref.value);
    invoiceToUpdate.data.refs = invoiceToUpdate.data.refs
      ? [...invoiceToUpdate.data.refs, ...refs]
      : refs;

    const inv = await onSaveInvoice(invoiceToUpdate);
    if (inv) inv.isProd = true;
    // console.log(saved);
    return inv;
  };

  const onSaveDocument = async (doc) => {
    // const isNew = doc._id === "new" ? true : false;
    if (doc._id === "new") delete doc._id;

    try {
      const { data } = await saveDocument(doc);
      return data;
      // else window.location = "/documents";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      return null;
    }
  };

  const onSaveInvoice = async (invoice) => {
    if (invoice.__v) delete invoice.__v;
    try {
      const { data } = await saveEntity(invoice);
      return data;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      return null;
    }
  };

  const handleDelete = (txn) => {
    const filtered = invoices.filter((i) => i._id !== txn._id);

    setInvoices(filtered);
  };

  const handleCustomerSelect = (selected) => {
    setSelectedCustomer(selected);
  };

  return (
    <>
      <div className="px-4">
        <div className="d-flex py-2">
          <h2 className="text-uppercase">Generate Inventory Shipments</h2>
        </div>
        <p>
          Select date range to find invoices for generating inventory shipments,
          a customer name is optional. Choose a storage from which you want to
          ship items.
        </p>
        <SelectDateBox
          fromDate={fromDate}
          toDate={toDate}
          setFromDate={(value) => setFromDate(value)}
          setToDate={(value) => setToDate(value)}
          handleDateChange={handleGetData}
        />
        <div className="d-flex justify-content-md-between border-bottom mt-2 pb-2 d-print-none">
          <div>
            <SelectBox
              searchQuery={searchQuery}
              onSearch={handleSearch}
              options={searchOptions}
              onSelect={handleCustomerSelect}
              label={"Select customer..."}
            />
            <Select
              name="storage"
              value={selectedStorage}
              options={storages}
              onSelect={(storage) => setSelectedStorage(storage)}
              label={"Search storage..."}
            />
          </div>
          <div>
            <button
              type="button"
              className="btn btn-info mr-2 shadow-sm"
              disabled={invoiceDetails < 1}
              onClick={handleCreateShipments}
            >
              Generate Inventory Shipments
            </button>
          </div>
        </div>
        <GenSaleShipmentsTable
          title={
            selectedCustomer
              ? `${selectedCustomer.label} - Invoice Details`
              : "Invoice Details"
          }
          items={invoiceDetails}
          sorting={sortColumn}
          onCreateShipment={createInventoryShipment}
          onDelete={handleDelete}
        />
      </div>
    </>
  );
};

export default withLoading(GenSaleShipments);
