import React from "react";
import ConsignmentBalanceTable from "./ConsignmentBalanceTable";

const ConsignmentBalance = () => {
  return (
    <div>
      <h2>Balances</h2>
      <ConsignmentBalanceTable />
    </div>
  );
};

export default ConsignmentBalance;
