import React, { useEffect, useState } from "react";
import withLoading from "../common/withLoading";
import SelectDateBox from "./../common/SelectDateBox";
import SearchBox from "../common/searchBox";
import InventoryReceiptTable from "./InventoryReceiptTable";
import { DateTime } from "luxon";
import { getInventoryReceiptsQuery } from "../../services/reportService";
import numeral from "numeral";
import { toast } from "react-toastify";
import _ from "lodash";

const InventoryReceipts = (props) => {
  const { loadingOn, loadingOff } = props;
  const [records, setRecords] = useState([]);
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItem, setSelected] = useState(null);
  const [sortColumn, setSortColumn] = useState({
    path: "doc.txnDate",
    order: "asc",
  });
  const [fromDate, setFromDate] = useState(DateTime.local().startOf("month"));
  const [toDate, setToDate] = useState(DateTime.local().endOf("month"));

  const populateData = async () => {
    let query = `?fromDate=${fromDate.toISO()}&toDate=${toDate.toISO()}`;
    if (selectedItem) query = query + `&item=${selectedItem.value}`;
    // query = query + `&item=614394f57ed2cb44841f64f4`;
    try {
      const { data } = await getInventoryReceiptsQuery(query);
      setRecords(data);
      loadingOff();
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      loadingOff();
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  const handleDateChange = async () => {
    loadingOn();
    await populateData();
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const getData = () => {
    let filtered = [...records];

    if (searchQuery) {
      filtered = records.filter((i) =>
        i.inventory.label.toLowerCase().includes(searchQuery.toLowerCase())
      );
      // } else if (selectedType) {
      //   filtered = allDocuments.filter((i) => i.entity === selectedType.value);
    }

    const totals = {
      qty: 0,
      docs: filtered.length,
    };

    filtered.forEach((line) => {
      totals.qty = totals.qty + numeral(line.qty).value();
    });

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);
    return { data: sorted, totals };
  };

  const { data, totals } = getData();

  return (
    <main className="container-fluid px-5">
      <div className="d-flex py-2">
        <h2 className="text-uppercase">Inventory Receipts</h2>
      </div>
      {/* <div className="d-none d-print-block">{`Period: ${fromDate.toISODate()} - ${toDate.toISODate()}`}</div> */}
      <div className="d-none d-print-block">{`Period: ${fromDate.toISODate()} - ${toDate.toISODate()}`}</div>
      <SelectDateBox
        fromDate={fromDate}
        toDate={toDate}
        setFromDate={(value) => setFromDate(value)}
        setToDate={(value) => setToDate(value)}
        handleDateChange={handleDateChange}
      />
      <SearchBox
        value={searchQuery}
        placeholder="Search inventory..."
        onChange={handleSearch}
      />
      <InventoryReceiptTable
        lines={data}
        totals={totals}
        sortColumn={sortColumn}
        onSort={(sortColumn) => setSortColumn(sortColumn)}
      />
    </main>
  );
};

export default withLoading(InventoryReceipts);
