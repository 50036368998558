import React from "react";
import Loader from "./LocalLoader";

const PdfView = ({ id, title, pdfFile, fileLoading = false }) => {
  return (
    <div
      className="modal fade"
      id={id}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div
        className="modal-dialog mw-100 mh-100"
        style={{ width: "95%" }}
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {title ? title : "PDF view"}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {fileLoading ? (
              <div style={{ minHeight: "780px", paddingTop: "350px" }}>
                <Loader />
              </div>
            ) : (
              <iframe
                title="PDF File"
                src={pdfFile}
                frameBorder="0"
                width="100%"
                height="100%"
                scrolling="auto"
                style={{ border: "none", minHeight: "780px" }}
              />
              // <object
              //   data={pdfFile}
              //   type="application/pdf"
              //   width="100%"
              //   height="100%"
              //   scrolling="auto"
              //   style={{ minHeight: "780px" }}
              // >
              // <iframe
              //   title="PDF File"
              //   src={pdfFile}
              //   frameBorder="0"
              //   width="100%"
              //   height="100%"
              //   scrolling="auto"
              //   style={{ border: "none", minHeight: "780px" }}
              // >
              //   <p>
              //     Your browser does not support PDFs.
              //     <a href={pdfFile}>Download the PDF</a>.
              //   </p>
              // </iframe>
              // </object>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
      {/* <a href={samplePDF} rel="noopener noreferrer" target="_blank">
          Download Pdf
        </a> */}
    </div>
  );
};

export default PdfView;
