export const setPeriodsArray = (fromDate, toDate, period = "week") => {
  //  verification if luxon format dates;

  let count = "";
  if (period === "day") count = "days";
  else if (period === "hour") count = "hours";
  else if (period === "week") count = "weeks";
  else if (period === "month") count = "months";
  else if (period === "year") count = "years";
  else return null;

  const periodFirstDate = fromDate.startOf(period);
  const periodLastDate = toDate.endOf(period);

  const difference = periodLastDate.diff(periodFirstDate, [`${count}`]);
  const iter = Math.ceil(difference[count]);

  const periods = [];

  const setPeriodName = (dt) => {
    if (period === "day") return dt.toFormat("ccc' 'yyyy'-'LL'-'dd");
    else if (period === "hour")
      return dt.toFormat("h' 'a' 'ZZZZ' 'yyyy'-'LL'-'dd");
    else if (period === "week") return dt.toFormat("yyyy'-W'WW");
    else if (period === "month") return dt.toFormat("LLL yyyy");
    else if (period === "year") return dt.toFormat("yyyy");
    else return "";
  };

  for (let index = 0; index < iter; index++) {
    const nextDate = periodFirstDate.plus({ [count]: index });
    const startDate = nextDate.toISODate();
    const endDate = nextDate.endOf(period).toISODate();
    // const name = nextDate.toISOWeekDate().substr(0,8);
    const name = setPeriodName(nextDate);

    const element = {
      name: name,
      startDate: startDate,
      endDate: endDate,
    };
    periods.push(element);
  }
  return periods;
};

export const periodList = [
  { value: "day", label: "Daily", hours: 8 },
  { value: "week", label: "Weekly", hours: 40 },
  { value: "month", label: "Monthly", hours: 173 },
  { value: "year", label: "Annually", hours: 2080 },
];

export const years = [
  { value: "2019", label: "2019" },
  { value: "2020", label: "2020" },
  { value: "2021", label: "2021" },
  { value: "2022", label: "2022" },
];

export const months = [
  { value: "01", label: "January" },
  { value: "02", label: "February" },
  { value: "03", label: "March" },
  { value: "04", label: "April" },
  { value: "05", label: "May" },
  { value: "06", label: "June" },
  { value: "07", label: "July" },
  { value: "08", label: "August" },
  { value: "09", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];
