import React, { Component } from "react";
// import auth from "../services/authService";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "./../common/table";

class StorageTable extends Component {
  tableColumns = [
    {
      path: "data.code",
      label: "Code",
    },
    {
      path: "title",
      label: "Name",
      content: (storage) => (
        <Link to={`/storages/${storage._id}`}>{storage.title}</Link>
      ),
    },
    {
      path: "type",
      label: "Type",
      content: (storage) =>
        storage.data.isInventory
          ? "Inventory"
          : storage.data.isCustomerSite
          ? "CustomerSite"
          : "Equipment",
    },
    {
      key: "controls",
      content: (storage) => (
        <div className="text-right">
          <button
            onClick={() => this.props.onClick(storage)}
            className="btn btn-secondary btn-sm mr-1"
          >
            <FontAwesomeIcon icon="edit" />
          </button>
          <button
            onClick={() => this.props.onDelete(storage)}
            className="btn btn-danger btn-sm"
          >
            <FontAwesomeIcon icon="trash" />
          </button>
        </div>
      ),
    },
  ];

  listColumns = [
    {
      path: "title",
      label: "Name",
      content: (storage) => (
        <Link to={`/storages/${storage._id}`}>{storage.title}</Link>
      ),
    },
    {
      path: "data.code",
      label: "Code",
    },
    {
      key: "delete",
      content: (storage) => (
        <button
          onClick={() => this.props.onDelete(storage)}
          className="btn btn-danger btn-sm"
        >
          <FontAwesomeIcon icon="trash" />
        </button>
      ),
    },
  ];

  // constructor() {
  //   super();
  //   // const user = auth.getCurrentUser();
  //   // if (user && user.isAdmin) this.tableColumns.push(this.deleteColumn);
  // }

  render() {
    const { items, onSort, sortColumn, isList } = this.props;
    const columns = isList ? this.listColumns : this.tableColumns;

    return (
      <Table
        columns={columns}
        data={items}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default StorageTable;
