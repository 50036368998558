import numeral from "numeral";
import { DateTime } from "luxon";
import { addressToString } from "./addressFormat";
// import {findEntity} from "./findEntity";
import {
  getEntity,
  getEntities,
  getEntitiesQuery,
} from "../services/entityService";
import _ from "lodash";

export async function mapDataToQbo(bill) {
  if (!bill.data) return null;

  let qbo = {};
  if (bill.qbo) qbo = { ...bill.qbo };

  const vendor = await getVendorRef(bill.data.vendor);

  if (vendor) {
    qbo.VendorRef = {
      value: vendor.Id,
      name: vendor.DisplayName,
    };

    if (vendor.CurrencyRef)
      qbo.CurrencyRef = {
        value: vendor.CurrencyRef.value,
      };
    if (vendor.CurrencyRef.value === "CAD") qbo.ExchangeRate = 1;
    if (vendor.CurrencyRef.value === "USD") qbo.ExchangeRate = 1.3;
    if (vendor.CurrencyRef.value === "EUR") qbo.ExchangeRate = 1.5;
    if (vendor.CurrencyRef.value === "GBR") qbo.ExchangeRate = 1.7;
  } else {
    return null;
  }

  if (!bill.data.salesClass) {
    if (vendor.data.salesClass) bill.data.salesClass = vendor.data.salesClass;
  }

  if (bill.data.salesClass) {
    const classRef = await getClassRef(bill.data.salesClass);
    if (classRef) {
      qbo.ClassRef = {
        value: classRef.Id,
      };
    }
  }

  if (bill.data.txnNumber) qbo.DocNumber = bill.data.txnNumber;

  qbo.TxnDate = DateTime.fromISO(bill.data.txnDate).toISODate();
  qbo.DueDate = DateTime.fromISO(bill.data.dueDate).toISODate();

  // qbo.CustomField = [
  //   {
  //     DefinitionId: "1",
  //     Name: "Ref",
  //     Type: "StringType",
  //     StringValue: bill.data.vendorDocRef,
  //   },
  //   {
  //     DefinitionId: "2",
  //     Name: "PO",
  //     Type: "StringType",
  //     StringValue: bill.data.vendorPO,
  //   },
  // ];
  qbo.PrivateNote = {
    value: bill.data.memo,
  };
  qbo.PrivateNote = bill.data.note;

  const lines = await getLines(bill.data);

  qbo.Line = lines;

  bill.qbo = qbo;

  return bill;
}

async function getVendorRef(vendor) {
  try {
    const { data } = await getEntity("Vendor", vendor.value);
    if (data.qbo && data.qbo.Id) {
      return data.qbo;
    } else {
      return null;
    }
  } catch (ex) {
    return null;
  }
}

async function getClassRef(salesClass) {
  const { data: salesClasses } = await getEntities("Class");
  const classGroup = salesClasses.find((c) => c._id === salesClass.value);
  return classGroup.qbo || {};
}

async function getLines(data) {
  const { details } = data;

  const { data: taxCodes } = await getEntities("TaxCode");

  const classRef = data.classRef ? data.classRef : null;

  const lines = await Promise.all(
    details.map(async (line) => {
      if (!line.item) return null;
      const { data: item } = await getEntity("Item", line.item.value);
      if (!item) return null;
      const itemRef = item.qbo;

      const taxCode = taxCodes.find((t) => t._id === line.taxCode.value);
      if (!taxCode) return null;
      const taxCodeRef = taxCode.qbo;
      let qboLine = null;
      if (line.account) {
        qboLine = qboAccountLineDetail(line, itemRef);
      } else {
        qboLine = await qboItemLineDetail(line, itemRef, taxCodeRef, classRef);
      }
      return qboLine;
    })
  );

  return lines;
}

const qboItemLineDetail = async (
  item,
  itemRef,
  taxCodeRef,
  classRef = null
) => {
  const detail = {
    DetailType: "ItemBasedExpenseLineDetail",
    ItemBasedExpenseLineDetail: {
      Qty: item.qty,
      UnitPrice: item.cost,
      ItemRef: {
        name: itemRef.Name,
        value: itemRef.Id,
      },
      TaxCodeRef: {
        value: taxCodeRef.Id,
      },
      ClassRef: {},
    },
    Amount: item.amount,
    Description: item.description,
  };
  if (classRef)
    detail.ItemBasedExpenseLineDetail.ClassRef = {
      value: classRef.Id,
    };
  return detail;
};

const qboAccountLineDetail = async (
  account,
  accountRef,
  taxCodeRef,
  classRef = null
) => {
  const detail = {
    DetailType: "AccountBasedExpenseLineDetail",
    AccountBasedExpenseLineDetail: {
      AccountRef: {
        name: accountRef.Name,
        value: accountRef.Id,
      },
      TaxCodeRef: {
        value: taxCodeRef.Id,
      },
      ClassRef: {},
    },
    Amount: account.amount,
    Description: account.description,
  };
  if (classRef)
    detail.AccountBasedExpenseLineDetail.ClassRef = {
      value: classRef.Id,
    };
  return detail;
};

export function getDefaultValues() {
  const data = {
    vendor: null,
    salesClass: null,
    poOrder: null,
    txnDate: DateTime.local(),
    dueDate: DateTime.local().plus({ days: 30 }),
    memo: "",
    note: "",
    billingAddress: "",
    shippingAddress: "",
    status: "",
    details: [],
    totals: {},
  };
  return { data: data };
}

export const mapQBOtoData = (qbo, oldData = {}) => {
  const data = { ...oldData };
  if (qbo.DocNumber) data.txnNumber = qbo.DocNumber;
  if (qbo.TxnDate) data.txnDate = qbo.TxnDate;
  if (qbo.DueDate) data.dueDate = qbo.DueDate;
  if (qbo.PrivateNote) data.note = qbo.PrivateNote;
  if (qbo.POEmail) data.email = qbo.POEmail;
  data.vendor = {
    label: qbo.VendorRef.name,
  };
  data.totals = {
    total: qbo.TotalAmt,
    balance: qbo.Balance,
    totalHome: qbo.HomeTotalAmt || 0,
  };

  if (qbo.TxnTaxDetail) {
    data.totals.totalTax = qbo.TxnTaxDetail.TotalTax;
  }

  if (qbo.CurrencyRef) {
    data.currency = {
      value: qbo.CurrencyRef.value,
      label: qbo.CurrencyRef.name,
    };
  }

  if (qbo.VendorAddr) {
    data.billingAddressObject = {
      line1: qbo.VendorAddr.Line1,
      line2: qbo.VendorAddr.Line2,
      city: qbo.VendorAddr.City,
      subDivision: qbo.VendorAddr.CountrySubDivisionCode,
      country: qbo.VendorAddr.Country,
      postalCode: qbo.VendorAddr.PostalCode,
      long: qbo.VendorAddr.long,
      lat: qbo.VendorAddr.lat,
    };
    data.addressFrom = addressToString(data.billingAddressObject);
  }
  if (qbo.ShipAddr) {
    data.shippingAddressObject = {
      line1: qbo.ShipAddr.Line1,
      line2: qbo.ShipAddr.Line2,
      city: qbo.ShipAddr.City,
      subDivision: qbo.ShipAddr.CountrySubDivisionCode,
      country: qbo.ShipAddr.Country,
      postalCode: qbo.ShipAddr.PostalCode,
      long: qbo.ShipAddr.long,
      lat: qbo.ShipAddr.lat,
    };
    data.addressTo = addressToString(data.shippingAddressObject);
  }
  if (qbo.ClassRef) {
    data.salesClass = { label: qbo.ClassRef.name };
  }

  if (qbo.Line) {
    const details = [];
    qbo.Line.forEach((line) => {
      let classRef = null;
      // if (oldData.details) {
      //   const i = oldData.details.findIndex((d) => d.lineId === line.Id);
      //   if (i !== -1) detail = oldData.details[i];
      // }
      if (line.DetailType === "ItemBasedExpenseLineDetail") {
        const detail = {
          lineId: line.Id,
          description: line.Description,
          amount: line.Amount,
          qty: line.ItemBasedExpenseLineDetail.Qty,
          cost: line.ItemBasedExpenseLineDetail.UnitPrice,
          item: {
            label: line.ItemBasedExpenseLineDetail.ItemRef.name,
          },
          taxCode: {
            qboId: line.ItemBasedExpenseLineDetail.TaxCodeRef.value,
          },
        };
        if (line.ItemBasedExpenseLineDetail.ClassRef)
          classRef = { name: line.ItemBasedExpenseLineDetail.ClassRef.name };
        if (!data.salesClass && classRef)
          data.salesClass = { label: classRef.name };
        details.push(detail);
      } else if (line.DetailType === "AccountBasedExpenseLineDetail") {
        const detail = {
          account: {
            label: line.AccountBasedExpenseLineDetail.AccountRef.name,
          },
          description: line.Description,
          amount: line.Amount,
          taxCode: {
            qboId: line.AccountBasedExpenseLineDetail.TaxCodeRef.value,
          },
        };
        details.push(detail);
      }
    });

    data.details = details;
  }

  return data;
};

export const generateTitle = (data) => {
  const txnNumber = data.txnNumber ? data.txnNumber + " " : "";
  const txnDate = DateTime.fromISO(data.txnDate).toISODate();
  const total = numeral(data.totals.total).format("0,0.00");
  let currency = "";
  if (data.currency && data.currency.value) currency = data.currency.value;

  const title =
    "" +
    txnNumber +
    data.vendor.label.toUpperCase() +
    " " +
    txnDate +
    " " +
    total +
    " " +
    currency;
  return title;
};

export function updateSubTotalAmount(details, name = "amount") {
  const sumAmount = details.reduce(
    (sum, { amount }) => sum + numeral(amount).value(),
    0
  );
  return sumAmount;
}

export function updateTotalAmount(details, name = "amount") {
  const totalAmount = details.reduce(
    (sum, { amount }) => sum + numeral(amount).value(),
    0
  );
  return totalAmount;
}

export function updateTaxAmount(details, taxes) {
  const totalTaxes = [];

  taxes.forEach((tax) => {
    const rate = tax.rate || 0;
    const total = {
      value: tax.value,
      label: tax.label,
    };
    const filtered = [...details].filter(
      (i) => i.taxCode && i.taxCode.value === tax.value
    );
    total.amount = filtered.reduce(
      (sum, { amount }) => sum + numeral(amount).value() * rate,
      0
    );
    totalTaxes.push(total);
  });

  const totalTax = totalTaxes.reduce((sum, { amount }) => sum + amount, 0);
  return totalTax;
}

//  review mapping
export const createBillsFromXLS = (data) => {
  const txnId = "BillNo";
  const uniq = _.uniqBy(data, txnId);
  const bills = uniq.map((item) => {
    item.vendor = data.vendor;
    const bill = {
      entity: "Bill",
    };
    bill.data = mapXLS(item);
    const details = data.filter((line) => line[txnId] === item[txnId]);
    bill.data.details = mapXLSBillDetails(details);
    bill.title = `${item.vendor.label} - Ref: ${item.BillNo}`;
    bill.totals = {
      subTotal: updateSubTotalAmount(bill.data.details),
      totalTax: updateTaxAmount(bill.data.details),
      total: updateTotalAmount(bill.data.details),
    };
    return bill;
  });
  return bills;
};

const mapXLS = (data) => {
  // let dueDate = data.DueDate ? DateTime.fromFormat(data.DiscountDate, "dd-LLL-yy").toISODate() : DateTime.fromFormat(data.DueDate, "dd-LLL-yy").toISODate() ;
  let dueDate = data.DueDate ? DateTime.fromISO(data.DueDate).toISO() : "";
  // const txnDate = DateTime.fromFormat(data.BillDate, "dd-LLL-yy").toISODate();
  const txnDate = data.BillDate ? DateTime.fromISO(data.BillDate).toISO() : "";

  const bill = {
    memo: data.Memo,
    txnNumber: data.BillNo,
    txnDate: txnDate,
    dueDate: dueDate,
    salesClass: null,
    salesOrder: null,
    vendor: { label: data.Vendor },
    currency: {
      value: data.Currency,
    },
  };
  return bill;
};
// TODO: review details mapping
const mapXLSBillDetails = (data) => {
  // "Item",
  // "ItemDescription",
  // "ItemQuantity",
  // "ItemAmount",
  // "ItemTaxCode",
  const details = data.map((line, index) => {
    const lineId = line.Line ? line.Line : index + 1;
    const price =
      numeral(line.ItemAmount).value() / numeral(line.ItemQuantity).value();
    return {
      lineId: lineId,
      item: {
        label: line.Item,
      },
      description: data.ItemDescription,
      qty: data.ItemQty,
      price: price,
      amount: data.ItemAmount,
      taxCode: { label: line.ItemTaxCode },
    };
  });
  return details;
};

export const getVendorBills = async (vendorId) => {
  if (!vendorId) return;
  let query = "?Vendor=" + vendorId;

  try {
    const { data: bills } = await getEntitiesQuery("Bill", query);
    return bills;
  } catch (ex) {
    if (ex.response && ex.response.status === 400) {
      console.log(ex.response.data);
      return;
    }
  }
};
