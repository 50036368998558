import http from "./httpService";

const apiEndpoint = "/records";

function recordUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getRecords() {
  return http.get(apiEndpoint);
}

export function getLocationRecords(locationId, query = "") {
  return http.get(apiEndpoint + "/locations/" + locationId + query);
}

export function getRecordsQuery(query) {
  return http.get(apiEndpoint + query);
}

export function getRecord(recordId) {
  return http.get(recordUrl(recordId));
}

export function saveRecord(record) {
  if (record._id) {
    const body = { ...record };
    delete body._id;
    return http.put(recordUrl(record._id), body);
  }

  return http.post(apiEndpoint, record);
}
export function patchRecord(record) {
  const body = {
    itemId: record.itemId,
    uom: record.uom,
  };

  return http.patch(recordUrl(record._id), body);
}

export function deleteRecord(recordId) {
  return http.delete(recordUrl(recordId));
}
