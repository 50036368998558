import { getItems, saveEntity } from "../services/entityService";
import { saveQboEntity } from "../services/qboEntityService";
// import { DateTime } from "luxon";
import { toast } from "react-toastify";
import * as yup from "yup";

export function getDefaultValues() {
  const data = {
    type: "NonInventory",
    displayName: "",
    description: "",
    purchaseDescription: "",
    uom: "EACH",
    unitPrice: 0,
    cost: 0,
  };
  return { data: data };
}

export const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  type: yup.string().required("Type is required"),
  // status: yup.mixed().required("Status is required"),
  // description: yup.string().max(1000, "Must be 1000 characters or less"),
  purchaseDescription: yup
    .string()
    .max(1000, "Must be 1000 characters or less"),
  preferredVendor: yup.mixed(),
  uom: yup
    .string()
    .max(6, "Must be 6 characters or less")
    .required("Unit of measure is required"),
  uomOptions: yup.array().of(
    yup.object().shape({
      code: yup
        .string()
        .required("Code is required")
        .max(6, "Must be 6 characters or less"),
      description: yup
        .string()
        .required("Description is required")
        .max(50, "Must be 50 characters or less"),
      ratioToBase: yup.mixed().required("Ratio to Base is required"),
    })
  ),
  costOptions: yup.array().of(
    yup.object().shape({
      code: yup
        .string()
        .required("Code is required")
        .max(10, "Must be 10 characters or less"),
      description: yup
        .string()
        .required("Description is required")
        .max(50, "Must be 50 characters or less"),
      // uom: yup.mixed().required("UOM is required"),
      cost: yup.mixed().required("Coste is required"),
    })
  ),
  sku: yup.string().max(50, "Must be 50 characters or less"),
  // isSales: yup.boolean(),
  isPurchasing: yup.boolean(),
  isProduction: yup.boolean(),
  isInactive: yup.boolean(),
  parts: yup.array().of(
    yup.object().shape({
      item: yup
        .mixed()
        .required("Can't be empty. Select from the dropdown menu"),

      uom: yup
        .string()
        .required("Unit of measure is required")
        .max(6, "Must be 6 characters or less"),
      qty: yup.mixed().required("Qty of measure is required"),
    })
  ),

  // salesClass: yup
  //   .object({
  //     value: yup
  //       .string()
  //       .required("Can't be empty. Select from the dropdown menu"),
  //     label: yup
  //       .string()
  //       .required("Can't be empty. Select from the dropdown menu"),
  //   })
  //   .typeError("Select a sales group from the dropdown menu")
  //   .required("Can't be empty. Select from the dropdown menu"),
});

export const mapQBOtoData = (qbo) => {
  if (!qbo) return;

  const data = {
    name: qbo.Name,
    type: qbo.Type || "NonInventory",
    sku: qbo.Sku || "",
    description: qbo.Description || "",
    purchaseDescription: qbo.PurchaseDesc || "",
    unitPrice: qbo.UnitPrice || 0,
    cost: qbo.PurchaseCost || 0,
  };

  if (qbo.PrefVendorRef?.name) data.preferredVendor = { label: qbo.PrefVendorRef.name }

  return data;
};

export const getItemRefs = async (uniq) => {
  const { data: allItems } = await getItems();

  const items = await Promise.all(
    uniq.map(async (item) => {
      if (item.data && item.data.sku) {
        let found = allItems.find(
          (c) => c.data && c.data.sku === item.data.sku
        );
        if (found) return found;
      } else if (item.label) {
        let found = allItems.find((c) => c.title === item.label);
        if (found) return found;
      } else if (item.title) {
        let found = allItems.find((c) => c.title === item.title);
        if (found) return found;
      }

      if (item.title || item.label) {
        const { data: created } = await saveEntity({
          entity: "Item",
          title: item.title || item.label,
          data: item.data || {},
        });
        if (created) return created;
      }

      item.errors = [];
      item.errors.push({ message: "Can't find item ref" });
      return item;
    })
  );
  return items;
};

export const getTypes = () => {
  const types = [
    {
      value: "",
      label: "All Items",
    },
    {
      value: "Inventory",
      label: "Inventory",
    },
    {
      value: "Service",
      label: "Service",
    },
    {
      value: "NonInventory",
      label: "Non Inventory",
    },
  ];
  return types;
};

export const partsToTextArea = (array) => {
  let str = "";
  array.forEach((line, index) => {
    str = str + line.item.label + ", Qty: " + line.qty + " " + line.uom;
    if (index < array.length - 1) str = `${str} \n`;
  });
  return str;
};

//  #TODO
export const mapDataToQBO = (item) => {
  if (!item.data) return null;

  let qbo = {};

  item.qbo = qbo;
  console.log(item);
  return item;
};

export const handleQBOExport = async (item) => {
  const mapped = await mapDataToQBO(item);
  console.log(`${item.title} QBO export`, mapped);
  if (!mapped) {
    toast.error(`Incorrect ${item.title} QBO export mapping`);
  }

  try {
    const { data: exported } = await saveQboEntity("item", mapped);
    toast.success(`${exported.title} exported to QBO`);
    return exported;
  } catch (ex) {
    if (ex.response && ex.response.status === 400) {
      toast.error(ex.response.data);
    }
  }
};
