import React, { Component } from "react";
import { Link } from "react-router-dom";

import withLoading from "../common/withLoading";
import ProgressModal from "../common/progressModal";
import ItemTable from "./ItemTable";
import ListGroup from "../common/listGroup";
import Pagination from "../common/pagination";
import SearchBox from "../common/searchBox";
import CheckBox from "../common/checkBox";
import { paginate } from "../../utils/paginate";
import {
  // getSalesGroups,
  getItems,
  saveEntity,
} from "../../services/entityService";
import auth from "../../services/authService";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { mapQBOtoData } from "../../utils/itemUtils";
import { updateData } from "../../utils/updateData";

class Items extends Component {
  state = {
    items: [],
    groups: [],
    showInactive: false,
    showProductionOnly: false,
    showPurchasingOnly: false,
    currentPage: 1,
    pageSize: 150,
    searchQuery: "",
    selectedGroup: null,
    sortColumn: { path: "title", order: "asc" },
    loading: true,
    showProgress: false,
    progress: { title: "", value: 0, total: 100 },
  };

  populateGroups = (items) => {
    const vendors = [];
    items.forEach((i) => {
      if (i?.data?.preferredVendor)
        vendors.push({
          value: i.data.preferredVendor.value,
          label: i.data.preferredVendor.label,
        });
    });
    // console.log(vendors);
    const groups = _.uniqBy(vendors, "value");
    const sorted = _.orderBy(groups, "label", "asc");
    return [{ value: "", label: "All Suppliers" }, ...sorted];
  };

  populateItems = async (query) => {
    try {
      const { data } = await getItems(query);
      const items = data.map((item) => {
        let search = item.title;
        if (item.data && item.data.description)
          search = search + " " + item.data.description;
        if (item.data && item.data.purchaseDescription)
          search = search + " " + item.data.purchaseDescription;
        item.search = search;
        return item;
      });
      const groups = this.populateGroups(items);
      this.setState({ items, groups });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  async componentDidMount() {
    const { showInactive } = this.state;
    // await this.populateSalesGroups();
    await this.populateItems(`?showInactive=${showInactive}`);
    this.props.loadingOff();
  }

  handleDelete = async (item) => {
    const originalItems = this.state.items;
    const items = originalItems.filter((m) => m._id !== item._id);
    this.setState({ items });

    try {
      item.data.isInactive = true;
      delete item.search;
      await saveEntity(item);
      toast("The item was marked as inactive.");
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("Couldn't find this item.");

      this.setState({ items: originalItems });
    }
  };

  handleClick = (item) => {
    this.props.history.push(`/detail-items/${item._id}`);
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleGroupSelect = (group) => {
    this.setState({
      selectedGroup: group,
      searchQuery: "",
      currentPage: 1,
    });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      selectedGroup: null,
      currentPage: 1,
    });
  };

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleShowProductionOnly = async (showProductionOnly) => {
    this.props.loadingOn();
    const { showInactive } = this.state;
    await this.populateItems(
      `?showInactive=${showInactive}&production=${showProductionOnly}`
    );
    this.setState({
      showProductionOnly: showProductionOnly,
      showPurchasingOnly: false,
    });
    this.props.loadingOff();
  };

  handleShowPurhasingOnly = async (showPurchasingOnly) => {
    this.props.loadingOn();
    const { showInactive } = this.state;
    await this.populateItems(
      `?showInactive=${showInactive}&purchasing=${showPurchasingOnly}`
    );
    this.setState({
      showProductionOnly: false,
      showPurchasingOnly: showPurchasingOnly,
    });
    this.props.loadingOff();
  };

  handleShowInactive = async (showInactive) => {
    this.props.loadingOn();
    const { showProductionOnly, showPurchasingOnly } = this.state;
    let query = "";
    if (showProductionOnly) query = "production=true";
    if (showPurchasingOnly) query = "purchasing=true";
    await this.populateItems(`?showInactive=${showInactive}&${query}`);
    this.setState({ showInactive: showInactive });
    this.props.loadingOff();
  };

  saveItem = async (item) => {
    try {
      await saveEntity(item);
      item.isSaved = true;
      return item;
    } catch (error) {
      item.errors = [];
      item.errors.push({ message: "Can't save item", error: error });
      return item;
    }
  };

  openProgressBar = () => {
    this.setState({ showProgress: true });
  };

  handleProgressBar = (value = 50) => {
    let { progress } = this.state;
    console.log("handle progress");
    progress.value = value;
    this.setState({ progress });
  };

  closeProgressBar = () => {
    let progress = { title: "", value: 0, total: 100 };
    this.setState({ progress, showProgress: false });
  };

  handleQboSync = async () => {
    const { items, progress } = this.state;

    progress.title = "Saving data...";
    progress.total = items.length;

    this.setState({ progress });
    this.openProgressBar();

    let d = 0;
    for (let item of items) {
      d++;
      progress.value = d;
      this.setState(progress);

      const mapped = mapQBOtoData(item.qbo);
      const data = updateData(item.data, mapped);

      let entity = {
        _id: item._id,
        entity: item.entity,
        title: item.title,
        data: data,
      };

      try {
        let { data: saved } = await saveEntity(entity);
        console.log("Saved: ", progress.value, saved.title);
      } catch (error) {
        item.errors = [];
        item.errors.push({ message: "Can't save this item", error: error });
        console.log("Error: " + error);
      }
    }

    this.closeProgressBar();
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      selectedGroup,
      searchQuery,
      items: allItems,
    } = this.state;

    let filtered = allItems;

    if (searchQuery)
      filtered = allItems.filter((o) =>
        o.search.toLowerCase().includes(searchQuery.toLowerCase())
      );
    else if (selectedGroup && selectedGroup.value)
      filtered = allItems.filter(
        (o) => o?.data?.preferredVendor?.value === selectedGroup.value
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const items = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: items };
  };

  render() {
    const {
      progress,
      showProgress,
      showProductionOnly,
      showPurchasingOnly,
      showInactive,
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
    } = this.state;

    const user = auth.getCurrentUser();

    const { totalCount, data: items } = this.getPagedData();
    return (
      <>
        <div id="sidebar-left" className="col-2 pt-3">
          <div>
            {user && (
              <Link
                to="/items/new"
                className="btn btn-primary shadow"
                style={{ marginBottom: 20 }}
              >
                <FontAwesomeIcon icon="plus" className="mr-2" />
                Add Item
              </Link>
            )}
          </div>
          <ListGroup
            items={this.state.groups}
            valueProperty="value"
            textProperty="label"
            selectedItem={this.state.selectedGroup}
            onItemSelect={this.handleGroupSelect}
            selectedCount={totalCount}
          />
        </div>
        <div className="col mt-2">
          <div className="d-flex py-2 border-bottom">
            <h2 className="text-uppercase">All Items, Products, Services</h2>
          </div>
          <div className="d-flex mt-2 justify-content-between">
            <div className="row px-3">
              <SearchBox
                value={searchQuery}
                placeholder="Search Title..."
                col="col mr-1"
                onChange={this.handleSearch}
              />
              <CheckBox
                value={showProductionOnly}
                onChange={this.handleShowProductionOnly}
                className="ml-2"
                label=" Production"
              />
              <CheckBox
                value={showPurchasingOnly}
                onChange={this.handleShowPurhasingOnly}
                className="ml-2"
                label=" Purchasing"
              />
              <CheckBox
                value={showInactive}
                onChange={this.handleShowInactive}
                className="ml-2"
                label="Inactive"
              />
            </div>
            <div className="flex-row-reverse px-3">
              <button
                onClick={this.handleQboSync}
                className="btn btn-info btn-sm shadow-sm"
                data-toggle="tooltip"
                data-placement="bottom"
                title="QBO Sync"
              >
                <FontAwesomeIcon icon="sync" />
              </button>
              <button
                onClick={() => {
                  this.props.history.push("/import-items");
                }}
                className="btn btn-secondary btn-sm shadow-sm ml-1"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Import items"
              >
                <FontAwesomeIcon icon="file-import" />
              </button>
            </div>
          </div>

          <ItemTable
            items={items}
            sortColumn={sortColumn}
            onSort={this.handleSort}
            onDelete={this.handleDelete}
            onClick={this.handleClick}
          />
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
          />
        </div>
        <div id="progressBar" className={showProgress ? "" : "d-none"}>
          <ProgressModal
            title={progress.title}
            value={progress.value}
            total={progress.total}
          />
        </div>
      </>
    );
  }
}

export default withLoading(Items);
