import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import Select from "react-select";

const InputSelect = ({ label, name, options, ...props }) => {
  const { control, errors } = useFormContext();
  const error = errors[name] ? errors[name].message : null;

  return (
    <div className="form-group">
      {label ? (
        <label
          htmlFor={name}
          className="form-label form-label-sm font-weight-bold"
          style={{ marginBottom: "0px", marginRight: "10px" }}
        >
          {label}
        </label>
      ) : null}
      <div>
        <Controller
          name={name}
          control={control}
          render={({ name, value }) => (
            <Select name={name} value={value} options={options} {...props} />
          )}
        />
      </div>

      <small className="text-danger">{error}</small>
    </div>
  );
};

export default InputSelect;
