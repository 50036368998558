import React from "react";
import SearchBox from "./searchBox";

const SearchDropdown = (props) => {
  const {
    id = "search-items",
    query,
    col = "col-4",
    options = [],
    onChange,
    onSelect,
    placeholder = " Start typing..",
  } = props;
  const classNames = "form-control mb-1 " + col;
  const elemClassName = "d-print-none " + col;
  return (
    <div className={elemClassName}>
      <input
        type="text"
        // name="query"
        className="form-control mb-1"
        placeholder={placeholder}
        value={query}
        id={`dropdown-${id}`}
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onChange={(e) => onChange(e.currentTarget.value)}
      />
      <div className="dropdown-menu" aria-labelledby={`dropdown-${id}`}>
        {options.length === 0 && query.length === 0 && (
          <div className="px-3">Start taping to view options</div>
        )}
        {options.length === 0 && query.length > 0 && (
          <div className="px-3">Nothing found</div>
        )}
        {options.map((option, index) => (
          <button
            className="dropdown-item"
            key={option.value || option._id || `dropdown-item-${index}`}
            type="button"
            onClick={() => onSelect(option)}
          >
            {option.label || option.title}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SearchDropdown;
