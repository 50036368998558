import React from "react";
import { Text, View, Image } from "@react-pdf/renderer";
import { DateTime } from "luxon";
import { getSetting } from "../../services/settingService";
import { getMimetype } from "./../common/images/utils";

const PoPdfHeader = ({ title, info, data, styling }) => {
  const formTitle = title ? `${title.toUpperCase()}` : `DOCUMENT`;
  const line3 = `${info.city}, ${info.subDivision} ${info.postalCode}`;
  const txnDate = DateTime.fromISO(data.txnDate).toISODate();
  const dueDate = data.dueDate
    ? DateTime.fromISO(data.dueDate).toISODate()
    : "   N/A    ";
  const logo = getSetting("logo") || {};
  const mimetype = getMimetype(logo.data);
  return (
    <View>
      <View style={styling.companyAndLogoContainer}>
        <View style={styling.companyInfo}>
          <Text style={styling.companyName}>{info.legalName}</Text>
          <Text>{info.line1}</Text>
          <Text>{info.line2}</Text>
          <Text>{line3}</Text>
          <Text>{info.country}</Text>
          <Text>GST/HST: {info.fiscalNumber}</Text>
          <Text>Phone: {info.mainPhone}</Text>
          <Text>Email: {info.mainEmail}</Text>
        </View>
        {logo !== null ? (
          <View>
            <Image
              style={styling.logo}
              src={`data:${mimetype};base64,${logo.data}`}
            />
          </View>
        ) : (
          ""
        )}
      </View>
      <View style={styling.title}>
        <Text>{formTitle}</Text>
      </View>
      <View style={styling.rowFlex}>
        <View style={styling.col3}>
          <Text style={styling.invoiceToBold}>Supplier:</Text>
          <Text style={{ fontFamily: "Helvetica-Bold" }}>
            {data.vendor && data.vendor.label}
          </Text>
          <Text>{data.addressFrom}</Text>
        </View>
        <View style={styling.col3}>
          <Text style={styling.invoiceToBold}>Ship To:</Text>
          {/* <Text>{info.legalName}</Text> */}
          <Text>{data.addressTo}</Text>
        </View>
        <View style={[styling.col3, { alignSelf: "flex-end" }]}>
          <Text
            style={[styling.invoiceToDateLabels, { alignSelf: "flex-end" }]}
          >{`PO #: ${data.txnNumber}`}</Text>
          <Text
            style={[styling.invoiceToDateLabels, { alignSelf: "flex-end" }]}
          >{`Date: ${txnDate}`}</Text>
          <Text
            style={[styling.invoiceToDateLabels, { alignSelf: "flex-end" }]}
          >{`Due Date: ${dueDate}`}</Text>
        </View>
      </View>
      <View>
        <Text style={styling.horizontalHeaderLine}></Text>
      </View>
    </View>
  );
};

export default PoPdfHeader;
