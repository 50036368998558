import http from "./httpService";

const apiEndpoint = "/items";

function itemUrl(id) {
  return `${apiEndpoint}/${id}`;
}

export function getItemList(query = "") {
  return http.get(apiEndpoint + "/list" + query);
}

export function getPurchaseItems() {
  return http.get(apiEndpoint + "/purchasing");
}

export function getItems(query = "") {
  return http.get(apiEndpoint + query);
}

export function getItemsQty(query = "") {
  return http.get(apiEndpoint + "/qty" + query);
}

export function getItemsInStock(query = "") {
  return http.get(apiEndpoint + "/inStock" + query);
}
