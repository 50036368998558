import React from "react";
import Field from "../common/fieldText";
import FieldNumber from "../common/fieldNumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MetaCard = ({ meta = {} }) => {
  const { brand, finish, material, name, size, supplier, thickness } = meta;
  return (
    <>
      <h5 className="text-uppercase border-bottom">Details</h5>
      <div className="row">
        <div className="col-md-6">
          <Field name="brand" label="Brand" value={brand} />
          <Field name="name" label="Name" value={name} />
          <Field name="supplier" label="Supplier" value={supplier} />
        </div>
        <div className="col-md-6">
          <Field name="material" label="Material" value={material} />
          <Field name="finish" label="Finish" value={finish} />
          {/* <Field name="size" label="Size" value={size} /> */}
          <Field name="thickness" label="Thickness" value={thickness} />
        </div>
      </div>
    </>
  );
};

export default MetaCard;
