import * as yup from "yup";

export const schema = yup.object().shape({
  txnDate: yup.mixed().required("Transaction date is required"),
  dueDate: yup.mixed(),
  txnNumber: yup.string().max(50, "Must be 50 characters or less"),
  status: yup.mixed().required("Status is required"),
  vendor: yup.mixed().required("Vendor is required"),
  addressFrom: yup.string().max(255, "Must be 255 characters or less"),
  locationTo: yup.mixed().required("Location To is required"),
  addressTo: yup.string().max(255, "Must be 255 characters or less"),
  email: yup.string(),
  currency: yup.mixed(),
  // details: yup
  //   .array()
  //   .of(
  //     yup.object().shape({
  //       item: yup
  //         .object({
  //           value: yup.string(),
  //           label: yup.string(),
  //         })
  //         .required("Please select item from the list"),
  //       description: yup.string().max(100, "Must be 100 characters or less"),
  //       uom: yup
  //         .string()
  //         .max(6, "Must be 6 characters or less")
  //         .required("Unit of measure is required"),
  //       qty: yup.mixed().required("Qty is required"),
  //       unitCost: yup.mixed().required("Cost is required"),
  //       amount: yup.mixed().required("Amount is required"),
  //       taxCode: yup.mixed().required("Tax code is required"),
  //     })
  //   )
  //   .min(1, "You should add a line"),
  memo: yup.string().max(1000, "Must be 1000 characters or less"),
  notes: yup.string().max(1000, "Must be 1000 characters or less"),
});
