import qboApi from "./qboApiService";

const apiEndpoint = qboApi.apiUrl + "/qboData";

export function getQboData() {
  return qboApi.get(apiEndpoint);
}
export function getQboLists() {
  return qboApi.get(apiEndpoint + "/lists");
}
export function getQboTxns() {
  return qboApi.get(apiEndpoint + "/txns");
}
