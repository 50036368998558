import React, { useEffect, useState } from "react";
import withLoading from "../common/withLoading";
import QboImportListsTable from "./QboImportListsTable";
import ListGroup from "../common/listGroup";
import Pagination from "../common/pagination";
import QboExportListTable from "./QboExportListsTable";
import { getQboLists } from "../../services/qboData";
import {
  // getSalesGroups,
  getItems,
  saveEntity,
} from "../../services/entityService";
import { exportToQboById, batchCreate } from "../../services/qboExportService";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

const QboExportLists = (props) => {
  const { loadingOn, loadingOff, progressOn, progressOff, progressUpdate } =
    props;
  const [entityTypes, setEntityTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [entities, setEntities] = useState([]);
  const [sortColumn, setSortColumn] = useState({ path: "title", order: "asc" });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(300);
  const [showInactive, setShowInactive] = useState(false);

  const populateEntityTypes = async () => {
    const itemTypes = ["Item"];
    // const itemTypes = ["Vendor", "Item", "PurchaseOrder", "Estimate"];
    const { data } = await getQboLists();
    const types = data.filter((t) => itemTypes.includes(t.entityName));
    setEntityTypes(types);
    if (types.length > 0) {
      handleEntityTypeSelect(types[0]);
    }
  };

  const populateItems = async (query) => {
    try {
      const { data } = await getItems(query);
      const items = data.filter((item) => !item?.qbo?.Id);

      setEntities(items);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  useEffect(() => {
    populateEntityTypes();
    loadingOff();
    return () => {
      setEntities([]);
      setEntityTypes([]);
      setSelectedType(null);
    };
  }, []);

  // limit entities to inventory QBO lists only: items, vendors, taxes?
  // check latest updates for specified entities in QBO based on latest entity
  // optional check latest updates for each entity
  // 1. update entities based on qbo updates
  // 2. create new QBO entities based on iours
  // calls for QBO must be handled by qbo-backend

  const handleEntityTypeSelect = async (selected) => {
    setSelectedType(selected);
    loadingOn();
    if (selected.entityName === "Item") {
      await populateItems(`?showInactive=${showInactive}&purchasing=true`);
    }
    loadingOff();
  };

  const handleDelete = () => {
    console.log("handle delete");
  };

  const handleSort = () => {
    console.log("handle sort");
  };

  const handlePageChange = () => {
    console.log("handle page");
  };

  const handleSave = () => {
    console.log("handle save");
  };

  const onQBOExport = async (item) => {
    // props.loadingOn();

    if (!item._id) {
      toast.error("Save the item first and after export it");
      // props.loadingOff();
      return;
    }
    if (item?.qbo?.Id) {
      toast.info("Use import QBO list option");
      // props.loadingOff();
      return;
    }

    try {
      const { data: exported } = await exportToQboById("Item", item._id);
      // toast.success("This Item was exported!");
      return exported;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      return;
    }

    // props.loadingOff();
  };

  const handleSingleExport = async (item) => {
    loadingOn();
    const exported = await onQBOExport(item);
    if (exported?.qbo) item.qbo = exported.qbo;
    loadingOff(exported);
  };

  const handleListExport = async () => {
    let d = 0;
    progressUpdate("Exporting items...", d, entities.length);
    progressOn();
    for (let item of entities) {
      d++;
      progressUpdate("Exporting items...", d, entities.length);
      const exported = await onQBOExport(item);
      if (exported?.qbo) item.qbo = exported.qbo;
    }
    progressOff();
  };

  const onBatchCreate = async (batch) => {};

  const handleSingleBatch = async () => {
    loadingOn();
    const filtered = entities.filter((item) => !item.qbo || !item.qbo.Id);
    const batch = filtered.slice(0, 30);
    console.log("Batch length", batch.length);
    const { data } = await batchCreate(selectedType.entityName, batch);
    if (data.ok) toast.success(`${data.nModified} items exported`);
    console.log("Batch create result", data);
    if (selectedType.entityName === "Item") {
      await populateItems(`?showInactive=${showInactive}&purchasing=true`);
    }
    loadingOff(data);
  };

  const handleBatchCreateQBO = async () => {
    let d = 0;
    progressUpdate("Exporting items...", d, entities.length);
    progressOn();
    for (let item of entities) {
      d++;
      progressUpdate("Exporting items...", d, entities.length);
      const exported = await onBatchCreate(item);
      if (exported?.qbo) item.qbo = exported.qbo;
    }
    progressOff();
  };

  return (
    <>
      <div id="sidebar-left" className="col-2 pt-4">
        <ListGroup
          items={entityTypes}
          valueProperty="entityName"
          textProperty="entityName"
          selectedItem={selectedType}
          onItemSelect={handleEntityTypeSelect}
          // selectedCount={totalCount}
        />
      </div>
      <div className="col mt-2">
        <div className="d-flex py-2">
          <h2 className="text-uppercase">QBO Exports</h2>
        </div>
        {entities.length === 0 && (
          <div id="noRecords">
            <p> No items to update since your last sync.</p>
          </div>
        )}
        <div className="card mb-3">
          <p className="pl-3 pt-3">
            Total entities to export: {entities.length}
          </p>
          <div className="row p-3">
            {/* <div className="col-6">
                  Click "Delete" icon on the right to remove this item from the
                  list.
                </div> */}
            <div className="col-6">
              <button
                className="btn btn-success btn-sm shadow ml-2"
                onClick={handleListExport}
                data-toggle="tooltip"
                data-placement="top"
                title="Export entities to QBO"
              >
                Export ...
              </button>
              <button
                className="btn btn-info btn-sm shadow ml-2"
                onClick={handleSingleBatch}
                data-toggle="tooltip"
                data-placement="top"
                title="Export a single batch to QBO"
              >
                Export single batch...
              </button>
            </div>
          </div>
        </div>
        <QboExportListTable
          items={entities}
          type={selectedType}
          sortColumn={sortColumn}
          onDelete={handleDelete}
          onSort={handleSort}
          onExport={handleSingleExport}
          isList={true}
        />
      </div>
    </>
  );
};

export default withLoading(QboExportLists);
