import React, { Component } from "react";
import withLoading from "../common/withLoading";
// import { getQboTxns } from "../services/qboData";
import { DateTime } from "luxon";
import SidebarLeft from "./../common/SidebarLeft";
import ListGroup from "./../common/listGroup";
import SelectDateBox from "./../common/SelectDateBox";
import SelectBox from "./SelectBox";
import Select from "react-select";

import {
  getEntities,
  getItems,
  getStorages,
  getEntitiesQuery,
  saveEntity,
} from "../../services/entityService";
import { saveDocument } from "../../services/documentService";
import { getInventoriesQuery } from "./../../services/inventoryService";

import { toast } from "react-toastify";
import { mapInvoiceDetailToProduction } from "../../utils/productionUtils";

import GenProdTable from "./GenProdTable";

class GenProd extends Component {
  state = {
    customers: [],
    storages: [],
    productionItems: [],
    inventories: [],
    invoices: [],
    invoiceDetails: [],
    verified: [],
    receipts: [],
    // inventories: [],
    selectedEntity: null,
    selectedStorage: null,
    selectedCustomer: null,
    currentPage: 1,
    pageSize: 150,
    searchQuery: "",
    searchOptions: [],
    sortColumn: { path: "title", order: "asc" },
    fromDate: DateTime.local().startOf("month"),
    toDate: DateTime.local().endOf("month"),
    queryOption: 1,
    query: {},
  };

  populateStorages = async () => {
    try {
      const { data } = await getStorages();
      const storages = data.map((s) => {
        return { value: s._id, label: s.title, qty: 0, code: s.data.code };
      });
      let found = storages.find((s) =>
        s.label.toLowerCase().includes("shipping")
      );
      let selectedStorage = found ? found : storages[0];
      // const storages = [{ value: "", label: "All storages" }, ...mapped];
      this.setState({ storages, selectedStorage });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  populateCustomers = async () => {
    try {
      const { data } = await getEntities("Customer");
      const customers = data.map((i) => ({
        label: i.title,
        value: i._id,
        // data: i.data,
      }));
      this.setState({ customers });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  populateInventories = async () => {
    // const query = encodeURI("?type=Finished Products");
    try {
      const { data } = await getInventoriesQuery();
      const filtered = data.filter((i) => i.type === "Finished Products");
      const inventories = filtered.map((i) => {
        const item = {
          value: i._id,
          label: i.name,
          uom: i.uom || "EACH",
          itemId: i.itemId,
          type: i.type,
          description: i.description || i.name,
        };
        return item;
      });
      this.setState({ inventories });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  populateProductionItems = async () => {
    // const query = encodeURI("?type=Finished Products");
    try {
      const { data } = await getItems(`?production=${true}`);
      const productionItems = data.map((i) => {
        const item = {
          value: i._id,
          label: i.title,
        };
        return item;
      });
      this.setState({ productionItems });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  populateInvoiceDetails = async () => {
    const { fromDate, toDate, selectedCustomer } = this.state;
    let query = "";
    if (fromDate) query = query + `?fromDate=${fromDate.toISO()}`;
    if (toDate)
      query = query
        ? query + `&toDate=${toDate.toISO()}`
        : `&toDate=${toDate.toISO()}`;
    // console.log(selectedCustomer);
    if (selectedCustomer)
      query = query
        ? query + "&Customer=" + selectedCustomer.value
        : "?Customer=" + selectedCustomer.value;
    this.props.loadingOn();
    try {
      const { data: invoices } = await getEntitiesQuery("Invoice", query);
      this.setState({ invoices });
      const invoiceDetails = this.mapInvoiceDetails(invoices);
      // const invoices = data.map((inv) => {
      //   if (inv.data && inv.data.refs) {
      //     const prodRefs = inv.data.refs.filter(
      //       (r) => r.type === "ProductionReceipt"
      //     );
      //     inv.isProd = prodRefs.length > 0 ? true : false;
      //   }
      //   return inv;
      // });
      this.props.loadingOff();
      this.setState({ invoiceDetails });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      this.props.loadingOff();
    }
  };

  async componentDidMount() {
    await this.populateStorages();
    await this.populateInventories();
    await this.populateProductionItems();
    await this.populateCustomers();
    // await this.populateSalesClasses();
    // await this.populateTaxes();
    // const entityTypes = await this.populateEntityTypes();
    let selectedEntity = { value: "Invoice", label: "Invoices" };
    // await this.handleOptionsSelect(selected);
    this.setState({ selectedEntity });
    this.props.loadingOff();
  }

  mapInvoiceDetails = (invoices) => {
    let details = [];
    const { productionItems, inventories, selectedStorage } = this.state;
    console.log("Total invoices", invoices.length);
    let invoiceNum = 0;
    for (const invoice of invoices) {
      invoiceNum++;
      console.log(`Invoice # ${invoiceNum} ${invoice.title}`, invoice);
      if (invoice.data && invoice.data.details) {
        for (const line of invoice.data.details) {
          if (invoice.data && invoice.data.refs) {
            console.log("Refs found: ", invoice);
            let ref = invoice.data.refs.find(
              (ref) =>
                ref.type === "ProductionReceipt" && ref.lineId === line.lineId
            );
            if (ref) continue;
          }
          if (!line.item) continue;

          if (!line.item.value) {
            if (!line.item.label) continue;
            let item = productionItems.find(
              (prod) => prod.label === line.item.label
            );
            if (!item) continue;
            line.item = item;
          }
          // #TODO redo inventory creation
          let foundInventory = inventories.find(
            (inventory) => inventory.itemId === line.item.value
          );

          if (!foundInventory) {
            console.log(
              `Not found inventory item ${line.item.label}`,
              foundInventory
            );
            continue;
          }

          let detail = {
            ref: {
              type: "Invoice",
              value: invoice._id,
              label: invoice.title,
              customer: invoice.data.customer,
              currency: invoice.data.currency || { value: "CAD" },
              txnNumber: invoice.data.txnNumber,
              lineId: line.lineId,
            },
            customer: invoice.data.customer,
            storage: selectedStorage,
            inventory: foundInventory,
            item: line.item,
            txnDate: invoice.data.txnDate,
            qty: line.qty,
          };
          console.log(`Detail ${line.item.label}`, detail);
          details.push(detail);
        }
      }
    }
    console.log("Details list: ", details);
    return details;
  };

  handleEntitySelect = async (entity) => {
    // console.log("Selected entity: ", entity);
  };

  handleStorageSelect = async (storage) => {
    this.setState({ selectedStorage: storage });
  };

  handleSearch = (query) => {
    if (!query) this.setState({ selectedCustomer: null });
    // this.setState({ selectedCustomer: null });
    const { customers } = this.state;

    const filtered = customers.filter((item) =>
      item.label.toLowerCase().includes(query.toLowerCase())
    );

    const options = filtered.slice(0, 5);

    this.setState({
      searchQuery: query,
      searchOptions: options,
    });
  };

  onCustomerSelect = async (customer) => {
    this.setState({
      selectedCustomer: customer,
      searchQuery: customer.label,
      currentPage: 1,
    });
    // await this.populateInvoiceDetails();
  };

  handleGetData = async () => {
    await this.populateInvoiceDetails();
  };

  handleCreateProdReceipts = async () => {
    console.log("Called save...");
    const { invoiceDetails } = this.state;

    this.props.progressOn();

    // const { data } = await getTxnNewNumber("Invoice");
    // let { newNumber } = Number(data.newNumber);

    let d = 0;
    for (let txn of invoiceDetails) {
      // if (!invoice.data.txnNumber) {
      //   invoice.data.txnNumber = newNumber;
      //   invoice.title = "" + newNumber + " " + invoice.title;
      //   newNumber = newNumber + 1
      // }
      txn = await this.onCreateProdReceipt(txn);
      d++;
      this.props.progressUpdate(
        "Saving production receipts...",
        d,
        invoiceDetails.length
      );
      console.log("Saved: ", d, txn.title);
    }

    this.props.progressOff();

    const notSaved = invoiceDetails.filter((o) => o.errors !== undefined);
    if (notSaved.length === 0) {
      window.location = "/generate-production";
    } else {
      this.setState({ imported: notSaved });
    }
  };

  onCreateProdReceipt = async (txn) => {
    // this.props.loadingOn();
    const { invoices } = this.state;
    // txn.storage = selectedStorage;
    txn.user = this.props.user;
    const prod = await mapInvoiceDetailToProduction(txn);
    const txnDate = DateTime.fromISO(prod.data.txnDate).toISODate();

    prod.title = `${txnDate} Production Receipt`;

    if (prod.data.records) prod.records = prod.data.records;
    delete prod.data.details;
    delete prod.data.records;

    // console.log(doc);

    const saved = await this.onSaveDocument(prod);
    let refs = [
      {
        type: "ProductionReceipt",
        value: saved._id,
        label: saved.title,
        lineId: txn.ref.lineId,
      },
    ];
    // console.log(refs);

    const invoiceToUpdate = invoices.find((i) => i._id === txn.ref.value);
    invoiceToUpdate.data.refs = invoiceToUpdate.data.refs
      ? [...invoiceToUpdate.data.refs, ...refs]
      : refs;

    const inv = await this.onSaveInvoice(invoiceToUpdate);
    if (inv) inv.isProd = true;
    // console.log(saved);
    // this.props.loadingOff();
    return inv;
  };

  onSaveDocument = async (doc) => {
    // const isNew = doc._id === "new" ? true : false;
    if (doc._id === "new") delete doc._id;

    try {
      const { data } = await saveDocument(doc);
      return data;
      // else window.location = "/documents";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      return null;
    }
  };

  onSaveJob = async (job) => {
    if (job._id === "new") delete job._id;

    try {
      const { data } = await saveEntity(job);
      return data;
      // else window.location = "/documents";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      return null;
    }
  };

  onSaveInvoice = async (invoice) => {
    // this.props.loadingOn();
    if (invoice.__v) delete invoice.__v;
    try {
      const { data } = await saveEntity(invoice);
      return data;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      return null;
    }
  };

  onDelete = (txn) => {
    const { invoices: allInvoices } = this.state;

    const invoices = allInvoices.filter((i) => i._id !== txn._id);

    this.setState({ invoices });
  };

  render() {
    const {
      fromDate,
      toDate,
      searchOptions,
      invoiceDetails,
      storages,
      searchQuery,
      selectedCustomer,
      selectedStorage,
    } = this.state;
    return (
      <>
        <SidebarLeft>
          <ListGroup
            items={[{ value: "Invoice", label: "Invoices" }]}
            valueProperty="value"
            textProperty="label"
            selectedItem={{ value: "Invoice", label: "Invoices" }}
            onItemSelect={this.handleEntitySelect}
            selectedCount={invoiceDetails.length}
          />
        </SidebarLeft>
        <div className="col mt-2">
          <div className="d-flex py-2">
            <h2 className="text-uppercase">Generate Production Receipts</h2>
          </div>
          <p>
            Select date range to find invoices to generate production receipts,
            a customer is optional. Choose a storage where you want to store
            produced items.
          </p>
          <SelectDateBox
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={(value) => this.setState({ fromDate: value })}
            setToDate={(value) => this.setState({ toDate: value })}
            handleDateChange={this.handleGetData}
          />
          <div className="d-flex justify-content-md-between border-bottom mt-2 pb-2 d-print-none">
            <div>
              <SelectBox
                searchQuery={searchQuery}
                onSearch={this.handleSearch}
                options={searchOptions}
                onSelect={this.onCustomerSelect}
                label={"Select customer..."}
              />
              <Select
                name="storage"
                value={selectedStorage}
                options={storages}
                onSelect={this.onStorageSelect}
                label={"Search storage..."}
              />
            </div>
            <div>
              <button
                type="button"
                className="btn btn-info mr-2 shadow-sm"
                disabled={invoiceDetails < 1}
                onClick={this.handleCreateProdReceipts}
              >
                Generate Production
              </button>
            </div>
          </div>

          <GenProdTable
            title={
              selectedCustomer
                ? `${selectedCustomer.label} - Invoice Details for review`
                : "Invoice Details"
            }
            items={invoiceDetails}
            sorting={{ path: "data.dueDate", order: "asc" }}
            onCreateProdReceipt={this.onCreateProdReceipt}
            onDelete={this.onDelete}
          />
        </div>
      </>
    );
  }
}

export default withLoading(GenProd);
