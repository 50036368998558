import { searchEntities, getEntity } from "../../../services/entityService";
import {
  getInventory,
  getInventoryByItemId,
} from "../../../services/inventoryService";
import numeral from "numeral";
import { DateTime } from "luxon";

const populateItem = async (id) => {
  if (!id) return null;
  try {
    const { data } = await getEntity("Item", id);
    return data;
  } catch (error) {
    return null;
  }
};

const populateInventory = async (id) => {
  if (!id) return null;
  try {
    const { data } = await getInventory(id);
    return data;
  } catch (error) {
    return null;
  }
};

const findInventoryByItemId = async (id) => {
  if (!id) return null;
  try {
    const { data } = await getInventoryByItemId(id);
    return data;
  } catch (error) {
    return null;
  }
};

// TODO update after updating inventory to batches
export const mapBillDetailToInventoryReceipt = async (txn) => {
  const records = [];
  const details = [];

  const newRecord = {
    lineId: txn.ref.lineId,
    itemId: txn.item.value,
    inventory: { value: txn.inventory.value, label: txn.inventory.label },
    uom: txn.inventory.uom || "EACH",
    description: txn.inventory.description || "",
    qty: txn.qty,
  };
  records.push(newRecord);

  details.push({
    lineId: txn.ref.lineId,
    item: txn.item,
    inventory: { value: txn.inventory.value, label: txn.inventory.label },
    qty: txn.qty,
  });

  let txnDate = DateTime.fromISO(txn.txnDate).toISODate();
  const data = {
    txnDate: txn.txnDate,
    currency: txn.ref.currency,
    details: details,
    records: records,
    addressFrom: txn.ref.addressFrom || "",
    addressTo: "",
    locationTo: txn.storage,
    name: txn.ref.vendor,
    memo: `Vendor: ${txn.ref.vendor.label} \nBill: ${txn.ref.txnNumber}  ${txnDate}`,
    refs: [
      {
        type: "Bill",
        value: txn.ref.value,
        lineId: txn.ref.lineId,
        label: `${txn.ref.txnNumber}  ${txnDate} ${txn.ref.vendor.label}`,
      },
    ],
    messages: [
      {
        timeStamp: DateTime.local().toISO(),
        user: { value: txn.user._id, label: txn.user.name },
        message: `Document created by importing a vendor's bill`,
      },
    ],
  };
  console.log(data);
  return {
    entity: "InventoryReceipt",
    data: data,
  };
};
