import React from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";
import numeral from "numeral";

numeral.zeroFormat("-");

// import DateFormat from "../common/dateFormat";

const InventoryToPartsTable = (props) => {
  const { storage, items = [], onSort, sortColumn, totals } = props;

  const columns = [
    {
      path: "name",
      label: "Item",
      className: "w-25",
      content: (item) => <Link to={`/items/${item._id}`}>{item.name}</Link>,
    },
    // {
    //   path: "description",
    //   label: "Available to Produce",
    //   className: "w-25",
    //   content: (inventory) => (
    //     <div style={{ whiteSpace: "pre-wrap" }}>
    //       {inventory.parts && partsToTextArea(inventory.parts)}
    //     </div>
    //   ),
    // },
    // { path: "uom", label: "UOM", className: "text-center" },
    { path: "in", label: "Received", className: "text-center" },
    { path: "out", label: "Shipped", className: "text-center" },
    { path: "balance", label: "Balance", className: "text-center" },
    {
      path: "unitPrice",
      label: "Value",
      className: "text-center",
      content: (item) => <>{numeral(item.unitPrice).format("0,0.0000")}</>,
      total: () => (
        <div className="text-center">{`Total ${
          storage?.currency ? "(" + storage.currency + ")" : ""
        }: `}</div>
      ),
    },
    {
      key: "totalValue",
      label: "Total value",
      className: "text-right pr-2",
      content: (item) => (
        <>
          {numeral(item.unitPrice)
            .multiply(storage?.costRatio ?? 1)
            .multiply(item.balance)
            .format("0,0.00")}
        </>
      ),
      total: (totals) => (
        <div className="text-right">
          {numeral(totals.value).format("0,0.00")}
        </div>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      data={items}
      sortColumn={sortColumn}
      onSort={onSort}
      totals={totals}
      includeTotals={true}
    />
  );
};

export default InventoryToPartsTable;
