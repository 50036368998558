import React, { Component } from "react";
import auth from "../../services/authService";
import Loader from "../common/loader";
import {
  authorizeUri,
  disconnect,
  refreshAccessToken,
  getConnectionStatus,
  getCompanyInfo,
} from "../../services/qboAuthService";

class QboConnect extends Component {
  state = {
    conn: {
      status: false,
      message: "Checking connection...",
    },
    companyInfo: {
      name: "",
      email: "",
      phone: "",
    },
    refreshedTime: null,
    loading: true,
  };

  async componentDidMount() {
    await this.checkStatus();
    this.setState({ loading: false });
  }

  checkStatus = async () => {
    try {
      let { data: conn } = await getConnectionStatus();
      this.setState({ conn, loading: false });
    } catch (error) {
      console.log("Couldn't update connection status");
      let conn = { status: false, message: "Disconnected" };
      this.setState({ conn, loading: false });
    }
  };

  intuitAuthPopup = (authUri) => {
    // Launch Popup using the JS window Object
    let parameters = "location=1,width=800,height=800";

    parameters +=
      ",left=" +
      (window.screen.width - 800) / 2 +
      ",top=" +
      (window.screen.height - 800) / 2;
    window.open(authUri, "connectPopup", parameters);
    // let win = window.open(authUri, "connectPopup", parameters);
  };

  authorize = async () => {
    try {
      const { data: authUri } = await authorizeUri();
      this.intuitAuthPopup(authUri);
    } catch (error) {
      console.log("Auth API Error");
    }
  };

  handleRefreshToken = async () => {
    this.setState({ loading: true });
    try {
      const { data } = await refreshAccessToken();
      this.setState({ refreshedTime: data, loading: false });
      this.props.history.push("/settings/qbo-connect");
    } catch (error) {
      console.log("Can't update token");
      this.setState({ loading: false });
    }
  };

  handleDisconnect = async () => {
    this.setState({ loading: true });
    try {
      await disconnect();
      let conn = { status: false, message: "Disconnected" };
      this.setState({ conn, loading: false });
      this.props.history.push("/");
    } catch (error) {
      console.log("Couldn't disconnect");
      this.setState({ loading: false });
    }
  };

  getCompanyInfo = async () => {
    this.setState({ loading: true });
    try {
      const { data } = await getCompanyInfo();
      let company = data.CompanyInfo;
      let companyInfo = this.state.companyInfo;
      companyInfo.name = company.CompanyName;
      companyInfo.email = company.Email.Address;
      companyInfo.phone = company.PrimaryPhone.FreeFormNumber;
      //   console.log(companyInfo);
      this.setState({ companyInfo, loading: false });
    } catch (error) {
      console.log("Couldn't get company info");
      this.setState({ loading: false });
    }
  };

  render() {
    const user = auth.getCurrentUser();
    const { conn, companyInfo, loading } = this.state;

    const CompanyInfo = () => {
      return (
        <div className="card m-3">
          <div className="card-body">
            <div>
              Name: <strong>{companyInfo.name}</strong>
            </div>
            <div>
              Email: <strong>{companyInfo.email}</strong>
            </div>
            <div>
              Primary Phone: <strong>{companyInfo.phone}</strong>
            </div>
          </div>
        </div>
      );
    };

    return (
      <React.Fragment>
        <div id="Loader" className={loading ? "" : "d-none"}>
          <Loader />
        </div>
        <h2 className="text-uppercase py-2">QuickBooks Online Connection</h2>
        <hr />
        <h4 className="text-uppercase">Current Status</h4>
        <p>
          {conn.status
            ? `Connected using Intuit API 2.0, QBO ${conn.message} `
            : `${conn.message}`}
        </p>
        <div className="row">
          <div className="col">
            <button
              type="button"
              id="makeAPICall"
              disabled={!conn.status}
              className="btn btn-sm btn-primary"
              onClick={this.getCompanyInfo}
            >
              Show Company Info
            </button>
            <br />

            {companyInfo.name ? <CompanyInfo /> : ""}
          </div>
        </div>
        <hr />
        <h4 className="text-uppercase">Manage QBO Connection</h4>
        <p>
          To connect a QBO company click either the <b>Connect to QuickBooks</b>{" "}
          or <b>Sign with Intuit</b>.
        </p>
        <button className="btn" onClick={this.authorize} id="authorize">
          <img
            src={"/images/C2QB_green_btn_lg_default.png"}
            alt="Connect to QBO"
            width="178"
          />
        </button>
        <p>
          To disconnect your company click <b>Disconnect</b>
        </p>
        <button
          type="button"
          id="disconnect"
          onClick={this.handleDisconnect}
          className="btn btn-sm btn-danger mx-2"
        >
          Disconnect
        </button>
        <hr />
        <h4 className="text-uppercase">Update Information</h4>
        {/* <button
          type="button"
          id="connStatus"
          onClick={this.checkStatus}
          className="btn btn-sm btn-secondary m-2"
          style={{ width: "178px" }}
        >
          Check Connection Status
        </button> */}
        <button
          type="button"
          id="refreshToken"
          onClick={() => this.handleRefreshToken(user)}
          className="btn btn-sm btn-secondary m-2"
        >
          Refresh Token
        </button>
        <hr />
      </React.Fragment>
    );
  }
}

export default QboConnect;
