import http from "./httpService";

const apiEndpoint = "/entities";

function typeUrl(entity) {
  return `${apiEndpoint}/${entity}`;
}

// function entityUrl(entity) {
//   return `${apiEndpoint}/${entity}`;
// }

export function getEntityTypes() {
  return http.get(`${apiEndpoint}/types`);
}

export function getStorages(query = "") {
  return http.get(`${apiEndpoint}/storages` + query);
}

export function getCustomers() {
  return http.get(`${apiEndpoint}/customers`);
}

export function getVendors() {
  return http.get(`${apiEndpoint}/vendors`);
}

export function getEmployees() {
  return http.get(`${apiEndpoint}/employees`);
}

export function getWorkers() {
  return http.get(`${apiEndpoint}/workers`);
}

export function getItems(query = "") {
  return http.get(`${apiEndpoint}/items` + query);
}

export function getItemList(query = "") {
  return http.get(`${apiEndpoint}/items/list` + query);
}

export function getSalesGroups() {
  return http.get(`${apiEndpoint}/salesgroups`);
}

export function getSalesOrders() {
  return http.get(`${apiEndpoint}/salesorders`);
}

export function getAllEntities() {
  return http.get(`${apiEndpoint}`);
}

export function getEntities(type) {
  return http.get(`${apiEndpoint}/${type}`);
}

export function getEntitiesQuery(entity, query) {
  return http.get(typeUrl(entity) + query);
}

export function countEntitiesQuery(entity, query) {
  return http.get(typeUrl(entity) + "/count" + query);
}

export function getEntity(entity, id) {
  return http.get(`${apiEndpoint}/${entity}/${id}`);
}

export function searchEntities(body) {
  return http.post(`${apiEndpoint}/search`, body);
}

export function saveEntity(item) {
  if (item._id) {
    const body = { ...item };
    delete body._id;
    return http.put(`${apiEndpoint}/${item.entity}/${item._id}`, body);
  }
  return http.post(`${apiEndpoint}/${item.entity}`, item);
}

export function deleteEntity(entity, id) {
  return http.delete(`${apiEndpoint}/${entity}/${id}`);
}
