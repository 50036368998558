import http from "./httpService";
import { getJwt } from "./authService";

const api_endpoint = process.env.REACT_APP_API_INTEGRATION_URL;

const getOptionsHeader = async () => {
  const token = getJwt();
  return {
    headers: {
      "Content-Type": "multipart/form-data",
      "x-auth-token": token,
    },
  };
};

export async function uploadFile(file, root, folder) {
  const options_default = await getOptionsHeader();
  const options = { ...options_default, params: { root, folder } };
  const formData = new FormData();
  formData.append("file", file);
  try {
    const result = await http.put(
      `${api_endpoint}/digitalocean`,
      formData,
      options
    );
    return result;
  } catch (error) {
    return error;
  }
}

export async function uploadFiles(files, root, folder) {
  const options_default = await getOptionsHeader();
  const options = { ...options_default, params: { root, folder } };
  const formData = new FormData();

  for (let index = 0; index < files.length; index++) {
    const file = files[index];
    formData.append(`file${index}`, file);
  }
  try {
    const result = await http.put(
      `${api_endpoint}/digitalocean`,
      formData,
      options
    );
    return result;
  } catch (error) {
    return error;
  }
}

export async function listFiles(root, folder) {
  const options_default = await getOptionsHeader();
  const options = { ...options_default, params: { root, folder } };
  return await http.get(`${api_endpoint}/digitalocean`, options);
}

export async function download(id) {
  const encodedId = encodeURIComponent(id);
  const options = await getOptionsHeader();
  const result = await http.get(
    `${api_endpoint}/digitalocean/download/${encodedId}`,
    {
      ...options,
      responseType: "arraybuffer",
    }
  );

  console.log("download(id) result:", result);

  return result;
}
