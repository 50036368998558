import http from "./httpService";
const apiEndpoint = "/settings";

export function getSettings() {
  return http.get(apiEndpoint);
}

export const setPreferences = (data) => {
  sessionStorage.setItem("inventoryPref", JSON.stringify(data));
};

export const getPreferences = () => {
  return JSON.parse(sessionStorage.getItem("inventoryPref"));
};

export async function populateSettings() {
  const { data: generalInfo } = await populateSetting("general");
  const { data: inventoryPref } = await populateSetting("inventoryPref");
  setSetting("general", generalInfo);
  setSetting("salesPref", inventoryPref);
}

export function logout() {
  sessionStorage.removeItem("inventoryPref");
  sessionStorage.removeItem("general");
}

export function populateSetting(type) {
  return http.get(`${apiEndpoint}/${type}`);
}

export function saveSetting(type, setting) {
  return http.post(`${apiEndpoint}/${type}`, setting);
}

export const setSetting = (type, data) => {
  sessionStorage.setItem(type, JSON.stringify(data));
};

export const getSetting = (type) => {
  return JSON.parse(sessionStorage.getItem(type));
};

const settings = {
  getSettings,
  setPreferences,
  getPreferences,
  populateSettings,
  logout,
};

export default settings;
