import React, { useState } from "react";
import { toast } from "react-toastify";
import Loader from "../common/LocalLoader";
import { resetPassword } from "../../services/loginApiService";

const AuthResetPassword = (props) => {
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
    loading: false,
    errors: {},
  });

  const handleChange = ({ currentTarget: input }) => {
    // formData[input.name] = input.value
    const data = { ...formData };
    // const errorMessage = this.validateProperty(input);
    const errorMessage = "";
    if (errorMessage) data.errors[input.name] = errorMessage;
    else delete data.errors[input.name];
    data[input.name] = input.value;
    setFormData(data);
    // setFormData({ [input.name]: input.value, errors });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    formData.loading = true;
    try {
      await resetPassword(props.match.params.token, formData.password);
      formData.loading = false;
      window.location = "/auth";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      formData.loading = false;
    }
  };

  const match =
    formData.password && formData.password === formData.confirmPassword
      ? true
      : false;

  return (
    <div>
      <div className="card-header">
        <h5>Reset Password</h5>
      </div>
      <div className="card-body">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="password"
              name="password"
              id="password"
              aria-describedby="emailHelp"
              placeholder="Enter new password..."
              className="form-control"
              value={formData.password}
              onChange={handleChange}
              autoFocus
              required
              pattern="[A-z0-9À-ž]+"
              minLength="5"
              maxLength="12"
              error={formData.errors["password"]}
            />
          </div>
          <div className="form-group">
            <input
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              aria-describedby="emailHelp"
              placeholder="Repeat new password.. ."
              className="form-control"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
              pattern="[A-z0-9À-ž]+"
              minLength="5"
              maxLength="12"
              error={formData.errors["confirmPassword"]}
              // error={errors["username"]}
            />
          </div>
          {!match && (
            <p>Passwords must be at least 5 characters and matching</p>
          )}
          <button
            type="submit"
            className="btn btn-primary mb-2 shadow-sm"
            disabled={!match}
          >
            Reset Password
          </button>
        </form>
      </div>
      {formData.loading && <Loader />}
    </div>
  );
};

export default AuthResetPassword;
