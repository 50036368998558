import React, { Component } from "react";
import withLoading from "../common/withLoading";
import IncomingMaterialTable from "./IncomingMaterialTable";
// import SidebarLeft from "../common/SidebarLeft";
// import ListGroup from "../common/listGroup";
import SelectDateBox from "./../common/SelectDateBox";
import SearchBox from "../common/searchBox";

import { getDocumentsQuery } from "../../services/documentService";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { paginate } from "../../utils/paginate";
import { getMetaData } from "../../services/metaDataService";
import _ from "lodash";

class ReceivingInventory extends Component {
  state = {
    documents: [],
    metaKeys: [],
    fromDate: DateTime.local().startOf("month"),
    toDate: DateTime.local().endOf("month"),
    sortColumn: { path: "doc.txnDate", order: "asc" },
    currentPage: 1,
    pageSize: 300,
    searchQuery: "",
    selectedType: null,
  };

  populateDocuments = async () => {
    let query = "?type=InventoryReceipt";
    if (this.state.fromDate && this.state.toDate)
      query =
        query +
        `&fromDate=${this.state.fromDate.toISODate()}&toDate=${this.state.toDate.toISODate()}`;
    else if (this.state.fromDate)
      query = query + `&fromDate=${this.state.fromDate.toISODate()}`;
    else if (this.state.toDate)
      query = query + `&toDate=${this.state.toDate.toISODate()}`;
    // else query = "";

    try {
      const { data } = await getDocumentsQuery(query);
      const documents = data.map((d) => {
        d.search = d.data.txnNumber + " " + d.data.memo;
        return d;
      });
      this.setState({ documents });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      return null;
    }
  };

  populateMetaKeys = () => {
    const metaKeys = getMetaData("InventoryReceipt");
    this.setState({ metaKeys });
  };

  async componentDidMount() {
    this.populateMetaKeys();
    await this.populateDocuments();
    this.props.loadingOff();
  }

  handleDateChange = async () => {
    this.props.loadingOn();
    await this.populateDocuments();
    this.props.loadingOff();
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      selectedType: null,
      currentPage: 1,
    });
  };

  handleSort = (sortColumn) => {
    this.setState({
      sortColumn: sortColumn,
    });
  };

  getSummaryData = () => {
    const { documents: allDocs, sortColumn, metaKeys } = this.state;

    const docs = allDocs;
    // const docs = allDocs.map((doc) => {
    //   const dt = DateTime.fromISO(doc.doc.txnDate).endOf(selectedPeriod.value);
    //   const endDate = dt.toISODate();
    //   return {
    //     value: doc._id,
    //     label: doc.inventory.label,
    //     inventory: doc.inventory,
    //     ref: doc.doc,
    //     docDate: doc.doc.txnDate,
    //     endDate: endDate,
    //     qty: numeral(doc.qty).value(),
    //   };
    // });

    const lines = [];

    const reportCols = metaKeys.map((key, index) => ({
      path: `key-${index}`,
      label: key,
    }));

    const cols = [{ path: "docNumber", title: "DocNumber" }, ...reportCols];

    for (const doc of docs) {
      let line = {
        docNumber: doc.data.txnNumber,
        _id: doc._id,
        title: doc.title,
      };

      metaKeys.forEach((index) => {
        let name = `key-${index}`;
        let value = "";
        if (doc.data?.metadata?.length > 0) {
          value = doc.data?.metadata[index]?.value ?? "";
        }
        line[name] = value;
      });
      lines.push(line);
    }

    // periods.forEach((period) => {
    //   const filtered = docs.filter((doc) => doc.endDate === period.endDate);
    //   let uniqueItems = _.uniqBy(filtered, "label");
    //   //   if (filtered.length !== 0) {
    //   let line = {
    //     name: period.name,
    //     startDate: period.startDate,
    //     endDate: period.endDate,
    //     docList: [],
    //     products: uniqueItems.length,
    //     docs: 0,
    //     qty: 0,
    //   };
    //   line.docs = filtered.length;
    //   filtered.forEach((doc) => {
    //     line.qty = line.qty + doc.qty;
    //     line.docList.push(doc);
    //   });
    //   lines.push(line);
    //   //   }
    // });

    // const totals = {
    //   docs: 0,
    //   products: 0,
    //   qty: 0,
    // };

    // lines.forEach((line) => {
    //   totals.products = totals.products + line.products;
    //   totals.qty = totals.qty + line.qty;
    //   totals.docs = totals.docs + line.docs;
    // });

    const sorted = _.orderBy(lines, [sortColumn.path], [sortColumn.order]);
    return { data: sorted, cols };
  };

  render() {
    const { fromDate, toDate, sortColumn } = this.state;

    const { data: lines, cols } = this.getSummaryData();

    return (
      <main className="container-fluid px-5">
        <div className="d-flex py-2">
          <h2 className="text-uppercase">
            Incoming Material Inspection Report
          </h2>
        </div>
        <div className="d-none d-print-block">{`Period: ${fromDate.toISODate()} - ${toDate.toISODate()}`}</div>
        <SelectDateBox
          fromDate={fromDate}
          toDate={toDate}
          setFromDate={(value) => this.setState({ fromDate: value })}
          setToDate={(value) => this.setState({ toDate: value })}
          handleDateChange={this.handleDateChange}
        />
        <IncomingMaterialTable
          lines={lines}
          cols={cols}
          sortColumn={sortColumn}
          onSort={this.handleSort}
          onClick={this.handleClick}
          // totals={totals}
        />
      </main>
    );
  }
}

export default withLoading(ReceivingInventory);
