import React from "react";
import { RowTwoColumns, RowFourColumns } from "../common/formLayout";
import Field from "../common/fieldText";

const HeaderView = (props) => {
  const { docType, data } = props;

  switch (docType) {
    case "InventoryTransfer":
      return (
        <RowTwoColumns
          colOne={
            <Field
              name="locationFrom"
              label="From"
              value={data.locationFrom && data.locationFrom.label}
            />
          }
          colTwo={
            <Field
              name="locationTo"
              label="To"
              value={data.locationTo && data.locationTo.label}
            />
          }
        />
      );
    case "InventoryReceipt":
      return (
        <>
          <RowTwoColumns
            colOne={
              <Field
                name="name"
                label="From vendor"
                value={data.name && data.name.label}
              />
            }
            colTwo={
              <Field
                name="locationTo"
                label="To"
                value={data.locationTo && data.locationTo.label}
              />
            }
          />
          <RowFourColumns
            colOne={
              <Field
                name="purchaseOrder"
                label="PO"
                value={data.purchaseOrder && data.purchaseOrder.label}
              />
            }
          />
        </>
      );
    case "InventoryShipment":
      return (
        <RowTwoColumns
          colOne={
            <Field
              name="locationFrom"
              label="From"
              value={data.locationFrom && data.locationFrom.label}
            />
          }
          colTwo={
            <Field
              name="name"
              label="To customer"
              value={data.name && data.name.label}
            />
          }
        />
      );
    case "InventoryCount":
      return (
        <RowTwoColumns
          colOne={
            <Field
              name="locationFrom"
              label="Location"
              value={data.locationFrom && data.locationFrom.label}
            />
          }
        />
      );
    case "InventoryWriteDown":
      return (
        <RowTwoColumns
          colOne={
            <Field
              name="locationFrom"
              label="Location"
              value={data.locationFrom && data.locationFrom.label}
            />
          }
        />
      );
    case "MaterialsRequisitionForm":
      return (
        <RowTwoColumns
          colOne={
            <Field
              name="locationFrom"
              label="Location"
              value={data.locationFrom && data.locationFrom.label}
            />
          }
          colTwo={
            <Field
              name="name"
              label="Job code"
              value={data.name && data.name.label}
            />
          }
        />
      );
    case "ProductionReceipt":
      return (
        <RowTwoColumns
          colOne={
            <Field
              name="name"
              label="Job code"
              value={data.name && data.name.label}
            />
          }
          colTwo={
            <Field
              name="locationTo"
              label="Produce Received To"
              value={data.locationTo && data.locationTo.label}
            />
          }
        />
      );
    default:
      return (
        <RowTwoColumns
          colOne={
            <Field
              name="locationFrom"
              label="From"
              value={data.locationFrom && data.locationFrom.label}
            />
          }
          colTwo={
            <Field
              name="locationTo"
              label="To"
              value={data.locationTo && data.locationTo.label}
            />
          }
        />
      );
  }
};

export default HeaderView;
