import React, { Component } from "react";
import withLoading from "../common/withLoading";
import QboImportListsTable from "./QboImportListsTable";
import ListGroup from "../common/listGroup";
import Pagination from "../common/pagination";
import { getQboLists } from "../../services/qboData";

import { getList, countEntities } from "../../services/qboEntityService";
import { qboEntityLastUpdated, saveQboEntity } from "./qboExchange";
import { mapQBOtoData as mapVendor } from "../../utils/vendorUtils";
import { mapQBOtoData as mapItem } from "../../utils/itemUtils";

import { paginate } from "../../utils/paginate";
import { DateTime } from "luxon";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { toast } from "react-toastify";

class QboImportLists extends Component {
  state = {
    imported: [],
    entityTypes: [],
    summary: [],
    selected: null,
    currentPage: 1,
    pageSize: 150,
    searchQuery: "",
    sortColumn: { path: "title", order: "asc" },
    fromDate: DateTime.local().startOf("month").minus({ months: 12 }),
    query: null,
    requestSize: 1000,
    requestPage: 1,
    displayImport: false,
    displaySummary: true,
    showAllItems: false,
  };

  populateEntityTypes = async () => {
    const itemTypes = ["Vendor", "Item", "PurchaseOrder", "Estimate"];
    const { data } = await getQboLists();
    const types = data.filter((t) => itemTypes.includes(t.entityName));
    return types;
  };

  async componentDidMount() {
    const entityTypes = await this.populateEntityTypes();
    let selected = entityTypes[0];
    this.setState({ entityTypes, selected });
    await this.handleEntityTypeSelect(selected);
    this.props.loadingOff();
  }

  // #TODO lastUpdated --> updatedAt
  getLastUpdated = async (entity, showAllItems) => {
    let { query } = this.state;
    if (showAllItems === true) return null;

    let { data: lastUpdated } = await qboEntityLastUpdated(entity);

    if (lastUpdated) {
      query = {
        filter: `Metadata.LastUpdatedTime > '${lastUpdated}'`,
      };
      this.setState({ query });
    }
    return query;
  };

  handleShowAll = async () => {
    let { showAllItems, selected } = this.state;
    const reversed = !showAllItems;

    await this.handleEntityTypeSelect(selected, reversed);
  };

  handleEntityTypeSelect = async (selected, showAllItems = false) => {
    this.props.loadingOn();

    let { requestSize } = this.state;

    const query = await this.getLastUpdated(selected.entity, showAllItems);

    const { data: entities } = await countEntities(selected.entity, query);

    const pagesCount = Math.ceil(entities.totalCount / requestSize);

    let imported = [];
    for (let p = 0; p < pagesCount; p++) {
      let startPosition = p * requestSize + 1;
      let received = await this.getImports(
        selected,
        query,
        startPosition,
        requestSize
      );
      if (received) imported = [...imported, ...received];
    }

    this.setState({
      selected: selected,
      searchQuery: "",
      showAllItems,
      imported: imported,
      currentPage: 1,
    });
    this.props.loadingOff();
  };

  getImports = async (entityType, query, startPosition, requestSize) => {
    try {
      let { data: received } = await getList(
        entityType.entity,
        query,
        startPosition,
        requestSize
      );
      return received;
    } catch (error) {
      console.log("Error: " + error);
      return null;
    }
  };

  handleImport = async () => {
    let { selected, imported } = this.state;
    this.props.progressOn();

    let d = 0;
    for (let item of imported) {
      d++;
      this.props.progressUpdate("Updating items...", d, imported.length);

      let data = {};
      let entity = selected.entityName;

      if (selected.entityName === "Vendor") {
        data = mapVendor(item);
      }

      if (selected.entityName === "Class") {
        data.displayName = item[selected.title];
      }

      if (selected.entityName === "Item") {
        data = mapItem(item);
        entity = "Item";
      }

      entity = {
        entity: selected.entityName,
        title: item[selected.title],
        data: data,
        qbo: item,
      };

      try {
        let { data: saved } = await saveQboEntity(entity.entity, entity);
        console.log("Saved: ", saved.title);
      } catch (error) {
        item.errors = [];
        item.errors.push({ message: "Can't save item", error: error });
        console.log("Error: " + error);
      }
    }
    const notImported = imported.filter((item) => item.errors);
    this.setState({ imported: notImported });

    this.props.progressOff();
  };

  handleSave = async (item) => {
    this.loadingOn();
    let { selected } = this.state;
    let entity = {
      entity: selected.entityName,
      title: item[selected.title],
      qbo: item,
    };

    try {
      let { data: saved } = await saveQboEntity(selected.entityName, entity);
      toast.success("Saved: ", saved.title);
      this.loadingOff();
    } catch (error) {
      this.loadingOff();
      toast.error("Error: " + error);
    }
  };

  handleDelete = (item) => {
    const imported = this.state.imported.filter((t) => t.Id !== item.Id);
    this.setState({ imported: imported });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      imported: allImported,
    } = this.state;

    let filtered = allImported;

    if (searchQuery)
      filtered = allImported.filter((m) =>
        m.title.toLowerCase().includes(searchQuery.toLowerCase())
      );

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.entity]);

    const imported = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: imported };
  };

  render() {
    let {
      pageSize,
      currentPage,
      imported: allEntities,
      sortColumn,
      showAllItems,
      selected,
    } = this.state;
    // const user = auth.getCurrentUser();

    const { totalCount, data: imported } = this.getPagedData();
    const totalItems = allEntities.length;

    let displayImport = "d-none";
    this.state.imported.length === 0
      ? (displayImport = "d-none")
      : (displayImport = "");

    let displayNoRecords = "";
    this.state.imported.length === 0
      ? (displayNoRecords = "")
      : (displayNoRecords = "d-none");

    return (
      <>
        <div id="sidebar-left" className="col-2 pt-4">
          <ListGroup
            items={this.state.entityTypes}
            valueProperty="entityName"
            textProperty="entityName"
            selectedItem={this.state.selected}
            onItemSelect={this.handleEntityTypeSelect}
            selectedCount={totalCount}
          />
        </div>
        <div className="col mt-2">
          <div className="d-flex py-2">
            <h2 className="text-uppercase">Import Lists from QBO</h2>
          </div>
          <div className="row pb-3">
            <div className="col">
              <p>
                Showing items since your last update.
                <br />
                if you would like to see all items in Quickbooks Online, check
                the box below.
              </p>
            </div>
            <div className="w-100"></div>
            <div className="col">
              <div className="form-check mx-2 align-bottom">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="showAllItems"
                  value={showAllItems}
                  onChange={this.handleShowAll}
                />
                <label className="form-check-label" htmlFor="showAllItems">
                  Display All Items
                </label>
              </div>
            </div>
          </div>
          <div id="noRecords" className={displayNoRecords}>
            <p> No items to update since your last sync.</p>
          </div>
          <div id="displayImported" className={displayImport}>
            <div className="card mb-3">
              <div className="row p-3">
                <div className="col-6">
                  Click "Delete" icon on the right to remove this item from the
                  list.
                </div>
                <div className="col-6 d-flex justify-content-end">
                  <button
                    className="btn btn-success btn-sm shadow ml-2"
                    onClick={this.handleImport}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Save items to database"
                  >
                    Import Items
                  </button>
                </div>
              </div>
            </div>

            <h5>Review to update items in "iours"</h5>
            {showAllItems ? (
              <p>Total {totalItems} items available to import </p>
            ) : (
              <p>Total {totalItems} items since your last update.</p>
            )}

            <QboImportListsTable
              items={imported}
              type={selected}
              sortColumn={sortColumn}
              onDelete={this.handleDelete}
              onSort={this.handleSort}
              onSave={this.handleSave}
              isList={true}
            ></QboImportListsTable>
          </div>
          <div>
            <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </div>
        </div>
      </>
    );
  }
}

export default withLoading(QboImportLists);
