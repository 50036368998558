import React, { useEffect, useState } from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DetailsHeader from "./DetailsHeader";
import InputTableTextArea from "../common/inputTableTextArea";
import InputTableText from "../common/inputTableText";
import InputTableSelect from "../common/inputTableSelect";
import InputTableNumber from "../common/inputTableNumber";
import { getInventories } from "./../../services/inventoryService";

const EditDetails = (props) => {
  const initialDetail = {
    lineId: 0,
    inventory: null,
    description: "",
    uom: "EACH",
    qty: 0,
  };
  const { control, getValues, setValue } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "details",
  });

  const [inventories, setInventories] = useState([]);

  useEffect(() => {
    async function populateInventories() {
      const { data } = await getInventories("Item");
      const mapped = data.map((i) => {
        const item = {
          value: i._id,
          label: i.name,
          itemId: i.itemId,
          uom: i.uom || "EACH",
          description: i.description || i.name,
        };
        return item;
      });
      if (mapped) setInventories(mapped);
    }
    populateInventories();
    return () => {};
  }, []);

  // const { register } = useFormContext();
  const handleItemSelect = (name, selected) => {
    setValue(selected.name, { value: name.value, label: name.label });
    let str = selected.name;
    let ni1 = str.indexOf("[");
    let ni2 = str.indexOf("]");
    const index = str.substring(ni1 + 1, ni2);
    setValue(`details[${index}].uom`, name.uom);
    setValue(`details[${index}].description`, name.description);
    setValue(`details[${index}].itemId`, name.itemId);
  };

  const addLine = (detail = null) => {
    const line = detail ? detail : initialDetail;
    const values = getValues();
    const { details } = values;
    let maxValue = 0;
    if (details) maxValue = Math.max(...details.map((o) => o.lineId), 0);
    line.lineId = maxValue + 1;
    append(line);
  };

  return (
    <div>
      <table className="table table-sm border-bottom">
        <DetailsHeader />
        <tbody>
          {fields.map((line, index) => (
            <tr key={line.lineid}>
              <th scope="row" className="align-baseline text-center">
                <input
                  type="hidden"
                  name={`details[${index}]._id`}
                  defaultValue={line._id}
                  // ref={register}
                />
                <input
                  type="hidden"
                  name={`details[${index}].lineId`}
                  defaultValue={line.lineId}
                  // ref={register}
                />
                <input
                  type="hidden"
                  name={`details[${index}].itemId`}
                  defaultValue={line.itemId}
                  // ref={register}
                />
                {line.lineId}
              </th>
              <td>
                <InputTableSelect
                  name={`details[${index}].inventory`}
                  defaultValue={line.inventory}
                  options={inventories}
                  onChange={handleItemSelect}
                  valueOption="value"
                  labelOption="label"
                />
              </td>
              <td>
                <InputTableTextArea
                  name={`details[${index}].description`}
                  defaultValue={line.description}
                />
              </td>
              <td>
                <InputTableText
                  name={`details[${index}].uom`}
                  defaultValue={line.uom}
                />
              </td>
              <td>
                <InputTableNumber
                  name={`details[${index}].qty`}
                  defaultValue={line.qty}
                  decimalScale="3"
                />
              </td>
              <td>
                <button
                  onClick={() => remove(index)}
                  className="btn btn-danger btn-sm"
                >
                  <FontAwesomeIcon icon="trash" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <button
          type="button"
          className="btn btn-sm btn-primary mr-2 shadow-sm"
          onClick={() => addLine()}
        >
          + Add Item
        </button>
      </div>
    </div>
  );
};

export default EditDetails;
