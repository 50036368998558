import React from "react";
import Table from "../common/table";
import DateFormat from "../common/dateFormat";
import numeral from "numeral";

const DetailsTable = ({ lines = [], sortColumn, onSort }) => {
  const columns = [
    {
      path: "item.label",
      label: "Item",
    },
    {
      path: "docNumber",
      label: "Invoice #",
    },
    {
      path: "customer.label",
      label: "Customer",
      className: "w-25",
    },
    {
      path: "docDate",
      label: "Shipping Date",
      content: (line) => <DateFormat value={line?.docDate} />,
    },
    {
      path: "description",
      label: "Description",
      className: "w-25",
      content: (line) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{line.description}</div>
      ),
    },
    {
      path: "currency",
      label: "Currency",
      className: "text-center",
    },
    {
      path: "qty",
      label: "Qty",
      className: "text-center",
      content: (line) => <span>{numeral(line.qty).format("0,0")}</span>,
    },
    {
      path: "price",
      label: "Price",
      className: "text-center",
      content: (line) => <span>{numeral(line.price).format("0,0.0000")}</span>,
    },
    {
      path: "amount",
      label: "Amount",
      className: "text-right",
      content: (line) => <span>{numeral(line.amount).format("0,0.00")}</span>,
    },
  ];
  return (
    <Table
      columns={columns}
      data={lines}
      sortColumn={sortColumn}
      onSort={onSort}
      // totals={totals}
      includeTotals={true}
    />
  );
};

export default DetailsTable;
