import http from "./httpService";
import { loginWithJwt } from "./authService";

const apiEndpoint = "/callback";
const companyInfo = "companyInfo";

export async function connectCompany(token) {
  const { data: jwt } = await http.post(apiEndpoint, token);
  // console.log("Jwt", jwt);
  loginWithJwt(jwt);
}

export function setCompanyInfo(db) {
  localStorage.setItem(companyInfo, db);
}

export function disconnect() {
  localStorage.removeItem(companyInfo);
}

export function getCompanyInfo() {
  return localStorage.getItem(companyInfo);
}

const connect = { connectCompany, getCompanyInfo, setCompanyInfo };
export default connect;
