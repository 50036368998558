import React from "react";
import Panel from "../common/panel";
import Thumbnail from "../common/images/Thumbnail";
import numeral from "numeral";

const ItemDetailsCard = ({ item, inStock = 0, onThumbnailSave = () => {} }) => {
  const { data, thumbnail } = item;
  const metadata = data.metadata || null;
  console.log(thumbnail);
  const qty = numeral(inStock).format("0,0");
  const slabsmith = item.slabsmith ? item.slabsmith : null;
  return (
    <div>
      <Panel>
        <div className="row">
          <div className="col-12 col-md-4">
            {/* <div style={{ width: "175px" }}> */}
            <Thumbnail
              // allowUpload={true}
              name={data.name}
              thumbnail={thumbnail}
              classNames="img-fluid border"
              showUploadButton={false}
              onSaveImage={onThumbnailSave}
            />
            {/* </div> */}
          </div>
          <div className="col-12 col-md-8 h-100">
            <div className="card-title h5 d-flex">
              <span>QTY:</span>
              <span className="text-primary ml-2">{qty}</span>
              {/* <span>{item.inStock}</span> */}
            </div>
            <hr />
            <div className="row">
              <div className="col">
                <div className="py-1 card-text">
                  {data.purchaseDescription || data.description}
                </div>
                {/* {slabsmith && 
                <div className="py-1 card-text">
                  
                </div>
                } */}
              </div>
              <div className="col">
                {metadata &&
                  metadata.map((item) => (
                    <div key={item.key} className="mb-2">
                      <strong>{item.key}: </strong>
                      {item.value}
                    </div>
                  ))}
              </div>
            </div>
            <div className="align-text-bottom">Files</div>
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default ItemDetailsCard;
