import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const InputDate = ({ label, name, ...props }) => {
  const { control, errors } = useFormContext();
  const error = errors[name] ? errors[name].message : null;

  return (
    <div className="form-group">
      {label ? (
        <label
          htmlFor={name}
          className="form-label form-label-sm font-weight-bold"
          style={{ marginBottom: "0px", marginRight: "10px" }}
        >
          {label}
        </label>
      ) : null}
      <div>
        <Controller
          name={name}
          control={control}
          render={({ onChange, onBlur, value }) => (
            <DatePicker
              className="form-control"
              dateFormat="yyyy-MM-dd"
              selected={Date.parse(value)}
              onChange={onChange}
              onBlur={onBlur}
              {...props}
            />
          )}
        />
      </div>

      <small className="text-danger">{error}</small>
    </div>
  );
};

export default InputDate;
