import React from "react";
import numeral from "numeral";
import { DateTime } from "luxon";
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
} from "recharts";
import { setPeriodsArray } from "../../utils/timePeriods";

const ChartTrend = (props) => {
  const { lines, fromDate, toDate, period } = props;

  const periods = setPeriodsArray(fromDate, toDate, period);

  // const totalDataQty = (array) => {
  //   return array.reduce((sum, i) => {
  //     return sum + numeral(i.data.qty).value();
  //   }, 0);
  // };

  // for (const week of lines) {
  // const filtered = lines.filter(
  //   (j) =>
  //     j.data.txnDate &&
  //     DateTime.fromISO(j.data.txnDate).startOf(period).toISODate() ===
  //       week.startDate
  // );
  // const completeJobs = filtered.filter(
  //   (job) =>
  //     job.data.status.label === "Complete" ||
  //     job.data.status.label.toLowerCase().includes("ready")
  // );
  // const wastedJobs = filtered.filter(
  //   (job) => job.data.status && job.data.status.label === "Waste"
  // );
  // const rescheduledJobs = filtered.filter(
  //   (job) => job.data.status && job.data.status.label === "Rescheduled"
  // );
  // week.produced = totalDataQty(filtered);
  // week.products = filtered.length;
  // week.produced = totalDataQty(completeJobs);
  // week.wasted = totalDataQty(wastedJobs);
  // week.rescheduled = totalDataQty(rescheduledJobs);
  // }
  // const mapped = jobs.map( job => ({
  //     name:
  // }));

  return (
    <div className="card pb-3 mb-2 shadow-sm" style={{ height: 250 }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={lines}
          margin={{
            top: 20,
            right: 20,
            left: 10,
            bottom: 10,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis yAxisId="left" />
          <YAxis yAxisId="right" orientation="right" />
          <Tooltip />
          <Legend />
          <Line type="monotone" yAxisId="left" dataKey="qty" stroke="#76a8dd" />
          {/* <Line
            type="monotone"
            yAxisId="left"
            dataKey="produced"
            stroke="#82ca9d"
          /> */}
          {/* <Line
            type="monotone"
            yAxisId="right"
            dataKey="wasted"
            stroke="#dd7876"
          /> */}
          <Line
            type="monotone"
            yAxisId="right"
            dataKey="products"
            stroke="#dd7876"
            // stroke="#e9c46a"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ChartTrend;
