import React, { Component } from "react";
// import { Link } from "react-router-dom";
import {
  // CellCenter,
  // CellIndent,
  CellCurrency,
  // CellPercent,
} from "../common/tableCellFormats";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "../common/table";
// import { DateTime } from "luxon";

class QboImportInvoiceTable extends Component {
  checkmark = (
    <div className="text-center text-success">
      <FontAwesomeIcon icon="check-circle" />
    </div>
  );

  tableColumns = [
    {
      path: "data.txnNumber",
      label: "Number",
    },
    {
      path: "data.txnDate",
      label: "Date",
    },

    {
      path: "data.vendor.label",
      label: "Vendor",
      content: (entity) => entity.data.vendor && entity.data.vendor.label,
    },
    {
      path: "totals.total",
      label: "Amount",
      className: "text-center",
      content: (entity) =>
        entity.data.totals ? (
          <CellCurrency value={entity.data.totals.total || 0} />
        ) : (
          "-"
        ),
    },
    {
      key: "lastUpdated",
      label: "Last Updated",
      className: "text-center",
      content: (entity) =>
        entity.qbo.MetaData && entity.qbo.MetaData.LastUpdatedTime,
      // {
      //   entity.MetaData &&
      //     DateTime.fromISO(entity.MetaData.LastUpdatedTime).toFormat("F");
      // },
    },
    {
      key: "import",
      label: "Import",
      className: "text-center",
      content: (entity) =>
        entity.isImported ? (
          <FontAwesomeIcon icon="check-circle" />
        ) : (
          <button
            onClick={() => this.props.onSave(entity)}
            className="btn btn-outline-primary btn-sm"
            disabled={entity.isImported ? true : false}
          >
            <FontAwesomeIcon icon="file-download" />
          </button>
        ),
    },
    {
      key: "delete",
      content: (item) => (
        <button
          onClick={() => this.props.onDelete(item)}
          className="btn btn-outline-danger btn-sm"
          data-toggle="tooltip"
          data-placement="top"
          title="Remove from the update list"
        >
          <FontAwesomeIcon icon="trash" />
        </button>
      ),
    },
  ];

  simpleListColumns = [
    {
      key: "qboId",
      label: "Qbo Id",
      content: (item) => <div className="text-center">{item.Id}</div>,
    },
    {
      path: "DocNumber",
      label: "Number",
    },
    {
      path: "TxnDate",
      label: "Date",
    },

    {
      path: "VendorRef.name",
      label: "Vendor",
      content: (entity) =>
        entity.VendorRef ? <div>{entity.VendorRef.name}</div> : "",
    },
    {
      path: "TotalAmt",
      label: "Amount",
      className: "text-center",
      content: (entity) =>
        entity.TotalAmt ? <CellCurrency value={entity.TotalAmt || 0} /> : "-",
    },
    {
      key: "lastUpdated",
      label: "Last Updated",
      className: "text-center",
      content: (entity) => entity.MetaData && entity.MetaData.LastUpdatedTime,
    },
    {
      key: "delete",
      content: (item) => (
        <button
          onClick={() => this.props.onDelete(item)}
          className="btn btn-outline-danger btn-sm"
          data-toggle="tooltip"
          data-placement="top"
          title="Remove from the update list"
        >
          <FontAwesomeIcon icon="trash" />
        </button>
      ),
    },
  ];

  render() {
    const { items, onSort, onSave, onDelete, sortColumn, isList } = this.props;
    const columns = isList ? this.simpleListColumns : this.tableColumns;

    return (
      <Table
        columns={columns}
        data={items}
        sortColumn={sortColumn}
        onSort={onSort}
        onSave={onSave}
        onDelete={onDelete}
      />
    );
  }
}

export default QboImportInvoiceTable;
