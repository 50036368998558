import React, { useEffect, useState, useMemo } from "react";
import withLoading from "../common/withLoading";
import DatePicker from "react-datepicker";
// import PanelTabs from "../common/PanelTabs";
import ConsignmentShippedTable from "./ConsignmentShippedTable";
import ConsignmentShippedDetailsTable from "./ConsignmentShippedDetailsTable";
// import { getStorages } from "../../services/entityService";
import { getConsignmentShipping } from "../../services/reportService";
import { toast } from "react-toastify";
import numeral from "numeral";

const Consignment = (props) => {
  const { loadingOn, loadingOff } = props;
  const [items, setItems] = useState([]);
  const [showDetails, setShowDetails] = useState();
  const [details, setDetails] = useState([]);
  const [fromDate, setFromDate] = useState(
    new Date(new Date() - 30 * 24 * 60 * 60 * 1000).toISOString()
  );
  const [toDate, setToDate] = useState(new Date().toISOString());
  const [sortColumn, setSortColumn] = useState({ path: "name", order: "asc" });

  useEffect(() => {
    const populateItems = async () => {
      loadingOn();
      const query = `?fromDate=${fromDate}&toDate=${toDate}`;
      try {
        const { data } = await getConsignmentShipping(query);
        setItems(data);
        loadingOff();
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          toast.error(ex.response.data);
        }
        loadingOff();
      }
    };

    populateItems();

    return () => {
      setItems([]);
    };
  }, [fromDate, toDate, loadingOn, loadingOff]);

  const totals = useMemo(() => {
    const qty = items.reduce((sum, i) => {
      return sum + numeral(i.qty).value();
    }, 0);
    const amount = items.reduce((sum, i) => {
      return sum + numeral(i.amount).value();
    }, 0);
    return { qty, amount };
  }, [items]);

  const handleShowDetails = (selected) => {
    setShowDetails(selected._id);
    setDetails(selected?.items);
    console.log("details :>> ", selected.items);
  };

  const handleHideDetails = (selected) => {
    setShowDetails();
    setDetails([]);
  };

  if (showDetails)
    return (
      <div className="container-fluid px-5 mt-3">
        <div className="d-flex pt-2 pb-1 mb-2 border-bottom">
          <h2 className="text-uppercase">{`Transactions for item ${showDetails}`}</h2>
        </div>
        <div className="d-flex align-items-baseline">
          <h5 className="clickable" onClick={handleHideDetails}>
            {`< Go back to summary`}
          </h5>
        </div>
        <div className="d-flex py-2">
          <ConsignmentShippedDetailsTable
            lines={details}
            sortColumn={sortColumn}
            onSort={(sortColumn) => setSortColumn(sortColumn)}
            onShowDetails={handleShowDetails}
          />
        </div>
      </div>
    );

  return (
    <div className="container-fluid px-5 mt-3">
      <div className="d-flex pt-2 pb-1 mb-2 border-bottom">
        <h2 className="text-uppercase">Consignment Shipments</h2>
      </div>
      {/* <div className="mb-1 pb-2">
        <PanelTabs
          tabs={storages}
          selected={selectedStorage}
          onSelectTab={(selected) => setSelectedStorage(selected)}
        />
      </div> */}
      <div className="d-flex align-items-baseline">
        <h5 className="px-1">PERIOD: from</h5>
        <div className="form-group col-2">
          <DatePicker
            className="form-control"
            dateFormat="yyyy-MM-dd"
            selected={new Date(fromDate)}
            onChange={(value) => {
              setFromDate(value.toISOString());
            }}
          />
        </div>
        <h5 className="px-1">to</h5>
        <div className="form-group col-2">
          <DatePicker
            className="form-control"
            dateFormat="yyyy-MM-dd"
            selected={new Date(toDate)}
            onChange={(value) => {
              setToDate(value.toISOString());
            }}
          />
        </div>
        {/* <h5>Currency: {selectedStorage?.currency ?? "CAD"} </h5> */}
      </div>
      <div className="d-flex py-2">
        <ConsignmentShippedTable
          items={items}
          sortColumn={sortColumn}
          onSort={(sortColumn) => setSortColumn(sortColumn)}
          onShowDetails={handleShowDetails}
          totals={totals}
        />
      </div>
    </div>
  );
};

export default withLoading(Consignment);
