import React, { Component } from "react";

class TableTotals extends Component {
  renderCell = (totals, column) => {
    if (column.total) return column.total(totals);

    return "";
  };

  createKey = item => {
    return "Total_" + (item.path || item.key);
  };

  render() {
    const { columns, totals } = this.props;

    return (
      <tfoot>
        <tr>
          {columns.map(column => (
            <td
              className="font-weight-bolder"
              key={this.createKey(column)}
              //   onClick={() => this.raiseSort(column.path)}
            >
              {this.renderCell(totals, column)}
            </td>
          ))}
        </tr>
      </tfoot>
    );
  }
}

export default TableTotals;
