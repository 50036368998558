import { StyleSheet } from "@react-pdf/renderer";

const marginRight = 0;
const blueColor = "#284680";
const headerBackgroundColor = "#D4DAE6";

export const docPrintStyles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    display: "flex",
    paddingLeft: "24",
    paddingRight: "24",
    paddingTop: "10",
    paddingBottom: "10",
    fontSize: 10,
  },

  rowFlex: {
    // display: "flex",
    flexDirection: "row",
    // marginLeft: -24,
    flexWrap: "nowrap",
    justifyContent: "space-between",
  },

  rowFlexEnd: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },

  rowFlexCenter: {
    flexDirection: "row",
    justifyContent: "center",
  },

  col: {
    flexDirection: "column",
  },
  col2: {
    width: "50%",
    flexDirection: "column",
  },
  col3: {
    width: "33%",
    flexDirection: "column",
  },
  col4: {
    width: "25%",
    flexDirection: "column",
  },

  flexCol: {
    flexDirection: "column",
  },

  companyInfo: {
    display: "flex",
    width: "40%",
    marginBottom: "15",
    marginTop: 24,
  },
  companyName: {
    fontFamily: "Helvetica-Bold",
    fontSize: 11,
    marginBottom: 6,
  },
  logo: {
    marginTop: "20",
    marginRight: "0",
    maxHeight: "75",
    maxWidth: "150px",
  },

  companyAndLogoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  title: {
    fontSize: 24,
    fontWeight: "bold",
    fontFamily: "Helvetica-Bold",
    color: blueColor,
    paddingTop: 6,
    paddingBottom: 6,
    marginBottom: 10,
    borderTop: 1,
    borderBottom: 1,
    borderColor: "#284680",
  },

  h1: {
    fontFamily: "Helvetica-Bold",
    color: blueColor,
    fontSize: 16,
    marginBottom: 3,
  },
  h2: {
    fontFamily: "Helvetica-Bold",
    color: blueColor,
    fontSize: 14,
    marginBottom: 3,
  },
  h3: {
    fontFamily: "Helvetica-Bold",
    color: blueColor,
    fontSize: 11,
    marginBottom: 3,
  },

  invoiceToContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  invoiceTo: {
    display: "flex",
    width: "3in",
  },

  invoiceToBold: {
    fontFamily: "Helvetica-Bold",
    color: blueColor,
    fontSize: 11,
    marginBottom: 3,
  },

  invoiceToDateArea: {
    display: "flex",
    flexDirection: "row",
    // width: "30%",
  },
  invoiceToDateLabels: {
    fontFamily: "Helvetica-Bold",
    display: "flex",
    flexDirection: "column",
    marginRight: "5",
  },
  invoiceToDateLabel: {
    alignSelf: "flex-end",
  },
  invoiceToDateValues: {
    display: "flex",
    flexDirection: "column",
  },
  horizontalHeaderLine: {
    borderTop: 1,
    borderColor: "#284680",
    marginTop: "10px",
    marginBottom: "10px",
    marginRight: marginRight,
  },
  poInfo: {
    marginBottom: "0",
  },

  poInfoBold: {
    fontFamily: "Helvetica-Bold",
  },

  content: {
    flexDirection: "column",
    fontSize: 10,
  },
  contentHeader: {
    color: blueColor,
    backgroundColor: headerBackgroundColor,
    flexDirection: "row",
    justifyContent: "space-between",
    // marginTop: "25",
    // marginBottom: "25",
    // marginRight: "50",
  },
  columnDescriptionItem: {
    padding: "3px",
    width: "15%",
  },

  columnDescription: {
    padding: "3px",
    width: "40%",
  },

  columnUOM: {
    padding: "3px",
    width: "8%",
    textAlign: "center",
  },

  columnQuantity: {
    padding: "3px",
    width: "12%",
    textAlign: "right",
  },
  columnRate: {
    padding: "3px",
    width: "10%",
    textAlign: "right",
  },
  columnAmount: {
    padding: "3px",
    width: "15%",
    textAlign: "right",
  },

  contentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginRight: marginRight,
  },

  contentPageNo: {
    fontFamily: "Helvetica-Bold",
  },

  footerHorizontalLine: {
    borderTop: 1,
    borderStyle: "dotted",
    borderColor: "#284680",
    marginTop: "25",
    marginBottom: "10",
    marginRight: marginRight,
  },

  footerArea: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  footerThanksForBusinessArea: {
    display: "flex",
    flexDirection: "column",
    fontSize: "10",
  },

  footerSubTotalArea: {
    flexDirection: "row",
    width: "40%",
    justifyContent: "space-around",
  },

  footerSubTotalLabels: {
    display: "flex",
    flexDirection: "column",
    // marginRight: "100",
  },
  footerSubTotalLabel: {
    alignSelf: "flex-start",
    fontFamily: "Helvetica-Bold",
    marginBottom: 6,
  },
  footerSubTotalValue: {
    alignSelf: "flex-end",
    marginBottom: 6,
  },
  footerBalanceDue: {
    fontSize: "16",
    fontFamily: "Helvetica-Bold",
  },

  footer: {
    display: "flex",
    fontSize: 8,
    flexDirection: "row",
    width: "100%",
    paddingTop: "5px",
    justifyContent: "space-between",
    position: "absolute",
    paddingBottom: "10px",
    left: 24,
    bottom: 12,
    borderTop: 0.5,
  },
});
