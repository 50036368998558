import React, { Component } from "react";
import withLoading from "./../common/withLoading";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import numeral from "numeral";
import SelectDateBox from "./../common/SelectDateBox";
import RowGroup from "./../common/RowGroup";
import DocumentListTable from "./DocumentListTable";
import Chart from "./ChartProduction";
import ProductionTable from "./ProductionTable";
// import { getDocumentsQuery } from "../../services/documentService";
import { getRecordsQuery } from "../../services/recordService";
import { setPeriodsArray, periodList } from "../../utils/timePeriods";
import _ from "lodash";

class ProductionByProcess extends Component {
  state = {
    docs: [],
    statuses: [],
    isSelectedDocs: false,
    selectedDocs: [],
    selectedPeriod: { value: "week", label: "Weekly" },
    fromDate: DateTime.local().startOf("month").minus({ months: 3 }),
    toDate: DateTime.local().endOf("month").plus({ months: 1 }),
    sortColumn: { path: "endDate", order: "asc" },
  };

  populateDocs = async (fromDate, toDate) => {
    let query = "?docType=ProductionReceipt";
    if (fromDate && toDate)
      query = query + `&fromDate=${fromDate.toISO()}&toDate=${toDate.toISO()}`;
    else if (fromDate) query = query + `&fromDate=${fromDate.toISO()}`;
    else if (toDate) query = query + `&toDate=${toDate.toISO()}`;
    else query = "";

    try {
      const { data: docs } = await getRecordsQuery(query);
      this.setState({ docs });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  async componentDidMount() {
    await this.populateDocs(this.state.fromDate, this.state.toDate);
    this.props.loadingOff();
  }

  handleSort = (sortColumn) => {
    this.setState({ sortColumn });
  };

  handleClick = (line, col = "") => {
    let docs = line.docList;
    if (col) {
      docs = line.docList.filter((j) => j.status === col);
    }
    if (docs.length === 0) return;
    this.setState({
      isSelectedDocs: true,
      selectedDocs: docs,
      sortColumn: { path: "docDate", order: "asc" },
    });
  };

  handleBackToSummary = () => {
    this.setState({
      isSelectedDocs: false,
      selectedDocs: [],
      sortColumn: { path: "endDate", order: "asc" },
    });
  };

  handlePeriodSelect = (period) => {
    this.setState({
      selectedPeriod: period,
    });
  };

  handleDateChange = async () => {
    this.props.loadingOn();
    await this.populateDocs(this.state.fromDate, this.state.toDate);
    this.props.loadingOff();
  };

  getSummaryData = () => {
    const {
      docs: allDocs,
      selectedPeriod,
      fromDate,
      toDate,
      sortColumn,
    } = this.state;

    const periods = setPeriodsArray(fromDate, toDate, selectedPeriod.value);

    const docs = allDocs.map((doc) => {
      const dt = DateTime.fromISO(doc.doc.txnDate).endOf(selectedPeriod.value);
      const endDate = dt.toISODate();
      return {
        value: doc._id,
        label: doc.inventory.label,
        inventory: doc.inventory,
        ref: doc.doc,
        docDate: doc.doc.txnDate,
        endDate: endDate,
        qty: numeral(doc.qty).value(),
      };
    });

    const lines = [];

    periods.forEach((period) => {
      const filtered = docs.filter((doc) => doc.endDate === period.endDate);
      let uniqueItems = _.uniqBy(filtered, "label");
      //   if (filtered.length !== 0) {
      let line = {
        name: period.name,
        startDate: period.startDate,
        endDate: period.endDate,
        docList: [],
        products: uniqueItems.length,
        docs: 0,
        qty: 0,
      };
      line.docs = filtered.length;
      filtered.forEach((doc) => {
        line.qty = line.qty + doc.qty;
        line.docList.push(doc);
      });
      lines.push(line);
      //   }
    });

    const totals = {
      docs: 0,
      products: 0,
      qty: 0,
    };

    lines.forEach((line) => {
      totals.products = totals.products + line.products;
      totals.qty = totals.qty + line.qty;
      totals.docs = totals.docs + line.docs;
    });

    const sorted = _.orderBy(lines, [sortColumn.path], [sortColumn.order]);
    const cols = [];
    return { data: sorted, totals, cols };
  };

  render() {
    const {
      //   docs,
      fromDate,
      toDate,
      selectedPeriod,
      sortColumn,
      isSelectedDocs,
      selectedDocs,
    } = this.state;
    const { totals, data: lines, cols } = this.getSummaryData();

    if (isSelectedDocs)
      return (
        <main className="container-fluid px-5">
          <div className="d-flex py-2">
            <h2 className="text-uppercase">Document List</h2>
          </div>
          <div className="btn btn-link" onClick={this.handleBackToSummary}>
            Back to Summary
          </div>
          <DocumentListTable
            items={selectedDocs}
            sortColumn={sortColumn}
            onSort={this.handleSort}
          />
        </main>
      );

    return (
      <main className="container-fluid px-5">
        <div className="d-flex py-2">
          <h2 className="text-uppercase">Production Summary</h2>
        </div>
        <div className="d-none d-print-block">{`Period: ${fromDate.toISODate()} - ${toDate.toISODate()}`}</div>
        <SelectDateBox
          fromDate={fromDate}
          toDate={toDate}
          setFromDate={(value) => this.setState({ fromDate: value })}
          setToDate={(value) => this.setState({ toDate: value })}
          handleDateChange={this.handleDateChange}
        />
        <div className="d-flex d-print-none mb-2">
          <RowGroup
            items={periodList}
            selectedItem={selectedPeriod}
            onItemSelect={this.handlePeriodSelect}
          />
        </div>
        <Chart
          lines={lines}
          fromDate={fromDate}
          toDate={toDate}
          period={selectedPeriod.value}
        />
        <ProductionTable
          lines={lines}
          innerCols={cols}
          sortColumn={sortColumn}
          onSort={this.handleSort}
          onClick={this.handleClick}
          totals={totals}
        />
      </main>
    );
  }
}

export default withLoading(ProductionByProcess);
