import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faAngleDown,
  faAngleRight,
  faAngleUp,
  faArrowLeft,
  faArrowRight,
  faBan,
  faBars,
  faCalendarCheck,
  faCalendarPlus,
  faCalendarTimes,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faClock,
  faCog,
  faEye,
  faEyeSlash,
  faEdit,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFileExport,
  faFileImport,
  faFileUpload,
  faFileDownload,
  faIgloo,
  faHandPaper,
  faPaperPlane,
  faPlay,
  faPause,
  faPlus,
  faPlusSquare,
  faPrint,
  faSave,
  faSearch,
  faSortDown,
  faSortUp,
  faSpinner,
  faSync,
  faTools,
  faTrash,
  faUser,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faAngleDown,
  faAngleRight,
  faAngleUp,
  faArrowLeft,
  faArrowRight,
  faBan,
  faBars,
  faCalendarCheck,
  faCalendarPlus,
  faCalendarTimes,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faClock,
  faCog,
  faEye,
  faEyeSlash,
  faEdit,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFileExport,
  faFileImport,
  faFileUpload,
  faFileDownload,
  faIgloo,
  faHandPaper,
  faPaperPlane,
  faPlay,
  faPause,
  faPlus,
  faPlusSquare,
  faPrint,
  faSave,
  faSearch,
  faSortDown,
  faSortUp,
  faSpinner,
  faSync,
  faTools,
  faTrash,
  faUser,
  faWindowClose
);
