import React, { useState } from "react";
import Table from "../common/table";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import _ from "lodash";

const PurchaseOrdersList = (props) => {
  const {
    title,
    items,
    onHeaderClick,
    headerToolTip,
    columns,
    sorting = { path: "title", order: "asc" },
    controls = null,
  } = props;

  const [sortColumn, setSortColumn] = useState(sorting);
  const [currentPage, setPage] = useState(1);
  const pageSize = 30;

  const handleSort = (sortColumn) => {
    setSortColumn(sortColumn);
  };

  const totals = { amount: 0, items: items.length };
  // totals.amount = items.reduce((sum, i) => {
  //   return sum + i.data.amount;
  // }, 0);

  const sorted = _.orderBy(items, [sortColumn.path], [sortColumn.order]);

  const data = paginate(sorted, currentPage, pageSize);

  return (
    <div className="my-2">
      <div className="d-flex justify-content-between pt-2">
        <h5
          className="text-uppercase clickable"
          data-toggle="tooltip"
          data-placement="top"
          title={headerToolTip}
          onClick={onHeaderClick}
        >{`${title} (${totals.items})`}</h5>
        <div>{controls}</div>
      </div>
      <div className="border rounded bg-white p-3 shadow-sm">
        {totals.items < 1 ? (
          "No purchase orders found"
        ) : (
          <Table
            columns={columns}
            data={data}
            sortColumn={sortColumn}
            onSort={handleSort}
            totals={totals}
          />
        )}
        <Pagination
          itemsCount={totals.items}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={(pageNumber) => setPage(pageNumber)}
        />
      </div>
    </div>
  );
};

export default PurchaseOrdersList;
