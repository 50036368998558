import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers";
// import { toast } from "react-toastify";
import Panel from "../common/panel";
import Controls from "./Controls";
import { RowFourColumns, RowOneLeftTwoRight } from "../common/formLayout";
import InputText from "../common/inputText";
import InputTextArea from "./../common/inputTextArea";
import InputCheckbox from "./../common/inputCheckbox";
import InputSelect from "../common/inputSelect";
import { getEntities } from "../../services/entityService";

const Edit = (props) => {
  const { data, onSave, inventoryId } = props;
  // const { data: defaultValues } = getDefaultValues();
  const defaultValues = {
    name: "",
    typeRef: null,
    reservalions: [],
    balances: [],
    current: [],
    isInactive: false,
    item: null,
  };

  const isNew = inventoryId === "new" ? true : false;

  const methods = useForm({ defaultValues, mode: "onBlur" });
  const { reset, setValue, register } = methods;

  const types = React.useMemo(
    () => [
      { value: "010", label: "Raw Materials" },
      { value: "020", label: "Semi-finished" },
      { value: "030", label: "Parts" },
      { value: "040", label: "Finished Products" },
      { value: "050", label: "Resale Products" },
      { value: "060", label: "Packaging" },
      { value: "070", label: "Equipment" },
      { value: "080", label: "Tools" },
    ],
    []
  );

  const [items, setItems] = useState([]);

  useEffect(() => {
    async function populateItems() {
      try {
        const { data } = await getEntities("Item");
        const items = data.map((item) => ({
          value: item._id,
          label: item.title,
          description: (item.data && item.data.description) || "",
          uom: (item.data && item.data.uom) || "EACH",
        }));
        setItems(items);
      } catch (ex) {
        if (ex.response && ex.response.status === 404)
          this.props.history.replace("/not-found");
      }
    }

    if (inventoryId === "new") populateItems();
  }, [inventoryId]);

  useEffect(() => {
    const inventory = { ...data };
    // inventory.typeRef = setTypeRef(inventory.type);
    inventory.typeRef = types.find((t) => t.label === inventory.type);
    reset(inventory);
  }, [data, types, reset]);

  const handleItemSelect = (name, selected) => {
    setValue(selected.name, { value: name.value, label: name.label });
    setValue("name", name.label);
    setValue("uom", name.uom);
    setValue("description", name.description);
  };

  const handleTypeSelect = (name, selected) => {
    setValue(selected.name, { value: name.value, label: name.label });
    setValue("type", name.label);
  };

  const onSubmit = async (values) => {
    await onSave(values);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Panel>
          <input type="hidden" name="type" ref={register} />
          <RowFourColumns
            colOne={
              <InputSelect
                name="typeRef"
                label="Type"
                options={types}
                valueOption="value"
                labelOption="label"
                onChange={handleTypeSelect}
              />
            }
            colTwo={
              isNew && (
                <InputSelect
                  name="item"
                  label="Item"
                  options={items}
                  valueOption="value"
                  labelOption="label"
                  onChange={handleItemSelect}
                />
              )
            }
          />
          <RowOneLeftTwoRight
            colOne={<InputText name="name" label="Name" />}
            colTwo={<InputText name="uom" label="Units of Measure" />}
          />
          <RowOneLeftTwoRight
            colOne={<InputTextArea name="description" label="Description" />}
          />
          {!isNew && (
            <RowOneLeftTwoRight
              colOne={<InputCheckbox name="isInactive" label="Inactive" />}
            />
          )}
        </Panel>
        <Controls isEdit={true} {...props} />
      </form>
    </FormProvider>
  );
};

export default Edit;
