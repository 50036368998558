import React from "react";

const ProgressBar = ({ percentage }) => {
  return (
    <div className="progress" style={{ height: "30px" }}>
      <div
        className="progress-bar progress-bar-striped progress-bar-animated bg-info"
        role="progressbar"
        aria-valuenow={percentage}
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ width: `${percentage}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
