import React from "react";
import { useFormContext } from "react-hook-form";
import InputText from "./inputText";

const MetaDataEdit = (props) => {
  const { metaKeys } = props;
  const { register } = useFormContext();
  const secondColumnStart = Math.floor(metaKeys.length / 2);

  if (metaKeys.length < 1) return null;

  return (
    <div>
      <h5 className="text-uppercase border-bottom">Additional Information</h5>
      <div className="row">
        <div className="col-md-6">
          {metaKeys.slice(0, secondColumnStart).map((item, index) => (
            <div key={item.key}>
              <input
                type="hidden"
                name={`metadata[${index}].key`}
                defaultValue={item.key}
                ref={register}
              />
              <InputText name={`metadata[${index}].value`} label={item.key} />
            </div>
          ))}
        </div>
        <div className="col-md-6">
          {metaKeys.slice(secondColumnStart).map((item, index) => (
            <div key={item.key}>
              <input
                type="hidden"
                name={`metadata[${index + secondColumnStart}].key`}
                defaultValue={item.key}
                ref={register}
              />
              <InputText
                name={`metadata[${index + secondColumnStart}].value`}
                label={item.key}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MetaDataEdit;
