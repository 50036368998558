import React from "react";
import Table from "./../common/table";
import DateTimeFormat from "./../common/DateTimeFormat";

const MessagesTable = (props) => {
  const { messages, sortColumn, onSort } = props;
  const columns = [
    {
      path: "timeStamp",
      label: "Time Stamp",
      className: "w-25",
      content: (item) => <DateTimeFormat value={item.timeStamp} />,
    },
    {
      path: "user.label",
      label: "User",
      className: "w-25 text-center",
    },

    {
      path: "message",
      label: "Message",
      className: "w-50",
      content: (item) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{item.message}</div>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      data={messages}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default MessagesTable;
