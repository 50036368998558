import React from "react";
import MessagesTable from "./MessagesTable";

const Messages = ({ messages = [] }) => {
  // useEffect(() => {}, [messages]);
  return (
    <div className="py-2 border-top">
      <h5 className="text-uppercase">Logs</h5>
      <MessagesTable messages={messages} />
    </div>
  );
};

export default Messages;
