import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PagePrint = () => {
  return (
    <button
      className="btn btn-sm border shadow-sm"
      data-toggle="tooltip"
      data-placement="top"
      title="Print this page"
      onClick={() => window.print()}
    >
      <FontAwesomeIcon icon="print" />
    </button>
  );
};

export default PagePrint;
