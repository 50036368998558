import React from "react";
import Loader from "./loader";

function withFormMode(Component) {
  return class WithFormMode extends React.Component {
    state = { loading: false };

    loadingOff = () => this.setState({ loading: false });
    loadingOn = () => this.setState({ loading: true });

    render() {
      return (
        <div className="container-xl">
          <Component
            {...this.props}
            loadingOn={this.loadingOn}
            loadingOff={this.loadingOff}
          />
          {this.state.loading && <Loader />}
        </div>
      );
    }
  };
}

export default withFormMode;
