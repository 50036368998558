import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ProtectedRoute from "./components/common/protectedRoute";
import CompanyConnect from "./components/auth/connect";
import NavBar from "./components/navigation-bar/navBar";
import Auth from "./components/auth/auth";
import Logout from "./components/auth/logout";
import Records from "./components/item-card/Records";
import Documents from "./components/documents/Documents";
import Document from "./components/documents/Document";
import Inventories from "./components/inventories/Inventories";
import InventoriesOld from "./components/inventories/Inventories-old";
import Inventory from "./components/inventories/Inventory";
import ItemInventoryList from "./components/inventories/ItemInventoryList";
import Items from "./components/items/Items";
import ImportItems from "./components/tools/ImportItemsXLS";
import ImportPurchaseOrders from "./components/tools/ImportPurchaseOrders";
import Item from "./components/items/Item";
import PoDetails from "./components/po-details/PoDetails";
import PurchaseOrdersDashboard from "./components/purchase-orders/Dashboard";
import PurchaseOrders from "./components/purchase-orders/PurchaseOrders";
import PurchaseOrder from "./components/purchase-orders/PurchaseOrder";
import QboExportLists from "./components/qboExchange/QboExportLists";
import QboImportLists from "./components/qboExchange/QboImportLists";
import QboImportTxns from "./components/qboExchange/QboImportTxns";
import Settings from "./components/settings/Settings";
import Storages from "./components/storages/Storages";
import Storage from "./components/storages/Storage";
import Vendor from "./components/vendors/Vendor";
import Vendors from "./components/vendors/Vendors";
import Tools from "./components/tools/Tools";
import GenProd from "./components/tools/GenProd";
import GenMateriallUsage from "./components/tools/GenMaterialUsage";
import GenInventoryReceipts from "./components/tools/GenInventoryReceipts";
import GenInventoryShipments from "./components/tools/GenSaleShipments";
import Reports from "./components/reports/Reports";
import auth from "./services/authService";
import "./stylesheets/fontawesome";
// import "./App.css";
import "react-toastify/dist/ReactToastify.css";

class App extends React.Component {
  render() {
    const user = auth.getCurrentUser();

    return (
      <div className="App">
        <header>
          <NavBar user={user} />
        </header>
        <main>
          <Switch>
            <Route path="/auth" component={Auth}></Route>
            <Route path="/logout" component={Logout}></Route>
            <Route path="/connect" component={CompanyConnect}></Route>
            {!user && <Redirect to="/auth" />}
            <ProtectedRoute path="/settings" user={user} component={Settings} />
            <ProtectedRoute
              path="/export-qbo-lists"
              user={user}
              component={QboExportLists}
            />
            <ProtectedRoute
              path="/import-qbo-lists"
              user={user}
              component={QboImportLists}
            />
            <ProtectedRoute
              path="/import-qbo-txns"
              user={user}
              component={QboImportTxns}
            />
            <ProtectedRoute
              path="/import-items"
              user={user}
              component={ImportItems}
            />
            <ProtectedRoute
              path="/import-purchase-orders"
              user={user}
              component={ImportPurchaseOrders}
            />
            <ProtectedRoute
              path="/vendors/:id"
              user={user}
              component={Vendor}
            />
            <ProtectedRoute path="/vendors" user={user} component={Vendors} />
            <ProtectedRoute
              path="/storages/:id"
              user={user}
              component={Storage}
            />
            <ProtectedRoute path="/storages" user={user} component={Storages} />
            <ProtectedRoute path="/tools" user={user} component={Tools} />
            <ProtectedRoute path="/items/:id" user={user} component={Item} />
            <ProtectedRoute path="/items" user={user} component={Items} />
            <ProtectedRoute
              path="/generate-production"
              user={user}
              component={GenProd}
            />
            <ProtectedRoute
              path="/generate-material-usage"
              user={user}
              component={GenMateriallUsage}
            />
            <ProtectedRoute
              path="/generate-inventory-receipts"
              user={user}
              component={GenInventoryReceipts}
            />
            <ProtectedRoute
              path="/generate-inventory-shipments"
              user={user}
              component={GenInventoryShipments}
            />
            <ProtectedRoute path="/reports" user={user} component={Reports} />
            <ProtectedRoute
              path="/transactions/:id"
              user={user}
              component={Records}
            />
            <ProtectedRoute
              path="/transactions"
              user={user}
              component={Records}
            />
            <ProtectedRoute
              path="/documents/:id"
              user={user}
              component={Document}
            />
            <ProtectedRoute
              path="/documents"
              user={user}
              component={Documents}
            />
            <ProtectedRoute
              path="/purchase-orders/:id"
              user={user}
              component={PurchaseOrder}
            />
            <ProtectedRoute
              path="/ordered-items"
              user={user}
              component={PoDetails}
            />
            <ProtectedRoute
              path="/purchase-orders"
              user={user}
              component={PurchaseOrders}
            />
            <ProtectedRoute
              path="/purchase-orders-dashboard"
              user={user}
              component={PurchaseOrdersDashboard}
            />
            <ProtectedRoute
              path="/inventory/by-item/:id"
              user={user}
              component={ItemInventoryList}
            />
            <ProtectedRoute
              path="/inventory/:id"
              user={user}
              component={Inventory}
            />
            <ProtectedRoute
              path="/inventory-old"
              user={user}
              component={InventoriesOld}
            />
            <ProtectedRoute
              path="/inventory"
              user={user}
              component={Inventories}
            />
            <Redirect from="/" exact to="/inventory" />
            <Redirect to="/not-found" />
          </Switch>
        </main>
        <footer className="d-print-none">
          <div className="text-center small font-weight-light pt-3">
            &copy; 2022 iours: business tools. In partnership with IBM
            ventureLAB.
          </div>
        </footer>
        <ToastContainer />
      </div>
    );
  }
}

export default App;
