import React, { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { schema } from "../../utils/itemUtils";
// import { toast } from "react-toastify";
import Panel from "../common/panel";
import FormControls from "./FormControls";
import EditParts from "./EditParts";
import MetaDataEdit from "../common/MetaDataEdit";
import { RowOneLeftTwoRight, RowFourColumns } from "../common/formLayout";
import InputText from "../common/inputText";
import InputTextArea from "./../common/inputTextArea";
import InputNumber from "../common/inputNumber";
import InputSelect from "../common/inputSelect";
import InputCheckbox from "./../common/inputCheckbox";
import UomOptionsEdit from "./UomOptionsEdit";
import CostOptionsEdit from "./CostOptionsEdit";
import { getMetaData } from "../../services/metaDataService";
import { getVendors } from "../../services/entityService";

const FormEdit = (props) => {
  const { data, onSave } = props;
  const [vendors, setVendors] = useState([]);
  const defaultValues = {
    name: "",
    type: "Item",
    // description: "",
    purchaseDescription: "",
    preferredVendor: null,
    uom: "",
    uomOptions: [],
    sku: "",
    parts: [],
    cost: "",
    costOptions: [],
    // unitPrice: "",
    // priceOptions: [],
    isInactive: false,
  };

  const methods = useForm({
    defaultValues,
    mode: "onBlur",
    resolver: yupResolver(schema),
  });
  const { reset, watch, setValue, clearErrors } = methods;

  const metaKeys = useMemo(() => {
    return getMetaData("Item");
  }, []);

  const populateVendors = async () => {
    const { data } = await getVendors();
    // console.log("Vendors: ", data);
    const mapped = data.map((s) => {
      return {
        value: s._id,
        label: s.title,
        currency:
          s.data && s.data.currency ? s.data.currency : { value: "CAD" },
        email: s.data && s.data.email ? s.data.email : "",
      };
    });
    setVendors(mapped);
  };

  useEffect(() => {
    populateVendors();
    return () => {
      setVendors([]);
    };
  }, []);

  useEffect(() => {
    const itemData = { ...data };
    // if ( data.costOption)
    // const mappedCostOptions = itemData?.costOptions?.map((line) => ({
    //   ...line,
    //   uomOption: { value: line.uom, label: line.uom },
    // }));
    // itemData.costOptions = mappedCostOptions;
    reset(itemData);
    return () => {};
  }, [data, reset]);

  const isProduction = watch("isProduction");

  const handleSelect = async (name, selected) => {
    setValue(selected.name, { value: name.value, label: name.label });
    clearErrors(selected.name);
  };

  const onSubmit = async (values) => {
    console.log("Cost options on save", values.costOptions);
    console.log("Purchasing on save", values.isPurchasing);

    const data = {
      name: values.name,
      type: values.type,
      // category: values.category,
      // description: values.description,
      purchaseDescription: values.purchaseDescription,
      preferredVendor: values.preferredVendor,
      uom: values.uom,
      uomOptions: values.uomOptions,
      parts: values.parts,
      metadata: values.metadata,
      sku: values.sku,
      cost: values.cost,
      costOptions: values.costOptions,
      // unitPrice: values.unitPrice,
      // priceOptions: values.priceOptions,
      // isSales: values.isSales,
      isProduction: values.isProduction,
      isPurchasing: values.isPurchasing,
      isInactive: values.isInactive,
    };
    await onSave(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Panel>
          <RowOneLeftTwoRight
            colOne={<InputText name="name" label="Name" />}
            colTwo={
              <InputText
                name="sku"
                label="Item code / SKU"
                comment="Helps for automation"
              />
            }
            colThree={<InputText name="type" label="Type" readOnly />}
          />
          <RowFourColumns
            colOne={<InputText name="uom" label="Base unit of measure" />}
          />
          <UomOptionsEdit {...props} />
          <hr />
          {/* <h5>SALES</h5>
          <RowOneLeftTwoRight
            colOne={
              <InputTextArea name="description" label="Sales Description" />
            }
            colTwo={
              <InputNumber
                name="unitPrice"
                label="Unit Price"
                decimalScale="4"
              />
            }
          />
          <hr /> */}
          <h5>PURCHASING</h5>
          <RowOneLeftTwoRight
            colOne={
              <InputTextArea
                name="purchaseDescription"
                label="Purchase Description"
              />
            }
            colTwo={<InputNumber name="cost" label="Cost" decimalScale="4" />}
          />
          <RowOneLeftTwoRight
            colOne={
              <InputSelect
                name="preferredVendor"
                label="Preferres Supplier"
                defaultValue={data?.preferredVendor}
                options={vendors}
                onChange={handleSelect}
                valueOption="value"
                labelOption="label"
              />
            }
          />
          <CostOptionsEdit {...props} />
          {/* <RowFourColumns
            colOne={<InputText name="sku" label="SKU" />}
            colTwo={<InputText name="uom" label="UOM" />}
            colThree={<InputNumber name="cost" label="Cost" decimalScale="4" />}
            colFour={
              <InputNumber
                name="unitPrice"
                label="Unit Price"
                decimalScale="4"
              />
            }
          />
          <hr /> */}
          <hr />
          {isProduction && (
            <>
              <h5>PRODUCTTION</h5>
              <EditParts parts={data.parts} {...props} />
              <hr />
            </>
          )}
          {data.meta ? (
            <div>Meta Card</div>
          ) : (
            <MetaDataEdit metaKeys={metaKeys} />
          )}
          <hr />
          <div className="row">
            {/* <div className="pl-3">
              <InputCheckbox name="isSales" label="Sales" />
            </div> */}
            <div className="pl-3">
              <InputCheckbox name="isPurchasing" label="Purchasing" />
            </div>
            <div className="pl-3">
              <InputCheckbox
                name="isProduction"
                label="Production"
                // readOnly
                // checked
              />
            </div>
            <div className="pl-3">
              <InputCheckbox name="isInactive" label="Is Inactive?" />
            </div>
          </div>
        </Panel>
        <FormControls isEdit={true} {...props} />
      </form>
    </FormProvider>
  );
};

export default FormEdit;
