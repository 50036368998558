import React, { useState } from "react";
import Table from "../common/table";
import numeral from "numeral";
numeral.zeroFormat("-");
numeral.nullFormat("-");

const CostOptions = (props) => {
  const { options = [] } = props;
  console.log("Cost Options", options);
  const [sortColumn, setSortColumn] = useState({ path: "code", order: "asc" });
  const onSort = (sortColumn) => {
    setSortColumn(sortColumn);
  };

  const columns = [
    {
      path: "code",
      label: "Code",
    },
    {
      path: "description",
      label: "Description",
      content: (option) => (
        <>
          <div style={{ whiteSpace: "pre-wrap" }}>{option.description}</div>
          <div className="text-right">
            <small className="text-primary">
              base: {option?.uomOption?.baseUom}
            </small>
          </div>
        </>
      ),
    },
    {
      path: "uom",
      label: "UOM",
      className: "text-center",
      content: (option) => (
        <>
          <div>{option.uomOption?.code}</div>
          <small className="text-primary">
            {numeral(option?.uomOption?.ratioToBase || 0).format("0,0.000")}
          </small>
        </>
      ),
    },
    {
      path: "cost",
      label: "Cost",
      className: "text-right pr-1",
      content: (option) => (
        <>
          <div>{numeral(option.cost).format("$ 0,0.00")}</div>
          <small className="text-primary">
            {numeral(option.cost)
              .divide(option?.uomOption?.ratioToBase || 1)
              .format("$ 0,0.00")}
          </small>
        </>
      ),
    },
    // {
    //   path: "isDefault",
    //   label: "Default",
    //   className: "text-center",
    //   content: (option) =>
    //     option.isDefault && <FontAwesomeIcon icon="check-circle" />,
    // },
  ];
  if (options.length < 1) return <p>No purchase cost options</p>;
  return (
    <Table
      columns={columns}
      data={options}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default CostOptions;
