import React from "react";

const CheckBox = ({ value, onChange, label, ...props }) => {
  return (
    <div className="form-group form-check pt-2">
      <input
        type="checkbox"
        name="checkbox"
        className="form-check-input"
        value={value}
        onChange={(e) => onChange(e.currentTarget.checked)}
        checked={value}
        {...props}
      />
      <label className="form-check-label pt-1 ml-1">{label}</label>
    </div>
  );
};

export default CheckBox;
