import React from "react";
import withLoading from "../common/withLoading";
import numeral from "numeral";
// import { getQboTxns } from "../services/qboData";
import { DateTime } from "luxon";
import SidebarLeft from "./../common/SidebarLeft";
import ListGroup from "./../common/listGroup";
import SelectDateBox from "./../common/SelectDateBox";
// import SelectBox from "./SelectBox";
import Select from "react-select";

import {
  //   getEntities,
  getItems,
  getStorages,
} from "../../services/entityService";
import {
  getDocumentsQuery,
  saveDocument,
} from "../../services/documentService";
import { getInventoriesQuery } from "./../../services/inventoryService";
import { getRecordsQuery } from "./../../services/recordService";

import { toast } from "react-toastify";
import { mapProductionToMaterialUsage } from "../../utils/productionUtils";
import GenMaterialUsageTable from "./GenMaterialUsageTable";

class GenMaterialUsage extends React.Component {
  state = {
    // customers: [],
    storages: [],
    productionItems: [],
    inventories: [],
    records: [],
    productionReceipts: [],
    productionDetails: [],
    verified: [],
    // receipts: [],
    // inventories: [],
    // selectedEntity: null,
    selectedStorage: null,
    // selectedCustomer: null,
    currentPage: 1,
    pageSize: 150,
    // searchQuery: "",
    // searchOptions: [],
    sortColumn: { path: "title", order: "asc" },
    fromDate: DateTime.local().startOf("month"),
    toDate: DateTime.local().endOf("month"),
  };

  populateStorages = async () => {
    try {
      const { data } = await getStorages();
      const storages = data.map((s) => {
        return { value: s._id, label: s.title, qty: 0, code: s.data.code };
      });
      let found = storages.find((s) =>
        s.label.toLowerCase().includes("material")
      );
      let selectedStorage = found ? found : storages[0];
      // const storages = [{ value: "", label: "All storages" }, ...mapped];
      this.setState({ storages, selectedStorage });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  populateInventories = async () => {
    // const query = encodeURI("?type=Finished Products");
    try {
      const { data } = await getInventoriesQuery();
      //   const filtered = data.filter((i) => i.type === "Finished Products");
      const inventories = data.map((i) => {
        const item = {
          value: i._id,
          label: i.name,
          uom: i.uom || "EACH",
          itemId: i.itemId,
          type: i.type,
          description: i.description || i.name,
        };
        return item;
      });
      this.setState({ inventories });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  populateItems = async () => {
    // const query = encodeURI("?type=Finished Products");
    try {
      const { data } = await getItems(`?production=${true}`);
      //   const { data } = await getItems();

      const filtered = data.filter((item) => item.data && item.data.parts);

      const productionItems = filtered.map((i) => ({
        value: i._id,
        label: i.title,
        parts: i.data.parts,
      }));
      this.setState({ productionItems });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  populateRecords = async (query) => {
    try {
      const { data: records } = await getRecordsQuery(query);

      return records;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  populateDocuments = async (query) => {
    query += "&type=ProductionReceipt";
    try {
      const { data: productionReceipts } = await getDocumentsQuery(query);
      return productionReceipts;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };
  populateProductionDetails = async () => {
    const { fromDate, toDate } = this.state;
    const query = `?fromDate=${fromDate.toISO()}&toDate=${toDate.toISO()}`;

    this.props.loadingOn();
    // console.log(selectedCustomer);
    const productionReceipts = await this.populateDocuments(query);
    const records = await this.populateRecords(query);
    const productionDetails = this.mapProductionDetails(
      productionReceipts,
      records
    );

    // this.setState({ productionReceipts, records });
    this.setState({ productionReceipts, records, productionDetails });

    this.props.loadingOff();
  };

  async componentDidMount() {
    await this.populateStorages();
    await this.populateInventories();
    await this.populateItems();
    // await this.populateRecords();
    // await this.populateProductionItems();
    this.props.loadingOff();
  }

  mapProductionDetails = (receipts, records) => {
    let details = [];
    const { productionItems, inventories, selectedStorage } = this.state;
    for (const receipt of receipts) {
      if (receipt.data && receipt.data.refs) {
        // console.log(receipt.data.refs);
        let doc = receipt.data.refs.find(
          (ref) => ref.type === "MaterialsRequisitionForm"
        );
        if (doc) continue;
      }

      let docRecords = records.filter(
        (record) => record.doc.value === receipt._id
      );

      if (docRecords) {
        for (const line of docRecords) {
          let foundInventory = inventories.find(
            (inventory) => inventory.value === line.inventory.value
          );
          if (!foundInventory) continue;

          let foundItem = productionItems.find(
            (item) => item.value === foundInventory.itemId
          );
          if (!foundItem || !foundItem.parts) continue;

          for (const part of foundItem.parts) {
            const foundPart = inventories.find(
              (inventory) => inventory.itemId === part.item.value
            );
            if (!foundPart) continue;

            let detail = {
              ref: {
                type: "ProductionReceipt",
                value: receipt._id,
                label: receipt.title,
                txnNumber: receipt.data.txnNumber,
                currency: receipt.data.currency,
                lineId: line.lineId,
              },
              storage: selectedStorage,
              txnDate: receipt.data.txnDate,
              item: line.inventory,
              qty: line.qty,
              inventory: foundPart,
              partQty: numeral(line.qty).value() * numeral(part.qty).value(),
              user: this.props.user,
            };
            details.push(detail);
          }
        }
      }
    }
    return details;
  };

  handleGetData = async () => {
    await this.populateProductionDetails();
  };

  handleCreateDocuments = async () => {
    console.log("Called save...");
    const { productionDetails } = this.state;

    this.props.progressOn();

    let d = 0;
    for (let txn of productionDetails) {
      const newDoc = await this.onCreateDocument(txn);
      d++;
      this.props.progressUpdate(
        "Saving Material requisitions...",
        d,
        productionDetails.length
      );
      console.log("Saved: ", d, newDoc.title);
    }

    this.props.progressOff();

    const notSaved = productionDetails.filter((o) => o.errors !== undefined);
    if (notSaved.length === 0) {
      window.location = "/generate-material-usage";
    } else {
      this.setState({ imported: notSaved });
    }
  };

  onCreateDocument = async (txn) => {
    // console.log(txn);
    const { productionReceipts, records } = this.state;
    const newDocument = mapProductionToMaterialUsage(txn);

    const txnDate = DateTime.fromISO(newDocument.data.txnDate).toISODate();

    newDocument.title = `${txnDate} Material Requisition Form`;

    if (newDocument.data.records)
      newDocument.records = newDocument.data.records;
    delete newDocument.data.records;

    // console.log(doc);

    const saved = await this.onSaveDocument(newDocument);
    let refs = [
      {
        type: saved.entity,
        value: saved._id,
        label: saved.title,
        lineId: txn.ref.lineId,
      },
    ];

    // console.log(txn, receipts);
    const docToUpdate = productionReceipts.find((i) => i._id === txn.ref.value);
    delete docToUpdate.user;
    delete docToUpdate.dateCreated;
    delete docToUpdate.lastUpdated;
    delete docToUpdate.__v;

    docToUpdate.records = records.filter(
      (record) => record.doc.value === docToUpdate._id
    );

    docToUpdate.data.refs = docToUpdate.data.refs
      ? [...docToUpdate.data.refs, ...refs]
      : refs;

    const prod = await this.onSaveDocument(docToUpdate);
    prod.isComplete = true;
    // console.log(saved);
    // this.props.loadingOff();
    // return txn;
    return prod;
    // console.log("on create");
  };

  onSaveDocument = async (doc) => {
    if (doc._id === "new") delete doc._id;

    try {
      const { data } = await saveDocument(doc);
      return data;
      // else window.location = "/documents";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      return null;
    }
  };

  onDelete = async () => {
    console.log("delete");
  };

  render() {
    const { fromDate, toDate, storages, selectedStorage, productionDetails } =
      this.state;

    return (
      <>
        <SidebarLeft>
          <ListGroup
            items={[
              { value: "ProductionReceipt", label: "Production Receipts" },
            ]}
            valueProperty="value"
            textProperty="label"
            selectedItem={{
              value: "ProductionReceipt",
              label: "Production Receipts",
            }}
            onItemSelect={() => {}}
            selectedCount={productionDetails.length}
          />
        </SidebarLeft>
        <div className="col mt-2">
          <div className="d-flex py-2">
            <h2 className="text-uppercase">
              Generate Material Requisition Forms
            </h2>
          </div>
          <p>
            Select date range to find production receipts to generate material
            requisition forms. Choose a storage from you want to receive
            materials.
          </p>
          <SelectDateBox
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={(value) => this.setState({ fromDate: value })}
            setToDate={(value) => this.setState({ toDate: value })}
            handleDateChange={this.handleGetData}
          />
          <div className="d-flex row border-bottom mt-2 pb-2 d-print-none">
            <div className="col-6">
              {/* <SelectBox
                searchQuery={searchQuery}
                onSearch={this.handleSearch}
                options={searchOptions}
                onSelect={this.onCustomerSelect}
                label={"Select customer..."}
              /> */}
              <Select
                name="storage"
                value={selectedStorage}
                options={storages}
                onSelect={this.onStorageSelect}
                label={"Search storage..."}
              />
            </div>
            <div>
              <button
                type="button"
                className="btn btn-info mr-2 shadow-sm"
                disabled={productionDetails < 1}
                onClick={this.handleCreateDocuments}
              >
                Generate Material Requisition
              </button>
            </div>
          </div>
          <GenMaterialUsageTable
            title="Production Receipt Details"
            items={productionDetails}
            sorting={{ path: "data.dueDate", order: "asc" }}
            onCreate={this.onCreateDocument}
            onDelete={this.onDelete}
          />
        </div>
      </>
    );
  }
}

export default withLoading(GenMaterialUsage);
