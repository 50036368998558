import React from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
// import { toast } from "react-toastify";
import numeral from "numeral";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputTableText from "../common/inputTableText";
import InputTableTextArea from "../common/inputTableTextArea";
import InputTableSelect from "../common/inputTableSelect";
import InputTableNumber from "../common/inputTableNumber";
// import InputTableCheckbox from "../common/inputTableCheckbox";
import Button from "../common/button";

const CostOptionsEdit = ({ data }) => {
  const initialDetail = {
    code: "",
    description: "",
    uom: "",
    baseUom: data.uom,
    uomOption: null,
    ratioToBase: 1,
    cost: 0,
    isDefault: false,
  };
  const { control, watch, getValues, setValue } = useFormContext();

  // const uomOptions = getValues("uomOptions");
  // const baseCost = getValues("cost");
  const values = getValues();
  const { uomOptions, cost: baseCost, uom } = values;
  // const baseCost = cost || 0;
  let options = uomOptions
    ? uomOptions.map((option) => ({
        ...option,
        value: option.code,
        label: option.code,
        ratioToBase: option.ratioToBase,
      }))
    : [];
  options = [
    {
      code: uom,
      value: uom,
      label: uom,
      description: `Base UOM: ${uom}`,
      ratioToBase: 1,
    },
    ...options,
  ];

  const { fields, append, remove } = useFieldArray({
    control,
    name: "costOptions",
  });

  const costOptions = watch("costOptions");

  // useEffect(() => {
  //   console.log("Watch", costOptions);
  // }, [costOptions]);

  // fields.length ? watch('fieldArray', fields) : []
  const handleSelect = (name, selected) => {
    console.log(selected, name);
    setValue(selected.name, {
      value: name.value,
      label: name.label,
      code: name.code,
      description: name.description,
      baseUom: name.baseUom || uom,
      ratioToBase: name.ratioToBase,
    });

    const { name: str } = selected;
    let ni1 = str.indexOf("[");
    let ni2 = str.indexOf("]");
    const index = str.substring(ni1 + 1, ni2);
    console.log("Selected name", name);

    if (name.ratioToBase) {
      const cost = numeral(baseCost || 0)
        .multiply(name.ratioToBase || 1)
        .value();
      setValue(`costOptions[${index}].cost`, cost);
    }
  };

  return (
    <div id="costOptions" className="mt-2">
      <h5 className="text-uppercase">Cost Options</h5>
      <table className="table table-sm border-bottom">
        <thead>
          <tr className="text-center">
            <th scope="col" style={{ width: "5%" }}>
              #
            </th>
            <th scope="col" style={{ width: "20%" }}>
              Code
            </th>
            <th scope="col" style={{ width: "40%" }}>
              Description
            </th>
            <th scope="col" style={{ width: "10%" }}>
              UOM
            </th>
            <th scope="col" style={{ width: "15%" }}>
              Cost per UOM
            </th>
            {/* <th scope="col" style={{ width: "5%" }}>
              Default
            </th> */}
            <th scope="col" style={{ width: "5%" }}></th>
          </tr>
        </thead>
        <tbody>
          {fields.map((line, index) => (
            <tr key={index} name={`option-${index}`}>
              <th scope="row" className="align-baseline">
                {/* <input
                  type="hidden"
                  {...register}
                  name={`costOptions[${index}].uom`}
                  defaultValue={line.uom}
                  value={line.uom}
                  ref={register()}
                />
                <input
                  type="hidden"
                  name={`costOptions[${index}].baseUom`}
                  defaultValue={line.baseUom}
                  ref={register()}
                />
                <input
                  type="hidden"
                  name={`costOptions[${index}].ratioToBase`}
                  defaultValue={line.ratioToBase}
                  ref={register()}
                /> */}
                {index + 1}
              </th>
              <td className="align-baseline">
                <InputTableText
                  name={`costOptions[${index}].code`}
                  defaultValue={line.code}
                />
              </td>
              <td className="align-baseline">
                <InputTableTextArea
                  name={`costOptions[${index}].description`}
                  defaultValue={line.description}
                />
              </td>
              <td className="align-baseline">
                <InputTableSelect
                  name={`costOptions[${index}].uomOption`}
                  options={options}
                  onChange={handleSelect}
                  valueOption="value"
                  labelOption="label"
                  defaultValue={line.uomOption}
                />
                <div className="text-right pr-2">
                  <small className="text-primary">
                    {numeral(line?.uomOption?.ratioToBase || 0).format(
                      "0,0.000"
                    )}
                  </small>
                </div>
              </td>
              <td className="align-baseline">
                <InputTableNumber
                  name={`costOptions[${index}].cost`}
                  // onBlur={handleBlur}
                  defaultValue={line.cost}
                  decimalScale="3"
                />
                <div className="text-right pr-2">
                  <small className="text-primary">
                    {numeral(line.cost)
                      .divide(line?.uomOption?.ratioToBase || 1)
                      .format("$ 0,0.00")}
                  </small>
                </div>
              </td>
              {/* <td className="text-center align-middle">
                <InputTableCheckbox
                  name={`costOptions[${index}].isDefault`}
                  onChange={() => handleIsDefaultChange(index)}
                  // value={line.isDefault}
                  // onBlur={handleBlur}
                  checked={line.isDefault}
                  defaultValue={line.isDefault}
                />
              </td> */}
              <td className="align-baseline">
                <button
                  className="btn btn-secondary btn-sm d-print-none"
                  type="button"
                  onClick={() => remove(index)}
                >
                  <FontAwesomeIcon icon="trash" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="d-flex justify-content-between">
        <div>
          <Button
            label="+ Add Cost Option"
            type="button"
            className="btn-primary btn-sm shadow-sm"
            onClick={() => append(initialDetail)}
          />
        </div>
      </div>
    </div>
  );
};

export default CostOptionsEdit;
