import React from "react";
import { Link } from "react-router-dom";
import Panel from "../common/panel";
import Controls from "./Controls";
import { RowOneLeftTwoRight, RowFourColumns } from "../common/formLayout";
import Field from "../common/fieldText";
import Ordered from "./Ordered";
import Reservations from "./Reservations";
// import Transactions from "./Transactions";
import InStock from "./InStock";

const View = (props) => {
  const { data, inventoryId } = props;

  return (
    <div id="StorageView">
      <Panel>
        <div className="row">
        <div className="col">
          
        </div>
        <div className="col">

        </div>

        </div>
        {/* <RowFourColumns
          colOne={<Field name="type" label="Type" value={data.type} />}
        />

        <RowOneLeftTwoRight
          colOne={<Field name="name" label="Name" value={data.name} />}
          colTwo={
            <Field name="uom" label="Units of Measure" value={data.uom} />
          }
        />
        <RowOneLeftTwoRight
          colOne={
            <Field
              name="descriptoin"
              label="Description"
              value={data.description}
            />
          }
        />
        <RowOneLeftTwoRight
          colOne={data.isInactive ? <h6>Inactive</h6> : <h6>Active</h6>}
        /> */}
        <Link to={`/items/${data.itemId}`}>Go to item details...</Link>
      </Panel>
      <div className="row">
        <div className="col-md">
          <InStock current={data.current} />
        </div>
        <div className="col-md">
          <Ordered orders={data.orders || []} />
        </div>
      </div>

      <Reservations reservations={data.reservations} />
      {/* <Transactions date={data} {...props} /> */}
      <Controls isEdit={false} {...props} />
    </div>
  );
};

export default View;
