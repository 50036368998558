import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import qs from "query-string";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import withFormMode from "../common/withFormMode";
import Breadcrumb from "../common/Breadcrumb";
import { FormTitle, FormSubTitle } from "../common/formLayout";
import {
  // mapProductionToInvoice,
  mapProductionToRequisition,
} from "./../../utils/productionUtils";
import {
  getDocument,
  // getInventoryDocumentTypes,
  saveDocument,
} from "../../services/documentService";
import Edit from "./Edit";
import View from "./View";
import { camelPad } from "./../../utils/utils";

const Document = (props) => {
  const { loadingOn, loadingOff, user } = props;
  const [formMode] = useState({
    isAddActivities: false,
    isNew: false,
    isEdit: false,
    search: "",
    title: "Document",
  });

  // const [docTypes, setDocTypes] = useState([]);
  const [docRefs] = useState([
    {
      type: "ProductionReceipt",
      refs: [
        {
          value: "MaterialsRequisitionForm",
          label: "Materials Requisition",
        },
      ],
    },
  ]);

  const defaulValues = {
    title: "Adding new document...",
    entity: "",
    data: {
      // type: "",
      txnDate: DateTime.local(),
      // txnNumber: "",
      // locationFrom: null,
      // vendor: null,
      // addressFrom: "",
      // locationTo: null,
      // name: null,
      addressTo: "",
      currency: { value: "CAD" },
      // memo: "",
      // notes: "",
    },
    records: [],
  };

  const [document, setDocument] = useState(defaulValues);

  const { match, location } = props;
  const documentId = match.params.id;
  const documentPath = `/documents/${documentId}`;
  if (match.params.id === "new") {
    const { type } = qs.parse(location.search);
    // if (type && docTypes) {
    //   const docType = getDocType(type);
    //   console.log(docType);
    //   // const newDoc = { ...document };
    //   formMode.label = docType.title;
    //   formMode.type = type;
    //   // setDocument(newDoc);
    // }
    // const newForm = { ...formMode };
    // newForm.isNew = true;
    formMode.type = type || "";
    formMode.isNew = true;
    formMode.isEdit = true;
  }

  const docType = document.entity || formMode.type;
  const formTitle = camelPad(docType);

  const references = docRefs.filter((d) => d.type === docType);
  const newDocs = references.length > 0 ? references[0].refs : [];
  newDocs.forEach((d) => {
    if (document.data.refs) {
      const filtered = document.data.refs.filter((ref) => ref.type === d.value);
      d.isDisabled = filtered.length > 0 ? true : false;
    }
  });

  useEffect(() => {
    async function populateDocument(documentId) {
      try {
        const { data: document } = await getDocument(documentId);
        document.data.type = document.entity;
        // const { label } = getDocType(document.entity);
        // document.label = label;
        loadingOff();
        setDocument(document);
      } catch (ex) {
        if (ex.response && ex.response.status === 404)
          window.location.href = "/not-found";
      }
    }

    if (documentId === "new") {
      loadingOff();
      return;
    }

    populateDocument(documentId);
  }, [documentId, loadingOff]);

  const handleSave = async (values) => {
    const data = { ...document.data };

    if (values.txnDate) data.txnDate = values.txnDate;
    if (values.txnNumber) data.txnNumber = values.txnNumber;
    if (values.locationFrom) data.locationFrom = values.locationFrom;
    if (values.name) data.name = values.name;
    if (values.addressFrom) data.addressFrom = values.addressFrom;
    if (values.locationTo) data.locationTo = values.locationTo;
    if (values.addressTo) data.addressTo = values.addressTo;
    if (values.notes) data.notes = values.notes;
    if (values.memo) data.memo = values.memo;
    data.metadata = values.metadata;
    data.type = values.type || docType;

    const addMessage = {
      timeStamp: DateTime.local().toISO(),
      user: { value: user._id, label: user.name },
      message: documentId === "new" ? "Document created" : "Document updated",
    };
    const messages = data.messages ? data.messages : [];
    messages.push(addMessage);
    data.messages = messages;

    const txnDate = DateTime.fromISO(data.txnDate).toISODate();
    let title = "";
    if (data.txnNumber) title = `${data.txnNumber} `;
    title = title + `${txnDate} ${camelPad(data.type)}`;

    const doc = {
      _id: document._id,
      title: title,
      entity: data.type,
      data: data,
    };

    if (values.records) doc.records = values.records;

    // console.log(doc);
    const saved = await onSave(doc);
    if (saved) {
      window.location = `/documents/${saved._id}`;
    }
    if (saved) setDocument(saved);
  };

  const onSave = async (doc) => {
    // const isNew = doc._id === "new" ? true : false;
    if (doc._id === "new") delete doc._id;
    loadingOn();

    try {
      const { data } = await saveDocument(doc);
      loadingOff();
      return data;
      // else window.location = "/documents";
    } catch (ex) {
      loadingOff();
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      return null;
    }
  };

  const handleAddRefDoc = async (refDoc) => {
    // console.log(refDoc);
    let refDocData = {};
    switch (refDoc) {
      case "MaterialsRequisitionForm":
        // console.log(docType);
        if (docType === "ProductionReceipt")
          refDocData = await mapProductionToRequisition(document);
        break;

      default:
        break;
    }

    if (refDocData) {
      const addMessage = {
        timeStamp: DateTime.local().toISO(),
        user: { value: user._id, label: user.name },
        message: `Document created`,
      };

      refDocData.messages = [addMessage];

      const records = refDocData.records;
      delete refDocData.records;
      const txnDate = DateTime.fromISO(refDocData.txnDate).toISODate();
      let title = "";
      if (refDocData.txnNumber) title = `${refDocData.txnNumber} `;
      title = title + `${txnDate} ${camelPad(refDocData.type)}`;

      const doc = {
        _id: "new",
        title: title,
        entity: refDocData.type,
        data: refDocData,
        records: records,
      };

      // if (newDoc) console.log("New Doc: ", newDoc);
      const saved = await onSave(doc);
      if (saved) {
        toast.success(`${saved.title} was created`);
        const updateDoc = { ...document };
        const addMessage = {
          timeStamp: DateTime.local().toISO(),
          user: { value: user._id, label: user.name },
          message: `${saved.title} was added. See references.`,
        };
        const messages = updateDoc.data.messages ? updateDoc.data.messages : [];
        messages.push(addMessage);
        updateDoc.data.messages = messages;

        updateDoc.data.refs = updateDoc.data.refs
          ? updateDoc.data.refs.push({
              value: saved._id,
              label: saved.title,
              type: saved.entity,
            })
          : [{ value: saved._id, label: saved.title, type: saved.entity }];
        const updated = await onSave(updateDoc);
        if (updated) window.location = `/documents/${saved._id}`;
      }
    }
  };
  const breadcrumbs = [
    { value: "/documents", label: "Documents" },
    { value: `/documents/${documentId}`, label: `${document.title}` },
  ];

  return (
    <div>
      <Breadcrumb items={breadcrumbs} />
      <FormTitle
        title={
          formMode.isNew
            ? `Create ${formTitle || "Document"}`
            : `${formTitle || "Document"}`
        }
      />
      <FormSubTitle
        title={
          <span className="font-weight-bolder text-primary">
            {document.title}
          </span>
        }
      />
      <Switch>
        <Route
          exact
          path={`${documentPath}`}
          component={(props) =>
            formMode.isNew ? (
              <Edit
                data={document.data}
                records={document.records}
                documentPath={documentPath}
                documentId={documentId}
                onSave={handleSave}
                docType={docType}
                // setDocType={setDocType}
                // docTypes={docTypes}
                {...props}
              />
            ) : (
              <View
                data={document.data}
                docType={docType}
                refDocs={newDocs}
                onAddRefDoc={handleAddRefDoc}
                records={document.records}
                documentPath={documentPath}
                title={document.title}
                {...props}
              />
            )
          }
        />
        <Route
          path={`${documentPath}/edit`}
          component={(props) => (
            <Edit
              data={document.data}
              records={document.records}
              docType={docType}
              // docTypes={docTypes}
              documentPath={documentPath}
              documentId={documentId}
              onSave={handleSave}
              {...props}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default withFormMode(Document);
