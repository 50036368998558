import { populateSetting } from "./../services/settingService";

let acceptableExtensions = null;
async function getAcceptableExtensions() {
  if (!acceptableExtensions) {
    const { data } = await populateSetting("jobs");
    const acceptableExtensionsString = data.details.acceptableJobFiles;
    acceptableExtensions = acceptableExtensionsString.split(" ");
  }
  return acceptableExtensions;
}

const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
const iframeExtensions = ["pdf", "jpg", "jpeg", "png", "gif", "bmp"];

export const fileTypes = {
  image: "image",
  pdf: "pdf",
  other: "other",
  invalid: false,
};

export async function checkFileExtension(name) {
  const acceptableExtensions = await getAcceptableExtensions();
  const extension = name
    .slice(name.lastIndexOf(".") + 1, name.length)
    .toLowerCase();
  const isValid = acceptableExtensions.includes(extension);

  if (!isValid) return fileTypes.invalid;
  if (extension === fileTypes.pdf) return fileTypes.pdf;
  const isImage = imageExtensions.includes(extension);
  if (isImage) return fileTypes.image;
  return fileTypes.other;
}

export const getExtension = (filename) => {
  return filename.split(".").pop();
};

export const checkIframeDisplayType = (filename) => {
  const ext = getExtension(filename).toLowerCase();
  return iframeExtensions.includes(ext);
};
