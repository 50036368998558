import React from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";
import DateFormat from "../common/dateFormat";
import { DateTime } from "luxon";

const DocumentListTable = (props) => {
  const { items, onSort, sortColumn } = props;

  const columns = [
    {
      path: "label",
      label: "Document",
      className: "w-50",
      content: (doc) => (
        <Link to={`/documents/${doc.ref.value}`}>{`${doc.ref.docType} ${
          doc.ref.txnNumber
        } ${DateTime.fromISO(doc.ref.txnDate).toISODate()}`}</Link>
      ),
    },
    {
      path: "docDate",
      label: "Doc Date",
      className: "text-center",
      content: (doc) => <DateFormat value={doc.docDate} />,
    },
    //   { path: "qty", label: "Qty" },
    //   { path: "status", label: "Status" },
  ];

  return (
    <Table
      columns={columns}
      data={items}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default DocumentListTable;
