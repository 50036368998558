import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FileListHeader = ({
  title = "Folder",
  folder,
  showFiles = false,
  onShowFiles,
}) => {
  return (
    <div className="d-flex justify-content-between">
      <div className="p-1">
        <h6 className="text-success">{title}</h6>
        <div
          className="text-primary mt-n1 ml-2"
          onClick={onShowFiles}
          style={{ cursor: "pointer" }}
        >{`. /${folder}`}</div>
      </div>
      <div>
        <button
          className="btn btn-light border rounded-circle"
          data-toggle="tooltip"
          data-placement="top"
          title={showFiles ? "Hide files " : "Show files"}
          onClick={onShowFiles}
        >
          <FontAwesomeIcon icon={showFiles ? "angle-up" : "angle-down"} />
        </button>
      </div>
    </div>
  );
};

export default FileListHeader;
