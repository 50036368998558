export const addressToString = (address) => {
  if (!address) return "";
  let line = address.line1 ? address.line1 : "";
  if (address.line2) line = `${line} \n${address.line2}`;
  if (line) line = `${line} \n`;
  return `${line}${address.city || ""} ${address.subDivision || ""} ${
    address.postalCode || ""
  } ${address.country || ""}`;
};

export const address = () => {
  return {
    line1: "",
    line2: "",
    city: "",
    subDivision: "",
    country: "",
    postalCode: "",
    long: "",
    lat: "",
  };
};
