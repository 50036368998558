import React from "react";
import Panel from "../panel";

const FileListWrapper = ({ children }) => {
  return (
    <Panel>
      <div className="bg-white border rounded m-n1 p-2">{children}</div>
    </Panel>
  );
};

export default FileListWrapper;
