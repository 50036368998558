import React from "react";
import { NavLink, Route, Redirect, Switch } from "react-router-dom";
import ToolChange from "./ToolChange";
import ToolMerge from "./ToolMerge";
import GenProd from "./GenProd";
import LeftBarItems from "./LeftBarItems";

const Tools = ({ match, location, user }) => {
  return (
    <div className="row" style={{ marginLeft: "0px", marginRight: "0px" }}>
      <LeftBarItems user={user} />
      <Switch>
        <Route
          path={`${match.path}/change`}
          component={(props) => <ToolChange {...props} user={user} />}
        />
        {/* <Route
          path={`${match.path}/merge`}
          component={(props) => <ToolMerge {...props} />}
        /> */}
        {/* <Route path={`${match.path}/users/:id`} component={UserForm} />
          <Route path={`${match.path}/users`} component={SettingsUsers} /> */}
        {/* <Route
          path={`/genProd`}
          component={(props) => <GenProd {...props} />}
        /> */}

        <Redirect from="/" exact to={`${match.path}/change`} />
      </Switch>
    </div>
  );
};

export default Tools;
