import React from "react";
import { Link } from "react-router-dom";

const ReportList = () => {
  return (
    <div className="container-fluid px-5">
      <div className="d-flex py-2">
        <h2 className="text-uppercase">Reports</h2>
      </div>
      <h5 className="text-uppercase border-bottom">Orders</h5>
      <div className="p-3">
        {/* <ul> */}

        {/* </ul> */}
      </div>
      <h5 className="text-uppercase border-bottom">Production</h5>
      <div className="p-3">
        <ul>
          <li>
            <Link to={`/reports/production`}>Production summary</Link>
          </li>
          {/* <li>
            <Link to={`/reports/production-by-salesgroup`}>
              Production by sales group
            </Link>
          </li> */}
        </ul>
      </div>

      <h5 className="text-uppercase border-bottom">Materials</h5>
      <div className="p-3">
        <ul>
          <li>
            <Link to={`/reports/inventory-receipts`}>Inventory Receipts</Link>
          </li>
          <li>
            <Link to={`/reports/incoming-material`}>
              Incoming Material Inspection Report
            </Link>
          </li>
          <li>
            <Link to={`/reports/inventory-to-parts`}>
              Materials to Parts Calculation
            </Link>
          </li>
          <li>
            <Link to={`/reports/item-transactions`}>Item Transactions</Link>
          </li>
          {/* <li>
            <Link to={`/reports/material-usage-by-project`}>
              Material usage by project
            </Link>
          </li> */}
          {/* <li>
            <Link to={`/reports/material-usage-by-project`}>
              Material usage by project
            </Link>
          </li> */}
        </ul>
      </div>
      <h5 className="text-uppercase border-bottom">Inventory Balance</h5>
      <div className="p-3">
        <ul>
          <li>
            <Link to={`/reports/inventory-by-location`}>
              Inventory by Location
            </Link>
          </li>
          <li>
            <Link to={`/reports/consignment`}>Consignment Balances</Link>
          </li>
          {/* <li>
            <Link to={`/reports/worker-jobs-summary`}>Worker jobs summary</Link>
          </li> */}
        </ul>
      </div>
      <h5 className="text-uppercase border-bottom">Consignments</h5>
      <div className="p-3">
        <ul>
          <li>
            <Link to={`/reports/consignment-shipped`}>Consignment Shipped</Link>
          </li>
          <li>
            <Link to={`/reports/consignment-transactions`}>
              Consignment Transactions
            </Link>
          </li>
          <li>
            <Link to={`/reports/consignment-balance`}>
              Consignment Balances
            </Link>
          </li>
          {/* <li>
            <Link to={`/reports/worker-jobs-summary`}>Worker jobs summary</Link>
          </li> */}
        </ul>
      </div>
      <h5 className="text-uppercase border-bottom">Receiving Materials</h5>
      <div className="p-3">
        <ul>
          {/* <li>
            <Link to={`/reports/receiving-inventory`}>Receiving Inventory</Link>
          </li> */}
          {/* <li>
            <Link to={`/reports/worker-jobs-summary`}>Worker jobs summary</Link>
          </li> */}
        </ul>
      </div>
    </div>
  );
};

export default ReportList;
