import React, { useState } from "react";
import Panel from "../common/panel";
import Table from "./../common/table";
import DateFormat from "../common/dateFormat";
import { camelPad } from "./../../utils/utils";
import numeral from "numeral";

const Reservations = (props) => {
  const { reservations } = props;

  const [sortColumn, setSortColumn] = useState({
    path: "doc.txnDate",
    order: "asc",
  });

  const columns = [
    {
      path: "doc.txnDate",
      label: "Date",
      content: (record) => (
        <DateFormat value={record.doc && record.doc.txnDate} />
      ),
    },
    {
      path: "doc.txnType",
      label: "Document",
      content: (record) => camelPad(record.doc.txnType),
    },
    {
      path: "doc.txnNumber",
      label: "Number",
    },
    {
      path: "qty",
      label: "Qty",
      className: "text-right pr-2",
      content: (record) => numeral(record.qty).format("0,0.000"),
    },
  ];

  const totalReserved = reservations.reduce((sum, i) => {
    return sum + i.qty;
  }, 0);

  return (
    <div>
      <h4>Reservations</h4>
      <Panel>
        <Table
          columns={columns}
          data={reservations}
          sortColumn={sortColumn}
          onSort={(sortColumn) => setSortColumn(sortColumn)}
        />
        <h6 className="text-right pr-2">{`Total: ${numeral(
          totalReserved
        ).format("0,0.000")}`}</h6>
      </Panel>
    </div>
  );
};

export default Reservations;
