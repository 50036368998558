import React, { Component } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../common/loader";
import login from "../../services/loginApiService";
import {
  getUserCompanies,
  getAppClients,
  getAccessToken,
} from "../../services/loginApiService";
import connect from "../../services/connectService";

class companySelect extends Component {
  state = {
    companies: [],
    user: {},
    loading: true,
  };

  async componentDidMount() {
    const user = login.getGlobalUser();
    const { data: clients } = await getAppClients();
    this.setState({ clients });
    try {
      const { data } = await getUserCompanies(user._id);
      const sorted = _.sortBy(data, (c) => c.name);
      this.setState({ user, companies: sorted, loading: false });
    } catch (error) {
      toast.error("Couldn't get companies");
    }
  }

  getCompanyToken = async (companyId) => {
    try {
      const { data } = await getAccessToken(companyId);
      return data.token;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      return null;
    }
  };

  getAccess = async (token) => {
    try {
      await connect.connectCompany({ token: token });
      window.location = "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  handleClick = async (company) => {
    const companyToken = await this.getCompanyToken(company._id);
    console.log(companyToken);
    await this.getAccess(companyToken);
  };

  render() {
    const { companies, loading } = this.state;

    return (
      <React.Fragment>
        <div className="card-header d-flex justify-content-between">
          <h5>Select a company to proceed</h5>
          <Link to="/logout">Logout</Link>
        </div>
        <div className="card-body">
          {companies.map((company) => (
            <div key={company.code} className="row ml-3 pb-2">
              <Link
                key={company.code}
                to="/"
                onClick={() => this.handleClick(company)}
              >
                {company.name}
              </Link>
            </div>
          ))}
        </div>
        <div id="Loader" className={loading ? "" : "d-none"}>
          <Loader />
        </div>
      </React.Fragment>
    );
  }
}

export default companySelect;
