import React, { useEffect } from "react";
import Image from "./ImageBase64";
import DragDropFile from "../DragDropFile";
import LiveToast from "../../../utils/liveToast";
import { compressImage } from "../../../utils/fileCompressor";
import { blobFileUrl } from "../../../utils/blobFileUrl";
import { checkFileExtension, fileTypes } from "../../../utils/fileExtensions";
import { getBase64 } from "./../../../utils/imageProcessing";

const ImageThumbnail = ({ name, data, classNames }) => {
  return (
    <img alt={name} src={data} className={classNames} />
    // <Image
    //   name={name}
    //   data={thumbnail.data}
    //   mimetype={thumbnail.type}
    //   classNames={classNames}
    // />
  );
};

const NoImageThumbnail = ({ classNames }) => {
  return (
    <img
      alt="No image found"
      src="/images/image-not-available-640x400.png"
      className={classNames}
    />
  );
};

export const UploadButton = ({ onClick }) => {
  return (
    <button
      type="file"
      className="btn btn-sm btn-outline-secondary mr-2 mt-2 shadow-sm"
      data-toggle="tooltip"
      data-placement="top"
      title="Upload a new thumbnail"
      accept=".jpeg, .png, .jpg"
      onClick={onClick}
    >
      Upload Image ...
    </button>
  );
};

const Thumbnail = ({
  navPath = "/",
  name = "Thumbnail",
  thumbnail = null,
  allowUpload = false,
  showUploadButton = false,
  classNames = "card-img-top p-2",
  onSaveImage = () => {},
}) => {
  // useEffect(() => {}, []);
  console.log(thumbnail);

  let fileCompressionRequired = true;

  const compressFile = async (file_original) => {
    let maxSize = 50000;
    let maxWidth = 175;
    let file_processed = null;
    const fileType = await checkFileExtension(file_original.name);
    switch (fileType) {
      case fileTypes.image:
        file_processed = await compressImage(file_original, maxSize, maxWidth);
        break;

      default:
        file_processed = file_original;
        break;
    }
    return file_processed;
  };

  const handleFileSelectorChange = async (event) => {
    if (event.target.files.length !== 1) return;
    const liveToast = new LiveToast("UploadingFile");

    const file_original = event.target.files[0];

    // const fileType = await checkFileExtension(file_original.name);
    // if (fileType === fileTypes.invalid) {
    //   liveToast.error("Unacceptable file extension");
    //   return;
    // }

    const file = fileCompressionRequired
      ? await compressFile(file_original)
      : file_original;

    console.log(file);

    try {
      const result = await getBase64(file);
      console.log(result);
      const img = {
        // name: file.name,
        data: result,
        type: file.type,
      };
      await onSaveImage(img);
    } catch (error) {
      console.log(error);
    }
    // getBase64(file)
    //   .then((result) => {
    //     file["base64"] = result;
    //     console.log("File Is", file);
    //     // this.setState({
    //     //   base64URL: result,
    //     //   file,
    //     // });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    // console.log(imageAsBase64);

    // console.log(img);

    // await onSaveImage(img);
  };

  const fileSelector = document.createElement("input");
  fileSelector.setAttribute("type", "file");
  fileSelector.onchange = handleFileSelectorChange;

  const handleUpload = (compress = true) => {
    fileCompressionRequired = compress;
    fileSelector.click();
  };

  if (!allowUpload)
    return (
      <div
        className="clickable"
        data-toggle="tooltip"
        data-placement="top"
        title="Click to view quantity details"
        onClick={() => (window.location = navPath)}
      >
        {thumbnail ? (
          <ImageThumbnail
            name={name}
            data={thumbnail.data}
            classNames={classNames}
          />
        ) : (
          <NoImageThumbnail classNames={classNames} />
        )}
        {showUploadButton && (
          <UploadButton onClick={() => handleUpload(true)} />
        )}
      </div>
    );

  return (
    <DragDropFile handleFile={handleUpload} multiple={false}>
      <div
        data-toggle="tooltip"
        data-placement="top"
        title="Drop file here or click to upload a new thumbnail"
        onClick={() => handleUpload(true)}
      >
        {thumbnail ? (
          <ImageThumbnail
            name={name}
            thumbnail={thumbnail}
            classNames={classNames}
          />
        ) : (
          <NoImageThumbnail classNames={classNames} />
        )}
      </div>
      {showUploadButton && <UploadButton onClick={() => handleUpload(true)} />}
      {/* {showUploadButton && (
        <button
          type="file"
          className="btn btn-sm btn-outline-secondary mr-2 mt-2 shadow-sm"
          data-toggle="tooltip"
          data-placement="top"
          title="Upload a new thumbnail"
          onClick={() => handleUpload(true)}
        >
          Upload Image ...
        </button>
      )} */}
    </DragDropFile>
  );
};

export default Thumbnail;
