import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { toast } from "react-toastify";
import withFormMode from "../common/withFormMode";
import { FormTitle, FormSubTitle } from "../common/formLayout";
import { DateTime } from "luxon";

import { getInventory, saveInventory } from "../../services/inventoryService";
import Edit from "./Edit";
import View from "./View";

const Inventory = (props) => {
  const [formMode] = useState({
    isAddActivities: false,
    isNew: false,
    isEdit: false,
    loading: true,
    search: "",
  });

  const [inventory, setInventory] = useState({
    name: "Adding new inventory...",
    type: "",
    reservations: [],
    tags: [],
    balances: [],
    current: [],
    isInactive: false,
  });

  const { match } = props;
  const inventoryId = match.params.id;
  const inventoryPath = `/inventory/${inventoryId}`;

  useEffect(() => {
    async function populateInventory(inventoryId) {
      try {
        const { data: inventory } = await getInventory(inventoryId);
        setInventory(inventory);
      } catch (ex) {
        if (ex.response && ex.response.status === 404)
          window.location.href = "/not-found";
      }
    }

    if (inventoryId === "new") {
      return;
    }

    populateInventory(inventoryId);
  }, [inventoryId]);

  if (match.params.id === "new") {
    formMode.isNew = true;
    formMode.isEdit = true;
  }

  const onSave = async (values) => {
    const updated = { ...inventory };
    if (values.name) updated.name = values.name;
    if (values.description) updated.description = values.description;
    if (values.type) updated.type = values.type;
    if (values.uom) updated.uom = values.uom;
    if (values.item) updated.itemId = values.item.value;
    if (values.reservations) updated.reservations = values.reservations;
    if (values.tags) updated.tags = values.tags;
    if (values.balances) updated.balances = values.balances;
    if (values.current) updated.current = values.current;
    if (values.isInactive) updated.isInactive = values.isInactive;

    if (match.params.id === "new")
      updated.name =
        updated.name + " - " + DateTime.local().toFormat("yyyyLLdd");

    if (updated._id === "new") {
      delete updated._id;
    }

    try {
      const { data } = await saveInventory(updated);
      toast.success("The inventory was saved!");
      window.location = "/inventory/" + data._id;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  return (
    <div>
      <FormTitle title={formMode.isNew ? `Create Inventory` : `Inventory`} />
      <FormSubTitle
        title={
          <span className="font-weight-bolder text-primary">
            {inventory.name}
          </span>
        }
      />
      <Switch>
        <Route
          exact
          path={`${inventoryPath}`}
          component={(props) =>
            formMode.isNew ? (
              <Edit
                data={inventory}
                inventoryPath={inventoryPath}
                inventoryId={inventoryId}
                onSave={onSave}
                {...props}
              />
            ) : (
              <View data={inventory} inventoryPath={inventoryPath} {...props} />
            )
          }
        />
        <Route
          path={`${inventoryPath}/edit`}
          component={(props) => (
            <Edit
              data={inventory}
              inventoryPath={inventoryPath}
              inventoryId={inventoryId}
              onSave={onSave}
              {...props}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default withFormMode(Inventory);
