import React from "react";
import Panel from "../common/panel";
import Controls from "./Controls";
import { RowOneLeftTwoRight, RowFourColumns } from "../common/formLayout";
import Field from "../common/fieldText";

const View = (props) => {
  const { data } = props;

  return (
    <div id="VendorView">
      <Panel>
        <RowOneLeftTwoRight
          colOne={
            <Field name="displayName" label="Name" value={data.displayName} />
          }
          colTwo={
            <Field
              name="currency"
              label="Currency"
              value={data.currency && data.currency.value}
            />
          }
        />
        <RowFourColumns
          colOne={<Field name="phone" label="Phone" value={data.phone} />}
          colTwo={<Field name="email" label="Email" value={data.email} />}
        />
        <RowOneLeftTwoRight
          colOne={
            <Field
              name="billingAddress"
              label="Billing Address"
              value={data.billingAddress}
            />
          }
        />
        <hr />
        <RowOneLeftTwoRight
          colOne={<Field name="note" label="Note" value={data.note} />}
        />
      </Panel>
      <Controls isEdit={false} {...props} />
    </div>
  );
};

export default View;
