import React from "react";
// import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
// import DatePicker from "react-datepicker";
import withLoading from "../common/withLoading";
import SidebarLeft from "../common/SidebarLeft";
import ListGroup from "../common/listGroup";
import SelectDateBox from "./../common/SelectDateBox";
import SearchBox from "../common/searchBox";
import ListControls from "./ListControls";

import DocumentTable from "./DocumentTable";

import {
  getDocumentsQuery,
  getInventoryDocumentTypes,
  deleteDocument,
} from "../../services/documentService";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { paginate } from "../../utils/paginate";
import _ from "lodash";
import NewDocument from "./NewDocument";

class Documents extends React.Component {
  state = {
    documents: [],
    types: [],
    fromDate: DateTime.local().startOf("month").minus({ months: 2 }),
    toDate: DateTime.local().endOf("month").plus({ months: 1 }),
    sortColumn: { path: "doc.txnDate", order: "asc" },
    currentPage: 1,
    pageSize: 300,
    searchQuery: "",
    selectedType: null,
  };

  populateTypes = async () => {
    try {
      const { data } = await getInventoryDocumentTypes();
      const mapped = data.map((s) => {
        return { value: s.entityName, label: s.label };
      });
      const sorted = _.orderBy(mapped, "label", "asc");
      const types = [{ value: "", label: "All types" }, ...sorted];
      this.setState({ types });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  populateDocuments = async () => {
    let query = "";
    if (this.state.fromDate && this.state.toDate)
      query = `?fromDate=${this.state.fromDate.toISODate()}&toDate=${this.state.toDate.toISODate()}`;
    else if (this.state.fromDate)
      query = `?fromDate=${this.state.fromDate.toISODate()}`;
    else if (this.state.toDate)
      query = `?toDate=${this.state.toDate.toISODate()}`;
    // else query = "";

    try {
      const { data } = await getDocumentsQuery(query);
      const documents = data.map((d) => {
        d.search = d.data.txnNumber + " " + d.data.memo;
        return d;
      });
      this.setState({ documents });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      return null;
    }
  };

  async componentDidMount() {
    await this.populateTypes();
    await this.populateDocuments();
    this.props.loadingOff();
  }

  handleTypeSelect = (type) => {
    this.setState({
      selectedType: type,
      searchQuery: "",
      currentPage: 1,
    });
  };

  handleDateChange = async () => {
    this.props.loadingOn();
    await this.populateDocuments();
    this.props.loadingOff();
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      selectedType: null,
      currentPage: 1,
    });
  };

  handleSort = (sortColumn) => {
    this.setState({
      sortColumn: sortColumn,
    });
  };

  handleClick = (document) => {
    this.props.history.push(`/documents/${document._id}/edit`);
  };

  handleDelete = async (document) => {
    const originalItems = this.state.documents;
    const documents = originalItems.filter((m) => m._id !== document._id);
    this.setState({ documents });

    try {
      await deleteDocument(document._id);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This order has already been deleted.");

      this.setState({ documents: originalItems });
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  getPagedData = () => {
    const {
      documents,
      searchQuery,
      selectedType,
      sortColumn,
      currentPage,
      pageSize,
    } = this.state;
    const allDocuments = [...documents];
    let filtered = allDocuments;
    if (searchQuery) {
      filtered = allDocuments.filter((i) =>
        i.title.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else if (selectedType) {
      filtered = allDocuments.filter((i) => i.entity === selectedType.value);
    }

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const items = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: items };
  };

  render() {
    const { types, selectedType, sortColumn, fromDate, toDate, searchQuery } =
      this.state;
    const { data: documents, totalCount } = this.getPagedData();

    return (
      <>
        <SidebarLeft>
          <NewDocument />
          <ListGroup
            items={types}
            valueProperty="value"
            textProperty="label"
            selectedItem={selectedType}
            onItemSelect={this.handleTypeSelect}
            selectedCount={totalCount}
          />
        </SidebarLeft>
        <div className="col mt-2">
          <div className="d-flex py-2">
            <h2 className="text-uppercase">Inventory Documents</h2>
          </div>
          <SelectDateBox
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={(value) => this.setState({ fromDate: value })}
            setToDate={(value) => this.setState({ toDate: value })}
            handleDateChange={this.handleDateChange}
          />
          <div className="d-flex justify-content-between border-bottom d-print-none ">
            <SearchBox
              value={searchQuery}
              placeholder="Search ..."
              onChange={this.handleSearch}
            />
            <div>
              <ListControls />
            </div>
          </div>
          <div className="d-flex border-top pb-n5 mb-3">
            <DocumentTable
              items={documents}
              sortColumn={sortColumn}
              isList={false}
              onDelete={this.handleDelete}
              onSort={this.handleSort}
              onClick={this.handleClick}
            />
          </div>
        </div>
      </>
    );
  }
}

export default withLoading(Documents);
