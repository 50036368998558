import React from "react";
import { RowFourColumns } from "../common/formLayout";
import DetailsHeader from "./DetailsHeader";
import FieldTable from "../common/fieldTable";
import FieldTableNumber from "../common/fieldTableNumber";
import numeral from "numeral";

const ViewDetails = (props) => {
  const { details, totals } = props;
  return (
    <div>
      <table className="table table-sm border-bottom">
        <DetailsHeader />
        <tbody>
          {details &&
            details.map((line, index) => (
              <tr key={index} name={`details-${index}`}>
                <th scope="row" className="align-baseline text-center">
                  {line.lineId || index + 1}
                </th>
                <td>
                  <FieldTable
                    name={`details[${index}].item`}
                    value={line.item.label}
                  />
                </td>
                <td>
                  <FieldTable
                    name={`details[${index}].description`}
                    value={line.description}
                  />
                </td>
                <td>
                  <FieldTable
                    name={
                      line.costOption?.uom
                        ? `details[${index}].costOption.uom`
                        : `details[${index}].uom`
                    }
                    value={line.costOption?.uom || line.uom}
                  />
                  <div className="text-right">
                    <small className="text-primary">
                      {`base: ${numeral(
                        line?.costOption?.uomOption?.ratioToBase || 1
                      ).format("0,0.0")} ${
                        line?.costOption?.uomOption?.baseUom || "UOM"
                      }`}
                    </small>
                  </div>
                </td>
                <td>
                  <FieldTableNumber
                    name={`details[${index}].qty`}
                    value={line.qty}
                    decimal="1"
                  />
                  <div className="text-right pr-3">
                    <small className="text-primary">
                      {numeral(line.qty)
                        .multiply(line?.costOption?.uomOption?.ratioToBase || 1)
                        .format("0,0.0")}
                    </small>
                  </div>
                </td>
                <td>
                  <FieldTableNumber
                    name={`details[${index}].unitCostPerBase`}
                    value={
                      line.unitCostPerBase ||
                      numeral(line.unitCost)
                        .divide(line?.costOption?.uomOption?.ratioToBase || 1)
                        .value()
                    }
                    decimal="4"
                  />
                </td>
                <td>
                  <FieldTableNumber
                    name={`details[${index}].unitCost`}
                    value={line.unitCost}
                    decimal="4"
                  />
                </td>
                <td>
                  <FieldTableNumber
                    name={`details[${index}].amount`}
                    value={line.amount}
                    decimal="2"
                  />
                </td>
                <td>
                  <FieldTable
                    name={`details[${index}].taxCode`}
                    value={line.taxCode && line.taxCode.label}
                  />
                </td>
                <td></td>
              </tr>
            ))}
        </tbody>
      </table>
      <hr />
      <RowFourColumns
        colThree={<h6 className="text-right pt-2">Subtotal: </h6>}
        colFour={
          <FieldTableNumber
            name="totals.subTotal"
            value={totals && totals.subTotal}
            decimal="2"
          />
        }
      />
      <RowFourColumns
        colThree={<h6 className="text-right pt-2">Taxes: </h6>}
        colFour={
          <FieldTableNumber
            name="totals.taxTotal"
            value={totals && totals.taxTotal}
            decimal="2"
          />
        }
      />
      <RowFourColumns
        colThree={<h6 className="text-right pt-2">Order Total: </h6>}
        colFour={
          <FieldTableNumber
            name="totals.total"
            value={totals && totals.total}
            decimal="2"
          />
        }
      />
    </div>
  );
};

export default ViewDetails;
