import React, { Component } from "react";
// import auth from "../services/authService";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "../common/table";
import DateFormat from "../common/dateFormat";
import { detailsToTextArea } from "../../utils/poUtils";
// import { numberInteger } from "../../utils/numberUtils";

class OrderTable extends Component {
  tableColumns = [
    {
      path: "data.txnNumber",
      label: "PO Number",
      className: "text-center",
      content: (order) => (
        <Link to={`/purchase-orders/${order._id}`}>
          {order.data && order.data.txnNumber}
        </Link>
      ),
    },
    {
      path: "data.txnDate",
      label: "Date",
      content: (order) => (
        <DateFormat value={order.data && order.data.txnDate} />
      ),
    },
    {
      path: "data.vendor.label",
      label: "Supplier",
      // className: "text-center",
    },
    {
      path: "data.locationTo.label",
      label: "To",
      className: "text-center",
    },

    {
      path: "details",
      label: "Details",
      content: (order) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {detailsToTextArea(order.data.details)}
        </div>
      ),
    },
    {
      path: "data.memo",
      label: "Memo",
      content: (order) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {order.data && order.data.memo}
        </div>
      ),
    },

    {
      key: "controls",
      content: (order) => (
        <div className="text-right d-print-none">
          <button
            onClick={() => this.props.onClick(order)}
            className="btn btn-secondary btn-sm mr-1"
          >
            <FontAwesomeIcon icon="edit" />
          </button>
          <button
            onClick={() => this.props.onDelete(order)}
            className="btn btn-danger btn-sm"
          >
            <FontAwesomeIcon icon="trash" />
          </button>
        </div>
      ),
    },
  ];

  listColumns = [
    {
      path: "data.txnNumber",
      label: "Doc Number",
      className: "text-center",
      content: (order) => (
        <Link to={`/purchase-orders/${order._id}`}>
          {order.data && order.data.txnNumber}
        </Link>
      ),
    },
    {
      path: "data.txnDate",
      label: "Name",
      content: (order) => (
        <DateFormat value={order.data && order.data.txnDate} />
      ),
    },
    {
      path: "entity",
      label: "Transaction Type",
      className: "text-center",
    },
    {
      key: "delete",
      content: (order) => (
        <button
          onClick={() => this.props.onDelete(order)}
          className="btn btn-danger btn-sm"
        >
          <FontAwesomeIcon icon="trash" />
        </button>
      ),
    },
  ];

  // constructor() {
  //   super();
  //   // const user = auth.getCurrentUser();
  //   // if (user && user.isAdmin) this.tableColumns.push(this.deleteColumn);
  // }

  render() {
    const { items, onSort, sortColumn, isList } = this.props;
    const columns = isList ? this.listColumns : this.tableColumns;

    return (
      <Table
        columns={columns}
        data={items}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default OrderTable;
