import { v4 as uuidv4 } from "uuid";

export const generateRandomId = () =>
  (+new Date() + Math.floor(Math.random() * 999999)).toString(36);

// dec2hex :: Integer -> String
// i.e. 0-255 -> '00'-'ff'
// function dec2hex(dec) {
//   return ("0" + dec.toString(16)).substr(-2);
// }

// generateId :: Integer -> String
export const generateId = () => {
  // var arr = new Uint8Array((len || 40) / 2);
  // window.crypto.getRandomValues(arr);
  // return Array.from(arr, dec2hex).join("");
  return uuidv4();
};

export function camelPad(str = "") {
  return (
    str
      // Look for long acronyms and filter out the last letter
      .replace(/([A-Z]+)([A-Z][a-z])/g, " $1 $2")
      // Look for lower-case letters followed by upper-case letters
      .replace(/([a-z\d])([A-Z])/g, "$1 $2")
      // Look for lower-case letters followed by numbers
      .replace(/([a-zA-Z])(\d)/g, "$1 $2")
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
      // Remove any white space left around the word
      .trim()
  );
}
