import React, { useEffect, useState } from "react";
import XLSX from "xlsx";
import DragDropFile from "../DragDropFile";
import DataInput from "../DataInput";
import { RowFourColumns } from "../formLayout";
import InputText from "../inputText";
import InputSelect from "../inputSelect";
import ImportControls from "../ImportControls";
import ImportHeader from "../ImportHeader";
import Panel from "../panel";

const ImportXLS = (props) => {
  const {
    title = "Import Excel File",
    steps = ["Select File", "Mapping", "Review and Import", "Exit"],
    step = 0,
    mapping = {},
    imports = [],
    onNavigate = () => {},
    onFileSelect = () => {},
    onMapping = () => {},
    onImport = () => {},
  } = props;

  const handleFileSelect = (file /*:File*/) => {
    props.progressOn();
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();

    props.progressUpdate("Reading file...", 20);
    const rABS = !!reader.readAsBinaryString;
    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;

      const wb = XLSX.read(bstr, {
        type: "binary",
        raw: true,
        cellDates: true,
      });
      const workSheets = wb.SheetNames;
      if (!workSheets) return null;

      onFileSelect(wb);
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  };

  return (
    <>
      <div className="d-flex border-bottom py-2 mt-1">
        <h2 className="text-uppercase">{title}</h2>
      </div>
      <ImportHeader steps={steps} currentStep={step + 1} />
      {step === 0 && (
        <div id="step-1">
          <DragDropFile handleFile={handleFileSelect}>
            <div className="jumbotron">
              <h4 className="display-5">Import from XLS file</h4>
              <p className="lead">
                Select your file or download a sample. File columns must match
                requirements
              </p>
              <hr className="my-3" />
              <p>Drag and drop your file or click a button below</p>
              <div className="d-flex">
                <DataInput handleFile={handleFileSelect} />
              </div>
            </div>
          </DragDropFile>
        </div>
      )}
      {step === 1 && (
        <div id="step-2">
          <Panel>
            <h4>Data columns</h4>
            <hr />
            {Object.entries(mapping.schema).map(([k, v], i) => (
              <RowFourColumns
                key={k}
                colOne={
                  <h5>
                    {i + 1}. {v.label}
                    {v.required && "*"}
                  </h5>
                }
              />
            ))}
          </Panel>
          <div className="d-flex">
            <button className="btn btn-success shadow" onClick={onMapping}>
              Load Data
            </button>
          </div>
        </div>
      )}
      {step === 2 && (
        <div id="step-3">
          <Panel>
            <h4>Items loaded from the file</h4>
            <hr />
            <div>{`Total ${imports.length} items will be imported`}</div>
          </Panel>
          <div className="d-flex">
            <button className="btn btn-success shadow" onClick={onImport}>
              Import Data
            </button>
          </div>
        </div>
      )}
      {step === 3 && (
        <div id="step-4">
          <Panel>
            <h4>Items loaded from the file</h4>
            <hr />
            <div>{`Total ${imports.length} items will be imported`}</div>
          </Panel>
          <div className="d-flex">
            <button
              className="btn btn-info shadow"
              onClick={() => onNavigate(0)}
            >
              Import another file
            </button>
            <button
              className="btn btn-success shadow ml-2"
              onClick={() => props.history.push("/tools")}
            >
              Exit
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ImportXLS;
