import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import Select from "react-select";

const InputSelect = ({ name, options, ...props }) => {
  const { control, errors } = useFormContext();
  let ni1 = name.indexOf("[");
  let ni2 = name.indexOf("]");
  let arr = name.substring(0, ni1);
  const idx = parseInt(name.substring(ni1 + 1, ni2), 10);
  const item = name.substring(ni2 + 2);
  const err = errors[arr] && errors[arr][idx] ? errors[arr][idx][item] : null;
  // const err = null;
  const error = err ? err.message : null;
  // const error = errors[name] ? errors[name].message : null;

  return (
    <div className="form-group" style={{ marginBottom: "0px" }}>
      <div>
        <Controller
          name={name}
          control={control}
          render={({ name, value }) => (
            <Select name={name} value={value} options={options} {...props} />
          )}
        />
      </div>

      <small className="text-danger">{error}</small>
    </div>
  );
};

export default InputSelect;
