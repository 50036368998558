import React from "react";
import { Text, View } from "@react-pdf/renderer";
import numeral from "numeral";

const SoPdfSummary = ({ data, styling }) => {
  const { totals = {}, currency = {} } = data;

  const numberFormat = "0,0.00";
  const total = currency
    ? `${currency.value} ${numeral(totals.total).format(numberFormat)}`
    : `${numeral(totals.total).format(numberFormat)}`;
  return (
    <View wrap={false}>
      <Text style={styling.footerHorizontalLine}></Text>
      <View style={styling.footerArea}>
        <View style={styling.footerThanksForBusinessArea} wrap={true}>
          <Text>{data.memo}</Text>
        </View>
        <View style={styling.footerSubTotalArea}>
          <View style={styling.footerSubTotalLabel}>
            <Text style={styling.footerSubTotalLabel}>TOTAL</Text>
          </View>
          <View>
            <Text style={styling.footerSubTotalValue}>{total}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default SoPdfSummary;
