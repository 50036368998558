import React from "react";
import { Link } from "react-router-dom";
import Panel from "../common/panel";

const DocumentSelect = (props) => {
  return (
    <Panel>
      <div className="btn-group shadow" role="group">
        <Link
          className="btn btn-success"
          to="/documents/new?type=InventoryTransfer"
        >
          Inventory Transfer
        </Link>
        <Link
          className="btn btn-success"
          to="/documents/new?type=InventoryReceipt"
        >
          Inventory Receipt
        </Link>
        <Link
          className="btn btn-success"
          to="/documents/new?type=InventoryShipment"
        >
          Inventory Shipment
        </Link>
        <Link
          className="btn btn-success"
          to="/documents/new?type=InventoryWriteDown"
        >
          Inventory Write-down
        </Link>
        <Link
          className="btn btn-success"
          to="/documents/new?type=InventoryCount"
        >
          Inventory Count
        </Link>
      </div>
    </Panel>
  );
};
export default DocumentSelect;
