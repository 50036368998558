import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LocalLoader = () => {
  return (
    <div className="p-2">
      <div className="d-flex justify-content-center">
        <FontAwesomeIcon icon="spinner" size="2x" pulse />
      </div>
    </div>
  );
};

export default LocalLoader;
