import React from "react";
import queryString from "query-string";
import connect from "../../services/connectService";

const CompanyConnect = ({ location }) => {
  const result = queryString.parse(location.search);
  let errors = "";

  const getAccess = async (token) => {
    try {
      await connect.connectCompany(token);
      window.location = "/";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        errors = ex.response.data;
      }
    }
  };

  const isConnected = getAccess(result);

  return (
    <main className="container">
      <div className="sw-block">
        <div className="card shadow">
          <div className="card-body">
            <h5>Connection status:</h5>

            {isConnected ? (
              <p>Connected to database</p>
            ) : (
              <p>Can't connect. Errors: {errors}</p>
            )}
          </div>
        </div>
      </div>
    </main>
  );
};

export default CompanyConnect;
