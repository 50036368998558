import React from "react";

const Tab = ({ tab, selected, onSelectTab }) => {
  let classNames = "nav-item nav-link clickable";
  if (tab.isDisabled) classNames = classNames + " disabled";
  if (tab.label === selected?.label || tab === selected)
    classNames = classNames + " active";
  return (
    <li className={classNames} onClick={() => onSelectTab(tab)}>
      {tab.label || tab}
    </li>
  );
};

const PanelTabs = ({ tabs = [], selected = null, onSelectTab = () => {} }) => {
  return (
    <ul className="nav nav-tabs mt-2 mb-2">
      {tabs.map((tab) => (
        <Tab
          key={tab.label}
          tab={tab}
          selected={selected}
          onSelectTab={onSelectTab}
        />
      ))}
    </ul>
  );
};

export default PanelTabs;
