import React from "react";
import Panel from "../common/panel";

const User = ({ user }) => {
  return (
    <div>
      <h2>Current User</h2>
      <Panel>
        <div className="mb-2">
          <strong>Name: </strong>
          {user.name}
        </div>
        <div className="mb-2">
          <strong>Email: </strong>
          {user.email}
        </div>
        <div className="mb-2">
          <strong>Admin: </strong>
          {user.isAdmin ? "Yes" : "No"}
        </div>
      </Panel>
    </div>
  );
};

export default User;
