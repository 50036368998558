import React, { Component } from "react";
import auth from "../../services/authService";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "../common/table";
import { partsToTextArea } from "./../../utils/itemUtils";

class ItemTable extends Component {
  columns = [
    {
      path: "title",
      label: "Title",
      content: (item) => <Link to={`/items/${item._id}`}>{item.title}</Link>,
    },

    // {
    //   path: "data.description",
    //   label: "Sales Description",
    //   className: "w-25",
    //   content: (item) => (
    //     <div style={{ whiteSpace: "pre-wrap" }}>
    //       {item.data && item.data.description}
    //     </div>
    //   ),
    // },
    {
      path: "data.purchaseDescription",
      label: "Purchase Description",
      className: "w-25",
      content: (item) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {item.data && item.data.purchaseDescription}
        </div>
      ),
    },
    {
      path: "data.preferredVendor.label",
      label: "Preferred Supplier",
    },
    {
      path: "data.uom",
      className: "text-center",
      label: "UOM",
    },
    {
      path: "data.parts",
      label: "Parts",
      className: "w-25",
      content: (item) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {item.data && item.data.parts && partsToTextArea(item.data.parts)}
        </div>
      ),
    },
    {
      path: "data.isSales",
      label: "SALES",
      className: "text-center text-primary",
      content: (item) =>
        item.data &&
        item.data.isSales && <FontAwesomeIcon icon="check-circle" />,
    },
    {
      path: "data.isPurchasing",
      label: "PUR",
      className: "text-center text-primary",
      content: (item) =>
        item.data &&
        item.data.isPurchasing && <FontAwesomeIcon icon="check-circle" />,
    },
    {
      path: "data.isProduction",
      label: "PROD",
      className: "text-center text-primary",
      content: (item) =>
        item.data &&
        item.data.isProduction && <FontAwesomeIcon icon="check-circle" />,
    },
    {
      path: "qbo",
      label: "QBO",
      className: "text-center text-success",
      content: (item) => item.qbo && <FontAwesomeIcon icon="check-circle" />,
    },
  ];

  listColumns = [
    {
      path: "title",
      label: "Title",
      content: (item) => (
        <Link to={`/detail-items/${item._id}`}>{item.title}</Link>
      ),
    },
    {
      path: "data.sku",
      className: "text-center",
      label: "SKU",
    },
    {
      path: "data.uom",
      className: "text-center",
      label: "UOM",
    },
    {
      path: "data.description",
      label: "Description",
      content: (item) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {item.data && item.data.description}
        </div>
      ),
    },
    {
      path: "data.unitPrice",
      className: "text-center",
      label: "Price",
    },
  ];

  deleteColumn = {
    key: "delete",
    content: (item) => (
      <button
        onClick={() => this.props.onDelete(item)}
        className="btn btn-danger btn-sm"
      >
        <FontAwesomeIcon icon="trash" />
      </button>
    ),
  };

  constructor() {
    super();
    const user = auth.getCurrentUser();
    if (user && user.isAdmin) this.columns.push(this.deleteColumn);
  }

  render() {
    const { items, onSort, sortColumn, isList } = this.props;

    return (
      <Table
        columns={isList ? this.listColumns : this.columns}
        data={items}
        sortColumn={sortColumn}
        onSort={onSort}
      />
    );
  }
}

export default ItemTable;
