import React from "react";
import ProgressBar from "./progressBar";

const ProgressModal = ({ title, value = 50, total = 100 }) => {
  if (isNaN(value)) value = 50;
  if (isNaN(total)) total = 100;

  let percentage = Math.abs(value / total) * 100;
  if (isNaN(percentage)) percentage = 1;
  if (percentage > 100) percentage = 100;

  let message = `Working on ${value} of ${total}`;

  return (
    <div className="io-modal">
      <div className="io-modal-dialog">
        <div className="card shadow">
          <div className="card-body">
            <h5 className="card-title">{title}</h5>
            <p>{message}</p>
            <ProgressBar percentage={percentage} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressModal;
