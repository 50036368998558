// import * as fs from "fs";

// export async function base64Encode(file) {
//   return `data:${file.type};base64,` + fs.readFileSync(file, "base64");
// }

export const getBase64 = (file) => {
  return new Promise((resolve) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
  });
};
