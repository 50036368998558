import React from "react";
import ImageCard from "./ImageCard";
// import InventoryCard from "./InventoryCard";

const InventoryCards = ({ items }) => {
  return (
    <div className="row row-cols-sm-1 row-cols-lg-2 row-cols-xl-4">
      {items.map((item) => (
        <div key={item._id} className="col mb-3">
          <ImageCard item={item} />
          {/* <InventoryCard item={item} /> */}
        </div>
      ))}
    </div>
  );
};

export default InventoryCards;
