import React from "react";
import PagePrint from "../common/PagePrint";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VendorsControls = (props) => {
  return (
    <div className="d-print-none">
      {props.user.isAdmin && (
        <div
          className="btn btn-sm btn-info shadow-sm mr-1"
          data-toggle="tooltip"
          data-placement="top"
          title="Sync with QuickBooks Online"
          onClick={() => props.onQboSync()}
        >
          <FontAwesomeIcon icon="sync" />
        </div>
      )}
      <PagePrint />
    </div>
  );
};

export default VendorsControls;
