import React from "react";

const PdfView = (props) => {
  return (
    <div
      className="modal fade"
      id={props.id}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="PDFModalWindow"
      aria-hidden="true"
    >
      <div
        className="modal-dialog mw-100 mh-100"
        style={{ width: "95%", height: "95%" }}
        role="document"
      >
        <div className="modal-content" style={{ height: "100%" }}>
          <div className="modal-header">
            <h5 className="modal-title" id="PDFModalWindow">
              {props.title ? props.title : "PDF view"}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {props.children}
            {/* <object
              data={props.pdfFile}
              type="application/pdf"
              width="100%"
              height="100%"
              style={{ minHeight: "780px" }}
            >
              <iframe
                title="Job Drawings"
                src={props.pdfFile}
                width="100%"
                height="100%"
                style={{ border: "none", minHeight: "780px" }}
              >
                <p>
                  Your browser does not support PDFs.
                  <a href={props.pdfFile}>Download the PDF</a>.
                </p>
              </iframe>
            </object> */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
      {/* <a href={samplePDF} rel="noopener noreferrer" target="_blank">
          Download Pdf
        </a> */}
    </div>
  );
};

export default PdfView;
