import React, { useEffect, useState } from "react";
import Panel from "../common/panel";
import Table from "./../common/table";
import { getStorages } from "../../services/entityService";
import _ from "lodash";
import numeral from "numeral";

const InStock = (props) => {
  const { current } = props;

  const [sortColumn, setSortColumn] = useState({ path: "label", order: "asc" });
  const [storages, setStorages] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const updated = current.map((loc) => {
      const storage = storages.find((s) => s._id === loc.locationId);
      if (storage) {
        loc = {
          value: loc.locationId,
          label: storage.title || "",
          code: storage.data.code || "",
          qty: loc.qty,
        };
      }
      return loc;
    });

    const sorted = _.orderBy(updated, "code", "asc");
    setLocations(sorted);
    return () => {};
  }, [storages, current]);

  useEffect(() => {
    const populateStorages = async () => {
      const { data } = await getStorages();
      setStorages(data);
    };

    populateStorages();
  }, []);

  const columns = [
    {
      path: "label",
      label: "Location",
    },
    {
      path: "qty",
      label: "Qty",
      className: "text-right pr-2",
      content: (item) => numeral(item.qty).format("0,0.000"),
    },
  ];

  const totalInStock = current.reduce((sum, i) => {
    return sum + i.qty;
  }, 0);

  return (
    <div>
      <h4>In stock</h4>
      <Panel>
        <Table
          columns={columns}
          data={locations}
          sortColumn={sortColumn}
          onSort={(sortColumn) => setSortColumn(sortColumn)}
        />
        <h6 className="text-right pr-2">{`Total: ${numeral(totalInStock).format(
          "0,0.000"
        )}`}</h6>
      </Panel>
    </div>
  );
};

export default InStock;
