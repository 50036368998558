import React, { useEffect, useState } from "react";
import XLSX from "xlsx";
import withLoading from "../common/withLoading";
// import Pagination from "./common/pagination";
// import { paginate } from "../utils/paginate";
// import {
//   getCustomerInvoices,
//   createInvoicesFromGEFile,
//   createInvoicesFromXLS,
//   //   updateInvoicesDetailsQty,
// } from "../utils/invoiceUtils";
// import { getCustomerOrders } from "../utils/salesOrderUtils";
// import { saveEntity, getEntities } from "./../services/entityService";
// import { getCustomerRefs } from "./../utils/customerUtils";
// import { getItemRefs } from "./../utils/itemUtils";
// import { mapToQbo } from "../utils/invoiceQBOExport";
// import { saveQboEntity } from "../services/qboEntityService";
// import InvoiceTable from "./invoiceTable";
import { getVendors, getItems, saveEntity } from "../../services/entityService";
import _ from "lodash";
import { toast } from "react-toastify";
import ImportXLS from "../common/ImportXLS/ImportXLS";

const ImportItems = (props) => {
  const { loadingOn, loadingOff } = props;

  const steps = [
    // "Preset",
    "Select File",
    "Map columns",
    "Review Imports",
    "Exit Page",
  ];
  const mappingOptions = ["column", "default", "selected"];
  const concatChars = [" ", ", ", " / ", " | ", " - ", " > "];
  const schema = {
    type: { label: "Type", option: "", value: "" },
    category: { label: "Category", option: "", value: "" },
    sku: { label: "SKU", option: "", value: "", required: true },
    name: { label: "Name", option: "", value: "", required: true },
    description: { label: "Description", option: "", value: "" },
    purchaseDescription: {
      label: "PurchaseDescription",
      option: "",
      value: "",
    },
    uom: { label: "Base UOM", option: "", value: "", required: true },
    cost: { label: "Purchase Cost", option: "", value: "" },
    unitPrice: { label: "Sale Price", option: "", value: "" },
    preferredSupplier: { label: "Preferred Supplier", option: "", value: "" },
    brand: { label: "Brand", option: "", value: "" },
    productLine: { label: "Product Line", option: "", value: "" },
    material: { label: "Material", option: "", value: "" },
    finish: { label: "Finish", option: "", value: "" },
    thickness: { label: "Thickness", option: "", value: "" },
    size: { label: "Size", option: "", value: "" },
    width: { label: "Width", option: "", value: "" },
    height: { label: "Height", option: "", value: "" },
    weight: { label: "Weight", option: "", value: "" },
  };

  const mappingSchema = {
    concatChars,
    mappingOptions,
    schema,
  };
  const [step, setStep] = useState(0);
  const [defaults, setDefaults] = useState({});
  const [workbook, setWorkbook] = useState({});
  const [workSheets, setWorkSheets] = useState([]);
  const [selectedWorkSheet, setSelectedWorkSheet] = useState("");
  const [vendors, setVendors] = useState([]);
  const [items, setItems] = useState([]);
  const [mapping, setMapping] = useState(mappingSchema);
  const [imports, setImports] = useState([]);

  const populateItems = async () => {};

  useEffect(() => {
    const populateVendors = async () => {
      const { data } = await getVendors();
      const mapped = data.map((s) => {
        return { value: s._id, label: s.title };
      });
      if (mapped) setVendors(mapped);
    };
    populateVendors();
    return () => {
      setVendors([]);
    };
  }, []);

  useEffect(() => {
    const populateItems = async () => {
      const { data } = await getItems();
      if (data) setItems(data);
    };
    populateItems();
    loadingOff();
    return () => {
      setItems([]);
    };
  }, []);

  const createItemsFromXLS = (values) => {
    const items = [];
    for (const row of values) {
      const data = {
        name: row.name,
        type: "NonInventory",
        isPurchasing: true,
        uom: row.baseUom,
        sku: row.sku,
        description: row.name + " | " + row.productLine + " | " + row.supplier,
        purchaseDescription: row.description,
        category: row.productLine || "",
        cost: row.baseCost,
        uomOptions: [
          {
            baseUom: row.baseUom,
            code: row.uom,
            description: row.size,
            ratioToBase: row.ratioToBase,
          },
        ],
        costOptions: [
          {
            code: row.specialPricing,
            description: row.specialPricing,
            cost: row.cost,
            uomOption: {
              baseUom: row.baseUom,
              uom: row.uom,
              code: row.uom,
              description: row.size,
              label: row.uom,
              ratioToBase: row.ratioToBase,
              value: row.uom,
            },
          },
        ],
        meta: {
          supplier: row.supplier || "",
          size: row.size || "",
          thickness: row.thickness || "",
          material: row.material || "Quarz",
          finish: row.finish || "",
          brand: row.brand || row.supplier,
          name: row.materialName || "",
        },
      };
      const vendorRef = vendors.find((v) =>
        v.label.toLowerCase().includes(row.supplier.toLowerCase())
      );
      if (vendorRef) data.preferredVendor = vendorRef;
      const item = {
        title: data.name,
        entity: "Item",
        data,
      };
      items.push(item);
    }
    return items;
  };

  const handleMove = (value) => {
    if (value === "+") setStep(step + 1);
    if (value === "-") setStep(step - 1);
  };

  const handleNavigate = (value) => {
    if (value === 0) window.location.href = "/import-items";
    // setStep(value);
  };

  const handleFileSelect = (wb) => {
    if (wb) {
      const workSheets = wb.SheetNames;
      setWorkbook(wb);
      setWorkSheets(workSheets);
      if (workSheets.length === 1) {
        const wsname = wb.SheetNames[0];
        setSelectedWorkSheet(wsname);
        // this.importFileData();
      }
      setStep(step + 1);
      props.progressOff();
    } else {
      props.progressOff();
    }
  };

  const handleMapping = async () => {
    props.progressOn();
    const ws = workbook.Sheets[selectedWorkSheet];
    props.progressUpdate("Reading data...", 40);

    const data = XLSX.utils.sheet_to_json(ws, {
      header: 1,
      dateNF: "YYYY-MM-DD",
    });

    const originalCols = data.shift();
    const cols = originalCols.map((c) => {
      return c.replace(/-|\s/g, "");
    });

    props.progressUpdate("Verifying mapping...", 60);
    const requiredFields = [];
    Object.entries(schema).forEach(([k, v]) => {
      if (v.required) requiredFields.push(k);
    });
    // console.log("Required", requiredFields);
    // console.log("Cols", cols);
    for (let item of requiredFields) {
      const found = cols.find((col) => col === item);
      // if (!found) console.log("Not found:", item);
      if (!found) {
        toast.error("Incorrect file, missing required fields");
        props.progressOff();
        setStep(0);
        setWorkbook({});
        setWorkSheets([]);
        setSelectedWorkSheet("");
        break;
      }
    }

    if (!selectedWorkSheet) return;

    const named = data.map((line) => {
      let newLine = {};
      line.forEach((value, index) => {
        const key = cols[index];
        newLine[key] = value;
      });
      return newLine;
    });

    const items = createItemsFromXLS(named);
    console.log("Item List", items.length);
    setImports(items);
    setStep(step + 1);

    props.progressOff();
  };

  const saveItem = async (item) => {
    const found = items.find(
      (i) => i.title.toLowerCase() === item.title.toLowerCase()
    );
    if (found) {
      const newData = { ...item.data };
      item = { ...found };
      item.data = newData;
    }

    console.log("Item: ", item);
    try {
      const { data } = await saveEntity(item);
      return data;
    } catch (error) {
      item.errors = [{ massage: "Can't save item", error }];
      return item;
    }
  };

  const handleImport = async () => {
    props.progressOn();
    let d = 0;
    for (let item of imports) {
      item = await saveItem(item);
      d++;
      props.progressUpdate("Saving items...", d, imports.length);
      console.log("Saved: ", d, item.title);
    }

    props.progressOff();

    const notSaved = imports.filter((o) => o.errors !== undefined);
    if (notSaved.length !== 0) setImports(notSaved);
    else setStep(step + 1);
  };

  return (
    <div className="container mt-2">
      <ImportXLS
        title="Import Items"
        steps={steps}
        step={step}
        mapping={mapping}
        imports={imports}
        onDirection={handleMove}
        onNavigate={handleNavigate}
        onFileSelect={handleFileSelect}
        onMapping={handleMapping}
        onImport={handleImport}
        {...props}
      />
    </div>
  );
};

export default withLoading(ImportItems);
