import React from "react";

function ImportHeader(props) {
  const { steps, currentStep } = props;

  const stepClass = (step, active) => {
    if (step < active) return "text-muted";
    else if (step === active) return "text-info font-weight-bolder";
    else return "d-none";
  };
  return (
    <div id="ImportHeader">
      <div className="row pt-4 text-uppercase">
        {steps.map((item, index) => (
          <div className="col" key={`item-${index}`}>
            <h5 className={stepClass(index + 1, currentStep)}>
              {`${index + 1}. ${item}`}
            </h5>
          </div>
        ))}
      </div>
      <hr />
    </div>
  );
}

export default ImportHeader;
