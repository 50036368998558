import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import numeral from "numeral";

const InputNumber = ({
  label,
  name,
  decimalScale = "2",
  handleBlur = () => "void",
  ...props
}) => {
  const { control, errors } = useFormContext();
  const error = errors[name] ? errors[name].message : null;

  const updateBlur = (value, onBlur) => {
    handleBlur(value);
    value = numeral(value).value();
    onBlur();
  };

  return (
    <div className="form-group">
      {label ? (
        <label
          htmlFor={name}
          className="form-label form-label-sm font-weight-bold"
          style={{ marginBottom: "0px", marginRight: "10px" }}
        >
          {label}
        </label>
      ) : null}
      <div>
        <Controller
          name={name}
          control={control}
          render={({ onChange, onBlur, value }) => (
            <NumberFormat
              className="form-control text-right"
              value={value}
              thousandSeparator={true}
              // isNumericString={true}
              fixedDecimalScale={true}
              decimalScale={decimalScale}
              onChange={onChange}
              // onBlur={onBlur}
              onBlur={() => updateBlur(value, onBlur)}
              {...props}
            />
          )}
        />
      </div>

      <small className="text-danger">{error}</small>
    </div>
  );
};

export default InputNumber;
