import React from "react";

export const FormTitle = ({ title, info }) => {
  return (
    <div className="mt-4 d-flex justify-content-between">
      <h2 className="text-uppercase">{title}</h2>
      <div className="h2">{info}</div>
    </div>
  );
};

export const FormSubTitle = ({ title }) => {
  return <h5 className="text-uppercase pt-1 border-bottom">{title}</h5>;
};

export const RowFourColumns = ({ colOne, colTwo, colThree, colFour }) => {
  return (
    <div className="row">
      <div className="col-md-6 d-flex">
        <div className="col-md-6 pl-0">{colOne && colOne}</div>
        <div className="col-md-6 pr-0">{colTwo && colTwo}</div>
      </div>
      <div className="col-md-6 d-flex justify-content-end">
        <div className="col-md-6 pl-0">{colThree && colThree}</div>
        <div className="col-md-6 pr-0">{colFour && colFour}</div>
      </div>
    </div>
  );
};

export const RowTwoColumns = ({ colOne, colTwo }) => {
  return (
    <div className="row">
      <div className="col-md-6">{colOne && colOne}</div>
      <div className="col-md-6">{colTwo && colTwo}</div>
    </div>
  );
};

export const RowTwoColLeftSmall = ({ colOne, colTwo }) => {
  return (
    <div className="row">
      <div className="col-md-3">{colOne && colOne}</div>
      <div className="col-md-9">{colTwo && colTwo}</div>
    </div>
  );
};

export const RowOneLeftTwoRight = ({ colOne, colTwo, colThree }) => {
  return (
    <div className="row">
      <div className="col-md-6">{colOne && colOne}</div>
      <div className="col-md-6 d-flex justify-content-end">
        <div className="col-md-6 pl-0">{colTwo && colTwo}</div>
        <div className="col-md-6 pr-0">{colThree && colThree}</div>
      </div>
    </div>
  );
};

export const RowTwoLeftOneRight = ({ colOne, colTwo, colThree }) => {
  return (
    <div className="row">
      <div className="col-md-6 d-flex">
        <div className="col-md-6 pl-0">{colOne && colOne}</div>
        <div className="col-md-6 pr-0">{colTwo && colTwo}</div>
      </div>
      <div className="col-md-6">{colThree && colThree}</div>
    </div>
  );
};
