import React from "react";
import { useFormContext } from "react-hook-form";

const InputTableText = ({ name, ...rest }) => {
  const { register, errors } = useFormContext();

  let ni1 = name.indexOf("[");
  let ni2 = name.indexOf("]");
  let arr = name.substring(0, ni1);
  const idx = parseInt(name.substring(ni1 + 1, ni2), 10);
  const item = name.substring(ni2 + 2);
  const err = errors[arr] && errors[arr][idx] ? errors[arr][idx][item] : null;
  const error = err?.message ?? null;

  return (
    <div className="form-group" style={{ marginBottom: "0px" }}>
      <input
        name={name}
        id={name}
        ref={register()}
        className="form-control"
        {...rest}
      />
      <small className="text-danger">{error}</small>
    </div>
  );
};

export default InputTableText;
