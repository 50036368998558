import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import withLoading from "../common/withLoading";
import ProgressModal from "../common/progressModal";
import SidebarLeft from "../common/SidebarLeft";
import SearchBox from "../common/searchBox";
import VendorsControls from "./VendorsControls";
import VendorTable from "./VendorTable";
import Pagination from "../common/pagination";
import { paginate } from "../../utils/paginate";
import { getEntities, saveEntity } from "../../services/entityService";
import { mapQBOtoData } from "./utils";
import { updateData } from "../../utils/updateData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";

class Vendors extends React.Component {
  state = {
    vendors: [],
    sortColumn: { path: "data.code", order: "asc" },
    selectedStatus: null,
    currentPage: 1,
    pageSize: 150,
    searchQuery: "",
    loading: true,
    showProgress: false,
    progress: { title: "", value: 0, total: 100 },
  };

  async getVendors() {
    try {
      const { data } = await getEntities("Vendor");
      const vendors = data.map((vendor) => {
        let search = vendor.title;
        if (vendor.data && vendor.data.note)
          search = search + " " + vendor.data.note;
        if (vendor.data && vendor.data.phone)
          search = search + " " + vendor.data.phone;
        vendor.search = search;
        return vendor;
      });
      return vendors;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      return null;
    }
  }
  async componentDidMount() {
    const vendors = await this.getVendors();

    this.setState({ vendors });
    this.props.loadingOff();
  }

  handleSort = (sortColumn) => {
    this.setState({
      sortColumn: sortColumn,
    });
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      // selectedStatus: null,
      currentPage: 1,
    });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  openProgressBar = () => {
    this.setState({ showProgress: true });
  };

  handleProgressBar = (value = 50) => {
    let { progress } = this.state;
    console.log("handle progress");
    progress.value = value;
    this.setState({ progress });
  };

  closeProgressBar = () => {
    let progress = { title: "", value: 0, total: 100 };
    this.setState({ progress, showProgress: false });
  };

  handleQboSync = async () => {
    const { vendors, progress } = this.state;

    progress.title = "Saving data...";
    progress.total = vendors.length;

    this.setState({ progress });
    this.openProgressBar();

    let d = 0;
    for (let vendor of vendors) {
      d++;
      progress.value = d;
      this.setState(progress);

      const mapped = mapQBOtoData(vendor.qbo);
      const data = updateData(vendor.data, mapped);

      let entity = {
        _id: vendor._id,
        entity: vendor.entity,
        title: vendor.title,
        data: data,
      };

      try {
        let { data: saved } = await saveEntity(entity);
        console.log("Saved: ", progress.value, saved.title);
      } catch (error) {
        vendor.errors = [];
        vendor.errors.push({ message: "Can't save this vendor", error: error });
        console.log("Error: " + error);
      }
    }

    this.closeProgressBar();
  };

  handleClick = (vendor) => {
    this.props.history.push(`/vendors/${vendor._id}/edit`);
  };

  handleDelete = async (vendor) => {
    const originalVendors = this.state.vendors;
    const vendors = originalVendors.filter((m) => m._id !== vendor._id);
    this.setState({ vendors });

    const inactive = {
      _id: vendor._id,
      title: vendor.title,
      entity: vendor.entity,
      data: vendor.data,
    };

    inactive.data.isInactive = true;

    try {
      await saveEntity(inactive);
    } catch (ex) {
      if (ex.response && ex.response.status === 404)
        toast.error("This vendor already has been deleted.");

      this.setState({ vendors: originalVendors });
    }
  };

  getPagedData = () => {
    const { vendors, sortColumn, searchQuery, currentPage, pageSize } =
      this.state;
    const allVendors = [...vendors];
    let filtered = allVendors;

    if (searchQuery) {
      filtered = allVendors.filter((i) =>
        i.search.toLowerCase().includes(searchQuery.toLowerCase())
      );
      // } else if (selectedStatus && selectedStatus.value) {
      //   filtered = allJobs.filter(
      //     (i) => i.data.status.value === selectedStatus.value
      //   );
    }

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const paged = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: paged };
  };

  render() {
    const {
      progress,
      showProgress,
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
    } = this.state;
    const { totalCount, data: vendors } = this.getPagedData();

    return (
      <>
        <SidebarLeft>
          <div>
            <Link
              to="/vendors/new"
              className="btn btn-primary shadow"
              style={{ marginBottom: 20 }}
            >
              <FontAwesomeIcon icon="plus" className="mr-2" />
              Add Vendor
            </Link>
          </div>
        </SidebarLeft>
        <div className="col mt-2">
          <div className="d-flex py-2">
            <h2 className="text-uppercase">Vendors</h2>
          </div>
          <div className="d-flex justify-content-between border-bottom py-2 d-print-none ">
            <SearchBox
              value={searchQuery}
              placeholder="Search by name, note or phone number..."
              onChange={this.handleSearch}
            />
            <div>
              <VendorsControls
                onQboSync={this.handleQboSync}
                user={this.props.user}
              />
            </div>
          </div>
          <div className="d-flex border-top pb-n5 mb-3">
            <VendorTable
              items={vendors}
              sortColumn={sortColumn}
              isList={false}
              onDelete={this.handleDelete}
              onSort={this.handleSort}
              onClick={this.handleClick}
            />
          </div>
          <Pagination
            itemsCount={totalCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={this.handlePageChange}
          />
          <div id="progressBar" className={showProgress ? "" : "d-none"}>
            <ProgressModal
              title={progress.title}
              value={progress.value}
              total={progress.total}
            />
          </div>
        </div>
      </>
    );
  }
}

export default withLoading(Vendors);
