import React from "react";
import { useFormContext } from "react-hook-form";

const CheckBox = ({ name, label, inputClass = "", ...props }) => {
  const { register, errors } = useFormContext();
  const classes = "form-group form-check pt-4 " + inputClass;
  const error = errors[name] ? errors[name].message : null;

  return (
    <div className={classes}>
      <input
        type="checkbox"
        id={name}
        name={name}
        className="form-check-input"
        ref={register}
        {...props}
      />
      <label className="form-check-label font-weight-bold pt-1">{label}</label>
      <small className="text-danger">{error}</small>
    </div>
  );
};

export default CheckBox;
