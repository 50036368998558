import React from "react";
import { NavLink, Route, Redirect, Switch } from "react-router-dom";
// import auth from "../services/authService";
import User from "./User";
import QboConnect from "./QboConnect";
import General from "./General";
import GeneralInfoEdit from "./GeneralEdit";
import Preferences from "./Preferences";
import PreferencesEdit from "./PreferencesEdit";

function LeftBarItems({ user }) {
  // const user = auth.getCurrentUser();
  const leftBarLinks = (
    <ul className="list-group shadow-sm">
      <NavLink
        to="/settings/me"
        className="list-group-item list-group-item-action"
        activeClassName="active"
      >
        User
      </NavLink>
      <NavLink
        to="/settings/general"
        className="list-group-item list-group-item-action"
        activeClassName="active"
      >
        General
      </NavLink>
      <NavLink
        to="/settings/preferences"
        className="list-group-item list-group-item-action"
        activeClassName="active"
      >
        Preferences
      </NavLink>
      {user.isAdmin && (
        <NavLink
          to="/settings/qbo-connect"
          className="list-group-item list-group-item-action"
          activeClassName="active"
        >
          QBO Connect
        </NavLink>
      )}

      {/* {user.isAdmin && (
        <NavLink
          to="/settings/processes"
          className="list-group-item list-group-item-action"
          activeClassName="active"
        >
          Processes
        </NavLink>
      )}

      {user.isAdmin && (
        <NavLink
          to="/settings/ordertypes"
          className="list-group-item list-group-item-action"
          activeClassName="active"
        >
          Order Types
        </NavLink>
      )} */}
    </ul>
  );

  return leftBarLinks;
}

const Settings = ({ match, location, user }) => {
  return (
    <div className="row" style={{ marginLeft: "0px", marginRight: "0px" }}>
      <div id="sidebar-left" className="col-2 pt-3">
        <LeftBarItems user={user} />
      </div>
      <div className="col-8 pt-3">
        <Switch>
          <Route
            path={`${match.path}/me`}
            component={(props) => <User {...props} user={user} />}
          />
          <Route
            path={`${match.path}/qbo-connect`}
            component={(props) => <QboConnect {...props} />}
          />
          {/* <Route path={`${match.path}/users/:id`} component={UserForm} />
          <Route path={`${match.path}/users`} component={SettingsUsers} /> */}
          <Route
            path={`${match.path}/preferences/edit`}
            component={(props) => <PreferencesEdit {...props} />}
          />
          <Route
            path={`${match.path}/preferences`}
            component={(props) => <Preferences {...props} />}
          />
          <Route
            path={`${match.path}/general/edit`}
            component={(props) => <GeneralInfoEdit {...props} user={user} />}
          />
          <Route
            path={`${match.path}/general`}
            component={(props) => <General {...props} user={user} />}
          />
          <Redirect
            from={`${match.path}/`}
            exact
            to={`${match.path}/general`}
          />
        </Switch>
      </div>
    </div>
  );
};

export default Settings;
