import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import LoginForm from "./loginForm";
import companySelect from "./authSelect";
import ForgotPassword from "./authForgotPassword";
import ResetPassword from "./authResetPassword";
import auth from "../../services/authService";

const Auth = ({ match }) => {
  if (auth.getCurrentUser()) return <Redirect to="" />;

  return (
    <div className="container">
      <div className="sw-block">
        <div className="card shadow">
          <Switch>
            <Route path={`${match.path}/login`} component={LoginForm} />
            <Route path={`${match.path}/select`} component={companySelect} />
            <Route
              path={`${match.path}/forgot-password`}
              component={ForgotPassword}
            />
            <Route
              path={`${match.path}/reset-password/:token`}
              component={ResetPassword}
            />
            <Redirect to={`${match.path}/login`} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Auth;
