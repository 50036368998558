import React from "react";
import { useFormContext, useFieldArray } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputTableText from "../common/inputTableText";
import InputTableTextArea from "../common/inputTableTextArea";
import InputTableNumber from "../common/inputTableNumber";
import Button from "../common/button";

const UomOptionsEdit = ({ data }) => {
  const initialDetail = {
    code: "",
    description: "",
    baseUom: data.uom,
    ratioToBase: 1,
  };
  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "uomOptions",
  });

  return (
    <div id="uomOptions" className="mt-2">
      <h5 className="text-uppercase">Uom Options</h5>
      <table className="table table-sm border-bottom">
        <thead>
          <tr className="text-center">
            <th scope="col" style={{ width: "20%" }}>
              Code
            </th>
            <th scope="col" style={{ width: "50%" }}>
              Description
            </th>
            <th scope="col" style={{ width: "20%" }}>
              Base UOM
            </th>
            <th scope="col" style={{ width: "20%" }}>
              Ratio to Base
            </th>
            <th scope="col" style={{ width: "5%" }}></th>
          </tr>
        </thead>
        <tbody>
          {fields.map((line, index) => (
            <tr key={index} name={`option-${index}`}>
              <td className="align-baseline">
                <InputTableText
                  name={`uomOptions[${index}].code`}
                  defaultValue={line.code}
                />
              </td>
              <td className="align-baseline">
                <InputTableTextArea
                  name={`uomOptions[${index}].description`}
                  defaultValue={line.description}
                />
              </td>
              <td>
                <InputTableText
                  name={`uomOptions[${index}].baseUom`}
                  defaultValue={line.baseUom}
                  readOnly
                />
              </td>
              <td className="align-baseline">
                <InputTableNumber
                  name={`uomOptions[${index}].ratioToBase`}
                  // onBlur={handleBlur}
                  defaultValue={line.ratioToBase}
                  decimalScale="4"
                />
              </td>
              <td className="align-baseline">
                <button
                  className="btn btn-secondary btn-sm d-print-none"
                  type="button"
                  onClick={() => remove(index)}
                >
                  <FontAwesomeIcon icon="trash" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="d-flex justify-content-between">
        <div>
          <Button
            label="+ Add UOM Option"
            type="button"
            className="btn-primary btn-sm shadow-sm"
            onClick={() => append(initialDetail)}
          />
        </div>
      </div>
    </div>
  );
};

export default UomOptionsEdit;
