import React from "react";
import PoPdfHeader from "./PoPdfHeader";
import PoPdfContentSimple from "./PoPdfContentSimple";
import PoPdfFooter from "./PoPdfFooter";
import { Document, Page } from "@react-pdf/renderer";

import { docPrintStyles as styling } from "../common/docPrint/styling";

const SoPdf = (props) => {
  const { title, info, data } = props;

  return (
    <Document>
      <Page size="LETTER" wrap style={styling.page}>
        <PoPdfHeader title={title} info={info} styling={styling} data={data} />
        <PoPdfContentSimple data={data} styling={styling} />
        <PoPdfFooter
          title={title}
          po={data.txnNumber}
          poDate={data.txnDate}
          styling={styling}
        />
      </Page>
    </Document>
  );
};

export default SoPdf;
