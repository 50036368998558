import { addressToString } from "./addressFormat";
import { getVendors, saveEntity } from "../services/entityService";

export function getDefaultValues() {
  const data = {
    displayName: "",
    billingAddress: {},
    shippingAddress: {},
    email: "",
    phone: "",
    currency: {
      value: "",
      label: "",
    },
  };
  return { data: data };
}

export const mapQBOtoData = (qbo) => {
  const data = {
    displayName: qbo.DisplayName,
  };

  if (qbo.BillAddr) {
    data.billingAddressObject = {
      line1: qbo.BillAddr.Line1,
      line2: qbo.BillAddr.Line2,
      city: qbo.BillAddr.City,
      subDivision: qbo.BillAddr.CountrySubDivisionCode,
      country: qbo.BillAddr.Country,
      postalCode: qbo.BillAddr.PostalCode,
      long: qbo.BillAddr.long,
      lat: qbo.BillAddr.lat,
    };
    data.billingAddress = addressToString(data.billingAddressObject);
  }

  if (qbo.ShipAddr) {
    data.shippingAddressObject = {
      line1: qbo.ShipAddr.Line1,
      line2: qbo.ShipAddr.Line2,
      city: qbo.ShipAddr.City,
      subDivision: qbo.ShipAddr.CountrySubDivisionCode,
      country: qbo.ShipAddr.Country,
      postalCode: qbo.ShipAddr.PostalCode,
      long: qbo.ShipAddr.long,
      lat: qbo.ShipAddr.lat,
    };
    data.shippingAddress = addressToString(data.shippingAddressObject);
  }

  if (qbo.PrimaryEmailAddr) {
    data.email = qbo.PrimaryEmailAddr.Address;
  }
  if (qbo.PrimaryPhone) {
    data.phone = qbo.PrimaryPhone.FreeFormNumber;
  }

  if (qbo.CurrencyRef) {
    data.currency = {
      value: qbo.CurrencyRef.value,
      label: qbo.CurrencyRef.name,
    };
  }

  return data;
};

export const getVendorRefs = async (uniq) => {
  const { data: allVendors } = await getVendors();

  const vendors = await Promise.all(
    uniq.map(async (item) => {
      let found = allVendors.find((c) => c.title === item.label);
      if (found) return found;

      const { data: created } = await saveEntity({
        entity: "Vendor",
        title: item.label,
        data: item.data,
      });
      if (created) return created;

      item.error = "No vendor";
      return item;
    })
  );
  return vendors;
};
