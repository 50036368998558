import React, { useState } from "react";
import Panel from "../common/panel";
import FormControls from "./FormControls";
// import Thumbnail from "../common/images/Thumbnail";
import ViewParts from "./ViewParts";
import ItemFiles from "./ItemFiles";
import MetaDataView from "../common/MetaDataView";
import {
  RowOneLeftTwoRight,
  RowTwoColumns,
  // RowFourColumns,
} from "../common/formLayout";
import Field from "../common/fieldText";
import FieldNumber from "../common/fieldNumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UomOptions from "./UomOptions";
import PriceOptions from "./PriceOptions";
import CostOptions from "./CostOptions";
// import PanelTabs from "../common/PanelTabs";
import MetaCard from "./MetaCard";

const FormView = (props) => {
  const { itemPath, data, thumbnail = null } = props;
  const tabs = [
    { label: "Main" },
    { label: "Sales", isDisabled: !data.isSales },
    { label: "Purchase", isDisabled: !data.isPurchasing },
    // { label: "Production", isDisabled: !data.isProduction },
    { label: "Files" },
  ];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const handleSelectTab = (selected) => {
    setSelectedTab(selected);
  };

  return (
    <>
      {/* <PanelTabs
        tabs={tabs}
        selected={selectedTab}
        onSelectTab={handleSelectTab}
      /> */}
      <Panel id="ItemView">
        {/* {selectedTab.label === "Main" && ( */}
        <div>
          <RowOneLeftTwoRight
            colOne={
              <Field name="name" label="Name" value={data && data.name} />
            }
            colTwo={<Field name="sku" label="SKU" value={data.sku} />}
          />
          <hr />
        </div>
        <div>
          <RowOneLeftTwoRight
            colOne={
              <Field
                name="purchaseDescription"
                label="Purchase Description"
                value={data && data.purchaseDescription}
              />
            }
            colTwo={
              <Field
                name="uom"
                label="Unit of measure (Base UOM)"
                value={data?.uom}
              />
            }
            colThree={
              <FieldNumber
                name="cost"
                label="Cost per base UOM"
                decimal="4"
                value={data.cost}
              />
            }
          />
          <RowOneLeftTwoRight
            colOne={
              <Field
                name="preferredVendor"
                label="Preferred Supplier"
                value={data?.preferredVendor?.label}
              />
            }
          />
          <hr />

          <RowTwoColumns
            colOne={
              <>
                <h5 className="text-uppercase">UOM Options</h5>
                <UomOptions options={data?.uomOptions} />
              </>
            }
            colTwo={
              <>
                <h5 className="text-uppercase">Cost Options</h5>
                <CostOptions options={data?.costOptions} />
              </>
            }
          />
          <hr />
          {data.meta ? (
            <MetaCard meta={data.meta} />
          ) : (
            data.metadata && (
              <>
                <MetaDataView metadata={data.metadata} />
                <hr />
              </>
            )
          )}
        </div>
        {/* )} */}
        {data.isProduction && <ViewParts parts={data.parts} {...props} />}
        {/* {selectedTab.label === "Production" && (
          <ViewParts parts={data.parts} {...props} />
        )} */}
        {selectedTab.label === "Files" && (
          <div>
            <ItemFiles folderName={data.name} title="Item Files" />
          </div>
        )}
        {/* <div className="row">
          <div className="col-12 col-md-4 mt-3 mb-3">
            <Thumbnail
              itemPath={itemPath}
              name={data.name}
              thumbnail={thumbnail}
              classNames="img-thumbnail"
              showUploadButton={true}
            />
          </div>
          <div className="col-12 col-md-8">
            <RowOneLeftTwoRight
              colOne={
                <Field name="name" label="Name" value={data && data.name} />
              }
              colTwo={
                <Field
                  name="uom"
                  label="Unit of measure"
                  value={data && data.uom}
                />
              }
              colThree={
                <Field name="type" label="Type" value={data && data.type} />
              }
            />
            <RowTwoColumns colOne={<UomOptions options={data?.uomOptions} />} />
            <hr />
            <RowOneLeftTwoRight
              colOne={
                <Field
                  name="description"
                  label="Sales Description"
                  value={data && data.description}
                />
              }
              colTwo={
                <Field
                  name="sku"
                  label="Item code"
                  value={data && data.sku}
                  comment="Stock Keeping Unit - for inventory tracking"
                />
              }
            />
            <hr />
          </div>
        </div> */}
        {/* <RowFourColumns
          colOne={<Field name="sku" label="SKU" value={data.sku} />}
          colTwo={<Field name="uom" label="Unit of measure" value={data.uom} />}
          // colThree={
          //   <FieldNumber
          //     name="cost"
          //     label="Cost per Unit"
          //     decimal="4"
          //     value={data.cost}
          //   />
          // }
          // colFour={
          //   <FieldNumber
          //     name="unitPrice"
          //     label="Unit Price"
          //     decimal="4"
          //     value={data.unitPrice}
          //   />
          // }
        /> */}
        {/* <hr /> */}

        <hr />

        <div className="row">
          {data && data.isSales && (
            <div className="pl-3">
              <FontAwesomeIcon icon="check-circle" /> Sales
            </div>
          )}
          {data && data.isPurchasing && (
            <div className="pl-3">
              <FontAwesomeIcon icon="check-circle" /> Purchasing
            </div>
          )}
          {data && data.isProduction && (
            <div className="pl-3">
              <FontAwesomeIcon icon="check-circle" /> Production
            </div>
          )}
          <div className="pl-3">
            {data && data.isInactive && <h6>This item is Inactive</h6>}
          </div>
        </div>
      </Panel>

      <FormControls isEdit={false} {...props} />
    </>
  );
};

export default FormView;
