import qboApi from "./qboApiService";

const apiEndpoint = qboApi.apiUrl + "/exports";

function typeUrl(entity) {
  return `${apiEndpoint}/${entity}`;
}

function entityUrl(entity, id) {
  return `${apiEndpoint}/${entity}/${id}`;
}

// route to export a single entity by providing entity id
// TODO create defaults form setup in settings
// router.put("/:entity/:id",

export function exportToQboById(entity, id) {
  return qboApi.put(entityUrl(entity, id));
}

export function batchCreate(entity, entities) {
  return qboApi.post(`${apiEndpoint}/batchCreate/${entity}`, entities);
}

// export function batchUpdateCustomers(entity, batchRequest) {
//   return qboApi.post(typeUrl(entity) + "/batchUpdate", batchRequest);
// }

// export function deleteEntity(entity, id) {
//   return qboApi.delete(entityUrl(entity, id));
// }
