import React from "react";
import { Text, View } from "@react-pdf/renderer";
import numeral from "numeral";

const SoPdfContent = ({ data, styling }) => {
  const { details } = data;
  return (
    <View style={styling.content}>
      <View style={styling.contentHeader} fixed={true}>
        <Text style={styling.columnDescriptionItem}>ITEM</Text>
        <Text style={styling.columnDescription}>DESCRIPTION</Text>
        <Text style={styling.columnUOM}>UOM</Text>
        <Text style={styling.columnQuantity}>QTY</Text>
        <Text style={styling.columnRate}>PRICE</Text>
        <Text style={styling.columnAmount}>AMOUNT</Text>
      </View>
      {details.map((row, index) => (
        <View style={styling.contentRow} key={`section-${index}`} wrap={false}>
          <View style={styling.columnDescriptionItem}>
            <Text>{row.item.label}</Text>
          </View>
          <View style={styling.columnDescription}>
            <Text>{row.description}</Text>
          </View>
          <View style={styling.columnUOM}>
            <Text>{row.uom}</Text>
          </View>
          <Text style={styling.columnQuantity}>
            {numeral(row.qty).format("0,0.0")}
          </Text>
          <Text style={styling.columnRate}>
            {numeral(row.unitCost).format("0,0.0000")}
          </Text>
          <Text style={styling.columnAmount}>
            {numeral(row.amount).format("0,0.00")}
          </Text>
        </View>
      ))}
    </View>
  );
};

export default SoPdfContent;
