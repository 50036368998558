import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { schema } from "./form/schema";
import numeral from "numeral";
import { DateTime } from "luxon";
import Panel from "../common/panel";
import Controls from "./Controls";
import EditDetails from "./EditDetails";
import { RowFourColumns, RowTwoColumns } from "../common/formLayout";
import InputText from "../common/inputText";
import InputDate from "../common/inputDate";
import InputSelect from "../common/inputSelect";
import InputTextArea from "./../common/inputTextArea";
import { getVendors, getStorages } from "../../services/entityService";
import { poStatuses } from "./../../utils/poUtils";
import { toast } from "react-toastify";

const Edit = (props) => {
  const { data, onSave } = props;
  const currency = { value: "CAD" };
  const defaultValues = {
    txnDate: DateTime.local().toJSDate(),
    dueDate: null,
    txnNumber: "",
    status: { value: "100", label: "New" },
    vendor: null,
    addressFrom: "",
    locationTo: null,
    addressTo: "",
    email: "",
    currency: currency,
    memo: "",
    notes: "",
    totals: {
      total: 0,
    },
  };

  const methods = useForm({
    defaultValues,
    mode: "onBlur",
    shouldUnregister: false,
    resolver: yupResolver(schema),
  });
  const { reset, setValue, watch, clearErrors } = methods;

  const [storages, setStorages] = useState([]);
  const [vendors, setVendors] = useState([]);

  const status = watch("status");
  const vendor = watch("vendor");
  const locationTo = watch("locationTo");

  useEffect(() => {
    const populateStorages = async () => {
      const { data } = await getStorages();
      const mapped = data.map((s) => {
        return { value: s._id, label: s.title, data: s.data };
      });
      if (mapped) setStorages(mapped);
    };
    populateStorages();
    return () => {
      setStorages([]);
    };
  }, []);

  useEffect(() => {
    const populateVendors = async () => {
      const { data } = await getVendors();
      const mapped = data.map((s) => {
        const billAddress =
          s.data && s.data.billingAddress ? s.data.billingAddress : "";
        // const email = s.data && s.data.email ? s.data.email : ""
        return {
          value: s._id,
          label: s.title,
          billingAddress: billAddress,
          currency:
            s.data && s.data.currency ? s.data.currency : { value: "CAD" },
          email: s.data && s.data.email ? s.data.email : "",
        };
      });
      if (mapped) setVendors(mapped);
    };
    populateVendors();
    return () => {
      setVendors([]);
    };
  }, []);

  useEffect(() => {
    const docData = { ...data };
    docData.txnDate = DateTime.fromISO(docData.txnDate).toJSDate();
    reset(docData);
  }, [data, reset]);

  const handleSelect = async (name, selected) => {
    setValue(selected.name, { value: name.value, label: name.label });
    clearErrors(selected.name);
  };

  const handleVendorSelect = async (name, selected) => {
    setValue(selected.name, { value: name.value, label: name.label });
    setValue("addressFrom", name.billingAddress);
    setValue("email", name.email);
    setValue("currency", name.currency);
    clearErrors("vendor");
  };

  const onSubmit = async (values) => {
    values.txnDate = DateTime.fromJSDate(values.txnDate).toISO();
    let errors = false;
    const details = values.details.map((i) => {
      if (!i.item) errors = true;
      const updated = i;
      updated.qty = numeral(i.qty).value();
      return updated;
    });
    if (errors) {
      toast.error("Select item");
      return null;
    }
    values.details = details;
    await onSave(values);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Panel>
          <RowFourColumns
            colOne={<InputDate name="txnDate" label="PO Date" />}
            colTwo={
              props.purchaseOrderId === "new" ? (
                ""
              ) : (
                <InputText name="txnNumber" label="PO Number" />
              )
            }
            colThree={<InputDate name="dueDate" label="Due Date" />}
            colFour={
              <InputSelect
                name="status"
                label="PO Status"
                defaultValue={status}
                options={poStatuses}
                onChange={handleSelect}
                valueOption="value"
                labelOption="label"
              />
            }
          />

          <RowTwoColumns
            colOne={
              <InputSelect
                name="vendor"
                label="Supplier"
                defaultValue={vendor}
                options={vendors}
                onChange={handleVendorSelect}
                valueOption="value"
                labelOption="label"
              />
            }
            colTwo={
              <InputSelect
                name="locationTo"
                label="To location"
                defaultValue={locationTo}
                options={storages}
                onChange={handleSelect}
                valueOption="value"
                labelOption="label"
              />
            }
          />
          <RowTwoColumns
            colOne={
              <InputTextArea name="addressFrom" label="From" minRows="2" />
            }
            colTwo={<InputTextArea name="addressTo" label="To" minRows="2" />}
          />
          <hr />
          <RowFourColumns colOne={<InputText name="email" label="Email" />} />
          {/* <EditDetails details={records} {...props} /> */}
          <EditDetails {...props} />
          <div id="docFooter">
            <hr />
            <RowTwoColumns
              colOne={<InputTextArea name="memo" label="Memo" minRows="2" />}
              colTwo={
                <InputTextArea name="notes" label="Intenal Notes" minRows="2" />
              }
            />
          </div>
        </Panel>
        <Controls isEdit={true} {...props} />
      </form>
    </FormProvider>
  );
};

export default Edit;
