import React from "react";
import PartsHeader from "./PartsHeader";
import FieldTable from "../common/fieldTable";
import FieldTableNumber from "../common/fieldTableNumber";

const ViewParts = (props) => {
  const { parts } = props;
  return (
    <div>
      <hr />
      <h5 className="text-uppercase">Parts</h5>
      <table className="table table-sm border-bottom">
        <PartsHeader />
        <tbody>
          {parts &&
            parts.map((line, index) => (
              <tr key={index} name={`parts-${index}`}>
                <th scope="row" className="align-baseline text-center">
                  {line.lineId || index + 1}
                </th>
                <td>
                  <FieldTable
                    name={`parts[${index}].item`}
                    value={line.item.label}
                  />
                </td>
                {/* <td>
                  <FieldTable
                    name={`parts[${index}].description`}
                    value={line.description}
                  />
                </td> */}
                <td>
                  <FieldTable name={`parts[${index}].uom`} value={line.uom} />
                </td>
                <td>
                  <FieldTableNumber
                    name={`parts[${index}].qty`}
                    value={line.qty}
                    decimal="6"
                  />
                </td>
                {/* <td>
                  <FieldTableNumber
                    name={`parts[${index}].unitCost`}
                    value={line.unitCost}
                    decimal="4"
                  />
                </td>
                <td>
                  <FieldTableNumber
                    name={`parts[${index}].amount`}
                    value={line.amount}
                    decimal="2"
                  />
                </td> */}
                <td></td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ViewParts;
