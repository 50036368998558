import React, { useState, useEffect } from "react";
import withLoading from "../common/withLoading";
import LeftBarItems from "./LeftBarItems";
import { getInventoriesQuery } from "./../../services/inventoryService";
import { getRecordsQuery, patchRecord } from "../../services/recordService";
import { toast } from "react-toastify";

const ToolChange = (props) => {
  const { loadingOn, loadingOff, progressOn, progressUpdate, progressOff } =
    props;
  // const [tools, setTools] = useState([]);
  const [selectedTool, setSelected] = useState({});
  const [inventories, setInventories] = useState({});
  const [records, setRecords] = useState([]);

  const tools = [
    { value: "itemIdOnRecords", label: "Update Items on Records" },
  ];

  useEffect(() => {
    setSelected(tools[0]);
    loadingOff();
  }, []);

  const populateInventories = async () => {
    const invMap = new Map();
    try {
      const { data } = await getInventoriesQuery();
      data.forEach((i) => {
        const item = {
          value: i._id,
          label: i.name,
          itemId: i.itemId,
        };
        invMap.set(item.value, item);
      });
      setInventories(invMap);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  const populateRecords = async () => {
    try {
      const { data } = await getRecordsQuery("?isItemId=false");
      setRecords(data);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  const handleGetItemIdData = async () => {
    loadingOn();
    Promise.all([populateInventories(), populateRecords()]).then(() =>
      loadingOff()
    );
  };

  const handleUpdateRecords = async () => {
    progressOn();
    let d = 0;
    for (let rec of records) {
      const inv = inventories.get(rec.inventory?.value);
      if (inv) {
        rec.itemId = inv.itemId;
      }
      rec = await updateRecord(rec);
      d++;
      progressUpdate("Saving records...", d, records.length);
      console.log("Saved: ", d, rec?.doc?.txnNumber);
    }
    progressOff();
    const notSaved = records.filter((r) => !r.itemId);
    if (notSaved.length === 0) {
      setRecords([]);
    } else {
      setRecords(notSaved);
    }
  };

  const updateRecord = async (rec) => {
    const updated = await patchRecord(rec);
    return updated;
  };

  return (
    <>
      {/* <LeftBarItems
        user={props.user}
        tools={tools}
        selectedTool={selectedTool}
        onToolSelect={(selected) => setSelected(selected)}
      /> */}
      <div className="col pt-3">
        <h2 className="text-uppercase">Update Tools</h2>
        <hr />
        <h4 className="text-uppercase">{selectedTool.label}</h4>
        <hr />
        {selectedTool.value === "itemIdOnRecords" && <div></div>}
        <h5>1. Get Data </h5>
        <div>
          <button
            className="btn btn-primary shadow-sm"
            onClick={handleGetItemIdData}
          >
            Get records to update
          </button>
        </div>
        {records.length > 0 ? (
          <div className="mt-3 px-3 border-top border-bottom">
            <h5>2. Update records </h5>
            <p>Total to update: {records.length} records</p>
            <div>
              <button
                className="btn btn-success shadow-sm"
                onClick={handleUpdateRecords}
              >
                Update records
              </button>
            </div>
          </div>
        ) : (
          <div className="mt-3 py-3 border-top border-bottom">
            No records found
          </div>
        )}
      </div>
    </>
  );
};

export default withLoading(ToolChange);
