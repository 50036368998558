import React from "react";
import { Link } from "react-router-dom";
import Table from "../common/table";
import { partsToTextArea } from "./../../utils/itemUtils";

// import DateFormat from "../common/dateFormat";

const InventoryToPartsTable = (props) => {
  const { items, onSort, sortColumn } = props;

  const columns = [
    {
      path: "label",
      label: "Material",
      className: "w-25",
      content: (inventory) => (
        <Link to={`/inventories/${inventory._id}`}>{inventory.name}</Link>
      ),
    },
    {
      path: "parts",
      label: "Available to Produce",
      className: "w-25",
      content: (inventory) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {inventory.parts && partsToTextArea(inventory.parts)}
        </div>
      ),
    },
    { path: "available", label: "Qty, available", className: "text-center" },
    { path: "uom", label: "UOM", className: "text-center" },
  ];

  return (
    <Table
      columns={columns}
      data={items}
      sortColumn={sortColumn}
      onSort={onSort}
    />
  );
};

export default InventoryToPartsTable;
