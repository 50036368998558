import React from "react";
// import DocPrint from "../common/docPrint/docPrint";
// import { docPrintStyles } from "../common/docPrint/styling.js";
import { PDFViewer } from "@react-pdf/renderer";
import PdfModal from "../common/PdfModal";
import PoPdfSimple from "./PoPdfSimple";

const PurchaseOrderPrint = (props) => {
  const { data, info } = props;
  return (
    <PdfModal id="POSimpleViewModal">
      <PDFViewer width="100%" height="100%">
        <PoPdfSimple title="Purchase Order Form" info={info} data={data} />
      </PDFViewer>
    </PdfModal>
  );
};

export default PurchaseOrderPrint;
