import React from "react";

const Button = ({ label = "Submit", className, ...props }) => {
  return (
    <button
      id={label}
      className={"btn " + className + " d-print-none"}
      {...props}
    >
      {label}
    </button>
  );
};

export default Button;
