import React from "react";
// import DocPrint from "../common/docPrint/docPrint";
// import { docPrintStyles } from "../common/docPrint/styling.js";
import { PDFViewer } from "@react-pdf/renderer";
import PdfModal from "../common/PdfModal";
import PoPdf from "./PoPdf";

const PurchaseOrderPrint = (props) => {
  const { data, info } = props;
  return (
    <PdfModal id="POViewModal">
      <PDFViewer width="100%" height="100%">
        <PoPdf title="Purchase Order" info={info} data={data} />
      </PDFViewer>
    </PdfModal>
  );
};

export default PurchaseOrderPrint;
