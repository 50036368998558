import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { DateTime } from "luxon";
import withLoading from "../common/withLoading";
import SidebarLeft from "../common/SidebarLeft";
import ListGroup from "../common/listGroup";
import SelectDateBox from "./../common/SelectDateBox";
import PageControls from "./PageControls";
import SearchBox from "../common/searchBox";
import PoDetailsTable from "./PoDetailsTable";

import { getPoDetails } from "../../services/poService";
// import { getStatuses } from "../../services/statusService";

// import { detailsToTextArea } from "../../utils/poUtils";
import { paginate } from "../../utils/paginate";
import { poStatuses } from "../../utils/poUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
class PoDetails extends React.Component {
  state = {
    details: [],
    statuses: [],
    fromDate: DateTime.local().startOf("month").minus({ months: 2 }),
    toDate: DateTime.local().endOf("month").plus({ months: 1 }),
    sortColumn: { path: "poDate", order: "desc" },
    currentPage: 1,
    pageSize: 300,
    searchQuery: "",
    selectedStatus: null,
  };

  populateStatuses = () => {
    const statuses = [{ value: "", label: "All statuses" }, ...poStatuses];
    this.setState({ statuses });
  };

  populatePoDetails = async () => {
    try {
      const { data } = await getPoDetails();
      const details = data.map((d) => {
        d.search =
          d?.item?.label +
          " " +
          d?.description +
          " " +
          d?.vendor?.label +
          " " +
          d?.poNumber;
        return d;
      });
      this.setState({ details });
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
      return null;
    }
  };

  async componentDidMount() {
    this.populateStatuses();
    await this.populatePoDetails();
    this.props.loadingOff();
  }

  handleStatusSelect = (type) => {
    this.setState({
      selectedStatus: type,
      searchQuery: "",
      currentPage: 1,
    });
  };

  handleDateChange = async () => {
    this.props.loadingOn();
    await this.populatePurchaseOrders();
    this.props.loadingOff();
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      selectedStatus: null,
      currentPage: 1,
    });
  };

  handleSort = (sortColumn) => {
    this.setState({
      sortColumn: sortColumn,
    });
  };

  handleClick = (purchaseOrder) => {
    this.props.history.push(`/purchase-orders/${purchaseOrder._id}`);
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  getPagedData = () => {
    const {
      details,
      searchQuery,
      selectedStatus,
      sortColumn,
      currentPage,
      pageSize,
    } = this.state;
    const allDetails = [...details];
    let filtered = allDetails;
    if (searchQuery) {
      filtered = allDetails.filter((i) =>
        i.search.toLowerCase().includes(searchQuery.toLowerCase())
      );
    } else if (selectedStatus && selectedStatus.value) {
      filtered = allDetails.filter(
        (i) => i?.status?.value === selectedStatus.value
      );
    }

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const items = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: items };
  };

  render() {
    const {
      statuses,
      selectedStatus,
      sortColumn,
      fromDate,
      toDate,
      searchQuery,
    } = this.state;
    const { data: details, totalCount } = this.getPagedData();

    return (
      <>
        <SidebarLeft>
          <div>
            <Link
              to="/purchase-orders/new"
              className="btn btn-primary shadow"
              style={{ marginBottom: 18 }}
              data-toggle="tooltip"
              data-placement="top"
              title="Create a new purchase order"
            >
              <FontAwesomeIcon icon="plus" className="mr-2" />
              Add Purchase Order
            </Link>
          </div>
          <ListGroup
            items={statuses}
            valueProperty="value"
            textProperty="label"
            selectedItem={selectedStatus}
            onItemSelect={this.handleStatusSelect}
            selectedCount={totalCount}
          />
        </SidebarLeft>
        <div className="col mt-2">
          <div className="d-flex py-2">
            <h2 className="text-uppercase">Ordered Items</h2>
          </div>
          <SelectDateBox
            fromDate={fromDate}
            toDate={toDate}
            setFromDate={(value) => this.setState({ fromDate: value })}
            setToDate={(value) => this.setState({ toDate: value })}
            handleDateChange={this.handleDateChange}
          />
          <div className="d-flex justify-content-between border-bottom py-2 d-print-none ">
            <SearchBox
              value={searchQuery}
              placeholder="Search ..."
              onChange={this.handleSearch}
            />
            <div>
              <PageControls />
            </div>
          </div>
          <div className="d-flex border-top pb-n5 mb-3">
            <PoDetailsTable
              items={details}
              sortColumn={sortColumn}
              isList={false}
              onDelete={this.handleDelete}
              onSort={this.handleSort}
              onClick={this.handleClick}
            />
          </div>
        </div>
      </>
    );
  }
}

export default withLoading(PoDetails);
