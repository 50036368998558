import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Loader = () => {
  return (
    <div className="io-modal">
      <div
        className="io-modal-dialog"
        style={{ width: "500px", height: "300px" }}
      >
        {/* <div className="card shadow p-5"> */}
        <div className="p-5">
          <div className="d-flex justify-content-center m-5">
          <FontAwesomeIcon icon="spinner" size="3x" pulse />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
