import React from "react";

const SearchBox = ({
  value,
  onChange,
  placeholder = "Search...",
  col = "col-4",
  ...props
}) => {
  // col must be in range [col-1...col-12] - see bootstrap 4 grid
  const classNames = "form-control mb-3 " + col;
  return (
    <input
      type="text"
      name="query"
      className={classNames}
      placeholder={placeholder}
      value={value}
      onChange={e => onChange(e.currentTarget.value)}
      {...props}
    />
  );
};

export default SearchBox;
