import React from "react";
import Table from "../common/table";
import DateFormat from "../common/dateFormat";
import numeral from "numeral";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";

const InventoryReceiptTable = ({ lines = [], sortColumn, onSort, totals }) => {
  const columns = [
    {
      path: "inventory.label",
      label: "Item",
    },
    {
      path: "doc.txnDate",
      label: "Date",
      content: (line) => <DateFormat value={line?.doc?.txnDate} />,
    },
    {
      path: "doc.txnNumber",
      label: "Doc #",
      total: (totals) => <span>{numeral(totals.docs).format("0,0")}</span>,
    },
    {
      path: "name.label",
      label: "Vendor",
    },
    {
      path: "memo",
      label: "Memo",
    },
    {
      path: "uom",
      label: "UOM",
      className: "text-center",
    },
    {
      path: "qty",
      label: "Qty",
      className: "text-center",
      total: (totals) => <span>{numeral(totals.qty).format("0,0.00")}</span>,
      content: (line) => <span>{numeral(line.qty).format("0,0.00")}</span>,
    },
  ];
  return (
    <Table
      columns={columns}
      data={lines}
      sortColumn={sortColumn}
      onSort={onSort}
      totals={totals}
      includeTotals={true}
    />
  );
};

export default InventoryReceiptTable;
