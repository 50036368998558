import React from "react";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
// import { useForm } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers";
import Input from "../common/input";
import Form from "../common/form";
import * as yup from "yup";
import { login, getGlobalUser } from "../../services/loginApiService";

function LoginForm() {
  const schema = yup.object().shape({
    email: yup.string().email().max(50).required().label("Username"),
    password: yup.string().min(5).max(50).required().label("Password"),
  });

  // const { register, errors, handleSubmit } = useForm({
  //   resolver: yupResolver(schema),
  // });

  const doSubmit = async (data) => {
    try {
      await login(data.email, data.password);
      window.location = "/auth/select";
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };
  if (getGlobalUser()) return <Redirect to="/auth/select" />;

  return (
    <React.Fragment>
      <div className="card-header">
        <h4>Login</h4>
      </div>
      <div className="card-body">
        <Form onSubmit={doSubmit} schema={schema}>
          <Input
            name="email"
            label="Username or email"
            type="email"
            maxLength="100"
          />
          <Input
            name="password"
            label="Password"
            type="password"
            maxLength="50"
          />
          <button type="submit" className="btn btn-success mb-2 shadow-sm">
            Sign in
          </button>
          <div className="font-italic text-center p-2">
            By clicking Sign In, you agree to our Terms of Service and have read
            and acknowledge our Privacy Statement.
          </div>
          <Link to="/auth/forgot-password" className="card-link mr-2">
            Forgot your password? Click here
          </Link>
        </Form>
      </div>
    </React.Fragment>
  );
}

export default LoginForm;
