import React from "react";
import Table from "../common/table";
import { Link } from "react-router-dom";
import DateFormat from "../common/dateFormat";

const IncomingMaterialTable = (props) => {
  const { lines, onSort, sortColumn, cols } = props;
  const columns = [
    {
      path: "txnNumber",
      label: "Doc number",
      className: "text-center",
      content: (document) => (
        <Link to={`/documents/${document._id}`}>
          {document.data && document.data.txnNumber}
        </Link>
      ),
    },
    {
      path: "partNumber",
      label: "Part #",
      className: "text-center",
    },
    {
      path: "inspectionDate",
      label: "Inspectoin Date",
      content: (document) => <DateFormat value={document?.data?.txnDate} />,
    },
    {
      path: "packingSlip",
      label: "Packing Slip",
    },
    {
      path: "vendor",
      label: "Vendor",
    },
    {
      path: "description",
      label: "Material/Part Description",
      content: (document) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{document?.data?.memo}</div>
      ),
    },
    {
      path: "actualDimensions",
      label: "Actual Dimensions",
      content: (document) => (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {document.actualDimensions}
        </div>
      ),
    },
    {
      path: "materialHeat",
      label: "Material Heat",
      className: "text-center",
    },
    {
      path: "isAccepted",
      label: "Accepted / Rejected",
      className: "text-center",
    },
    {
      path: "comments",
      label: "Additional Comments",
      content: (document) => (
        <div style={{ whiteSpace: "pre-wrap" }}>{document.comments}</div>
      ),
    },
  ];

  return (
    <Table
      columns={cols}
      data={lines}
      sortColumn={sortColumn}
      onSort={onSort}
      // totals={totals}
      includeTotals={false}
    />
  );
};

export default IncomingMaterialTable;
