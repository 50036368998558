import numeral from "numeral";
import { DateTime } from "luxon";
import { addressToString } from "./addressFormat";
import {
  getEntity,
  getEntities,
  // getEntitiesQuery,
} from "../services/entityService";
// import _ from "lodash";

export function getDefaultValues() {
  // TODO: update uom to the list object
  const data = {
    title: "Adding new Purchase Order...",
    entity: "PurchaseOrder",
    vendor: null,
    txnNumber: "",
    txnDate: new Date(),
    locationTo: null,
    addressFrom: "",
    addressTo: "",
    currency: "",
    salesOrder: null,
    status: {
      label: "New",
      value: "100",
    },
    memo: "",
    notes: "",
    details: [],
    totals: { total: 0 },
  };
  return { data: data };
}

export function mapToViewModel(order) {
  return {
    _id: order._id,
    title: order.title,
    entity: order.entity,
    txnDate: order.data.txnDate,
    txnNumber: order.data.txnNumber,
    vendor: order.data.vendor || {},
    locationTo: order.data.locationTo,
    locationFrom: order.data.locationFrom,
    currency: order.data.currency,
    email: order.data.email,
    salesOrder: order.data.salesOrder || {},
    status: order.data.status || {},
    memo: order.data.memo,
    notes: order.data.notes,
    details: order.data.details || [],
    totals: order.data.totals || { total: 0.0 },
  };
}

export function mapToSave(order, values) {
  let purchaseOrder = {};
  if (values._id !== "new") purchaseOrder = { ...order };

  purchaseOrder.entity = "PurchaseOrder";
  purchaseOrder.title = values.title;

  purchaseOrder.data = {
    txnDate: values.txnDate,
    txnNumber: values.txnNumber,
    vendor: values.vendor,
    locationTo: values.lcoationTo,
    addressFrom: values.addressFrom,
    addressTo: values.addressTo,
    email: values.email,
    salesOrder: values.salesOrder,
    status: values.status,
    memo: values.memo,
    notes: values.notes,
    details: values.details,
    totals: values.totals,
  };
  return purchaseOrder;
}

export function updateSubTotalAmount(details, name = "amount") {
  const sumAmount = details.reduce(
    (sum, { amount }) => sum + numeral(amount).value(),
    0
  );
  return sumAmount;
}

export function updateTotalAmount(details, name = "amount") {
  const totalAmount = details.reduce(
    (sum, { amount }) => sum + numeral(amount).value(),
    0
  );
  return totalAmount;
}

export function updateTaxAmount(details, taxes) {
  const totalTaxes = [];

  taxes.forEach((tax) => {
    const rate = tax.rate || 0;
    const total = {
      value: tax.value,
      label: tax.label,
    };
    const filtered = [...details].filter(
      (i) => i.taxCode && i.taxCode.value === tax.value
    );
    total.amount = filtered.reduce(
      (sum, { amount }) => sum + numeral(amount).value() * rate,
      0
    );
    totalTaxes.push(total);
  });

  const totalTax = totalTaxes.reduce((sum, { amount }) => sum + amount, 0);
  return totalTax;
}

export const poStatuses = [
  { value: "100", label: "New" },
  // {
  //   value: "200",
  //   label: "Confirmed",
  // },
  {
    value: "300",
    label: "In Process",
  },
  {
    value: "400",
    label: "Complete",
  },
  {
    value: "500",
    label: "Canceled",
  },
];

export const detailsToTextArea = (array = []) => {
  let str = "";
  array.forEach((line, index) => {
    if (line.item && line.item.label) {
      str = str + "Item: " + line.item.label + ", Qty: " + line.qty;
      if (index < array.length - 1) str = `${str} \n`;
    }
  });
  return str;
};

export const generateTitle = (data) => {
  const txnNumber = data.txnNumber ? data.txnNumber + " " : "";
  const txnDate = DateTime.fromISO(data.txnDate).toISODate();
  const total = numeral(data.totals.total).format("0,0.00");
  let currency = "";
  if (data.currency && data.currency.value) currency = data.currency.value;

  const title =
    "" +
    txnNumber +
    data.vendor.label.toUpperCase() +
    " " +
    txnDate +
    " " +
    total +
    " " +
    currency;
  return title;
};

export const mapQBOtoData = (qbo, oldData = {}) => {
  const data = { ...oldData };

  if (qbo.DocNumber) data.txnNumber = qbo.DocNumber;
  if (qbo.TxnDate) data.txnDate = qbo.TxnDate;
  if (qbo.DueDate) data.dueDate = qbo.DueDate;
  if (qbo.PrivateNote) data.notes = qbo.PrivateNote;
  if (qbo.Memo) data.memo = qbo.Memo;
  data.vendor = {
    label: qbo.VendorRef.name,
  };

  data.totals = {
    total: qbo.TotalAmt,
    totalHome: qbo.HomeTotalAmt || qbo.TotalAmt,
  };

  if (qbo.TxnTaxDetail && qbo.TxnTaxDetail.TaxLine) {
    data.totals.taxTotal = qbo.TxnTaxDetail.TaxLine.reduce(
      (sum, { Amount }) => sum + Amount,
      0
    );
  }

  if (qbo.CurrencyRef) {
    data.currency = {
      value: qbo.CurrencyRef.value,
      label: qbo.CurrencyRef.name,
    };
  }

  if (qbo.VendorAddr) {
    data.billingAddressObject = {
      line1: qbo.VendorAddr.Line1,
      line2: qbo.VendorAddr.Line2,
      city: qbo.VendorAddr.City,
      subDivision: qbo.VendorAddr.CountrySubDivisionCode,
      country: qbo.VendorAddr.Country,
      postalCode: qbo.VendorAddr.PostalCode,
      long: qbo.VendorAddr.long,
      lat: qbo.VendorAddr.lat,
    };
    data.addressFrom = addressToString(data.billingAddressObject);
  }
  if (qbo.ShipAddr) {
    data.shippingAddressObject = {
      line1: qbo.ShipAddr.Line1,
      line2: qbo.ShipAddr.Line2,
      city: qbo.ShipAddr.City,
      subDivision: qbo.ShipAddr.CountrySubDivisionCode,
      country: qbo.ShipAddr.Country,
      postalCode: qbo.ShipAddr.PostalCode,
      long: qbo.ShipAddr.long,
      lat: qbo.ShipAddr.lat,
    };
    data.addressTo = addressToString(data.shippingAddressObject);
  }
  if (qbo.ClassRef) {
    data.salesClass = { label: qbo.ClassRef.name };
  }

  if (qbo.POStatus === "Open") {
    data.status = { value: "100", label: "New" };
  } else if (qbo.POStatus === "Closed") {
    data.status = { value: "400", label: "Complete" };
  }

  if (qbo.Line) {
    const details = data.details ? [...data.details] : [];
    qbo.Line.forEach((line) => {
      // let classRef = null;
      // if (oldData.details) {
      //   const i = oldData.details.findIndex((d) => d.lineId === line.Id);
      //   if (i !== -1) detail = oldData.details[i];
      // }
      if (line.DetailType === "ItemBasedExpenseLineDetail") {
        let detail = {};
        const found = details.find((d) => d.lineId === line.Id);
        if (found) {
          detail = { ...found };
        } else {
          detail = {
            lineId: line.Id,
            description: line.Description,
            amount: line.Amount,
            qty: line.ItemBasedExpenseLineDetail.Qty,
            unitCost: line.ItemBasedExpenseLineDetail.UnitPrice,
            item: {
              label: line.ItemBasedExpenseLineDetail.ItemRef.name,
            },
            taxCode: {
              qboId: line.ItemBasedExpenseLineDetail.TaxCodeRef.value,
            },
          };

          // if (line.ItemBasedExpenseLineDetail.ClassRef)
          //   classRef = { name: line.ItemBasedExpenseLineDetail.ClassRef.name };
          // if (!data.salesClass && classRef)
          //   data.salesClass = { label: classRef.name };
          details.push(detail);
        }
      } else if (line.DetailType === "AccountBasedExpenseLineDetail") {
        const detail = {
          account: {
            label: line.AccountBasedExpenseLineDetail.AccountRef.name,
          },
          description: line.Description,
          amount: line.Amount,
          taxCode: {
            qboId: line.AccountBasedExpenseLineDetail.TaxCodeRef.value,
          },
        };
        details.push(detail);
      }
      data.details = details;
    });
  }

  return data;
};

export async function mapDataToQbo(purchaseOrder) {
  if (!purchaseOrder.data) return null;

  let qbo = {};
  if (purchaseOrder.qbo) qbo = { ...purchaseOrder.qbo };

  const vendor = await getVendorRef(purchaseOrder.data.vendor);

  if (vendor) {
    qbo.VendorRef = {
      value: vendor.Id,
      name: vendor.DisplayName,
    };

    if (vendor.CurrencyRef)
      qbo.CurrencyRef = {
        value: vendor.CurrencyRef.value,
      };
    if (vendor.CurrencyRef.value === "CAD") qbo.ExchangeRate = 1;
    if (vendor.CurrencyRef.value === "USD") qbo.ExchangeRate = 1.3;
    if (vendor.CurrencyRef.value === "EUR") qbo.ExchangeRate = 1.5;
    if (vendor.CurrencyRef.value === "GBR") qbo.ExchangeRate = 1.7;
  } else {
    return null;
  }

  // if (!purchaseOrder.data.salesClass) {
  //   if (vendor.data.salesClass)
  //     purchaseOrder.data.salesClass = vendor.data.salesClass;
  // }

  if (purchaseOrder.data.salesClass) {
    const classRef = await getClassRef(purchaseOrder.data.salesClass);
    if (classRef) {
      qbo.ClassRef = {
        value: classRef.Id,
      };
    }
  }

  if (purchaseOrder.data.txnNumber)
    qbo.DocNumber = purchaseOrder.data.txnNumber;

  qbo.TxnDate = DateTime.fromISO(purchaseOrder.data.txnDate).toISODate();
  qbo.DueDate = DateTime.fromISO(purchaseOrder.data.dueDate).toISODate();

  // qbo.CustomField = [
  //   {
  //     DefinitionId: "1",
  //     Name: "Ref",
  //     Type: "StringType",
  //     StringValue: purchaseOrder.data.vendorDocRef,
  //   },
  //   {
  //     DefinitionId: "2",
  //     Name: "PO",
  //     Type: "StringType",
  //     StringValue: purchaseOrder.data.vendorPO,
  //   },
  // ];
  // qbo.PrivateNote = {
  //   value: purchaseOrder.data.memo,
  // };
  qbo.Memo = purchaseOrder.data.memo;
  qbo.PrivateNote = purchaseOrder.data.notes;
  qbo.POEmail = { Address: purchaseOrder.data.email };
  qbo.GlobalTaxCalculation = "TaxExcluded";
  qbo.PrintStatus = "PrintComplete";

  if (
    purchaseOrder.data.status.label === "New" ||
    purchaseOrder.data.status.label === "Confirmed" ||
    purchaseOrder.data.status.label === "In Progress"
  ) {
    qbo.POStatus = "Open";
  } else {
    qbo.POStatus = "Closed";
  }

  const lines = await getLines(purchaseOrder.data);

  qbo.Line = lines;

  purchaseOrder.qbo = qbo;
  console.log(purchaseOrder);
  return purchaseOrder;
}

async function getVendorRef(vendor) {
  try {
    const { data } = await getEntity("Vendor", vendor.value);
    if (data.qbo && data.qbo.Id) {
      return data.qbo;
    } else {
      return null;
    }
  } catch (ex) {
    return null;
  }
}

async function getClassRef(salesClass) {
  const { data: salesClasses } = await getEntities("Class");
  const classGroup = salesClasses.find((c) => c._id === salesClass.value);
  return classGroup.qbo || {};
}

async function getLines(data) {
  const { details } = data;

  const { data: taxCodes } = await getEntities("TaxCode");

  const classRef = data.classRef ? data.classRef : null;

  const lines = await Promise.all(
    details.map(async (line) => {
      if (!line.item) return null;
      const { data: item } = await getEntity("Item", line.item.value);
      if (!item) return null;
      const itemRef = item.qbo;

      const taxCode = taxCodes.find((t) => t._id === line.taxCode.value);
      if (!taxCode) return null;
      const taxCodeRef = taxCode.qbo;
      let qboLine = await qboItemLineDetail(
        line,
        itemRef,
        taxCodeRef,
        classRef
      );
      return qboLine;
    })
  );

  return lines;
}

const qboItemLineDetail = async (
  item,
  itemRef,
  taxCodeRef,
  classRef = null
) => {
  const detail = {
    DetailType: "ItemBasedExpenseLineDetail",
    Id: item.lineId,
    ItemBasedExpenseLineDetail: {
      Qty: item.qty,
      UnitPrice: item.unitCost,
      ItemRef: {
        name: itemRef.Name,
        value: itemRef.Id,
      },
      TaxCodeRef: {
        value: taxCodeRef.Id,
      },
    },
    Amount: item.amount,
    Description: item.description,
  };
  if (classRef)
    detail.ItemBasedExpenseLineDetail.ClassRef = {
      value: classRef.Id,
    };
  return detail;
};
