import { DateTime } from "luxon";

export const mapInvoiceDetailToShipment = (txn) => {
  let records = [];
  let parts = [];

  const newRecord = {
    lineId: txn.ref.lineId,
    itemId: txn.item.value,
    inventory: { value: txn.inventory.value, label: txn.inventory.label },
    uom: txn.inventory.uom || "EACH",
    description: txn.inventory.description || "",
    qty: txn.qty,
  };
  records.push(newRecord);

  parts.push({
    lineId: txn.ref.lineId,
    item: txn.item,
    inventory: { value: txn.inventory.value, label: txn.inventory.label },
    qty: txn.qty,
  });

  // let txnDate = DateTime.fromISO(txn.txnDate).toISODate();
  let txnDate = txn.txnDate.substr(0, 10);
  const data = {
    txnDate: txnDate,
    currency: txn.ref.currency,
    details: parts,
    records: records,
    addressFrom: "Shipping",
    addressTo: "",
    locationFrom: txn.storage,
    customer: txn.ref.customer,
    memo: `Customer: ${txn.ref.customer.label} \nInvoice: ${txn.ref.txnNumber}  ${txnDate}`,
    refs: [
      {
        type: "Invoice",
        value: txn.ref.value,
        lineId: txn.ref.lineId,
        label: `${txn.ref.txnNumber}  ${txnDate} ${txn.ref.customer.label}`,
      },
    ],
    messages: [
      {
        timeStamp: DateTime.local().toISO(),
        user: { value: txn.user._id, label: txn.user.name },
        message: `Inventory Shipment created`,
      },
    ],
  };
  console.log(data);
  return {
    entity: "InventoryShipment",
    data: data,
  };
};
